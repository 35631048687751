import { Injectable } from '@angular/core';
import { IJob, IJobDependency, IUiClassName } from '@zipcrim/common';
import { Job } from '@zipcrim/common/models';
import uniq from 'lodash/uniq';

@Injectable({ providedIn: 'root' })
export class JobTreeService {
  /**
   * Check if a specific UI class name exists in the job collection.
   *
   * @param jobs All available jobs.
   * @param name UI class name to look for.
   */
  HasJob(jobs: IJob[], name: IUiClassName) {
    return jobs.some((job) => job.UiClassName === name);
  }

  /**
   * Check if a specific UI class name with incomplete status exists in the job collection.
   *
   * @param jobs All available jobs.
   * @param name UI class name to look for.
   * @param status Status to look for.
   */
  GetJobWithStatus(jobs: IJob[], name: IUiClassName, status: string) {
    return jobs.find(
      (job) => job.UiClassName === name && job.Status === status
    );
  }

  /**
   * Get all dependencies of a given job.
   *
   * @param jobs All available jobs.
   * @param root Starting job to get dependencies for.
   */
  GetJobDependencies(jobs: IJob[], root: IJob) {
    const output: Job[] = [];

    if (!jobs || !root) {
      return output;
    }

    const dependencies: IJobDependency[] = root.Dependencies || [];

    if (!dependencies.length) {
      return output;
    }

    dependencies.forEach((dependency) => {
      const source = jobs.find((item) => item.ID === dependency.JobID);

      if (source) {
        const job = new Job(source);
        const flatDependencies = this._GetDependenciesFlat(jobs, source);

        job.FlatDependencies = [];
        job.BlockingDependencies = this._GetBlockingJobs(jobs, source);

        flatDependencies.forEach((item) => {
          const dep = new Job(item);

          dep.BlockingDependencies = this._GetBlockingJobs(jobs, item);
          job.FlatDependencies.push(dep);
        });

        output.push(job);
      }
    });

    return output;
  }

  /**
   * Get a list of root level jobs. A root level job is a job that is not a dependencies of any other job.
   *
   * @param jobs All available jobs.
   */
  GetIndependentJobs(jobs: IJob[]) {
    const output: IJob[] = [];

    jobs.forEach((job) => {
      const hasDependencies = jobs.some((item) => {
        const dependencies: IJobDependency[] = item.Dependencies || [];
        return dependencies.some((dep) => dep.JobID === job.ID);
      });

      if (!hasDependencies) {
        output.push(job);
      }
    });

    return output;
  }

  /**
   * Get all direct blocking dependencies for a given job.
   *
   * @param jobs All available jobs.
   * @param root Job to get blocking jobs for.
   */
  private _GetBlockingJobs(jobs: IJob[], root: IJob) {
    const output: Job[] = [];

    if (!jobs || !root) {
      return output;
    }

    const dependencies: IJobDependency[] = root.Dependencies || [];

    dependencies.forEach((dependency) => {
      const match = jobs.find((item) => item.ID === dependency.JobID);

      if (match) {
        const job = new Job(match);

        if (job.Status !== 'Complete') {
          output.push(job);
        }
      }
    });

    return output;
  }

  /**
   * Recursively flatten all nested dependencies into a single list.
   *
   * @param jobs All available jobs.
   * @param root Starting job to get dependencies for.
   */
  private _GetDependenciesFlat(jobs: IJob[], root: IJob) {
    const dependencies: IJobDependency[] = root.Dependencies || [];
    const output: IJob[] = [];

    dependencies.forEach((dependency) => {
      const job = jobs.find((item) => item.ID === dependency.JobID);

      if (job) {
        output.push(job);

        const subDependencies = this._GetDependenciesFlat(jobs, job);
        output.push(...subDependencies);
      }
    });

    return uniq(output);
  }
}
