import {
  ContentChild,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { FilterSpecSortDirection } from '../api/filter-spec.enums';
import { L10nService } from '../l10n/l10n.service';
import { TableCellDirective } from './table-cell.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'zc-column',
})
export class TableColumnDirective implements OnChanges {
  constructor(private l10n: L10nService) {}

  @Input() header: string;
  @Input() headerL10nKey: string;
  headerText: string;
  @Input() sortProperty: string;
  sortDirection: FilterSpecSortDirection;

  get isSortable() {
    return !!this.sortProperty;
  }

  get sortIcon() {
    if (!this.sortDirection) {
      return 'sort';
    }

    return this.sortDirection === FilterSpecSortDirection.Desc
      ? 'sort-down'
      : 'sort-up';
  }

  @ContentChild(TableCellDirective, { read: TemplateRef })
  cellTemplate: TemplateRef<any>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.header || changes.headerL10nKey) {
      this.headerText = this.headerL10nKey
        ? this.l10n.instant(this.headerL10nKey)
        : this.header;
    }
  }
}
