import { IExternalAccess } from '../generated';

/**
 * Contact external access.
 */
export class ExternalAccess {
  constructor(source?: Partial<IExternalAccess>) {
    this._Init(source);
  }

  Username: string;
  LastLogin: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IExternalAccess>) {
    if (!source) {
      return;
    }

    this.Username = source.Username;
    this.LastLogin = source.LastLogin;
  }
}
