import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { CheckToHireSvcService } from '../common/check-to-hire-svc.service';
import { NextStep } from '../common/interfaces';

/**
 * Report service.
 */
@Injectable()
export class ReportService {
  constructor(private _CheckToHireSvc: CheckToHireSvcService) {}

  /**
   * Current case number.
   */
  CaseNum: string;

  /**
   * Current case id.
   */
  CaseId: number;

  /**
   * Indicates if any leads have report content (ready for download).
   */
  HasContent = new BehaviorSubject<boolean>(false);

  /**
   * Current work order.
   */
  get WorkOrderId() {
    return this._NextStep?.ID;
  }

  /**
   * Current subject id.
   */
  get SubjectId() {
    return this._NextStep?.SubjectID;
  }

  /**
   * Current case type.
   */
  get CaseType() {
    return this._NextStep?.CaseType;
  }

  /**
   * Next step title.
   */
  get NextStepTitle() {
    return this._NextStepTitle;
  }

  /**
   * Safely get next step ana groups, if available.
   */
  get NextStepAnaGroups() {
    return this._NextStep?.AnaGroups?.Items;
  }

  /**
   * All available lead names.
   */
  readonly LeadNames = ['MVR', 'CDLIS', 'CRIM', 'DOTPSP', 'DIQPRE'];

  /**
   * Next step.
   */
  private _NextStep: NextStep | null = null;

  /**
   * Calculated next step title.
   */
  private _NextStepTitle: string;

  /**
   * Set next step and associated items.
   */
  SetNextStep(nextStep: NextStep) {
    if (!nextStep) {
      this._NextStep = null;
      this._NextStepTitle = '';
      return;
    }

    this._NextStep = nextStep;

    const leads = nextStep.AnaGroups.Items.filter(
      (item) => item.IsSelected
    ).map((lead) => lead.LeadClass);

    this._NextStepTitle = [
      leads.slice(0, -1).join(', '),
      leads.slice(-1)[0],
    ].join(leads.length < 2 ? '' : ' & ');
  }

  /**
   * Get stage options.
   */
  GetStageOptions() {
    return this._CheckToHireSvc.GetCaseTypes().pipe(
      map((res) =>
        res.Items.filter((item) => !!item.AnaGroups.Items).map((item) => {
          const codes = item.AnaGroups.Items.map((x) => x.Code);
          const label =
            item.Blurb +
            ' (' +
            [codes.slice(0, -1).join(', '), codes.slice(-1)[0]].join(
              codes.length < 2 ? '' : ' & '
            ) +
            ')';

          return {
            label,
            value: item.Code,
            ...item,
          };
        })
      )
    );
  }
}
