<h1 class="header-1 text-center">
  <span translate [translateParams]="{ name: UserName }">
    CheckToHire.lblCompanyInfoMsg1
  </span>
  <br />
  {{ 'CheckToHire.lblCompanyInfoMsg2' | translate }}
</h1>

<div class="row">
  <div class="col-sm-8 col-sm-offset-2">
    <div class="home-form">
      <div class="mb-3">
        <h2 class="text-green header-2 text-center">
          {{ 'CheckToHire.lblPennsylvaniaDocument' | translate }}
        </h2>
        <p>{{ 'CheckToHire.msgPennsylvaniaDocument' | translate }}</p>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <span class="text-green header-2"
            >{{ 'CheckToHire.msgRecommended' | translate }}:</span
          >
          <h2 class="text--black header-2">
            {{ 'CheckToHire.lblPennsylvaniaRecommended' | translate }}
          </h2>
          <p>{{ 'CheckToHire.msgPennsylvaniaRecommended' | translate }}</p>
          <a href="pdf/PA-MVR.pdf" download> 
            <fa-icon icon="download"></fa-icon>
            {{ 'Common.cmdDownload' | translate }}
          </a>
        </div>
        <div class="col-sm-6">
          <h2 class="text--black header-2">
            {{ 'CheckToHire.lblPennsylvaniaIndividual' | translate }}
          </h2>
          <p>{{ 'CheckToHire.msgPennsylvaniaIndividual' | translate }}</p>
        </div>
      </div>
      <div class="text-center">
        <zc-button color="primary" (click)="Continue()">
          {{ 'Common.cmdContinue' | translate }}
        </zc-button>
      </div>
    </div>
  </div>
</div>
