import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AddressService,
  AuthService,
  CanDeactivateComponent,
  CaseService,
  IDob,
  IUiMeta,
  IUiMetaSection,
  SaveSpec,
  WorkOrderService,
} from '@zipcrim/common';
import { OptionConfig, OptionsService } from '@zipcrim/forms';
import {
  AddressValidator,
  AgeValidator,
  CityValidator,
  DriverLicenseValidator,
  NameValidator,
  ZipCodeValidator,
} from '@zipcrim/forms/validators';
import { getDate, getMonth, getYear, parse } from 'date-fns';
import { of } from 'rxjs';
import { finalize, map, tap, mergeMap } from 'rxjs/operators';

import { CheckToHireSvcService } from '../../common/check-to-hire-svc.service';

//import { DynamicNode } from '@forms\dynamic-node'
//import { IterableNode, NodeData } from '@forms/dynamic-node/dynamic-node.interfaces';
/**
 * Stage 1 component.
 */
@Component({
  selector: 'c2h-stage1',
  templateUrl: './stage1.component.html',
})
export class Stage1Component extends CanDeactivateComponent implements OnInit {
  constructor(
    private _Address: AddressService,
    private _AuthService: AuthService,
    private _Case: CaseService,
    private _CheckToHireSvc: CheckToHireSvcService,
    private _Options: OptionsService,
    private _Router: Router,
    private _WorkOrder: WorkOrderService
  ) {
    super();
  }

  // todo: replace with reactive form
  Config: any;

  /**
   * Is end user.
   */
  IsEndUser = false;

  /**
   * Busy loading indicator.
   */
  BusyLoading: boolean;

  /**
   * Busy saving indicator.
   */
  BusySaving: boolean;

  /**
   * Currently selected client code.
   */
  ClientCode: string;

  /**
   * Client code options.
   */
  ClientCodes: OptionConfig[] = [];

  @ViewChild('form')
  Form: NgForm;

  /**
   * On init.
   */
  ngOnInit() {
    this.BusyLoading = true;
    this._GetClientCodes()
      .pipe(
        tap((res) => {
          this.ClientCodes = res;
          this.ClientCode = this.ClientCodes[0].value;
        }),
        mergeMap(() => this._GetSpecAndBuildForm(this.ClientCode)),
        finalize(() => (this.BusyLoading = false))
      )
      .subscribe();
  }

  /**
   * On client change.
   */
  OnClientChange() {
    this.Config = null;
    this.IsEndUser = false;

    this.BusyLoading = true;
    this._GetSpecAndBuildForm(this.ClientCode)
      .pipe(finalize(() => (this.BusyLoading = false)))
      .subscribe();
  }

  canDeactivate() {
    return !this.Form?.dirty;
  }

  /**
   * On submit.
   */
  OnSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const value = form.value;
    let workOrderId: number;
    let leadNum: string[];
    let caseNum: string;
    let dob: Partial<IDob>;

    if (value.Dob) {
      const date = parse(value.Dob, 'MM/dd/yyyy', new Date());
      dob = {
        Month: getMonth(date) + 1,
        Day: getDate(date),
        Year: getYear(date),
      };
    }

    const spec = new SaveSpec(
      'WorkOrder',
      {
        ClientCode: this.ClientCode,
        CaseType: value.CaseType,
        EndUser: value.EndUser || undefined,
        Subject: {
          Names: value.Name ? [value.Name] : undefined,
          Mvrs: value.Mvr ? [value.Mvr] : undefined,
          Ssns: value.Ssn ? [value.Ssn] : undefined,
          Dobs: dob ? [dob] : undefined,
          Addresses: value.Address ? [value.Address] : undefined,
          Email: value.Email || undefined,
        },
      },
      true
    );

    this.BusySaving = true;
    (value.address ? this._Address.validate(value.address) : of(true))
      .pipe(
        mergeMap(() => this._WorkOrder.save(0, spec)),
        tap((res) => (workOrderId = res.ID)),
        mergeMap(() => this._WorkOrder.analyze(workOrderId)),
        mergeMap(() => this._WorkOrder.process(workOrderId)),
        tap((res) => (caseNum = res.CaseNum)),
        mergeMap(() => this._CheckToHireSvc.GetCaseSummary(caseNum)),
        tap((res) => (leadNum = res[0].LeadNum)),
        // todo (jbl): can remove extra api call if VFP can add CaseId to summary
        mergeMap(() => this._Case.getByNum(caseNum)),
        finalize(() => (this.BusyLoading = false))
      )
      .subscribe((res) => {
        this._Router.navigate(['reports', res.ID, caseNum, 'show-report'], {
          queryParams: {
            leadNum,
          },
        });
      });
  }

  /**
   * Get available client code options.
   */
  private _GetClientCodes() {
    return this._AuthService.getDomainsByRight('Case', 'Add').pipe(
      map((res) =>
        res.map((item) => ({
          label: `${item.Name} (${item.ClientCode})`,
          value: item.ClientCode,
        }))
      )
    );
  }

  /**
   * Get c;client create spec and build form.
   *
   * @param clientCode Client code.
   */
  private _GetSpecAndBuildForm(clientCode: string) {
    return this._CheckToHireSvc.GetCreateSpec(clientCode).pipe(
      tap((res) => {
        this.IsEndUser = !!res.UiMeta.Sections.EndUser;
        this._BuildConfig(res.CaseType, res.UiMeta);
      })
    );
  }

  /**
   * Get required or null validator.
   */
  private _RequiredOrNull(isRequired: boolean) {
    return isRequired ? Validators.required : Validators.nullValidator;
  }

  /**
   * Build form configuration.
   */
  private _BuildConfig(caseType: string, meta: IUiMeta) {
    const sections = meta.Sections;
    const sectionMap: Array<[IUiMetaSection, (meta: IUiMetaSection) => any]> = [
      [sections.SubjectName, this._NameConfig],
      [sections.SubjectDOB, this._DobConfig],
      [sections.SubjectSSN, this._SsnConfig],
      [sections.SubjectMVLicense, this._MvrConfig],
      [sections.SubjectEmail, this._EmailConfig],
      [sections.SubjectAddress, this._AddressConfig],
      [sections.EndUser, this._EndUserConfig],
      [sections.AuthConfirmation, this._AuthorizationConfig],
    ];

    this.Config = {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'container',
          nodes: [
            {
              nodeType: 'hidden',
              name: 'CaseType',
              value: caseType,
            },
          ],
        },
        ...sectionMap
          .filter((item) => !!item[0])
          .sort((a, b) => a[0].SortOrder - b[0].SortOrder)
          .map((item) => item[1].call(this, item[0])),
      ],
    };
  }

  /**
   * Get name configuration.
   */
  private _NameConfig(meta: IUiMetaSection) {
    if (!meta) {
      return null;
    }

    return {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'input',
          name: 'Name.FirstName',
          labelL10nKey: 'Common.lblFirstName',
          placeholderL10nKey: 'Common.lblFirstName',
          validators: [this._RequiredOrNull(meta?.IsRequired), NameValidator],
        },
        {
          nodeType: 'input',
          name: 'Name.MiddleName',
          labelL10nKey: 'Common.lblMiddleName',
          placeholderL10nKey: 'Common.lblMiddleName',
          validators: [
            {
              ignorable: true,
              fn: Validators.required,
            },
            NameValidator,
          ],
        },
        {
          nodeType: 'input',
          name: 'Name.LastName',
          labelL10nKey: 'Common.lblLastName',
          placeholderL10nKey: 'Common.lblLastName',
          validators: [this._RequiredOrNull(meta?.IsRequired), NameValidator],
        },
      ],
    };
  }

  /**
   * Get date of birth configuration.
   */
  private _DobConfig(meta: IUiMetaSection) {
    if (!meta) {
      return null;
    }

    return {
      nodeType: 'container',
      nodes: [
        {
          name: 'Dob',
          nodeType: 'input',
          labelL10nKey: 'Common.lblDob',
          placeholderL10nKey: 'Common.lblDob',
          validators: [
            this._RequiredOrNull(meta?.IsRequired),
            AgeValidator({ min: 18, max: 100 }),
          ],
        },
      ],
    };
  }

  /**
   * Get ssn configuration.
   */
  private _SsnConfig(meta: IUiMetaSection) {
    if (!meta) {
      return null;
    }

    return {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'radio-group',
          name: 'Ssn.IDType',
          value: 'US',
          labelL10nKey: 'CheckToHire.lblGovIdCountry',
          optionsAsync: this._Options.getNationalIDs().pipe(
            map((res) => {
              const items = res.filter(
                (item) => item.value === 'US' || item.value === 'SIN'
              );

              // override labels for shorter values
              items.forEach((item) => (item.label = item.CountryName));

              return items;
            })
          ),
          validators: [this._RequiredOrNull(meta?.IsRequired)],
        },
        {
          name: 'Ssn.SSN',
          nodeType: 'input',
          labelL10nKey: 'Common.lblNationalId',
          placeholderL10nKey: 'Common.lblNationalId',
          validators: [this._RequiredOrNull(meta?.IsRequired)],
          listen: {
            field: 'Ssn.IDType',
            onChange: (_value: any) => ({
              value: null as any,
              validators: [this._RequiredOrNull(meta?.IsRequired)],
            }),
          },
        },
      ],
    };
  }

  /**
   * Get mvr configuration.
   */
  private _MvrConfig(meta: IUiMetaSection) {
    if (!meta) {
      return null;
    }

    return {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'radio-group',
          name: 'Mvr.CountryCode',
          value: 'US',
          labelL10nKey: 'CheckToHire.lblLicenseCountry',
          options: [
            {
              labelL10nKey: 'CheckToHire.lblUSA',
              value: 'US',
            },
            {
              labelL10nKey: 'CheckToHire.lblCAN',
              value: 'CA',
            },
          ],
          validators: [this._RequiredOrNull(meta?.IsRequired)],
        },
        {
          nodeType: 'select',
          name: 'Mvr.LicenseState',
          labelL10nKey: 'CheckToHire.lblLicenseStateProvince',
          placeholderL10nKey: 'Common.lblPleaseSelect',
          optionsAsync: this._Options.getRegions('US'),
          validators: [this._RequiredOrNull(meta?.IsRequired)],
          listen: {
            field: 'Mvr.CountryCode',
            onChange: (value: string) => ({
              optionsAsync: this._Options.getRegions(value),
            }),
          },
        },
        {
          nodeType: 'input',
          name: 'Mvr.LicenseNumber',
          labelL10nKey: 'CheckToHire.lblDriverLicense',
          placeholderL10nKey: 'CheckToHire.lblDriverLicense',
          validators: [this._RequiredOrNull(meta?.IsRequired)],
          listen: (() => {
            let country: string;
            let state: string;

            return [
              {
                field: 'Mvr.CountryCode',
                onChange: (value: string) => {
                  const validators = [this._RequiredOrNull(meta?.IsRequired)];
                  country = value;

                  if (country && state) {
                    validators.push(DriverLicenseValidator(country, state));
                  }

                  return { validators };
                },
              },
              {
                field: 'Mvr.LicenseState',
                onChange: (value: string) => {
                  const validators = [this._RequiredOrNull(meta?.IsRequired)];
                  state = value;

                  if (country && state) {
                    validators.push(DriverLicenseValidator(country, state));
                  }

                  return { validators };
                },
              },
            ];
          })(),
        },
      ],
    };
  }

  /**
   * Get email configuration.
   */
  private _EmailConfig(meta: IUiMetaSection) {
    if (!meta) {
      return null;
    }

    return {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'input',
          name: 'Email.Value',
          labelL10nKey: 'Common.lblDriversEmail',
          placeholderL10nKey: 'Common.lblDriversEmail',
          validators: [
            {
              ignorable: true,
              fn: Validators.required,
            },
            Validators.email,
          ],
        },
      ],
    };
  }

  /**
   * Get address configuration.
   */
  private _AddressConfig(meta: IUiMetaSection) {
    if (!meta) {
      return null;
    }

    return {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'input',
          name: 'Address.StreetName',
          labelL10nKey: 'Common.lblAddressStreet',
          placeholderL10nKey: 'Common.lblAddressStreet',
          validators: [
            this._RequiredOrNull(meta?.IsRequired),
            AddressValidator,
          ],
        },
        {
          nodeType: 'input',
          name: 'Address.Address2',
          labelL10nKey: 'Common.lblAddressApt',
          placeholderL10nKey: 'Common.lblAddressApt',
          validators: [AddressValidator],
        },
        {
          nodeType: 'input',
          name: 'Address.City',
          labelL10nKey: 'Common.lblCity',
          placeholderL10nKey: 'Common.lblCity',
          validators: [this._RequiredOrNull(meta?.IsRequired), CityValidator],
        },
        {
          nodeType: 'hidden',
          name: 'Address.State',
        },
        {
          nodeType: 'select',
          name: 'Address.StateList',
          mapTo: 'State',
          labelL10nKey: 'Common.lblAddressState',
          placeholderL10nKey: 'Common.lblPleaseSelect',
          optionsAsync: this._Options.getRegions('US'),
          validators: [this._RequiredOrNull(meta?.IsRequired)],
          listen: {
            field: 'Address.CountryCode',
            onChange: (value: string) => ({
              isHidden: !(value === 'US' || value === 'CA'),
              optionsAsync: this._Options.getRegions(value),
            }),
          },
        },
        {
          nodeType: 'input',
          name: 'Address.StateInput',
          mapTo: 'State',
          labelL10nKey: 'Common.lblAddressState',
          placeholderL10nKey: 'Common.lblAddressState',
          validators: [this._RequiredOrNull(meta?.IsRequired)],
          listen: {
            field: 'Address.CountryCode',
            onChange: (value: string) => ({
              isHidden: value === 'US' || value === 'CA',
            }),
          },
        },
        {
          nodeType: 'input',
          name: 'Address.ZipCode',
          labelL10nKey: 'Common.lblAddressZip',
          placeholderL10nKey: 'Common.lblAddressZip',
          validators: [
            this._RequiredOrNull(meta?.IsRequired),
            ZipCodeValidator(null),
          ],
          listen: {
            field: 'Address.CountryCode',
            onChange: (value: string) => ({
              validators: [
                this._RequiredOrNull(meta?.IsRequired),
                ZipCodeValidator(value),
              ],
            }),
          },
        },
        {
          nodeType: 'select',
          name: 'Address.CountryCode',
          value: 'US',
          labelL10nKey: 'Common.lblAddressCountry',
          optionsAsync: this._Options.getCountries(['US', 'CA']),
          validators: [this._RequiredOrNull(meta?.IsRequired)],
        },
      ],
    };
  }

  /**
   * Get end user configuration.
   */
  private _EndUserConfig(meta: IUiMetaSection) {
    if (!meta) {
      return null;
    }

    return {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'input',
          name: 'EndUser.Name',
          labelL10nKey: 'CheckToHire.thirdPartyResellerFieldLbl',
          validators: [
            this._RequiredOrNull(meta?.IsRequired),
            Validators.maxLength(100),
            Validators.pattern(/(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 -.@&$]*)?$/),
          ],
        },
      ],
    };
  }

  /**
   * Get authorization configuration.
   */
  private _AuthorizationConfig(meta: IUiMetaSection) {
    if (!meta) {
      return null;
    }

    return {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'checkbox',
          checkboxLabelL10nKey: 'CheckToHire.lblAuthorizedApplicant',
          name: 'Authorized',
          validators: [this._RequiredOrNull(meta?.IsRequired)],
        },
      ],
    };
  }
}
