import { IDomain, IDomainRole } from '../generated';
import { DomainRole } from './domain-role';

/**
 * Domain.
 */
export class Domain {
  constructor(source?: Partial<IDomain>) {
    this._Init(source);
  }

  ID: number;
  ClientID: number;
  Name: string;
  Roles: DomainRole[];
  ClientCode?: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IDomain>) {
    if (!source) {
      this.Roles = [];
      return;
    }

    this.ID = source.ID;
    this.ClientID = source.ClientID;
    this.Name = source.Name;

    const roles: IDomainRole[] | void = source.Roles?.Items;

    this.Roles = roles ? roles.map((item) => new DomainRole(item)) : [];

    this.ClientCode = source.ClientCode;
  }
}
