import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import isArray from 'lodash/isArray';

import { AccessRight } from './auth.interfaces';
import { AuthService } from './auth.service';

/**
 * Route guard to restrict access based on the current user's rights.
 *
 * ```js
 * {
 *   path: 'top-secret',
 *   component: TopSecretComponent,
 *   canActivate: [HasRightsGuard],
 *   data: {
 *     hasRights: 'Contact'
 *     // ... or ...
 *     // hasRights: ['Contact', 'Add']
 *   }
 * }
 * ```
 */
@Injectable({ providedIn: 'root' })
export class HasRightsGuard implements CanActivate {
  constructor(private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const rights = route.data.hasRights;

    if (!rights) {
      return true;
    }

    let objectClass: string;
    let right: AccessRight;

    if (!isArray(rights)) {
      // string value of object name
      objectClass = rights;
    } else {
      // array with object name and access right
      [objectClass, right] = rights;
    }

    return this.auth.hasRight(objectClass, right);
  }
}
