import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import {
  FuzzyDateParts,
  FuzzyDateService,
  FuzzyDateStaticCode,
  L10nService,
} from '@zipcrim/common';
import { ButtonComponent } from '@zipcrim/common/button/button.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { FormService } from '@zipcrim/forms/form.service';
import { DynamicFuzzyDateModalComponent } from './fuzzy-date-modal.component';

/**
 * Fuzzy date.
 *
 * ```html
 * <zc-fuzzy-date [(ngModel)]="myDate"></zc-fuzzy-date>
 * ```
 */
@Component({
  selector: 'zc-dynamic-fuzzy-date',
  templateUrl: './fuzzy-date.component.html',
  styleUrls: ['./fuzzy-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFuzzyDateComponent),
      multi: true,
    },
  ],
})
export class DynamicFuzzyDateComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  constructor(
    private _BsModalService: BsModalService,
    private _FormService: FormService,
    private _FuzzyDate: FuzzyDateService,
    private _Injector: Injector,
    private _L10n: L10nService
  ) {}

  /**
   * Indicates if the input is disabled.
   */
  @Input()
  Disabled = false;

  /**
   * Indicates if the input is readonly.
   */
  @Input()
  Readonly = false;

  /**
   * Modal input label.
   */
  @Input()
  Label: string;

  /**
   * Allowed static codes.
   */
  @Input()
  AllowedStaticCodes: FuzzyDateStaticCode[];

  /**
   * Min date allowed.
   */
  @Input()
  Min: Date;

  /**
   * Max date allowed.
   */
  @Input()
  Max: Date;

  /**
   * Button reference.
   */
  @ViewChild('btn', { static: false })
  ButtonRef: ButtonComponent;

  /**
   * Translated display value.
   */
  Translation: string;

  /**
   * Translation information.
   */
  Parts: FuzzyDateParts;

  /**
   * Indicates if the async options is busy.
   */
  IsLoading: boolean;

  /**
   * Indicates if the async options failed.
   */
  IsFailure: boolean;

  /**
   * Input value getter / setter.
   */
  get Value() {
    return this._Value;
  }

  set Value(value) {
    this._Value = value;
    this.onChange(value);

    if (!value) {
      // clear display value
      this.Translation = null;
    }
  }

  /**
   * Input value.
   */
  private _Value: any;

  /**
   * Form control reference.
   */
  private _Control: FormControl;

  /**
   * Translate subscription.
   */
  private _TranslateSubscription: Subscription;

  /**
   * After view init.
   */
  ngAfterViewInit() {
    const ngControl = this._Injector.get(NgControl, null);
    this._Control = ngControl?.control as FormControl;
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    if (this._TranslateSubscription) {
      this._TranslateSubscription.unsubscribe();
    }
  }

  /**
   * On retry options.
   */
  OnRetry() {
    this.IsFailure = false;
    this._GetTranslation();
  }

  /**
   * On open modal.
   */
  OnOpenModal() {
    const modal = this._BsModalService.show(DynamicFuzzyDateModalComponent, {
      class: 'modal-sm',
    });
    const component = modal.content as DynamicFuzzyDateModalComponent;

    component.Label = this.Label;
    component.InitialParts = this.Parts;
    component.Options = {
      allowedStaticCodes: this.AllowedStaticCodes,
      required: this._FormService.hasRequired(this._Control),
      min: this.Min,
      max: this.Max,
    };
    component.OnChanges.next();
    component.result.then((res) => {
      this.Value = res.Value;
      this.Translation = res.Translation;
      this.Parts = res.Parts;
      this.onTouched();
      this.ButtonRef?.focus();
    }).catch(() => {
      this.onTouched();
      this.ButtonRef?.focus();
    });
  }

  /**
   * On change.
   */
  onChange = (_: any) => {
    // empty
  };

  /**
   * On touched.
   */
  onTouched = () => {
    // empty
  };

  /**
   * Write value.
   */
  writeValue(value: any) {
    if (this._FormService.HasValue(value)) {
      const hasChanged = this.Value !== value;

      this.Value = value;

      if (hasChanged) {
        this._GetTranslation();
      }
    }
  }

  /**
   * Register on change.
   */
  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  /**
   * Register on touched.
   */
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  /**
   * Set disabled state.
   */
  setDisabledState(isDisabled: boolean) {
    this.Disabled = isDisabled;
  }

  /**
   * Get translation of existing value for display.
   */
  private _GetTranslation() {
    if (!this.Value) {
      // no translation needed
      return;
    }

    // reset

    this.IsLoading = true;

    this._TranslateSubscription = this._FuzzyDate
      .translate({
        fuzzyDateString: this.Value,
        fromLanguageCode: this._FuzzyDate.apiLocale,
        toLanguageCode: this._L10n.currentLocale,
        allowedStaticCodes: this.AllowedStaticCodes,
      })
      .pipe(
        catchError((error) => {
          this.IsFailure = true;
          throw error;
        }),
        finalize(() => (this.IsLoading = false))
      )
      .subscribe((res) => {
        this.Translation = res.Translation;
        this.Parts = res.Parts;
      });
  }
}
