import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { ApiResult } from './api-result';
import { FilePayload } from './api.interfaces';
import { ApiService } from './api.service';

/**
 * File service.
 */
@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(private api: ApiService) {}

  /**
   * Upload file.
   *
   * @param url Endpoint url.
   * @param body File data.
   * @param params Http params.
   */
  upload<T>(url: string, body: any, params?: HttpParams) {
    const headers = new HttpHeaders().set('enctype', 'multipart/form-data');

    return this.api.request<T>('POST', url, {
      headers,
      body,
      params,
    });
  }

  /**
   * Download file.
   *
   * @param url Endpoint url.
   * @param params Http params.
   * @param save True if the user should be prompted to save file.
   */
  download(url: string, params?: HttpParams, save = false) {
    return this.api
      .request<FilePayload>('GET', url, {
        responseType: 'blob',
        body: new Blob([]),
        params,
      })
      .pipe(flatMap((res) => (save ? this.saveFile(res) : of(res))));
  }

  /**
   * Save file response.
   *
   * @param response Parsed http response.
   */
  private saveFile(response: ApiResult<FilePayload>) {
    const data = response.payload.Data;
    const name = response.payload.Name;

    FileSaver.saveAs(data, name);

    return of(response);
  }
}
