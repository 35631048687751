import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { L10nService } from '@zipcrim/common';

import { DynamicFormFieldComponent } from '../form-field/form-field.component';
import { OptionConfig } from '@zipcrim/forms/forms.interfaces';
import { DatePartsConfig, DatePartsOrder } from './date-parts.interfaces';

/**
 * Form input for a date object with separate fields for year, month, and day.
 *
 * ```html
 * <zc-dynamic-date-parts formGroupName="myDate"></zc-dynamic-date-parts>
 * ```
 */
@Component({
  selector: 'zc-dynamic-date-parts',
  templateUrl: './date-parts.component.html',
  styleUrls: ['./date-parts.component.scss'],
})
export class DynamicDatePartsComponent implements OnInit {
  constructor(
    private _L10n: L10nService,
    @Optional() private _ControlContainer: ControlContainer,
    @Optional() private _FormField: DynamicFormFieldComponent
  ) {}

  /**
   * Parent form group reference.
   */
  @Input()
  FormGroup: FormGroup;

  /**
   * Input id.
   */
  @Input()
  Id = '';

  /**
   * Indicated if the input is readonly.
   */
  @Input()
  Readonly = false;

  ////////////////////////////////////
  // TODO: EJA: Does this do anything?
  @Input()
  zcDisableCopyPaste: boolean;

  @Input()
  zcDisableScrolling: boolean;
  ////////////////////////////////////

  /**
   * Input configuration.
   */
  @Input()
  set Parts(value: DatePartsConfig) {
    if (value) {
      Object.assign(this.Inputs, value);
    }
  }

  /**
   * Month options.
   */
  MonthOptions: OptionConfig[] = [
    { value: 1, labelL10nKey: 'Common.lblMonthJan' },
    { value: 2, labelL10nKey: 'Common.lblMonthFeb' },
    { value: 3, labelL10nKey: 'Common.lblMonthMar' },
    { value: 4, labelL10nKey: 'Common.lblMonthApr' },
    { value: 5, labelL10nKey: 'Common.lblMonthMay' },
    { value: 6, labelL10nKey: 'Common.lblMonthJun' },
    { value: 7, labelL10nKey: 'Common.lblMonthJul' },
    { value: 8, labelL10nKey: 'Common.lblMonthAug' },
    { value: 9, labelL10nKey: 'Common.lblMonthSep' },
    { value: 10, labelL10nKey: 'Common.lblMonthOct' },
    { value: 11, labelL10nKey: 'Common.lblMonthNov' },
    { value: 12, labelL10nKey: 'Common.lblMonthDec' },
  ];

  /**
   * Item order.
   *
   * Note: The DOM elements need to be physically positioned in the correct order to prevent
   * issues with the <tab> key on form inputs. Flexbox ordering is not respected by the <tab> key.
   */
  Order: DatePartsOrder = 'year-month-day';

  /**
   * Input configuration.
   */
  Inputs: DatePartsConfig = {
    year: true,
    month: true,
    day: true,
  };

  /**
   * On init.
   */
  ngOnInit() {
    if (!this.FormGroup && this._ControlContainer) {
      this.FormGroup = this._ControlContainer.control as FormGroup;
    }

    if (!this.Id && this._FormField) {
      this.Id = this._FormField.Id;
    }

    this._SetItemOrder();
  }

  /**
   * Set the item order based on locale.
   */
  private _SetItemOrder() {
    const locale = this._L10n.currentLocale;
    const order = this._L10n.getDatePartsOrder(locale);

    this.Order = order.join('-') as DatePartsOrder;
  }
}
