import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, concatMap, map, share, tap } from 'rxjs/operators';
import { okResponseStatuses, ResultStatus } from '..';
import { OAuthService } from '../auth/oauth.service';

import {
  FeatureFlagService,
  L10nService,
  NotificationService,
  SessionService,
} from '@zipcrim/common';
import { ApiResult } from './api-result';
import {
  RequestConfig,
  RequestHeader,
  RequestOptions,
  SessionPostData,
} from './api.interfaces';

/**
 * API service.
 */
@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
    private l10n: L10nService,
    private notification: NotificationService,
    private session: SessionService,
    private oauthService: OAuthService,
    private featureFlag: FeatureFlagService
  ) {
    this.hydrateTokensFromCache();
  }

  /**
   * Current session token.
   */
  private zSessionToken: string;

  /**
   * Current extender token.
   */
  private zSessionExtenderToken: string;

  /**
   * Matches extensions from file names.
   */
  private fileExtension = /(?:\.([^.]+))?$/;

  /**
   * Cached refresh token observable.
   */
  private refreshToken$: Observable<ApiResult<string>>;

  /**
   * Cached refresh OAuth token observable.
   */
  private refreshOAuthToken$: Observable<void>;

  /**
   * Session storage key.
   */
  private readonly tokenCacheKey = 'zToken';

  /**
   * Get request.
   *
   * @param url Endpoint url.
   * @param params Http params.
   */
  get<T>(url: string, params?: HttpParams) {
    return this.request<T>('GET', url, {
      params,
    });
  }

  /**
   * Post request.
   *
   * @param url Endpoint url.
   * @param body Post data.
   * @param params Http params.
   */
  post<T>(url: string, body: any, params?: HttpParams) {
    return this.request<T>('POST', url, {
      body,
      params,
    });
  }

  /**
   * Delete request.
   *
   * @param url Endpoint url.
   * @param params Http params.
   */
  delete<T>(url: string, params?: HttpParams) {
    return this.request<T>('DELETE', url, {
      params,
    });
  }

  /**
   * Convert session.
   *
   * @param data Session data.
   */
  convertSession(data: SessionPostData) {
    this.session.data.PostData.NewSessionGuidString = data.SessionGuid;
    Object.assign(this.session.data.PostData, data);

    return this.refreshToken().pipe(
      tap(() => {
        this.session.data.PostData.NewSessionGuidString = null;
        this.session.data.PostData.UserName = null;
      })
    );
  }

  /**
   * Manually set token values.
   *
   * @param token Session token.
   * @param zs Extender token.
   */
  setTokens(token: string, zs: string) {
    this.zSessionToken = token;
    this.zSessionExtenderToken = zs;
    this.setTokenCache();
  }

  /**
   * Clear token values.
   */
  clearTokens() {
    this.zSessionToken = null;
    this.zSessionExtenderToken = null;
    this.clearTokenCache();
  }

  /**
   * General http request.
   *
   * @param method Http method.
   * @param url Endpoint url.
   * @param options Http options.
   */
  request<T>(
    method: string,
    url: string,
    options: RequestOptions = {}
  ): Observable<ApiResult<T>> {
    const { finalUrl, finalOptions } = this._hydrateRequest(url, options);

    if (this._shouldRefreshToken(finalOptions)) {
      if (this.featureFlag.isOn('oauth-migration-107055')) {
        return this.refreshOAuthToken().pipe(
          concatMap(() => this.request<T>(method, url, options))
        );
      }

      // refresh token and retry original request
      return this.refreshToken().pipe(
        concatMap(() => this.request<T>(method, url, options))
      );
    }

    return this.http
      .request(method, finalUrl, {
        observe: 'response',
        body: finalOptions.body,
        params: finalOptions.params,
        headers: finalOptions.headers,
        responseType: finalOptions.responseType,
      })
      .pipe(
        map((res) => {
          if (res.status && res.status === 401) {
            if (this._hasTokenExpired(res)) {
              return this.refreshToken().pipe(
                concatMap(() => this.request<T>(method, url, options))
              );
            }
          }
          return res;
        }),
        map((res) => this.handleResponse<T>(res)),
        catchError((error) =>
          this.handleError<T>(error, {
            method,
            url: finalUrl,
            options: finalOptions,
          })
        )
      );
  }

  /**
   * HuB API http request.
   *
   * @param method Http method.
   * @param url Endpoint url.
   * @param options Http options.
   */
  hubRequest<T>(
    method: string,
    url: string,
    options: RequestOptions = {}
  ): Observable<ApiResult<T>> {
    options.isHubRequest = true;

    const { finalUrl, finalOptions } = this._hydrateRequest(url, options);

    return this.http
      .request(method, finalUrl, {
        observe: 'response',
        body: finalOptions.body,
        params: finalOptions.params,
        headers: finalOptions.headers,
        responseType: finalOptions.responseType,
      })
      .pipe(
        map((res) => {
          if (res.status && res.status === 401) {
            if (this._hasTokenExpired(res)) {
              return this.refreshToken().pipe(
                concatMap(() => this.request<T>(method, url, options))
              );
            }
          }
          return res;
        }),
        map((res) => this.handleResponse<T>(res, true)),
        catchError((error) =>
          this.handleError<T>(error, {
            method,
            url: finalUrl,
            options: finalOptions,
          })
        )
      );
  }

  /**
   * Generate value for "ZH" header.
   */
  private getRequestHeaderValue() {
    const value: RequestHeader = {
      LC: this.l10n.currentLocale,
    };

    if (
      this.hasSessionTokens &&
      !this.featureFlag.isOn('oauth-migration-107055')
    ) {
      value.T = { ZS: `${this.zSessionExtenderToken}-${this.zSessionToken}` };
    }

    return JSON.stringify(value);
  }

  /**
   * Handle http response.
   *
   * Note: For any failed requests, the server will return a `200` status code with a response indicating the failure.
   *
   * @param response Http response.
   * @param isHubRequest determines when the request comes from a HuB, optional
   */
  private handleResponse<T>(response: HttpResponse<any>, isHubRequest = false) {
    const contentType = response.headers.get('Content-Type');
    const isFile =
      contentType && contentType.indexOf('application/octet-stream') > -1;
    let body = response.body;

    if (!body) {
      throw new Error(this.l10n.instant('Common.msgNoApiResultFail'));
    }

    if (isFile) {
      const filename = response.headers.get('ZFileName');
      const type = this.fileExtension.exec(filename)[1];
      const blob = new Blob([response.body], { type });

      body = {
        Result: 'Success',
        Payload: {
          Name: filename,
          Data: blob,
        },
      };
    }

    // For consistency, it is better to cast the hub's response object in our ResponseResult object to make it more generic.
    if (isHubRequest) {
      let responseStatus: ResultStatus;

      if (body.status && okResponseStatuses.includes(body.status)) {
        responseStatus = 'Success';
      } else if (body.Status && body.Status === 'Ok') {
        responseStatus = 'Success';
      } else {
        responseStatus = 'Failure';

        // look for possible hub failure responses.
        if (body.ServiceDetails && body.ServiceDetails.ServiceDetails) {
          responseStatus = body.ServiceDetails.ServiceDetails;
        }
      }

      body = {
        Result: responseStatus,
        Payload: { ...body },
      };
    }

    const result = new ApiResult<T>(body);

    if (!this.featureFlag.isOn('oauth-migration-107055')) {
      const token = response.headers.get('ZS') || result.zs;

      if (token) {
        this.zSessionExtenderToken = token;
      }
    }

    if (result.isExpired || result.isFailure) {
      throw result;
    }

    return result;
  }

  /**
   * Handle http error.
   *
   * @param error Http response or error.
   * @param sourceConfig Original request configuration.
   */
  private handleError<T>(
    error: HttpErrorResponse,
    sourceConfig: RequestConfig
  ) {
    let result: ApiResult<T | void>;

    if (error instanceof ApiResult) {
      // failed or expired
      result = error;
    } else if (error.error instanceof ErrorEvent) {
      // client-side or network error occurred
      result = ApiResult.failure(error.error.message);
    } else {
      // server returned an unsuccessful response code
      result = new ApiResult(error.error);
    }

    if (!result) {
      result = ApiResult.failure(
        this.l10n.instant('Common.msgNoApiResultFail')
      );
    }

    if (result.isExpired && !sourceConfig.options.skipTokenRefresh) {
      const { method, url, options } = sourceConfig;

      if (this.featureFlag.isOn('oauth-migration-107055')) {
        return this.refreshOAuthToken().pipe(
          concatMap(() => this.request<T>(method, url, options))
        );
      }

      // refresh token and retry original request
      return this.refreshToken().pipe(
        concatMap(() => this.request<T>(method, url, options))
      );
    }

    if (result.result === 'Abort') {
      // eslint-disable-next-line no-console
      console.error(result.message);
      this.notification.add(this.l10n.instant('Common.msgNoApiResultFail'));
    } else if (result.isFailure || result.message) {
      this.notification.add(result.message);
    }

    return throwError(result);
  }

  /**
   * Refresh token.
   * @deprecated Use refreshOAuthToken eventually this should be deleted
   */
  private refreshToken() {
    const url = this.session.data.Url.Session;
    const body = this.session.data.PostData;
    this.clearTokens();

    if (!url) {
      return throwError('Could not refresh token!');
    }

    if (this.refreshToken$) {
      // refresh already in progress, share the observable
      return this.refreshToken$;
    }

    return (this.refreshToken$ = this.request<string>('POST', url, {
      body,
      skipTokenRefresh: true,
    }).pipe(
      tap((res) => {
        this.zSessionToken = res.payload;
        this.refreshToken$ = null;
        this.setTokenCache();
      }),
      catchError((error) => {
        // clear cache to allow for next refresh attempt
        this.refreshToken$ = null;
        return throwError(error);
      }),
      share()
    ));
  }

  private refreshOAuthToken() {
    if (this.refreshOAuthToken$) {
      return this.refreshOAuthToken$;
    }

    return (this.refreshOAuthToken$ = from(
      this.oauthService.refreshToken()
    )).pipe(
      tap(() => {
        this.refreshOAuthToken$ = null;
      }),
      catchError((error) => {
        this.refreshOAuthToken$ = null;
        return throwError(error);
      }),
      share()
    );
  }

  /**
   * Get session tokens from storage, if available.
   */
  private hydrateTokensFromCache() {
    const value = sessionStorage.getItem(this.tokenCacheKey);

    if (!value) {
      return;
    }

    const parts = value.split('-');

    if (parts.length !== 2) {
      // invalid value
      return;
    }

    this.zSessionExtenderToken = parts[0];
    this.zSessionToken = parts[1];
  }

  /**
   * Save tokens to storage.
   */
  private setTokenCache() {
    if (!this.hasSessionTokens) {
      return;
    }

    const value = `${this.zSessionExtenderToken}-${this.zSessionToken}`;
    sessionStorage.setItem(this.tokenCacheKey, value);
  }

  /**
   * Remove tokens from storage.
   */
  private clearTokenCache() {
    sessionStorage.removeItem(this.tokenCacheKey);
  }

  /**
   * Indicates if both session tokens are currently present.
   */
  private get hasSessionTokens() {
    return !!this.zSessionExtenderToken && !!this.zSessionToken;
  }

  /**
   * Hydrate request for hub and api request
   *
   * @param options Http options.
   */
  private _hydrateRequest(url: string, options: RequestOptions) {
    let finalUrl: string = url;
    let finalOptions: RequestOptions = options;

    if (!finalOptions.headers) {
      finalOptions.headers = new HttpHeaders();
    }

    const headerValue = this.getRequestHeaderValue();
    finalOptions.headers = options.headers.set('ZH', headerValue);

    // warn developers when not using params
    if (finalUrl.indexOf('?') !== -1) {
      console.warn(
        'DEPRECATED: Pass url parameters to api using options, do not manually append parameters to url!'
      );
    }

    // ensure absolute url
    if (
      finalUrl.indexOf('http://') === -1 &&
      finalUrl.indexOf('https://') === -1
    ) {
      // if is a hub request then we remove the last ws/ that points to old api endpoints
      if (finalOptions.isHubRequest) {
        finalUrl = this.session.data.Url.API.slice(0, -3) + finalUrl;
      } else {
        finalUrl = this.session.data.Url.API + finalUrl;
      }
    }

    return { finalUrl, finalOptions };
  }

  /**
   * Determines if the token should be refreshed
   *
   * @param options the request options
   * @returns boolean
   */
  private _shouldRefreshToken(options: RequestOptions) {
    if (options.skipTokenRefresh) {
      return false;
    }

    if (this.featureFlag.isOn('oauth-migration-107055')) {
      return !this.oauthService.tokenExists();
    } else {
      return !this.hasSessionTokens && !options.skipTokenRefresh;
    }
  }

  /**
   * Determines if the server response is token expired.
   *
   * @param response Http response.
   */
  private _hasTokenExpired(response: HttpResponse<any>) {
    if (response.headers.has('www-authenticate')) {
      // If the OAuth token is there but the server responses with 401 invalid_token we need to refresh the token
      return (
        this.oauthService.tokenExists() &&
        response.headers.get('www-authenticate').includes('The token expired')
      );
    }

    return false;
  }
}
