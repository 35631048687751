import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { L10nService } from '@zipcrim/common';
import uniqueId from 'lodash/uniqueId';
import {
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { DynamicFormFieldComponent } from '../form-field/form-field.component';
import { FormService } from '@zipcrim/forms/form.service';
import { OptionConfig } from '@zipcrim/forms/forms.interfaces';
import { RadioGroupLayout } from './radio-group.interfaces';

/**
 * Form radio group.
 *
 * ```html
 * <zc-radio-group [Options]="myOptions" [(ngModel)]="myValue"></zc-radio-group>
 * ```
 */
@Component({
  selector: 'zc-dynamic-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicRadioGroupComponent),
      multi: true,
    },
  ],
})
export class DynamicRadioGroupComponent
  implements ControlValueAccessor, OnChanges, OnInit, OnDestroy
{
  constructor(
    private _L10n: L10nService,
    private _FormService: FormService,
    @Optional() private _FormField: DynamicFormFieldComponent
  ) {
    // used in `DynamicNodeItemComponent`
    this.OptionChange = combineLatest([
      this._ValueChange.asObservable(),
      this._OptionsChange.asObservable(),
    ]).pipe(
      map(
        () => this.AllOptions.find((item) => item.value === this.Value) || null
      )
    );

    this._SelectionSubscription = this.OptionChange.pipe(
      tap((option) => this.SelectionChange.next(option))
    ).subscribe();
  }

  /**
   * Input value getter / setter.
   */
  get Value() {
    return this._Value;
  }

  set Value(value) {
    this._Value = value;
    this._ValueChange.next();
    this.onChange(value);
  }

  /**
   * Currently selected option for the current value.
   */
  get Option() {
    return this._Option;
  }

  /**
   * Emits the selected option when the value changes.
   */
  @Output()
  SelectionChange = new EventEmitter<OptionConfig>();

  /**
   * Indicates radio buttons layout type.
   */
  @Input()
  Layout: RadioGroupLayout;

  /**
   * Indicates if the input is disabled.
   */
  @Input()
  Disabled: boolean;

  /**
   * Indicates if the input is readonly.
   */
  @Input()
  Readonly: boolean;

  /**
   * Options.
   */
  @Input()
  Options: OptionConfig[];

  /**
   * Async options.
   */
  @Input()
  OptionsAsync?: Observable<OptionConfig[]>;

  /**
   * All available options.
   */
  AllOptions?: OptionConfig[];

  /**
   * Emits a value when the value changes AND the associated option is available.
   */
  OptionChange: Observable<OptionConfig | null>;

  /**
   * Unique radio group name.
   */
  Name = uniqueId('dynamic-radio-group-');

  /**
   * Indicates if the async options is busy.
   */
  IsLoading: boolean;

  HasRequired$: Observable<boolean>;

  /**
   * Input value.
   */
  private _Value: any;

  /**
   * Currently selected option for the current value.
   */
  private _Option: OptionConfig;

  /**
   * Emits a value any time the value is changed.
   */
  private _ValueChange = new ReplaySubject<void>(1);

  /**
   * Emits a value any time the options have changed.
   */
  private _OptionsChange = new ReplaySubject<void>(1);

  /**
   * Options subscription.
   */
  private _OptionsSubscription: Subscription;

  /**
   * Selection subscription.
   */
  private _SelectionSubscription: Subscription;

  ngOnInit() {
    this.HasRequired$ = this._FormField?.HasRequired ?? of(false);
  }

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.Options || changes.OptionsAsync) {
      this._LoadOptions();
    }
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    if (this._OptionsSubscription) {
      this._OptionsSubscription.unsubscribe();
    }

    if (this._SelectionSubscription) {
      this._SelectionSubscription.unsubscribe();
    }
  }

  /**
   * On blur.
   */
  OnBlur() {
    this.onTouched();
  }

  /**
   * On change.
   */
  onChange = (_: any) => {
    // empty
  };

  /**
   * On touched.
   */
  onTouched = () => {
    // empty
  };

  /**
   * Write value.
   */
  writeValue(value: any) {
    if (this._FormService.HasValue(value)) {
      this.Value = value;
    }
  }

  /**
   * Register on change.
   */
  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  /**
   * Register on touched.
   */
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  /**
   * Set disabled state.
   */
  setDisabledState(isDisabled: boolean) {
    this.Disabled = isDisabled;
  }

  /**
   * Combine options and async options.
   */
  private _LoadOptions() {
    if (this._OptionsSubscription) {
      this._OptionsSubscription.unsubscribe();
    }

    if (!this.OptionsAsync) {
      this._SetAllOptions(this.Options || []);
      return;
    }

    // reset
    this.AllOptions = [];
    this.IsLoading = true;

    this._OptionsSubscription = this.OptionsAsync.pipe(
      map((items) => (this.Options ? [...this.Options, ...items] : items)),
      tap((items) => this._SetAllOptions(items)),
      finalize(() => (this.IsLoading = false))
    ).subscribe();
  }

  /**
   * Set `AllOptions` and emit change value.
   *
   * @param item Source collection of options.
   */
  private _SetAllOptions(items: OptionConfig[]) {
    items.forEach((item) => {
      item.label = item.labelL10nKey
        ? this._L10n.instant(item.labelL10nKey)
        : item.label;
    });

    this.AllOptions = items;
    this._OptionsChange.next();
  }
}
