import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches a valid password.
 */
const PASSWORD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,30}$/;

/**
 * Validate a password.
 */
export function PasswordValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (!value || PASSWORD_REGEXP.test(value)) {
    return null;
  }

  return {
    password: true,
  };
}
