import { ICertification } from '../generated';

/**
 * Certification.
 */
export class Certification {
  constructor(source: Partial<ICertification>) {
    this._Init(source);
  }

  CertifyingSource: string;
  FieldName: string;
  ID: number;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source: Partial<ICertification>) {
    this.CertifyingSource = source.CertifyingSource;
    this.FieldName = source.FieldName;
    this.ID = source.ID;
  }
}
