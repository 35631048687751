import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FooterState } from './footer.interfaces';

/**
 * Footer component.
 */
@Component({
  selector: 'c2h-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  constructor(private _Route: ActivatedRoute, private _Router: Router) {}

  /**
   * Header state.
   */
  State: FooterState;

  /**
   * Current year.
   */
  Year: number;

  /**
   * Default state values.
   */
  private readonly _DefaultState: FooterState = {
    root: true,
    nav: true,
  };

  /**
   * Router subscription.
   */
  private _RouterSubscription: Subscription;

  /**
   * On init.
   */
  ngOnInit() {
    this.Year = new Date().getFullYear();
    this.State = this._DefaultState;

    this._RouterSubscription = this._Router.events
      .pipe(filter((evt) => evt instanceof NavigationEnd))
      .subscribe(() => {
        const data = this._Route.root.firstChild.snapshot.data;
        this._SetState(data.footer);
      });
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    if (this._RouterSubscription) {
      this._RouterSubscription.unsubscribe();
    }
  }

  /**
   * Set header state.
   */
  private _SetState(data?: FooterState | boolean) {
    if (data === false) {
      data = {
        root: false,
      };
    }

    this.State = Object.assign({}, this._DefaultState, data);
  }
}
