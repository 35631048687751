import { Inject, Injectable } from '@angular/core';

import { CommonModuleConfig } from '../common.interfaces';
import { FeatureFlag } from './feature-flag.interfaces';

/**
 * Feature flag service.
 */
@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  constructor(
    @Inject('commonModuleConfig') private config: CommonModuleConfig
  ) {}

  /**
   * Indicates if a feature flag is enabled in the current environment.
   *
   * @param flag Feature flag name.
   */
  isOn(flag: FeatureFlag) {
    const featureFlags = this.config.featureFlags;

    return (featureFlags && featureFlags[flag]) || false;
  }
}
