import { IName, IUiMetaRecord } from '../generated';

/**
 * Name.
 */
export class Name {
  constructor(source?: Partial<IName>) {
    this._Init(source);
  }

  ID: number;
  FullName: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  Gender: string;
  FirstUsed: string;
  IsCurrent: boolean;
  HasDriversLicense: string;
  UiMeta?: IUiMetaRecord;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<IName>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.FullName = source.FullName;
    this.FirstName = source.FirstName;
    this.MiddleName = source.MiddleName;
    this.LastName = source.LastName;
    this.Suffix = source.Suffix;
    this.Gender = source.Gender;
    this.FirstUsed = source.FirstUsed;
    this.IsCurrent = !!source.IsCurrent;
    this.HasDriversLicense = source.HasDriversLicense;
    this.UiMeta = source.UiMeta;
  }
}
