import { IEmail } from '../generated';

/**
 * Email.
 */
export class Email {
  constructor(source?: Partial<IEmail>) {
    this._Init(source);
  }

  SubjectID: number;
  Value: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IEmail>) {
    if (!source) {
      return;
    }

    this.SubjectID = source.SubjectID;
    this.Value = source.Value;
  }
}
