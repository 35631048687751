import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { L10nService } from '../l10n/l10n.service';
import { ConfirmModalComponent } from './confirm-modal.component';
import { InfoModalComponent } from './info-modal.component';
import { ConfirmOptions, InfoOptions } from './modal.interfaces';

/**
 * Common modals.
 */
@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(
    private bsModal: BsModalService,
    private l10n: L10nService
  ) {}

  /**
   * Confirmation modal.
   *
   * @param options Confirmation options.
   */
  confirm(options?: ConfirmOptions) {
    const modal = this.bsModal.show(ConfirmModalComponent);
    const content = modal.content as ConfirmModalComponent;
    let title: string;
    let message: string;
    let noLabel: string;
    let yesLabel: string;

    if (options) {
      title = options.titleL10nKey
        ? this.l10n.instant(options.titleL10nKey)
        : options.title;
      message = options.messageL10nKey
        ? this.l10n.instant(options.messageL10nKey)
        : options.message;
      noLabel = options.noLabelL10nKey
        ? this.l10n.instant(options.noLabelL10nKey)
        : options.noLabel;
      yesLabel = options.yesL10nKey
        ? this.l10n.instant(options.yesL10nKey)
        : options.yesLabel;
    }

    content.title = title || this.l10n.instant('Common.lblConfirm');
    content.message = message || this.l10n.instant('Common.lblNoUndo');
    content.noLabel = noLabel || this.l10n.instant('Common.lblNo');
    content.yesLabel = yesLabel || this.l10n.instant('Common.lblYes');
    return content.result;
  }

  /**
   * Information modal.
   *
   * @param options Information options.
   */
  info(options?: InfoOptions) {
    const modal = this.bsModal.show(InfoModalComponent);
    const content = modal.content as InfoModalComponent;
    let title: string;
    let message: string;
    let doneLabel: string;

    if (options) {
      title = options.titleL10nKey
        ? this.l10n.instant(options.titleL10nKey)
        : options.title;
      message = options.messageL10nKey
        ? this.l10n.instant(options.messageL10nKey)
        : options.message;
      doneLabel = options.doneL10nKey
        ? this.l10n.instant(options.doneL10nKey)
        : options.doneLabel;
    }

    content.title = title || this.l10n.instant('Common.lblInformation');
    content.message = message;
    content.doneLabel =
      doneLabel || this.l10n.instant('Common.lblOkay');
    return content.result;
  }
}
