import { ApiMessage } from './api-message';
import { ResponseResult, ResultStatus } from './api.interfaces';

/**
 * Api response result.
 */
export class ApiResult<T> {
  /**
   * Create instance with `Success` result.
   *
   * @param message Result message.
   */
  static success<T>(payload: T, message?: string) {
    return new ApiResult<T>({
      Result: 'Success',
      Payload: payload,
      Message: { Blurb: message },
    });
  }

  /**
   * Create instance with `Failure` result.
   *
   * @param message Result message.
   */
  static failure(message: string) {
    return new ApiResult<void>({
      Result: 'Failure',
      Message: { Blurb: message },
    });
  }

  /**
   * Create instance with `Expired` result.
   *
   * @param message Result message.
   */
  static expired(message: string) {
    return new ApiResult<void>({
      Result: 'Expired',
      Message: { Blurb: message },
    });
  }

  constructor(source: ResponseResult) {
    this.init(source);
  }

  /**
   * Result status.
   */
  result: ResultStatus;

  /**
   * Result payload.
   */
  payload: T;

  /**
   * Result messages.
   */
  message: ApiMessage | null;

  /**
   * Result session token.
   */
  zs: string;

  /**
   * True if the result is a success.
   */
  get isSuccess() {
    return this.result === 'Success';
  }

  /**
   * True if the result is a failure.
   */
  get isFailure() {
    return this.result !== 'Success';
  }

  /**
   * True if the result is expired.
   */
  get isExpired() {
    return this.result === 'Expired';
  }

  /**
   * Recursively flattens `Messages`.
   */
  flattenMessages() {
    return this.message ? this.message.flattenInnerMessages() : [];
  }

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private init(source: ResponseResult) {
    this.result = source?.Result ?? 'Success';
    this.payload = source?.Payload;
    this.message = source.Message ? new ApiMessage(source.Message) : null;
    this.zs = source.ZS;
  }
}
