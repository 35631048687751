import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-common-types';

import { L10nService } from '../l10n/l10n.service';

/**
 * Empty state indicator.
 *
 * ```html
 * <zc-empty
 *  title="Move Along"
 *  description="These aren't the droids your looking for.">
 * </zc-empty>
 * ```
 */
@Component({
  selector: 'zc-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent implements OnChanges {
  constructor(private l10n: L10nService) {}

  @Input() file: string;
  @Input() icon: IconName;
  // TODO: EJA: This is what it was new: @Input() icon: string;
  @Input() title: string;
  @Input() titleL10nKey: string;
  @Input() description: string;
  @Input() descriptionL10nKey: string;
  titleText: string;
  descriptionText: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title || changes.titleL10nKey) {
      this.titleText = this.titleL10nKey
        ? this.l10n.instant(this.titleL10nKey)
        : this.title;
    }

    if (changes.description || changes.descriptionL10nKey) {
      this.descriptionText = this.descriptionL10nKey
        ? this.l10n.instant(this.descriptionL10nKey)
        : this.description;
    }
  }
}
