<!-- sidebar -->
<section
  class="sidebar"
  [cdkTrapFocus]="!isFixed && isOpen"
  [ngClass]="{
    'sidebar--open': isOpen,
    'sidebar--slide': !isFixed
  }"
>
  <ng-content></ng-content>
</section>

<!-- overlay -->
<div
  class="sidebar__overlay"
  *ngIf="!isFixed && isOpen"
  (click)="close()"
></div>
