import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches a valid city name.
 */
const CITY_REGEX = /^[a-zA-Z-'.,\s\x7f-\xff]{2,30}$/i;

/**
 * Validate a city name.
 */
export function CityValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (!value || CITY_REGEX.test(value)) {
    return null;
  }

  return {
    city: true,
  };
}
