import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { IBoList, IUiMetaBo } from '../../generated';

/**
 * Ui meta service.
 */
@Injectable({ providedIn: 'root' })
export class UiMetaService {
  constructor(private api: ApiService) {}

  /**
   * Get UI meta by names.
   */
  getByNames(names: string[]) {
    let params = new HttpParams();

    names.forEach((name) => {
      params = params.append('zNames', name);
    });

    return this.api
      .get<IBoList<IUiMetaBo>>('uiMetas', params)
      .pipe(map((res) => res.payload));
  }
}
