import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches a string is not all spaces.
 */
const EMPTY_REGEX = /^[\s]+$/i;

/**
 * Validate a city name.
 */
export function NotEmptyValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (!value || !EMPTY_REGEX.test(value)) {
    return null;
  }

  return {
    notEmpty: true,
  };
}
