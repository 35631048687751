import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AuthService,
  FileService,
  SaveSpec,
  SessionService,
  Transformers,
} from '@zipcrim/common';
import { OptionsService } from '@zipcrim/forms';
import {
  AddressValidator,
  CityValidator,
  NameValidator,
  PasswordValidator,
  StateValidator,
  TelephoneValidator,
  ZipCodeValidator,
} from '@zipcrim/forms/validators';
import { finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Component({
  selector: 'c2h-signup',
  templateUrl: 'signup.component.html',
  styleUrls: ['signup.component.scss'],
})
export class SignupComponent implements OnInit {
  constructor(
    private _Auth: AuthService,
    private _Fb: FormBuilder,
    private _FileService: FileService,
    private _Options: OptionsService,
    private _Router: Router,
    private _Sanitizer: DomSanitizer,
    private _Session: SessionService
  ) {}

  /**
   * Busy saving indicator.
   */
  BusySaving: boolean;

  /**
   * Terms of service URL
   */
  EulaUrl: SafeResourceUrl;

  FormGroup = this._Fb.group({
    Name: this._Fb.control(null, [
      Validators.required,
      Validators.pattern(/(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 -.@&$]*)?$/),
      Validators.maxLength(30),
    ]),
    Address: this._Fb.group({
      Address1: this._Fb.control(null, [Validators.required, AddressValidator]),
      Address2: this._Fb.control(null, [AddressValidator]),
      CountryCode: this._Fb.control(null, [Validators.required]),
      City: this._Fb.control(null, [Validators.required, CityValidator]),
      State: this._Fb.control(null, [Validators.required, StateValidator]),
      StateIntl: this._Fb.control(null, [StateValidator]),
      ZipCode: this._Fb.control(null, [
        Validators.required,
        ZipCodeValidator('US'),
      ]),
    }),
    IsAThirdParty: this._Fb.control(null, [Validators.required]),
    Attributes: this._Fb.group({
      DOTNumber: this._Fb.control(null, []),
    }),
    Contact: this._Fb.group({
      FirstName: this._Fb.control(null, [Validators.required, NameValidator]),
      LastName: this._Fb.control(null, [Validators.required, NameValidator]),
      Title: this._Fb.control(null, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9'-.\s]*$/),
        Validators.maxLength(20),
      ]),
      Phone: this._Fb.control(null, [
        Validators.required,
        TelephoneValidator(true),
      ]),
      Email: this._Fb.control(null, [Validators.required, Validators.email]),
      Password: this._Fb.control(null, [
        Validators.required,
        PasswordValidator,
      ]),
      Domains: this._Fb.array([]),
    }),
    Terms: this._Fb.control(null, [Validators.required]),
  });

  CountryOptions$ = this._Options.getCountries(['US', 'CA']);
  StateOptions$ = this._Options.getRegions('US');
  IsAThirdPartyOptions = [
    {
      labelL10nKey: 'Common.lblYes',
      value: true,
    },
    {
      labelL10nKey: 'Common.lblNo',
      value: false,
    },
  ];
  get IsAThirdParty() {
    return this.FormGroup.get('IsAThirdParty').value;
  }
  
  setDOTNumberValidators(set: boolean) {
    if (set) {
      this.FormGroup.get('Attributes').get('DOTNumber').setValidators([
        Validators.maxLength(12),
        Validators.minLength(5),
        Validators.pattern(/.*[0-9].*$/),
        Validators.required,
      ]);
    } else {
      this.FormGroup.get('Attributes').get('DOTNumber').clearValidators();
    }    
    this.FormGroup.get('Attributes').get('DOTNumber').updateValueAndValidity();
  }

  /**
   * On init.
   */
  ngOnInit() {
    if (environment.eulaUrl) {
      this.EulaUrl = this._Sanitizer.bypassSecurityTrustResourceUrl(
        environment.eulaUrl
      );
    }    
    this._BuildForm();
  }

  /**
   * Download sample report.
   */
  DownloadFile() {
    const params = new HttpParams()
      .append('clientCode', '')
      .append('id', 'someRandomFileIdentifier');

    this._FileService.download('file/download', params, true).subscribe();
  }

  /**
   * On form submit.
   */
  OnSubmit() {      
    if (this.FormGroup.invalid) {
      return;
    }

    const value = this.FormGroup.value;
    value.Address.State = value.Address.StateIntl || value.Address.State;
    value.Contact.Phone = Transformers.numericOnly(value.Contact.Phone);
    value.Contact.Domains = [
      {
        Roles: [{ Name: 'C2HAdmin' }, { Name: 'Full' }],
      },
    ];

    const spec = new SaveSpec('Client', value);

    this.BusySaving = true;
    this._Auth
      .createAccount(spec)
      .pipe(finalize(() => (this.BusySaving = false)))
      .subscribe(() => {
        this._Router.navigate(['/onboard']);
      });
  }

  /**
   * Build dynamic form configuration.
   */
  private _BuildForm() {
    const data = this._Session.data.AppData;

    this.FormGroup.patchValue({
      Name: data.CompanyName,
      Contact: {
        Email: data.Email,
      },
    }); 
  }
}