import { Component, OnInit, ViewChild } from '@angular/core';

import { TimelineTitleComponent } from '../timeline/timeline-title.component';
import { StepComponent } from './step.component';

@Component({
  selector: 'zc-step-title',
  templateUrl: './step-title.component.html',
})
export class StepTitleComponent implements OnInit {
  constructor(private step: StepComponent) {}

  @ViewChild(TimelineTitleComponent, { static: true })
  timelineTitleRef: TimelineTitleComponent;

  ngOnInit() {
    this.timelineTitleRef.SetTimelineItem(this.step.timelineItemRef);
  }
}
