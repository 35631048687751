import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ApiService } from '@zipcrim/common';
import { uniqueId } from 'lodash';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { noop, Observable, Observer, of, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FormService } from '..';
import { FormFieldControl } from '../form-field/form-field-control';
import { ControlWidth } from '../forms.interfaces';

@Component({
  selector: 'zc-auto-complete',
  templateUrl: './auto-complete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormFieldControl,
      useExisting: AutoCompleteComponent,
    },
  ],
})
export class AutoCompleteComponent
  implements
    ControlValueAccessor,
    FormFieldControl<any>,
    OnInit,
    OnChanges,
    OnDestroy
{
  constructor(
    @Self() public ngControl: NgControl,
    private ref: ChangeDetectorRef,
    private form: FormService,
    private api: ApiService
  ) {
    ngControl.valueAccessor = this;
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  @Input() apiUrl: string;
  @Input() paramsKey: string;
  @Input() typeaheadOptionField: string;
  @Input() id = uniqueId('control-');
  @Input() width: ControlWidth = 'md';
  @Input() bodyObjectSelector: string = '';
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() controlName: string = '';
  @Output() predictionSelected = new EventEmitter<any>();
  suggestions$?: Observable<any[]>;
  required: boolean;
  isLoading = false;
  readonly stateChanges = new Subject<void>();

  private _value: any;
  private readonly _destroyed = new Subject<void>();

  writeValue(value: any): void {
    this.value = value;
    this.ref.markForCheck();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.ref.markForCheck();
  }

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this.stateChanges.complete();
  }

  ngOnInit(): void {
    this.setRequired();
    this.ngControl.statusChanges
      .pipe(
        takeUntil(this._destroyed),
        tap(() => this.setRequired())
      )
      .subscribe();

    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.value);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          let params = new HttpParams();
          params = params.set(this.paramsKey, query.toString());

          return this.api
            .hubRequest<any>('GET', this.apiUrl, {
              params,
            })
            .pipe(
              map((result) => result.payload),
              map((body) => {
                /**
                 * Sometimes we want to have a selector that is inside an object were predictions are
                 * then use the object key to get those objects and map them, otherwise return the body object
                 */
                if (this.bodyObjectSelector.length > 0) {
                  return body[this.bodyObjectSelector];
                }

                return body;
              }),
              tap(
                () => noop,
                (err) => {
                  console.log(err);
                }
              )
            );
        }

        return of([]);
      }),
      finalize(() => {
        this.ref.markForCheck();
      })
    );
  }

  onSelectEvent($event: TypeaheadMatch) {
    this.predictionSelected.emit($event);
  }

  onChange = (_: any) => {
    // empty
  };

  onTouched = () => {
    // empty
  };

  onFocus() {
    // Don't want the field to be considered
    // touched/changed/dirty if it's readonly
    if (this.readonly) {
      return;
    }
  }

  onBlur() {
    // Don't want the field to be considered
    // touched/changed/dirty if it's readonly
    if (this.readonly) {
      return;
    }
    this.onTouched();
    this.stateChanges.next();
  }

  private setRequired() {
    const ctrl = this.ngControl.control;

    this.required = this.form.hasRequired(ctrl);
    this.stateChanges.next();
  }
}
