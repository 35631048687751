import { Directive, HostBinding, Input } from '@angular/core';

/**
 * Static and unique ID for automation tests.
 */
@Directive({
  selector: '[zcAutomationId]',
})
export class AutomationIdDirective {
  @Input('zcAutomationId')
  @HostBinding('attr.data-automation-id')
  id: string;
}
