import { Component, Input } from '@angular/core';

@Component({
  selector: 'c2h-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  /**
   * Used to store Title of page.
   */
  @Input()
  Title: string;

  /**
   * Used to store url for destination.
   */
  @Input()
  URL: string;

  /**
   * Used to store page name.
   */
  @Input()
  PageName: string;
}
