import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Form field help text.
 *
 * ```html
 * <zc-dynamic-help-text>Some very helpful text!</zc-dynamic-help-text>
 * ```
 */
@Component({
  selector: 'zc-dynamic-help-text',
  templateUrl: './help-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicHelpTextComponent {}
