import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@zipcrim/common';

@Component({
  templateUrl: 'pennsylvania-info.component.html',
})
export class PennsylvaniaInfoComponent implements OnInit {
  constructor(private _AuthService: AuthService, private _Router: Router) {}

  /**
   * Current user's name.
   */
  UserName: string;

  /**
   * On init.
   */
  ngOnInit(): void {
    this._AuthService.getCurrentUser().subscribe((user) => {
      this.UserName = user.Name.FirstName;
    });
  }

  /**
   * Navigate to next step.
   */
  Continue() {
    this._Router.navigate(['/onboard']);
  }
}
