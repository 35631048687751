import { Component, EventEmitter, Input, Output } from '@angular/core';
import uniqueId from 'lodash/uniqueId';
import { FileUploader } from 'ng2-file-upload';

/**
 * Drag and drop file upload.
 *
 * ```html
 * <zc-file-upload (fileChange)="MyFunction($event)"></zc-file-upload>
 * ```
 */
@Component({
  selector: 'zc-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() accept = '.csv,.xls,.xlsx,.txt';
  @Output() fileChange = new EventEmitter<File>();
  id = uniqueId('file-upload-');
  uploader = new FileUploader({});
  isFileOver = false;

  onFileOver(value: boolean) {
    this.isFileOver = value;
  }

  onFileDrop(event: FileList | File[]) {
    this.emit(event);
  }

  onFileSelect(event: Event) {
    const elm = event.target as HTMLInputElement;
    this.emit(elm.files);
  }

  private emit(files: FileList | File[]) {
    const file = files[0];
    this.fileChange.emit(file);
  }
}
