import { AbstractControl, ValidationErrors } from '@angular/forms';
import {
  endOfMonth,
  endOfYear,
  format,
  getDaysInMonth,
  isAfter,
  isBefore,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import isPlainObject from 'lodash/isPlainObject';

/**
 * Date parts required.
 */
interface DatePartsRequired {
  year?: boolean;
  month?: boolean;
  day?: boolean;
}

/**
 * Validator options object.
 */
export interface DatePartsOptions {
  required?: boolean | DatePartsRequired;
  min?: Date;
  max?: Date;
  locale?: string;
}

/**
 * @deprecated remove with dynamic-node
 */
export function DatePartsValidator(options: DatePartsOptions) {
  const { required, min, max, locale } = options;
  let isYearRequired: boolean;
  let isMonthRequired: boolean;
  let isDayRequired: boolean;

  if (isRequiredParts(required)) {
    isYearRequired = required.year || false;
    isMonthRequired = required.month || false;
    isDayRequired = required.day || false;
  } else {
    isYearRequired = isMonthRequired = isDayRequired = !!required;
  }

  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    const year: number = value?.Year;
    const month: number = value?.Month;
    const day: number = value?.Day;
    const hasYear = hasValue(year);
    const hasMonth = hasValue(month);
    const hasDay = hasValue(day);

    if (
      (isYearRequired && !hasYear) ||
      (isMonthRequired && !hasMonth) ||
      (isDayRequired && !hasDay)
    ) {
      return { required: true };
    }

    if (!hasYear && !hasMonth && !hasDay) {
      return null;
    }

    const errors: { [index: string]: any } = {};
    const maxDays =
      hasYear && hasMonth ? getDaysInMonth(new Date(year, month - 1)) : 31;

    if (hasMonth && (month < 1 || month > 12)) {
      errors.dateMonth = { min: 1, max: 12 };
    }

    if (hasDay && (day < 1 || day > maxDays)) {
      errors.dateDay = { min: 1, max: maxDays };
    }

    if (hasYear && year.toString().length !== 4) {
      errors.dateYear = true;
    }

    if (hasYear) {
      const monthIndex = hasMonth ? month - 1 : 0;
      const date = hasDay
        ? new Date(year, monthIndex, day)
        : new Date(year, monthIndex);

      if (min) {
        let targetMin: Date;

        if (hasDay) {
          targetMin = min;
        } else {
          targetMin = hasMonth ? startOfMonth(min) : startOfYear(min);
        }

        if (isBefore(date, targetMin)) {
          errors.dateMin = { min: formatDate(targetMin, locale) };
        }
      }

      if (max) {
        let targetMax: Date;

        if (hasDay) {
          targetMax = max;
        } else {
          targetMax = hasMonth ? endOfMonth(max) : endOfYear(max);
        }

        if (isAfter(date, targetMax)) {
          errors.dateMax = { max: formatDate(targetMax, locale) };
        }
      }
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };
}

/**
 * Check if the provided `value` is a valid value.
 *
 * @param value Value to check.
 */
function hasValue(value: any) {
  return value != null;
}

/**
 * Indicates if the `value` is an object with required parts.
 *
 * @param value Value to check.
 */
function isRequiredParts(
  value: boolean | DatePartsRequired
): value is DatePartsRequired {
  return value && isPlainObject(value);
}

/**
 * Formate date.
 *
 * @param date Source date.
 * @param locale target locale.
 */
function formatDate(date: Date, locale: string) {
  try {
    const formatter = new Intl.DateTimeFormat(locale);
    return formatter.format(date);
  } catch (error) {
    return format(date, 'P');
  }
}
