import { ICaseType } from '../generated';
import { AnaGroup } from './ana-group';

/**
 * Case type.
 */
export class CaseType {
  constructor(source?: Partial<ICaseType>) {
    this._Init(source);
  }

  Name: string;
  Code: string;
  AnaGroups: AnaGroup[];
  get AnaGroupCodes() {
    return this.AnaGroups.map((item) => item.Code);
  }

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<ICaseType>) {
    if (!source) {
      this.AnaGroups = [];
      return;
    }

    this.Code = source.Code;
    this.AnaGroups = source?.AnaGroups?.Items
      ? source.AnaGroups.Items.map((item) => new AnaGroup(item))
      : [];

    if (source.Name) {
      this.Name = source.Name;
    } else if (source.Blurb) {
      this.Name = source.Blurb;
    }
  }
}
