import { IDob, IUiMetaRecord } from '../generated';

/**
 * Date of birth
 */
export class Dob {
  constructor(source?: Partial<IDob>) {
    this._Init(source);
  }

  ID: number;
  Year: number;
  Month: number;
  Day: number;
  UiMeta?: IUiMetaRecord;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IDob>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Year = source.Year;
    this.Month = source.Month;
    this.Day = source.Day;
    this.UiMeta = source.UiMeta;
  }
}
