import { Component, Input } from '@angular/core';

import { TagColor } from './tag.interfaces';

/**
 * Tag component.
 *
 * ```html
 * <zc-tag color="info">Hello World</zc-tag>
 * ```
 */
@Component({
  selector: 'zc-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() showDot: boolean;
  @Input() color: TagColor;
}
