import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ClientService, SaveSpec } from '@zipcrim/common';
import { OptionsService } from '@zipcrim/forms';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: 'company-info.component.html',
})
export class CompanyInfoComponent implements OnInit {
  constructor(
    private _AuthService: AuthService,
    private _Fb: FormBuilder,
    private _Options: OptionsService,
    private _Router: Router,
    private _Route: ActivatedRoute,
    private _Client: ClientService
  ) {}

  /**
   * Current user's name.
   */
  UserName: string;

  /**
   * Saving busy indicator.
   */
  BusySaving: boolean;

  FormGroup = this._Fb.group({
    NatureOfBusiness: this._Fb.control(null, [Validators.required]),
    FCRAPurpose: this._Fb.group({
      EM: this._Fb.control(null),
      WI: this._Fb.control(null),
    }),
    // RequireControlsValidator { min: 1 }
  });

  NatureOfBusinessOptions$ = this._Options.getOptionSet('C2HIndustry');
  FCRAPurposeOptions = [
    {
      label: 'Common.lblEmployment',
      name: 'EM',
    },
    {
      label: 'CheckToHire.lblOther',
      name: 'WI',
      description: 'CheckToHire.msgFCRAOtherDescription',
    },
  ];

  /**
   * Client code.
   */
  private _ClientCode: string;

  /**
   * On init.
   */
  ngOnInit(): void {
    this._AuthService.getCurrentUser().subscribe((user) => {
      this.UserName = user.Name.FirstName;
      this._ClientCode = user.GetClientCode();
    });
  }

  /**
   * On submit.
   */
  OnSubmit() {
    if (this.FormGroup.invalid || !this._ClientCode) {
      return;
    }

    const { NatureOfBusiness, FCRAPurpose } = this.FormGroup.value;

    const spec = new SaveSpec('Client', {
      Code: this._ClientCode,
      Attributes: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        FCRA_Purpose: FCRAPurpose,
        NatureOfBusiness,
      },
    });

    this.BusySaving = true;
    this._Client
      .save(this._ClientCode, spec)
      .pipe(finalize(() => (this.BusySaving = false)))
      .subscribe(() => {
        this._Router.navigate(['../pennsylvania-info'], {
          relativeTo: this._Route,
        });
      });
  }
}
