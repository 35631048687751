import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Observable } from 'rxjs';

/**
 * An interface which allows a control to work inside of a `FormField`.
 */
@Directive()
export abstract class FormFieldControl<T> {
  value: T | null;
  readonly stateChanges: Observable<void>;
  readonly id: string;
  readonly placeholder: string;
  readonly ngControl: NgControl;
  readonly required: boolean;
  readonly disabled: boolean;
}
