import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { SessionService } from '../auth/session.service';
import { HotkeysService } from '../hotkeys/hotkeys.service';
import { KeyCode } from '../hotkeys/keyCode.enum';
import { WindowRefService } from '../window-ref/window-ref.service';
import { L10nPickerComponent } from './l10n-picker.component';

@Injectable({ providedIn: 'root' })
export class L10nService {
  constructor(
    private bsModal: BsModalService,
    private hotkeys: HotkeysService,
    private session: SessionService,
    private translate: TranslateService,
    private windowRef: WindowRefService
  ) {
    this.init();
  }

  currentLocale: string;
  currentCountry: string;
  private readonly defaultLocale = 'en-US';
  private readonly defaultCountry = 'US';

  /**
   * Get the expected date format order.
   *
   * Examples:
   * - `['year', 'month', 'day']`
   * - `['day', 'month', 'year']`
   * - `['month', 'day', 'year']`
   *
   * @param locale Target locale.
   */
  getDatePartsOrder(locale: string) {
    try {
      const formatter = new Intl.DateTimeFormat(locale);
      const parts = formatter.formatToParts();

      return parts
        .map((x) => x.type)
        .filter((x) => x === 'year' || x === 'month' || x === 'day');
    } catch {
      // ignore error and use default (en-US)
      return ['month', 'day', 'year'];
    }
  }

  /**
   * @deprecated Use TranslateService directly
   */
  instant(key: string, values?: Record<string, any>) {
    return this.translate.instant(key, values);
  }

  private init() {
    this.setCurrent();

    this.hotkeys
      // .addGlobal('keydown.control.alt.l')
      .addGlobal([KeyCode.ControlLeft, KeyCode.AltLeft, KeyCode.KeyL])
      .subscribe(() => this.openPicker());
  }

  private openPicker() {
    const modal = this.bsModal.show(L10nPickerComponent);
    const content = modal.content as L10nPickerComponent;

    // todo: dynamic
    content.locales = ['en-US', 'en-GB', 'es-MX', 'fr', 'fr-CA', 'fr-FR'];
    content.selected = this.currentLocale;

    return content.result.then((locale) => {
      sessionStorage.setItem('locale', locale);
      this.windowRef.nativeWindow.location.reload();
    });
  }

  private setCurrent() {
    const data = this.session.data.AppData;
    let country = data.Country;
    let locale = sessionStorage.getItem('locale') || data.Locale;

    if (country) {
      const countries = country.split(',');
      country = countries[0].trim();
    }

    if (locale) {
      locale = locale.replace('_', '-');
    }

    // eslint-disable-next-line
    this.currentLocale =
      locale || window.navigator.language || this.defaultLocale;
    this.currentCountry = country || this.defaultCountry;

    this.translate.use(this.currentLocale);
  }
}
