<ngx-intl-tel-input
  [preferredCountries]="preferredCountries"
  [enableAutoCountrySelect]="true"
  [enablePlaceholder]="true"
  [searchCountryFlag]="true"
  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
  [selectFirstCountry]="false"
  [selectedCountryISO]="selectedCountry"
  [maxLength]="16"
  [phoneValidation]="true"
  [separateDialCode]="separateDialCode"
  [numberFormat]="PhoneNumberFormat.International"
  [(ngModel)]="value"
  (countryChange)="onCountryChange($event)"
>
</ngx-intl-tel-input>
