import { Component, HostBinding, Input, OnChanges } from '@angular/core';

/**
 * Busy spinner.
 *
 * ```html
 * <zc-busy size="lg"></zc-busy>
 * ```
 */
@Component({
  selector: 'zc-busy',
  templateUrl: './busy.component.html',
  styleUrls: ['./busy.component.scss'],
})
export class BusyComponent implements OnChanges {
  @Input() size: 'xs' | 'sm' | 'lg';
  @Input() color: 'green';
  @HostBinding('class.d-block') @Input() centered: boolean;
  css: string;

  ngOnChanges() {
    this.setCss();
  }

  private setCss() {
    const classes = [];

    if (this.size) {
      classes.push(`busy--${this.size}`);
    }

    if (this.color) {
      classes.push(`busy--${this.color}`);
    }

    if (this.centered) {
      classes.push('busy--centered');
    }

    this.css = classes.join(' ');
  }
}
