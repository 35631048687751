import { IContactEmail } from '../generated';

/**
 * Contact email.
 */
export class ContactEmail {
  constructor(source?: Partial<IContactEmail>) {
    this._Init(source);
  }

  ID: number;
  Value: string;
  Purpose: 'Primary' | 'Secondary';

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IContactEmail>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Value = source.Value;
    this.Purpose = source.Purpose;
  }
}
