import { Injectable } from '@angular/core';

interface DateParts {
  year: number;
  month: number;
  day: number;
}

@Injectable({ providedIn: 'root' })
export class DateService {
  fromIso(src: string) {
    if (!src) {
      return {
        year: null,
        month: null,
        day: null,
      };
    }

    const parts = src.split('-');
    const year = parseInt(parts[0], 10) || null;
    const month = parseInt(parts[1], 10) || null;
    const day = parseInt(parts[2], 10) || null;

    return {
      year,
      month,
      day,
    };
  }

  toIso(src: DateParts) {
    if (!src) {
      return null;
    }

    const { year, month, day } = src;
    const parts = [];

    if (year) {
      parts.push(year.toString());
    }

    if (month) {
      parts.push(month.toString().padStart(2, '0'));
    }

    if (day) {
      parts.push(day.toString().padStart(2, '0'));
    }

    return parts.length > 0 ? parts.join('-') : null;
  }
}
