import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * Auto focus cursor to input.
 *
 * ```html
 * <input type="text" [zcAutoFocus]="true" />
 * ```
 */
@Directive({
  selector: '[zcAutoFocus]',
})
export class AutoFocusDirective implements AfterContentInit {
  constructor(private _Element: ElementRef) {}

  @Input('zcAutoFocus')
  set isEnabled(value: any) {
    this._isEnabled = value === '' || value === true;
  }

  private _isEnabled: boolean;

  ngAfterContentInit() {
    if (!this._isEnabled) {
      return;
    }

    setTimeout(() => this.focusInput());
  }

  private focusInput() {
    const input = this.getInput();

    if (input) {
      input.focus();
    }
  }

  private getInput() {
    const elm: HTMLElement = this._Element.nativeElement;

    return elm.tagName.toLowerCase() === 'input'
      ? (elm as HTMLInputElement)
      : elm.querySelector('input');
  }
}
