import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  ApiMessage,
  L10nService,
  ModalService,
} from '@zipcrim/common';

import { DynamicNodeComponent } from '../dynamic-node/dynamic-node.component';
import {
  CollectionNode,
  EmptyConfig,
} from '../dynamic-node/dynamic-node.interfaces';
import { DynamicNodeService } from '../dynamic-node/dynamic-node.service';
import { DynamicFormFieldLayout } from '../form-field/form-field.interfaces';
import { FormService } from '@zipcrim/forms/form.service';

/**
 * Dynamic collection component.
 */
@Component({
  selector: 'zc-dynamic-collection',
  templateUrl: './dynamic-collection.component.html',
  styleUrls: ['./dynamic-collection.component.scss'],
})
export class DynamicCollectionComponent implements OnInit {
  constructor(
    private _FormService: FormService,
    private _L10n: L10nService,
    private _Modal: ModalService,
    private _NodeService: DynamicNodeService,
    private _RootNode: DynamicNodeComponent
  ) {}

  /**
   * Node configuration.
   */
  @Input()
  Config: CollectionNode;

  /**
   * Parent form group.
   */
  @Input()
  Form: FormGroup;

  /**
   * Form array reference.
   */
  @Input()
  FormArray: FormArray;

  // TODO: EJA: Is this the correct approach?
  @Input()
  set PushToFormArray(value: AbstractControl<any, any>) {
    this.FormArray.controls.push(value);
  }

  /**
   * Collection of validation messages from an API response.
   */
  @Input()
  ApiMessages: ApiMessage[];

  /**
   * Busy indicator.
   */
  @Input()
  Busy: boolean;

  /**
   * Current node layout, provided to recursive tree.
   */
  @Input()
  Layout: DynamicFormFieldLayout;

  /**
   * Form array name.
   */
  FormArrayName: string;

  /**
   * Collection title.
   */
  Title: string;

  /**
   * Empty configuration.
   */
  EmptyConfig: EmptyConfig;

  /**
   * Collection of cached anchor ids for form groups.
   */
  private _AnchorIds = new Map<FormGroup, string>();

  /**
   * Indicates if the add action is available.
   */
  get CanAdd() {
    const { disableAdd, require } = this.Config;

    if (disableAdd) {
      return false;
    }

    return !require || !require.max || this._GetArrayCount() < require.max;
  }

  /**
   * Indicates if the remove action is available.
   */
  get CanRemove() {
    const { disableDelete, require } = this.Config;

    if (disableDelete) {
      return false;
    }

    return !require || !require.min || this._GetArrayCount() > require.min;
  }

  /**
   * Indicates if the array has no groups to display.
   */
  get IsEmpty() {
    return this._GetArrayCount() === 0;
  }

  /**
   * On init.
   */
  ngOnInit() {
    const { name, titleL10nKey, title } = this.Config;

    this.FormArrayName = name.split('.').pop();
    this.Title = titleL10nKey ? this._L10n.instant(titleL10nKey) : title;

    this._SetEmptyConfig();
  }

  /**
   * Indicates if a form group is flagged for delete.
   *
   * @param group Form group to check.
   */
  IsDeleted(group: AbstractControl) {
    const control = group.get('IsDeleted');
    return control && control.value;
  }

  /**
   * Indicates if card footer should be shown.
   *
   * @param index Form array index.
   */
  ShowFooter(index: number) {
    const length = this.FormArray.length;
    const formIndex = index + 1;

    if (formIndex >= length) {
      return true;
    }

    for (let i = formIndex; i < length; i++) {
      const group = this.FormArray.at(i);

      if (!this.IsDeleted(group)) {
        return false;
      }
    }

    return true;
  }

  // TODO: Is this the best approach?
  HasApiErrorsFromAbstractControl(group: AbstractControl<any, any>) {
    return this.HasApiErrors(group as FormGroup);
  }

  /**
   * Indicates if a form group has any associated api errors.
   *
   * @param group Form group.
   */
  HasApiErrors(group: FormGroup) {
    if (!this.ApiMessages || this.ApiMessages.length === 0) {
      return false;
    }

    const id = this._FormService.GetIdValue(group);

    if (!id) {
      return false;
    }

    const name = this._GetBoName();

    return this.ApiMessages.some((msg) => {
      const field = msg.getUiField(name);
      return field && field.ObjectID === id && field.Severity === 'Error';
    });
  }

  // TODO: Is this the best approach?
  GetAnchorIdFromAbstractControl(group: AbstractControl<any, any>) {
    return this.GetAnchorId(group as FormGroup);
  }

  /**
   * Get a linkable id for a form group.
   *
   * @param group Form group.
   */
  GetAnchorId(group: FormGroup) {
    if (this._AnchorIds.has(group)) {
      return this._AnchorIds.get(group);
    }

    const id = this._FormService.GetIdValue(group);
    const bo = this._GetBoName();
    const result = this._FormService.getBoId(bo, id);

    this._AnchorIds.set(group, result);

    return result;
  }

  /**
   * On add.
   */
  OnAdd() {
    if (!this.CanAdd) {
      return;
    }

    this._NodeService.AddCollectionControl(this.FormArray, this.Config);
  }

  /**
   * On remove.
   *
   * @param index Array index to remove.
   */
  OnRemove(index: number) {
    if (!this.CanRemove) {
      return;
    }

    this._Modal.confirm().then(
      () => {
        this._RemoveGroup(index);
      },
      () => {
        // empty
      }
    );
  }

  /**
   * Get associated BO name.
   */
  private _GetBoName() {
    let { name } = this.Config;
    const map = this._RootNode.UiMetaMap;

    if (map) {
      const alias = Object.keys(map).find((key) => map[key] === name);

      if (alias) {
        name = alias;
      }
    }

    return name;
  }

  /**
   * Remove a group from the form array.
   *
   * @param index Array index to remove.
   */
  private _RemoveGroup(index: number) {
    const group = this.FormArray.at(index) as FormGroup;
    const id = this._FormService.GetIdValue(group);

    if (id) {
      // disable all other controls to disable validation
      Object.keys(group.controls).forEach((name) => {
        if (name !== 'ID') {
          group.controls[name].disable();
        }
      });

      // flag for delete on server
      const control = new FormControl(true);
      group.addControl('IsDeleted', control);
      group.markAsTouched();
      group.markAsDirty();
    } else {
      // local delete
      this.FormArray.removeAt(index);
    }
  }

  /**
   * Get the number of non-deleted groups in the array.
   */
  private _GetArrayCount() {
    if (!this.FormArray || this.FormArray.controls.length === 0) {
      return 0;
    }

    return this.FormArray.controls.filter((item) => !this.IsDeleted(item))
      .length;
  }

  /**
   * Set empty configuration with defaults.
   */
  private _SetEmptyConfig() {
    const { empty } = this.Config;

    const defaultValues = {
      titleL10nKey: 'Common.lblCollectionEmptyTitle',
      descriptionL10nKey: 'Common.lblCollectionEmptyDescription',
    };

    if (empty === false) {
      // false to disable empty state display
      this.EmptyConfig = null;
    } else if (empty) {
      this.EmptyConfig = {
        ...defaultValues,
        ...empty,
      };
    } else {
      this.EmptyConfig = defaultValues;
    }
  }
}
