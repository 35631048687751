<div class="d-flex align-items-center">
  <!-- select -->
  <select
    class="form-select"
    [ngClass]="'input--width-' + width"
    (blur)="onBlur()"
    [attr.aria-describedby]="id + '__errors'"
    [attr.aria-required]="required"
    [attr.id]="id"
    [(ngModel)]="value"
    [disabled]="disabled || readonly"
    data-live-search-normalize="true">
    <!-- placeholder -->
    <ng-container *ngIf="placeholder">
      <option [ngValue]="undefined" disabled selected hidden>
        {{ placeholder }}
      </option>
    </ng-container>

    <!-- options -->
    <option *ngFor="let item of options" [ngValue]="item.value">
      {{ item.label || (item.labelL10nKey | translate) }}
    </option>

    <!-- options async -->
    <option *ngFor="let item of options$ | async" [ngValue]="item.value">
      {{ item.label || (item.labelL10nKey | translate) }}
    </option>

    <!-- deselect option -->
    <ng-container *ngIf="deselect">
      <option value="" disabled>
        &#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;
      </option>
      <option [ngValue]="null">{{ deselect }}</option>
    </ng-container>
  </select>

  <!-- loading -->
  <zc-busy *ngIf="isLoading" size="xs" class="ms-1"> </zc-busy>

  <!-- failed -->
  <zc-button
    size="sm"
    color="link"
    [buttonClasses]="['btn-condensed', 'ms-1']"
    [tooltip]="'Common.lblFailedRetry' | translate"
    (click)="onRetry()"
    *ngIf="isFailure">
    <fa-icon icon="exclamation-triangle" class="text-danger"></fa-icon>
    {{ 'Common.cmdTryAgain' | translate }}
  </zc-button>
</div>
