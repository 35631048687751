import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NgForm,
} from '@angular/forms';
import { ApiMessage, L10nService } from '@zipcrim/common';
import isObject from 'lodash/isObject';
import { Subject, Subscription } from 'rxjs';

import { ERROR_MESSAGES } from '../error/error-messages';
import { FormService } from '@zipcrim/forms/form.service';
import { ValidationMessage } from './validation-messages.interfaces';

/**
 * Extract arguments of function
 */
type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

/**
 * Creates an object like O. Optionally provide minimum set of properties P which the objects must share to conform
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type ObjectLike<O extends object, P extends keyof O = keyof O> = Pick<O, P>;

/**
 * Form field validation messages.
 *
 * @deprecated Use `ErrorsComponent` instead.
 */
@Component({
  selector: 'zc-dynamic-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss'],
})
export class DynamicValidationMessagesComponent
  implements OnChanges, OnInit, OnDestroy
{
  constructor(
    private _FormService: FormService,
    private _L10n: L10nService,
    private _NgForm: NgForm
  ) {}

  /**
   * Associated control to show messages for.
   */
  @Input()
  Control: AbstractControl;

  /**
   * Optional collection of additional field names to use when searching `ApiMessages`.
   */
  @Input()
  AlternativeNames: string[];

  /**
   * Collection of validation messages from an API response.
   */
  @Input()
  ApiMessages: ApiMessage[];

  /**
   * Collection of custom messages to display.
   */
  @Input()
  CustomMessages: ValidationMessage[];

  /**
   * Event emitted when a messaged is ignored.
   */
  @Output()
  OnIgnore = new EventEmitter<string>();

  /**
   * Collection of messages to display.
   */
  Messages: ValidationMessage[] = [];

  /**
   * Form control name.
   */
  private _ControlName: string;

  /**
   * Associated record object id.
   */
  private _ObjectId: number;

  /**
   * Control value change subscription.
   */
  private _ObjectIdSubscription: Subscription;

  /**
   * Control status subscription.
   */
  private _ControlStatusSubscription: Subscription;

  /**
   * Control touched subscription.
   */
  private _ControlTouchedSubscription: Subscription;

  /**
   * Form submit subscription.
   */
  private _SubmitSubscription: Subscription;

  /**
   * API messages for the current control.
   */
  private _FilteredApiMessages: ValidationMessage[] = [];

  /**
   * Indicates if API messages should be visible.
   *
   * Requirements:
   * - Visible after submit.
   * - Hidden after user changes input.
   * - Visible again after re-submit.
   */
  private _ShowApiMessages = true;

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.Control) {
      if (this._ObjectIdSubscription) {
        this._ObjectIdSubscription.unsubscribe();
      }

      this._ControlName = this._FormService.GetControlName(this.Control);
      this._ObjectId = this._FormService.GetIdValue(
        this.Control.parent as FormGroup
      );
    }

    if (changes.Control || changes.ApiMessages) {
      this._SetFilteredApiMessages();
    }

    this._SetMessages();
  }

  /**
   * On init.
   */
  ngOnInit() {
    if (this.Control) {
      const touchedChanges$ = this._ExtractTouchedChanges(this.Control);

      this._ListenForIdChanges();

      this._ControlStatusSubscription = this.Control.statusChanges.subscribe(
        () => this._SetMessages()
      );

      this._ControlTouchedSubscription = touchedChanges$.subscribe(() => {
        this._ShowApiMessages = false;
        this._SetMessages();
      });
    }

    this._SubmitSubscription = this._NgForm.ngSubmit.subscribe(() => {
      this._ShowApiMessages = true;
      this._FilteredApiMessages = [];
      this._SetMessages();
    });
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    if (this._ControlStatusSubscription) {
      this._ControlStatusSubscription.unsubscribe();
    }

    if (this._ControlTouchedSubscription) {
      this._ControlTouchedSubscription.unsubscribe();
    }

    if (this._SubmitSubscription) {
      this._SubmitSubscription.unsubscribe();
    }

    if (this._ObjectIdSubscription) {
      this._ObjectIdSubscription.unsubscribe();
    }
  }

  /**
   * On click ignore.
   *
   * @param item Message to be ignored.
   */
  OnClickIgnore(item: ValidationMessage) {
    this.OnIgnore.emit(item.id);
  }

  /**
   * Collections of messages to display.
   */
  private _SetMessages() {
    // do not show FormGroup level messages on touched, wait for submit
    const show =
      this._NgForm.submitted ||
      (this.Control instanceof FormControl && this.Control.touched);

    if (!show) {
      this.Messages = [];
      return;
    }

    const messages: ValidationMessage[] = this._GetErrorMessages();

    if (this._ShowApiMessages && this._FilteredApiMessages.length > 0) {
      messages.push(...this._FilteredApiMessages);
    }

    if (this.CustomMessages) {
      messages.push(...this.CustomMessages);
    }

    this.Messages = messages;
  }

  /**
   * Get all form validation error messages.
   */
  private _GetErrorMessages() {
    if (!this.Control || !this.Control.errors) {
      return [];
    }

    return Object.keys(this.Control.errors).map((key) => {
      const params = this.Control.errors[key];
      const msg = ERROR_MESSAGES[key];
      const text = this._L10n.instant(msg, params);
      let id: string;
      let ignorable: boolean;
      let ignoreLabelL10nKey: string;

      if (isObject(params)) {
        const msg = params as ValidationMessage;

        id = msg.id;
        ignorable = msg.ignorable;
        ignoreLabelL10nKey = msg.ignoreLabelL10nKey;
      }

      return { text, id, ignorable, ignoreLabelL10nKey };
    });
  }

  /**
   * Get all api messages specific to the current control.
   */
  private _SetFilteredApiMessages() {
    const messages: ValidationMessage[] = [];

    if (!this._ControlName || !this.ApiMessages) {
      this._FilteredApiMessages = messages;
      return;
    }

    this.ApiMessages.forEach((item) => {
      const field = item.getFirstUiField();

      if (field) {
        let match = this._ControlName === field.FieldName;

        if (!match && this.AlternativeNames) {
          match = this.AlternativeNames.indexOf(field.FieldName) !== -1;
        }

        if (match && this._ObjectId === field.ObjectID) {
          messages.push({ text: item.blurb });
        }
      }
    });

    this._FilteredApiMessages = messages;
  }

  /**
   * Listen for changes to ID control value.
   */
  private _ListenForIdChanges() {
    const control = this._FormService.GetIdControl(
      this.Control.parent as FormGroup
    );

    if (!control) {
      return;
    }

    this._ObjectIdSubscription = control.valueChanges.subscribe((value) => {
      this._ObjectId = this._FormService.ParseIdValue(value);
      this._SetFilteredApiMessages();
      this._SetMessages();
    });
  }

  /**
   * Get an observable to track touched changes on an abstract control.
   *
   * https://github.com/angular/angular/issues/10887#issuecomment-547392548
   *
   * @param control Source control.
   */
  private _ExtractTouchedChanges(
    control: ObjectLike<AbstractControl, 'markAsTouched' | 'markAsUntouched'>
  ) {
    const prevMarkAsTouched = control.markAsTouched;
    const prevMarkAsUntouched = control.markAsUntouched;

    const touchedChanges$ = new Subject<boolean>();

    function nextMarkAsTouched(
      ...args: ArgumentsType<AbstractControl['markAsTouched']>
    ) {
      prevMarkAsTouched.bind(control)(...args);
      touchedChanges$.next(true);
    }

    function nextMarkAsUntouched(
      ...args: ArgumentsType<AbstractControl['markAsUntouched']>
    ) {
      prevMarkAsUntouched.bind(control)(...args);
      touchedChanges$.next(false);
    }

    control.markAsTouched = nextMarkAsTouched;
    control.markAsUntouched = nextMarkAsUntouched;

    return touchedChanges$;
  }
}
