import { Component, OnInit } from '@angular/core';
import { IJob } from '@zipcrim/common';
import { JobService } from '@zipcrim/work-flow';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map, mergeMap } from 'rxjs/operators';

import { ReportService } from '../report.service';

/**
 * Drug and alcohol document upload.
 */
@Component({
  selector: 'c2h-drug-and-alcohol',
  templateUrl: 'drug-and-alcohol.component.html',
})
export class DrugAndAlcoholComponent implements OnInit {
  constructor(
    private _JobService: JobService,
    private _ReportService: ReportService
  ) {}

  /**
   * Available jobs.
   */
  Jobs: IJob[];

  /**
   * True if the user has choose to ignore drug and alcohol.
   */
  IsIgnored = false;

  /**
   * Busy saving indicator.
   */
  BusySaving: boolean;

  /**
   * Job to file map.
   */
  private _JobFiles = new Map<IJob, File>();

  /**
   * On init.
   */
  ngOnInit(): void {
    this._GetJobs().subscribe();
  }

  /**
   * On file change.
   *
   * @param job Associated job record.
   * @param File change event.
   */
  OnFileChange(job: IJob, file: File) {
    this._JobFiles.set(job, file);
  }

  /**
   * event handler to hide this panel and ignore
   */
  OnIgnore() {
    this.IsIgnored = true;
  }

  /**
   * On submit.
   */
  OnSubmit() {
    const saves$: Array<Observable<any>> = [];

    for (const [job, file] of this._JobFiles) {
      if (file) {
        saves$.push(this._SaveFile(job, file));
      }
    }

    if (saves$.length === 0) {
      return;
    }

    this.BusySaving = true;
    forkJoin(saves$)
      .pipe(
        mergeMap(() => this._GetJobs()),
        finalize(() => (this.BusySaving = false))
      )
      .subscribe();
  }

  /**
   * On file change.
   *
   * @param job Associated job record.
   * @param File change event.
   */
  private _SaveFile(job: IJob, file: File) {
    const data = new FormData();
    data.append('file', file);
    data.append('parameters', '{}');

    return this._JobService.saveDocument(job.ID, data);
  }

  /**
   * Get drug and alcohol upload jobs.
   */
  private _GetJobs() {
    const caseNum = this._ReportService.CaseNum;

    return this._JobService
      .getJobsByClassAndObject('CASE', caseNum, 'DIQ_UploadDOTRelease')
      .pipe(
        map((res) => {
          const jobs = res.Items.filter(
            (item) =>
              item.UiClassName === 'UploadDocumentGen' &&
              item.Status === 'Incomplete'
          );

          jobs.forEach((item) => {
            // As per Ed, this job is only applicable for US and is always in English
            item.DescriptionBlurb = item.DescriptionBlurb.replace(
              'Upload Employer Specific Release/Consent: ',
              ''
            );
          });

          return jobs;
        })
      );
  }
}
