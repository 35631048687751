/**
 * Fuzzy date static codes.
 */
export enum FuzzyDateStaticCode {
  Autumn = 'Autumn',
  Invalid = 'Invalid',
  NotApplicable = 'N/A',
  NotStated = 'Not Stated',
  Present = 'Present',
  Spring = 'Spring',
  Summer = 'Summer',
  Unknown = 'Unknown',
  Unspecified = 'Unspecified',
  Winter = 'Winter',
}
