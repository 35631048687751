import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { L10nService } from '@zipcrim/common';
import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { DynamicFormFieldComponent } from '../form-field/form-field.component';
import { FormService } from '@zipcrim/forms/form.service';
import { OptionConfig } from '@zipcrim/forms/forms.interfaces';

/**
 * Form select.
 *
 * ```html
 * <zc-dynamic-select [Options]="myOptions" [(ngModel)]="myValue"></zc-dynamic-select>
 * ```
 */
@Component({
  selector: 'zc-dynamic-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicSelectComponent),
      multi: true,
    },
  ],
})
export class DynamicSelectComponent
  implements ControlValueAccessor, OnInit, OnChanges, OnDestroy
{
  constructor(
    private _L10n: L10nService,
    private _FormService: FormService,
    @Optional() private _FormField: DynamicFormFieldComponent
  ) {
    // used in `DynamicNodeItemComponent`
    this.OptionChange = combineLatest([
      this._ValueChange.asObservable(),
      this._OptionsChange.asObservable(),
    ]).pipe(
      map(
        () => this.AllOptions.find((item) => item.value === this.Value) || null
      )
    );

    this._SelectionSubscription = this.OptionChange.pipe(
      tap((option) => this.SelectionChange.next(option))
    ).subscribe();
  }

  /**
   * Input value getter / setter.
   */
  get Value() {
    return this._Value;
  }

  set Value(value) {
    this._Value = value;
    this._ValueChange.next();
    this.onChange(value);
  }

  /**
   * Emits the selected option when the value changes.
   */
  @Output()
  SelectionChange = new EventEmitter<OptionConfig>();

  /**
   * Input id.
   */
  @Input()
  Id: string;

  /**
   * Indicates if the input is disabled.
   */
  @Input()
  Disabled: boolean;

  /**
   * Indicates if the input is readonly.
   *
   * Note: HTML does not support the `readonly` attribute on `<select>` elements. This will set the `disabled` attribute
   * on the element, but not set the associated `FormControl` to disabled.
   */
  @Input()
  Readonly: boolean;

  /**
   * Input placeholder text.
   */
  @Input()
  Placeholder: string;

  /**
   * Deselect option text.
   */
  @Input()
  Deselect: string;

  /**
   * Options.
   */
  @Input()
  Options: OptionConfig[];

  /**
   * Async options.
   */
  @Input()
  OptionsAsync?: Observable<OptionConfig[]>;

  /**
   * All available options.
   */
  AllOptions?: OptionConfig[];

  /**
   * Emits a value when the value changes AND the associated option is available.
   */
  OptionChange: Observable<OptionConfig | null>;

  /**
   * Indicates if the async options is busy.
   */
  IsLoading: boolean;

  /**
   * Indicates if the async options failed.
   */
  IsFailure: boolean;

  HasRequired$: Observable<boolean>;

  /**
   * Input value.
   */
  private _Value: any;

  /**
   * Emits a value any time the value is changed.
   */
  private _ValueChange = new ReplaySubject<void>(1);

  /**
   * Emits a value any time the options have changed.
   */
  private _OptionsChange = new ReplaySubject<void>(1);

  /**
   * Options subscription.
   */
  private _OptionsSubscription: Subscription;

  /**
   * Selection subscription.
   */
  private _SelectionSubscription: Subscription;

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.Options || changes.OptionsAsync) {
      this._LoadOptions();
    }
  }

  /**
   * On init.
   */
  ngOnInit() {
    this.HasRequired$ = this._FormField?.HasRequired ?? of(false);

    if (!this.Id && this._FormField) {
      this.Id = this._FormField.Id;
    }
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    if (this._OptionsSubscription) {
      this._OptionsSubscription.unsubscribe();
    }

    if (this._SelectionSubscription) {
      this._SelectionSubscription.unsubscribe();
    }
  }

  /**
   * On blur.
   */
  OnBlur() {
    this.onTouched();
  }

  /**
   * On retry options.
   */
  OnRetry() {
    this.IsFailure = false;
    this._LoadOptions();
  }

  /**
   * On change.
   */
  onChange = (_: any) => {
    // empty
  };

  /**
   * On touched.
   */
  onTouched = () => {
    // empty
  };

  /**
   * Write value.
   */
  writeValue(value: any) {
    if (this._FormService.HasValue(value)) {
      this.Value = value;
    }
  }

  /**
   * Register on change.
   */
  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  /**
   * Register on touched.
   */
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  /**
   * Set disabled state.
   */
  setDisabledState(isDisabled: boolean) {
    this.Disabled = isDisabled;
  }

  /**
   * Check if the current value exists in an option; Reset value to `null` if a corresponding
   * option does not exist.
   */
  private _IsValueInOptions() {
    if (!this._Value || this.AllOptions.length === 0) {
      // data not available
      return;
    }

    const exists = this.AllOptions.some((x) => x.value === this._Value);

    if (!exists) {
      /**
       * No option available for current value, reset value.
       *
       * Use case:
       * Control "B" has an initial value and dynamic options based on control "A" value. When
       * control "A" changes, the options for control "B" change no option exists for the original
       * value of control "B". Example: country -> state controls.
       */
      this.Value = null;
    }
  }

  /**
   * Combine options and async options.
   */
  private _LoadOptions() {
    if (this._OptionsSubscription) {
      this._OptionsSubscription.unsubscribe();
    }

    if (!this.OptionsAsync) {
      this._SetAllOptions(this.Options || []);
      return;
    }

    // reset
    this.AllOptions = [];
    this.IsLoading = true;

    this._OptionsSubscription = this.OptionsAsync.pipe(
      map((items) => (this.Options ? [...this.Options, ...items] : items)),
      tap((items) => this._SetAllOptions(items)),
      catchError((error) => {
        this.IsFailure = true;
        throw error;
      }),
      finalize(() => (this.IsLoading = false))
    ).subscribe();
  }

  /**
   * Set `AllOptions` and emit change value.
   *
   * @param item Source collection of options.
   */
  private _SetAllOptions(items: OptionConfig[]) {
    items.forEach((item) => {
      item.label = item.labelL10nKey
        ? this._L10n.instant(item.labelL10nKey)
        : item.label;
    });

    this.AllOptions = items;
    this._OptionsChange.next();
    this._IsValueInOptions();
  }
}
