import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { FilterSpec } from '../../api/filter-spec';
import { SaveSpec } from '../../api/save-spec';
import {
  IBoList,
  ILeadRequest,
  IReferenceField,
  IValidationRule,
  IWorkOrder,
} from '../../generated';
import { SubjectSummary, WorkOrderPropertyParam } from './work-order.interface';

/**
 * Work order API interactions.
 */
@Injectable({ providedIn: 'root' })
export class WorkOrderService {
  constructor(private api: ApiService) {}

  /**
   * Analyze work order.
   *
   * @param id Work order id.
   */
  analyze(id: number) {
    return this.api
      .post<void>(`workOrder/${id}/analyze`, {})
      .pipe(map((res) => res.payload));
  }

  /**
   * Process work order.
   *
   * @param id Work order id.
   */
  process(id: number) {
    return this.api
      .post<{ CaseNum: string }>(`workOrder/${id}/process`, {})
      .pipe(map((res) => res.payload));
  }

  /**
   * Get lead requests for a work order.
   *
   * @param id Work order id.
   */
  getLeadRequests(id: number) {
    const params = new HttpParams().append('isForcedList', 'true');

    return this.api
      .get<IBoList<ILeadRequest>>(`workOrder/${id}/leadRequests`, params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Save a work order.
   *
   * @param id Work order id.
   * @param spec Save spec.
   */
  save(id: number, spec: SaveSpec) {
    return this.api
      .post<{ ID: number; SubjectID: number }>(
        `workOrder/${id ? id : ''}`,
        spec
      )
      .pipe(map((res) => res.payload));
  }

  /**
   * Save a work order property.
   *
   * @param id Work order id.
   * @param property Property name.
   * @param spec Save spec.
   */
  saveProperty(id: number, property: WorkOrderPropertyParam, spec: SaveSpec) {
    return this.api
      .post<void>(`workOrder/${id}/${property}`, spec)
      .pipe(map((res) => res.payload));
  }

  /**
   * Remove a work order.
   *
   * @param id Work order id.
   */
  remove(id: number) {
    return this.api
      .post(`workOrder/${id}/remove`, {})
      .pipe(map((res) => res.payload));
  }

  /**
   * Get a work order by id.
   *
   * @param id Work order id.
   */
  get(id: number) {
    return this.api
      .get<IWorkOrder>(`workOrder/${id}`)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get work order property.
   *
   * @param id Work order id.
   * @param property Property name.
   */
  getProperty(
    id: number,
    property: 'LeadRequests'
  ): Observable<IBoList<ILeadRequest>>;
  getProperty(
    id: number,
    property: 'ReferenceFields'
  ): Observable<IBoList<IReferenceField>>;
  getProperty(id: number, property: WorkOrderPropertyParam) {
    return this.api
      .get<IBoList<unknown>>(`workOrder/${id}/${property}`)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get work order subject summary.
   *
   * @param id Work order id.
   * @param refreshValidation Indicates if validation refresh is required.
   */
  getSubjectSummary(id: number, refreshValidation?: boolean) {
    const param = refreshValidation ? 'true' : 'false';

    return this.api
      .get<SubjectSummary>(`workOrder/${id}/subjectSummary/${param}`)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get work order validation errors.
   *
   * @param id Work order id.
   */
  getValidationErrors(id: number) {
    return this.api.get<void>(`workOrder/${id}/validationErrors`);
  }

  /**
   * Get work order validation rules.
   *
   * @param id Work order id.
   * @param filterSpec Optional filter spec.
   */
  getValidationRules(id: number, filterSpec?: FilterSpec) {
    let params: HttpParams | undefined;

    if (filterSpec) {
      params = new HttpParams().append('filterSpec', filterSpec.toString());
    }

    return this.api
      .get<IBoList<IValidationRule>>(`workOrder/${id}/validationRules`, params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Complete SmartClient job.
   *
   * @param id Work order id.
   */
  completeSmartClientJob(id: number) {
    return this.api
      .post(`workOrder/${id}/completeSmartClientJob`, {})
      .pipe(map((res) => res.payload));
  }

  /**
   * Get work order ui meta.
   *
   * @param id Work order id.
   */
  getUiMeta(id: number) {
    return this.api
      .get(`workOrder/${id}/uimeta`)
      .pipe(map((res) => res.payload));
  }
}
