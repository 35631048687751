import {
  faBan,
  faCheck,
  faCheckCircle,
  faCloudDownloadAlt,
  faCopy,
  faEdit,
  faExclamationTriangle,
  faMinus,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

export const COMMON_ICONS = [
  faBan,
  faCheck,
  faCheckCircle,
  faCloudDownloadAlt,
  faCopy,
  faEdit,
  faExclamationTriangle,
  faMinus,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
];
