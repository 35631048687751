import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SsnFormat',
})
export class SsnFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    const stringNumber = typeof value === 'string' ? value : value.toString();
    let valueToReturn = '';

    if (stringNumber.length === 0) {
      return valueToReturn;
    }

    const onlyNumbersSsn = /^\d+$/;

    if (!onlyNumbersSsn.test(stringNumber)) {
      return stringNumber;
    }

    if (stringNumber.length <= 3) {
      valueToReturn = stringNumber.replace(/^(\d{0,3})/, '$1');
    } else if (stringNumber.length <= 5) {
      valueToReturn = stringNumber.replace(/^(\d{0,3})(\d{0,2})/, '$1-$2');
    } else {
      valueToReturn = stringNumber.replace(
        /^(\d{0,3})(\d{0,2})(.*)/,
        '$1-$2-$3'
      );
    }

    return valueToReturn;
  }
}
