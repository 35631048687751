import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { ButtonColor, ButtonSize } from './button.interfaces';

/**
 * Display a button.
 *
 * ```html
 * <zc-button color="primary" (click)="OnClick()">Click Me!</zc-button>
 * ```
 */
@Component({
  selector: 'zc-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnChanges {
  constructor(@Optional() private parent: NgForm) {}

  @Input() type = 'button';
  @Input() size: ButtonSize;
  @Input() disabled = false;
  @Input() busy: boolean;
  @Input() busyText = 'Loading';
  @Input() color: ButtonColor = 'secondary';
  @Input() buttonClasses: string[];

  @Input()
  @HostBinding('class.d-block')
  get block(): boolean | string {
    return this._block;
  }

  set block(value: boolean | string) {
    this._block = value === '' ? true : !!value;
  }

  @Input()
  get outline(): boolean | string {
    return this._outline;
  }

  set outline(value: boolean | string) {
    this._outline = value === '' ? true : !!value;
  }

  /**
   * Form reference used to submit an associated form when button is outside of the form.
   */
  @Input()
  form: NgForm;

  css: string;

  @ViewChild('btn', { read: ViewContainerRef })
  private buttonRef: ViewContainerRef;

  private _block = false;
  private _outline = false;

  ngOnChanges() {
    this.setCss();
  }

  /**
   * Set focus to current button.
   */
  focus() {
    if (this.buttonRef) {
      this.buttonRef.element.nativeElement.focus();
    }
  }

  /**
   * Catch event bubbled from button (or inner html) and stop propagation to prevent the host's
   * `(click)` from executing.
   *
   * Note: `<span>` in template is needed because `@HostListener` doesn't prevent outer `(click)`.
   * https://github.com/angular/angular/issues/9587
   *
   * @param event Click event.
   */
  onClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    this.submitForm();
  }

  /**
   * Safely submit associated form, when applicable.
   */
  private submitForm() {
    if (this.parent) {
      // button is within <form>, let browser handle submit
      return;
    }

    if (this.type === 'submit' && this.form) {
      this.form.onSubmit(null);
    }
  }

  private setCss() {
    const classes = ['btn'];

    if (this.outline) {
      classes.push(`btn-outline-${this.color}`);
    } else {
      classes.push(`btn-${this.color}`);
    }

    if (this.size) {
      classes.push(`btn-${this.size}`);
    }

    if (this._block) {
      classes.push('btn-block');
    }

    if (this.buttonClasses) {
      classes.push(...this.buttonClasses);
    }

    this.css = classes.join(' ');
  }
}
