<div class="signup">
  <div class="d-flex p-2">
    <div class="flex-grow-1">
      <a routerLink="/dashboard">
        <zc-image
          file="img/logo.svg"
          alt="CheckToHire"
          class="logo__wrapper"
        ></zc-image>
      </a>
    </div>
    <zc-nav>
      <zc-nav-item (click)="OnLogout()">
        {{ 'Common.cmdSignOut' | translate }}
      </zc-nav-item>
    </zc-nav>
  </div>

  <div class="container container--signup">
    <router-outlet></router-outlet>
  </div>
</div>
