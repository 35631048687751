import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import get from 'lodash/get';
import isEqualWith from 'lodash/isEqualWith';

/**
 * Validate all associated controls have the same value.
 *
 * @param names Associated form control names to validate.
 * @param customizer Optional function to customize comparison.
 */
export function MatchControlsValidator(
  names: string[],
  customizer?: (a: any, b: any) => boolean
) {
  // cache holds multiple controls to handle validator used in collections
  const cache = new Map<AbstractControl, FormGroup>();

  return (control: AbstractControl): ValidationErrors | null => {
    const root = control.root;

    if (!root || !root.value || names.length === 0) {
      return null;
    }

    let parent = cache.get(control);

    if (!parent) {
      parent = findParent(control);
      cache.set(control, parent);
    }

    const value = control.value;
    const formValues = parent.value;
    const match = names.every((name) =>
      isEqualWith(get(formValues, name), value, customizer)
    );

    return match ? null : { matchControls: true };
  };
}

/**
 * Find the highest level `FormGroup` within a `FormArray` relative to `control`, or the form root if the `control` is
 * not an descendant of a `FormArray`.
 *
 * @param control Starting form control.
 */
function findParent(control: AbstractControl) {
  let current = control.parent;

  while (current.parent && current !== current.parent) {
    if (current.parent instanceof FormArray) {
      // next level up is an array, return this group
      return current as FormGroup;
    }

    current = current.parent;
  }

  // no array found, return the root
  return current as FormGroup;
}
