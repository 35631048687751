<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ job?.Description }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <form>
      <!-- todo -->
    </form>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button color="info" outline class="me-1" (click)="onCancel()">
      {{ 'Common.lblCancel' | translate }}
    </zc-button>
    <zc-button color="primary" (click)="onSubmit()">
      {{ 'Common.cmdSubmit' | translate }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
