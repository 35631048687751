import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { ApiService } from '../api/api.service';
import { IAttestOption, IBoList } from '../generated';
import { ModalWithResult } from '../modal/modal-with-result';
import { AttestOption, AttestPrompt } from '../models';

@Component({
  templateUrl: './attest-modal.component.html',
})
export class AttestModalComponent extends ModalWithResult {
  constructor(private api: ApiService, protected bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  @Input() items: AttestPrompt[];
  @ViewChild('form') form: NgForm;
  busyLoading: boolean;
  busySaving: boolean;

  /**
   * Note: Manually executed by modal caller because `ngOnInit` is called before properties are set.
   */
  onModalInit() {
    const sources = this.items.map((item) =>
      this.getOptions(item).pipe(tap((res) => (item.Options = res)))
    );

    this.busyLoading = true;
    forkJoin(sources)
      .pipe(finalize(() => (this.busyLoading = false)))
      .subscribe();
  }

  onCancel() {
    this.dismiss('cancelled');
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const sources = this.items.filter((item) => !!item.AttestOptionID).map(
      (item) => this.update(item)
    );

    if (sources.length === 0) {
      this.close();
      return;
    }

    this.busySaving = true;
    forkJoin(sources)
      .pipe(finalize(() => (this.busySaving = false)))
      .subscribe((res) => this.close(res));
  }

  /**
   * Get available options for an attest item.
   *
   * @param item Attest item to get options for.
   */
  private getOptions(item: AttestPrompt) {
    return this.api
      .get<IBoList<IAttestOption>>(
        `subjectValidationError/${item.ValidationID}/attestOptions`
      )
      .pipe(
        map((res) =>
          res.payload.Items.map((x) => new AttestOption(x)).sort(
            (a, b) => a.SortOrder - b.SortOrder
          )
        )
      );
  }

  /**
   * Update an attest item.
   *
   * @param item Attest item to update.
   */
  private update(item: AttestPrompt) {
    return this.api.post(
      `subjectValidationError/${item.ValidationID}/updateAttestation/${item.AttestOptionID}`,
      {}
    );
  }
}
