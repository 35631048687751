import { Injectable } from '@angular/core';

import { WindowExt } from './window-ref.interfaces';

/**
 * Global `window` reference.
 */
@Injectable({ providedIn: 'root' })
export class WindowRefService {
  get nativeWindow(): WindowExt {
    // eslint-disable-next-line
    return window;
  }
}
