<div class="checkbox" [class.disabled]="Disabled">
  <label>
    <input
      type="checkbox"
      zcFormFieldAriaDescribedBy
      (blur)="OnBlur()"
      [attr.aria-required]="HasRequired$ | async"
      [id]="Id || ''"
      [readonly]="Readonly"
      [disabled]="Disabled"
      [checked]="Value"
      (change)="Value = !Value"
    />
    <ng-content></ng-content>
  </label>
  <ng-content select="zc-dynamic-help-text"></ng-content>
</div>
