import { Component, Input } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-common-types';

import { BadgeColor } from './badge.interfaces';

/**
 * Form badge component.
 *
 * ```html
 * <zc-dynamic-badge Color="success" Icon="check"></zc-dynamic-badge>
 * ```
 */
@Component({
  selector: 'zc-dynamic-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class DynamicBadgeComponent {
  /**
   * Badge icon.
   */
  @Input()
  Icon: IconName;
  // TODO: EJA: new: Icon: string;

  /**
   * Badge color.
   */
  @Input()
  Color: BadgeColor;
}
