import { Component, ViewChild } from '@angular/core';

import { TimelineItemComponent } from '../timeline/timeline-item.component';
import { TimelineDotState } from '../timeline/timeline.interfaces';

@Component({
  selector: 'zc-step',
  templateUrl: './step.component.html',
})
export class StepComponent {
  @ViewChild(TimelineItemComponent, { static: true })
  timelineItemRef: TimelineItemComponent;

  state: TimelineDotState;
}
