<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ label }}</h4>
  </zc-modal-header>

  <zc-modal-body>
    <form #form="ngForm" (ngSubmit)="onSubmit()">
      <ng-container [formGroup]="formGroup">
        <zc-form-field>
          <zc-radio-group
            formControlName="type"
            (selectionChange)="onTypeChange($event)"
            zcAutoFocus>
            <zc-radio-button
              *ngFor="let item of typeOptions"
              [value]="item.value">
              {{ item.labelL10nKey | translate }}
            </zc-radio-button>
          </zc-radio-group>
        </zc-form-field>

        <zc-form-field *ngIf="showDate">
          <zc-date
            formControlName="date"
            [hideDay]="hideDay"
            [monthPicker]="monthPicker"></zc-date>
        </zc-form-field>
      </ng-container>
    </form>
  </zc-modal-body>

  <zc-modal-footer>
    <div class="flex-grow-1" *ngIf="!options?.required">
      <zc-button color="link" (click)="onClear()">
        {{ 'Common.btnClear' | translate }}
      </zc-button>
      <div class="flex-grow-1"></div>
    </div>
    <zc-button color="secondary" outline class="me-1" (click)="onCancel()">
      {{ 'Common.lblCancel' | translate }}
    </zc-button>
    <zc-button type="submit" color="primary" [busy]="busySaving" [form]="form">
      {{ 'Common.cmdContinue' | translate }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
