import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * Base modal class to provide `Result` promise.
 */
@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class ModalWithResult<T = unknown> {
  constructor(protected bsModalRef: BsModalRef) {
    this.init();
  }

  /**
   * Promise resolved or rejected when modal is closed.
   */
  result: Promise<T>;

  /**
   * Resolve result.
   */
  private resolve: (result?: T) => void;

  /**
   * Reject result.
   */
  private reject: (reason?: any) => void;

  /**
   * Close the modal and resolve the `Result`.
   *
   * @param result Optional result.
   */
  protected close(result?: T) {
    this.bsModalRef.hide();
    this.resolve(result);
  }

  /**
   * Dismiss the modal and reject `Result`.
   *
   * @param reason Optional reason.
   */
  protected dismiss(reason?: any) {
    this.bsModalRef.hide();
    this.reject(reason);
  }

  /**
   * Initialize result promise.
   */
  private init() {
    this.result = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
