<ng-container ngModelGroup="roles">
  <ng-container *ngIf="hasOptions">
    <ng-content></ng-content>
    <zc-form-field [busy]="busyLoading">
      <zc-label>
        {{ 'Common.lblShowRolesFor' | translate }}
      </zc-label>
      <zc-select
        name="clientCode"
        required
        [disabled]="hasNoRole"
        [(ngModel)]="clientCode"
        (ngModelChange)="onClientChange($event)"
        [options]="clientOptions">
      </zc-select>
    </zc-form-field>
  </ng-container>
  <ng-container *ngIf="currentDomain">
    <alert type="danger" *ngIf="hasNoRole">
      <fa-icon icon="exclamation-triangle" class="me-1"></fa-icon>
      {{ 'Common.lblRoleRequired' | translate }}
    </alert>
    <div class="row">
      <div class="col-md-6">
        <zc-form-field>
          <zc-label>
            {{ 'Common.lblPrimaryRoles' | translate }}
          </zc-label>
          <zc-radio-group
            [(ngModel)]="currentDomain.primary"
            name="primary"
            required>
            <zc-radio-button
              *ngFor="let item of radioOptions"
              [value]="item.value">
              {{ item.label || (item.labelL10nKey | translate) }}
            </zc-radio-button>
          </zc-radio-group>
        </zc-form-field>
      </div>
      <div class="col-md-6">
        <zc-form-field>
          <zc-label>{{ 'Common.lblSecondaryRoles' | translate }}</zc-label>
          <zc-checkbox
            *ngFor="let option of checkboxOptions"
            [(ngModel)]="option.value">
            {{ option.label }}
            <zc-help-text *ngIf="option.description">
              {{ option.description }}
            </zc-help-text>
          </zc-checkbox>
        </zc-form-field>
      </div>
    </div>
  </ng-container>
</ng-container>
