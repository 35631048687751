<div
  class="file-upload"
  ng2FileDrop
  (onFileDrop)="onFileDrop($event)"
  (fileOver)="onFileOver($event)"
  [ngClass]="{ 'file-upload--is-dragging': isFileOver }"
  [uploader]="uploader"
>
  <!-- form -->
  <form>
    <input
      type="file"
      class="file-upload__input"
      (change)="onFileSelect($event)"
      [accept]="accept"
      [id]="id"
    />
  </form>

  <!-- label -->
  <label class="file-upload__label" [for]="id">
    <span class="btn btn-outline-info">
      {{ 'Common.lblBrowseFile' | translate }}
    </span>
    <span class="file-upload__description">
      {{ 'Common.lblDragFileHere' | translate }}
    </span>
  </label>

  <!-- caption -->
  <p
    class="file-upload__caption"
    translate
    [translateParams]="{ types: accept }"
  >
    Common.lblSupportedFileTypes
  </p>
</div>
