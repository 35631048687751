import { IJob, IJobStatus, IUiClassName } from '../generated';

/**
 * Job model.
 */
export class Job {
  constructor(source: Partial<IJob>) {
    this._Init(source);
  }

  /**
   * Job ID.
   */
  ID: number;

  /**
   * Job UI class name.
   */
  UiClassName: IUiClassName;

  /**
   * Job statues.
   */
  Status: IJobStatus;

  /**
   * Localized job description.
   */
  Description: string;

  /**
   * Localized job help text.
   */
  HelpText?: string;

  /**
   * Indicates if the job can be claimed.
   */
  CanBeClaimed?: boolean;

  /**
   * Indicates if the job can be completed.
   */
  CanBeCompleted?: boolean;

  /**
   * Indicates if the job is waiting to be completed by something else.
   */
  IsPending?: boolean;

  /**
   * Indicates if job's action can be accessed.
   */
  get CanAction(): boolean {
    return (
      this.CanBeCompleted &&
      this.Status !== 'Complete' &&
      this.Status !== 'Blocked'
    );
  }

  /**
   * Flattened list of all unique job dependencies.
   */
  FlatDependencies: Job[];

  /**
   * Direct blocking job dependencies.
   */
  BlockingDependencies: Job[];

  /**
   * Hydrate object properties from a source Job.
   *
   * @param source Source job used to hydrate.
   */
  private _Init(source: Partial<IJob>) {
    this.ID = source.ID;
    this.UiClassName = source.UiClassName;
    this.Status = source.Status;

    if (source.UserRights) {
      this.CanBeClaimed = source.UserRights.some(
        (item) => item === 'CanBeClaimed'
      );
      this.CanBeCompleted = source.UserRights.some(
        (item) => item === 'CanBeCompleted'
      );
    } else {
      this.CanBeClaimed = false;
      this.CanBeCompleted = false;
    }

    this.IsPending = !this.CanBeCompleted && this.Status === 'Incomplete';
    this.Description = source.DescriptionBlurb;
    this.HelpText = source.HelpTextBlurb;
  }
}
