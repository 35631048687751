import {
  Component,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';

import { TimelineDotState as TimelineItemState } from './timeline.interfaces';

@Component({
  selector: 'zc-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent implements OnChanges, OnInit {
  constructor(
    @SkipSelf() @Host() @Optional() private parent: TimelineItemComponent
  ) {}

  @Input() state: TimelineItemState;
  @Input() link: any[] | string;
  @Input() queryParams: { [key: string]: any };
  css: string;

  ngOnChanges() {
    this.setCss();
  }

  ngOnInit() {
    this.setCss();
  }

  private setCss() {
    const classes = [];

    if (this.link) {
      classes.push('timeline__item--link');
    }

    if (this.state === 'active') {
      classes.push('timeline__item--active');
    }

    if (this.parent) {
      classes.push('timeline__item--sub');
    }

    this.css = classes.join(' ');
  }
}
