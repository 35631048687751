<div class="notification" *ngIf="notifications.length > 0">
  <!-- header -->
  <div class="notification__header">
    <h1 class="notification__title">
      {{ 'Common.lblConnectionIssues' | translate }}
    </h1>
    <button class="close" (click)="onDismiss()" aria-label="dismiss alert">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>

  <div class="notification__content">
    <!-- notifications -->
    <ul class="notification__list">
      <li
        class="notification__item"
        *ngFor="let item of notifications; let i = index"
        [hidden]="!showAll && i > 0">
        <fa-icon icon="exclamation-triangle" class="me-1"></fa-icon>
        {{ item.severity }}: {{ item.description }}
      </li>
    </ul>

    <!-- toggle -->
    <zc-button
      color="link"
      *ngIf="notifications.length > 1"
      (click)="onToggle()">
      {{
        (showAll ? 'Common.cmdShowLessErrors' : 'Common.cmdShowMoreErrors')
          | translate
      }}
    </zc-button>
  </div>
</div>
