import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CaseService, FieldSpec, FileService } from '@zipcrim/common';
import { finalize, tap } from 'rxjs/operators';

import { ReportService } from '../report.service';

/**
 * Subject summary.
 */
@Component({
  selector: 'c2h-subject-summary',
  templateUrl: './subject-summary.component.html',
  styleUrls: ['./subject-summary.component.scss'],
})
export class SubjectSummaryComponent implements OnInit {
  constructor(
    private _Case: CaseService,
    private _ReportService: ReportService,
    private _ZFileService: FileService
  ) {}

  /**
   * Case number.
   */
  @Input()
  CaseNumber: string;

  /**
   * Subject summary data.
   */
  SummaryData: {
    Name: string;
    Dob: Date;
    Ssn: string;
  };

  /**
   * Busy loading indicator.
   */
  BusyLoading: boolean;

  /**
   * Busy downloading indicator.
   */
  BusyDownloading: boolean;

  /**
   * Used to store status of download report is visible or not
   */
  IsReportDownloadable = false;

  /**
   * On init.
   */
  ngOnInit() {
    this.BusyLoading = true;
    this._GetSubject()
      .pipe(finalize(() => (this.BusyLoading = false)))
      .subscribe();

    this._ReportService.HasContent.subscribe(
      (res) => (this.IsReportDownloadable = res)
    );
  }

  /**
   * Used to download report.
   */
  OnDownloadReport() {
    if (!this.CaseNumber) {
      return;
    }

    const url = `case/${this.CaseNumber}/renderedReport`;
    this.BusyDownloading = true;
    this._ZFileService
      .download(url, null, true)
      .pipe(finalize(() => (this.BusyDownloading = false)))
      .subscribe();
  }

  /**
   * Get subject data.
   */
  private _GetSubject() {
    const fieldSpec = new FieldSpec().add([
      'ID',
      'CaseNum',
      'SubjectID',
      'Subject',
      'Subject.Names',
      'Subject.Dobs',
      'Subject.Ssns',
    ]);
    const params = new HttpParams().append('fieldSpec', fieldSpec.toString());

    return this._Case.getByNum(this.CaseNumber, params).pipe(
      tap((res) => {
        const name = res.Subject.Names.Items[0];
        const dob = res.Subject.Dobs.Items[0];
        const ssn = res.Subject.Ssns.Items[0];

        this.SummaryData = {
          Name: name ? name.FullName : null,
          Dob: dob ? new Date(dob.Year, dob.Month, dob.Day) : null,
          Ssn: ssn ? `...${ssn.Last4}` : null,
        };
      })
    );
  }
}
