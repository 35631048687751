<div
  class="skeleton"
  [ngClass]="{
    'skeleton--fixed': fixed,
    'skeleton--responsive': responsive
  }"
>
  <div *ngFor="let cols of rows" class="skeleton__row">
    <div
      *ngFor="let col of cols"
      class="skeleton__col"
      [ngClass]="[
        'skeleton__col--' + col.size,
        col.offset ? 'skeleton__col--offset-' + col.offset : ''
      ]"
    >
      <div class="skeleton__bar skeleton__bar--loading"></div>
    </div>
  </div>
</div>
