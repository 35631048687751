import { IEmployment } from '../generated';
import { L10nService } from '../l10n/l10n.service';
import { Address } from './address';
import { Certification } from './certification';

/**
 * Employment.
 */
export class Employment {
  constructor();
  constructor(source: Partial<IEmployment>, l10n: L10nService);
  constructor(source?: Partial<IEmployment>, l10n?: L10nService) {
    this._Init(source, l10n);
  }

  CompanyName: string;
  ContactNumber: string;
  HireDate: string;
  SeparationDate: string;
  LastPosition: string;
  FinalSalary: string;
  FinalCommision: string;
  FinalBonus: string;
  ReasonForLeaving: string;
  IsCurrent: boolean;
  IsNotContactable: boolean;
  IsMilitary: boolean;
  Is3rdParty: boolean;
  FinalWorkAssignmentCompany: string;
  IsOnUnemployment: boolean;
  EligibleForRehire: 'Y' | 'N';
  Supervisor: string;
  EmployerID: string;
  Address: Address;
  ID: number;
  Certifications: Certification[];

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<IEmployment>, l10n?: L10nService) {
    if (!source) {
      this.Address = new Address(null, l10n);
      this.Certifications = [];
      return;
    }

    this.CompanyName = source.CompanyName;
    this.ContactNumber = source.ContactNumber;
    this.HireDate = source.HireDate;
    this.SeparationDate = source.SeparationDate;
    this.LastPosition = source.LastPosition;
    this.FinalSalary = source.FinalSalary;
    this.FinalCommision = source.FinalCommision;
    this.FinalBonus = source.FinalBonus;
    this.ReasonForLeaving = source.ReasonForLeaving;
    this.IsCurrent = !!source.IsCurrent;
    this.IsNotContactable =
      source.IsNotContactable == null ? null : source.IsNotContactable;
    this.IsMilitary = !!source.IsMilitary;
    this.Is3rdParty = !!source.Is3rdParty;
    this.FinalWorkAssignmentCompany = source.FinalWorkAssignmentCompany;
    this.IsOnUnemployment = !!source.IsOnUnemployment;
    this.EligibleForRehire = source.EligibleForRehire;
    this.Supervisor = source.Supervisor;
    this.EmployerID = source.EmployerID;
    this.Address = new Address(source.Address, l10n);
    this.ID = source.ID;
    this.Certifications = source.Certifications
      ? source.Certifications.Items.map((item) => new Certification(item))
      : [];
  }
}
