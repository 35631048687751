import { Component, Optional } from '@angular/core';

import { TimelineItemComponent } from './timeline-item.component';

/**
 * Timeline item title.
 */
@Component({
  selector: 'zc-timeline-title',
  templateUrl: './timeline-title.component.html',
  styleUrls: ['./timeline-title.component.scss'],
})
export class TimelineTitleComponent {
  constructor(@Optional() private _TimelineItem: TimelineItemComponent) {}

  /**
   * Indicates if the parent item is active.
   */
  get IsActive() {
    return this._TimelineItem && this._TimelineItem.state === 'active';
  }

  /**
   * In some cases, due to content projection, the "parent" timeline item is not available via DI. In these cases, the
   * item reference can be set manually.
   *
   * @param item Timeline item reference.
   */
  SetTimelineItem(item: TimelineItemComponent) {
    this._TimelineItem = item;
  }
}
