import { Injectable } from '@angular/core';

import { WindowRefService } from '../window-ref/window-ref.service';
import { BreakPoint } from './media-query.interfaces';

/**
 * Detect media query sizes to match CSS.
 */
@Injectable({ providedIn: 'root' })
export class MediaQueryService {
  constructor(private windowRef: WindowRefService) {}

  /**
   * Breakpoint min/max values in pixels.
   *
   * NOTE: These values should match CSS media queries!
   * https://getbootstrap.com/docs/3.3/css/#responsive-utilities
   */
  private readonly breakpoints = {
    xs: [0, 767],
    sm: [768, 991],
    md: [992, 1200],
    lg: [1200, Infinity],
  };

  /**
   * Checks if the current window matches the requested breakpoint or any smaller.
   *
   * @param name Breakpoint name.
   */
  isSizeOrSmaller(name: BreakPoint) {
    const width = this.windowRef.nativeWindow.innerWidth;
    const [, max] = this.breakpoints[name];

    return width <= max;
  }

  /**
   * Checks if the current window matches the requested breakpoint or any larger.
   *
   * @param name Breakpoint name.
   */
  isSizeOrLarger(name: BreakPoint) {
    const width = this.windowRef.nativeWindow.innerWidth;
    const [min] = this.breakpoints[name];

    return width >= min;
  }

  /**
   * Checks if the current window is within the requested breakpoint.
   *
   * @param name Breakpoint name.
   */
  isExactSize(name: BreakPoint) {
    const width = this.windowRef.nativeWindow.innerWidth;
    const [min, max] = this.breakpoints[name];

    return width >= min && width <= max;
  }

  /**
   * Get the current window size.
   */
  getCurrentSize() {
    return Object.keys(this.breakpoints).find((name: BreakPoint) =>
      this.isExactSize(name)
    );
  }
}
