import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JobService, JobTreeService } from '@zipcrim/work-flow';
import { map } from 'rxjs/operators';

/**
 * Used to redirect user to more info page if preliminary job found with incomplete status.
 */
@Injectable({ providedIn: 'root' })
export class OnboardGuard implements CanActivate {
  constructor(
    private _JobTree: JobTreeService,
    private _JobService: JobService,
    private _Router: Router
  ) {}

  /**
   * Can activate.
   */
  canActivate() {
    return this._CheckAccountActivation();
  }

  /**
   * Check account activation status when target is dashboard. Redirect to appropriate page if needed.
   */
  private _CheckAccountActivation() {
    return this._JobService.getAccountActivationJobs().pipe(
      map((res) => {
        const items = res.Items;

        if (!items || !items.length) {
          return true;
        } else {
          const prelim = this._JobTree.GetJobWithStatus(
            items,
            'SSP_SU_SelectAccountOptions',
            'Incomplete'
          );
          if (prelim && prelim.ID) {
            this._Router.navigate(['/signup/more-info']);
          } else {
            return true;
          }
        }

        return false;
      })
    );
  }
}
