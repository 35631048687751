<section class="subject-summary">
  <div class="d-flex">
    <div class="subject-summary__item">
      <h2 class="header-2">{{ SummaryData?.Name || '---' }}</h2>
    </div>
    <dl class="subject-summary__item">
      <dt>{{ 'Common.lblDob' | translate }}</dt>
      <dd>
        {{ SummaryData?.Dob ? (SummaryData?.Dob | date : 'shortDate') : '---' }}
      </dd>
    </dl>
    <dl class="subject-summary__item">
      <dt>{{ 'CheckToHire.lblSSN' | translate }}</dt>
      <dd>{{ SummaryData?.Ssn || '---' }}</dd>
    </dl>
    <dl class="subject-summary__item">
      <dt>{{ 'CheckToHire.lblCaseNumber' | translate }}</dt>
      <dd>{{ CaseNumber || '---' }}</dd>
    </dl>
  </div>
  <zc-button
    *ngIf="IsReportDownloadable"
    color="primary"
    (click)="OnDownloadReport()"
    [busy]="BusyDownloading">
    <fa-icon class="me-1" icon="file-pdf"></fa-icon>
    {{ 'Common.cmdDownload' | translate }}
  </zc-button>
</section>
