<div class="fuzzy-date">
  <!-- loading -->
  <zc-busy *ngIf="IsLoading" Size="xs" class="mr-1"> </zc-busy>

  <!-- failed -->
  <zc-button
    Size="sm"
    Color="link"
    [buttonClasses]="['btn-condensed', 'mr-1']"
    [tooltip]="'Common.lblFailedRetry' | translate"
    (click)="OnRetry()"
    *ngIf="IsFailure"
  >
    <fa-icon icon="exclamation-triangle" class="text-danger"></fa-icon>
    {{ 'Common.cmdTryAgain' | translate }}
  </zc-button>

  <!-- display value -->
  <span *ngIf="!IsLoading && !IsFailure">{{ Translation || Value }}</span>

  <zc-button
    #btn
    *ngIf="!Readonly"
    Color="secondary"
    zcFormFieldAriaDescribedBy
    zcFormFieldAriaLabeledBy
    [class.pull-right]="Value"
    [disabled]="Disabled"
    (click)="OnOpenModal()"
  >
    {{ (Value ? 'Common.lblDateEdit' : 'Common.lblDateEnter') | translate }}
  </zc-button>
</div>
