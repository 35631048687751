import { Component } from '@angular/core';

/**
 * 404 not found component.
 */
@Component({
  selector: 'zc-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {}
