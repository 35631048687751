import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm, Validators } from '@angular/forms';
import { ISsn, IUiMetaField } from '@zipcrim/common';
import get from 'lodash/get';

/**
 * Component for entering subject ssn.
 */
@Component({
  selector: 'c2h-data-entry-ssn',
  templateUrl: './data-entry-ssn.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
})
export class DataEntrySsnComponent implements OnInit {
  /**
   * Data to hydrate form.
   */
  @Input()
  Data: ISsn[];

  /**
   * UiMeta field spec.
   */
  @Input()
  UiMeta: IUiMetaField;

  // todo: replace with reactive form
  Config: any;

  /**
   * On init.
   */
  ngOnInit() {
    this._SetConfig();
  }

  /**
   * Set form configuration.
   */
  private _SetConfig() {
    const readonly = !!get(this.Data, '[0].SSN');

    this.Config = {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'collection',
          name: 'Ssns',
          titleL10nKey: 'Common.lblInformation',
          require: {
            min: this.UiMeta.Min,
            max: this.UiMeta.Max,
          },
          nodes: [
            {
              nodeType: 'hidden',
              name: 'ID',
            },
            {
              nodeType: 'input',
              name: 'SSN',
              labelL10nKey: 'CheckToHire.lblSSN',
              readonly,
              validators: [Validators.required],
            },
          ],
        },
      ],
    };
  }
}
