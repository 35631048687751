import { Directive, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { PasswordValidator } from './password.validator';

/**
 * Password validator.
 */
@Directive({
  selector:
    '[zcPassword][formControlName],[zcPassword][formControl],[zcPassword][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordDirective),
      multi: true,
    },
  ],
})
export class PasswordDirective implements Validator {
  /**
   * True to enable password validation.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('zcPassword')
  IsEnabled: boolean;

  /**
   * Validate.
   */
  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.IsEnabled) {
      return null;
    }

    return PasswordValidator(control);
  }
}
