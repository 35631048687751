<!-- busy -->
<ng-container *ngIf="Busy; else content">
  <div class="ana-group">
    <div class="ana-group__aside">
      <div class="ana-group__handle"></div>
    </div>
    <div class="ana-group__content">
      <zc-skeleton [rows]="[[{ size: 3 }], [{ size: 7 }]]"></zc-skeleton>
    </div>
  </div>
</ng-container>

<ng-template #content>
  <div class="ana-group ana-group--movable" [class.ana-group--faded]="Faded">
    <div class="ana-group__aside">  
      <div class="ana-group__handle"></div>
    </div>
    <div class="ana-group__content">
      <div class="d-flex justify-content-between">
        <dl class="ana-group__details">
          <dt class="header-2 m-0">{{ Data.Code }}</dt>
          <dd class="mb-0">
            <span class="header-2 text-gray">{{ Data.Description }}</span>
            <fa-icon
              icon="info-circle"
              class="text-info"
            (mouseenter)="showPopover = true"
            (mouseleave)="showPopover = false">
          </fa-icon>
          </dd>
        </dl>
        <div class="ana-group__aside">
          <span class="ana-group__price">{{ Data.Rate }}</span>
          <span class="ana-group__caption">{{ Data.Unit }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<div class="popover-container" *ngIf="showPopover" (mouseenter)="showPopover = true" (mouseleave)="showPopover = false">
  <!-- Popover Content -->
  <div class="popover-content">
   <!-- <p>{{ Data.FullDescription }}</p>-->

    <!-- Info -->
    <div class="info" *ngIf="showPopover" >
      {{ Data.FullDescription }}
      <!--This is the information you want to display.-->
    </div>
  </div>
</div>
