import { Component, OnInit } from '@angular/core';
import { ApiResult, AuthService } from '@zipcrim/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

import { JobModal } from '../job-modal';
import { JobService } from '../job.service';
import { VerificationState } from './verify-email-modal.enums';

/**
 * Modal for verify email job.
 */
@Component({
  templateUrl: './verify-email-modal.component.html',
})
export class VerifyEmailModalComponent extends JobModal implements OnInit {
  constructor(
    private auth: AuthService,
    private jobService: JobService,
    private bsModalRef: BsModalRef
  ) {
    super();
  }

  /**
   * Enum reference.
   */
  verificationState = VerificationState;

  /**
   * Current state.
   */
  currentState = VerificationState.EnterPin;

  /**
   * Pin value.
   */
  pin: string;

  /**
   * Email value.
   */
  newEmail: string;

  /**
   * Busy indicator.
   */
  busy: boolean;

  /**
   * Used to store user email.
   */
  userEmail: string;

  /**
   * used to indicate countdown status
   */
  showCountDown = false;

  /**
   * Validation messages.
   */
  message: string;

  /**
   * Used to store client Code
   */
  clientCode: string;

  /**
   * Used to store contact id
   */
  contactID = 0;

  /**
   * Used to store Email ID
   */
  emailID = 0;

  /**
   * Used to store verification code is already sent or not
   */
  isCodeAlreadySent = false;

  /**
   * On init.
   */
  ngOnInit(): void {
    this.auth.getCurrentUser().subscribe((response) => {
      if (response.PrimaryEmail) {
        this.userEmail = response.PrimaryEmail.Value;
        this.emailID = response.PrimaryEmail.ID;
      }
      this.clientCode = response.GetClientCode();
      this.contactID = response.ID;
    });
  }

  /**
   * On Job Available
   */
  onJobAvailable() {
    this.resendEmail();
  }

  /**
   * On submit.
   */
  onSubmit() {
    // reset
    this.message = null;

    switch (this.currentState) {
      case VerificationState.EnterPin:
        this.verifyEmail();
        break;
      case VerificationState.EnterEmail:
        this.updateEmail();
        break;
      default:
        console.warn('Unexpected state in verify email modal!');
        break;
    }
  }

  /**
   * Resend email and start countdown.
   */
  resendEmail() {
    // reset
    this.message = null;
    this.jobService.resendPIN(this.job.ID).subscribe(() => {
      this.showCountDown = true;
      this.isCodeAlreadySent = true;
    });
  }

  /**
   * On job cancel.
   */
  onCancel() {
    this.bsModalRef.hide();
  }

  /**
   * Set the currently active state.
   *
   * @param state State to set.
   */
  setState(state: VerificationState) {
    this.message = null;
    this.currentState = state;
  }

  /**
   * Verify email.
   */
  private verifyEmail() {
    this.busy = true;
    this.jobService
      .validatePIN(this.job.ID, this.pin)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        () => {
          this.job.Status = 'Complete';
          this.jobEmitter.next(this.job);
          this.bsModalRef.hide();
        },
        (error) => {
          if (error && error instanceof ApiResult) {
            this.message = error.message.blurb;
          }
        }
      );
  }

  /**
   * Update contact email.
   */
  private updateEmail() {
    if (!this.clientCode || !this.emailID || !this.contactID) {
      return;
    }

    this.busy = true;
    this.jobService
      .updateEmail(this.clientCode, this.contactID, this.emailID, this.newEmail)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        () => {
          this.userEmail = this.newEmail;
          this.setState(VerificationState.EnterPin);
        },
        (error) => {
          if (error && error instanceof ApiResult) {
            this.message = error.message.blurb;
          }
        }
      );
  }
}
