import { Component } from '@angular/core';

/**
 * Account side menu.
 */
@Component({
  selector: 'c2h-side-menu-account',
  templateUrl: './side-menu.component.html',
})
export class SideMenuInAccountComponent {}
