import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { SessionService } from './session.service';

/**
 * Route guard to restrict access based on authenticated user.
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private session: SessionService
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.isAuthenticated().pipe(
      map((res) => {
        if (!res) {
          const loginUrl = this.session.data.Url.Login;

          if (loginUrl) {
            const returnUrl = state.url;
            return this.router.parseUrl(`${loginUrl}?return=${returnUrl}`);
          }
        }

        return res;
      })
    );
  }
}
