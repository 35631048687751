<!-- busy -->
<ng-container *ngIf="Busy; else content">
  <div class="d-flex justify-content-between mb-4">
    <div class="flex-grow-1">
      <zc-skeleton
        [rows]="[[{ size: 3 }, { size: 3 }], [{ size: 5 }]]"></zc-skeleton>
    </div>
    <div>
      <fa-icon icon="star" class="text-green me-1"></fa-icon>
      <fa-icon icon="archive" class="text-gray"></fa-icon>
    </div>
  </div>
  <div class="mb-3">
    <zc-skeleton [rows]="[[{ size: 2 }], [{ size: 5 }]]"></zc-skeleton>
  </div>
</ng-container>

<ng-template #content>
  <section class="case-card">
    <!-- information -->
    <div class="d-flex justify-content-between">
      <div>
        <a class="case-card__title d-flex" (click)="Navigate()">
          <h1 class="header-2 m-0">{{ FullName }}</h1>
          <zc-busy class="ms-1" size="xs" *ngIf="BusyNavigating"></zc-busy>
        </a>
        <span class="type-item__description text-muted">{{ Ssn }}</span>
      </div>
      <div>
        <zc-button
          color="transparent"
          class="me-1"
          [ngClass]="Case.IsPinned ? 'text-green' : 'text-gray'"
          [busy]="BusySavingPinned"
          [busyText]="null"
          [buttonClasses]="['btn-condensed']"
          [title]="'CheckToHire.cmdUnstar' | translate"
          (click)="TogglePinned()">
          <fa-icon icon="star"></fa-icon>
        </zc-button>
        <zc-button
          color="transparent"
          class="text-gray"
          [busy]="BusySavingHidden"
          [busyText]="null"
          [buttonClasses]="['btn-condensed']"
          [title]="'CheckToHire.cmdArchive' | translate"
          (click)="ToggleHiddenWithConfirm()">
          <fa-icon icon="archive"></fa-icon>
        </zc-button>
      </div>
    </div>

    <!-- leads -->
    <c2h-lead-tags
      class="mt-1"
      [CaseNumber]="Case.CaseNum"
      [Leads]="Case.Leads"
      (OnTagClick)="Navigate($event)">
    </c2h-lead-tags>

    <!-- details -->
    <div class="d-flex">
      <dl class="db-dl">
        <dt class="db-dl__dt">
          {{ 'CheckToHire.lblLastCompleted' | translate }}
        </dt>
        <dd class="db-dl__dd">
          <a (click)="NavigateToLastCompleted()">{{ LastLeadTinyName }}</a>
        </dd>
      </dl>
      <dl class="db-dl">
        <dt class="db-dl__dt">{{ 'CheckToHire.lblOrderedBy' | translate }}</dt>
        <dd class="db-dl__dd">{{ OwnerName }}</dd>
      </dl>
      <dl class="db-dl">
        <dt class="db-dl__dt">
          {{ 'CheckToHire.lblLastReportRequest' | translate }}
        </dt>
        <dd class="db-dl__dd">{{ Completed | date : 'MM/dd/yyyy' }}</dd>
      </dl>
    </div>

    <!-- action required -->
    <a
      *ngIf="Case.HasJobs"
      class="case-card__footer case-card__footer--warning">
      <fa-icon icon="exclamation-triangle" class="icon"></fa-icon>
      {{ 'CheckToHire.lblActionRequired' | translate }}
    </a>
  </section>
</ng-template>
