<div
  [class.form-horizontal]="IsHorizontal"
  [ngSwitch]="Config.nodeType"
  [formGroup]="FormGroup"
  *ngIf="!Config.isHidden"
>
  <!-- container -->
  <div *ngSwitchCase="'container'" [ngClass]="Config.css">
    <zc-dynamic-node-item
      *ngFor="let item of Nodes"
      [ApiMessages]="ApiMessages"
      [Busy]="Busy"
      [Config]="item"
      [Form]="Form"
      [Layout]="Layout"
    >

  </zc-dynamic-node-item>
    <zc-busy *ngIf="IsBusy" [centered]="true"></zc-busy>
  </div>

  <!-- fieldset -->
  <fieldset *ngSwitchCase="'fieldset'" [ngClass]="Config.css">
    <legend *ngIf="Title" [ngClass]="Config.css">{{ Title }}</legend>
    <zc-dynamic-node-item
      *ngFor="let item of Nodes"
      [ApiMessages]="ApiMessages"
      [Busy]="Busy"
      [Config]="item"
      [Form]="Form"
      [Layout]="Layout"
    >

  </zc-dynamic-node-item>
    <zc-busy *ngIf="IsBusy" [centered]="true"></zc-busy>
  </fieldset>

  <!-- card -->
  <div *ngSwitchCase="'card'" [ngClass]="Config.css">
    <zc-card
      [title]="Title"
      [color]="Config.color"
      [isCollapsible]="Config.isCollapsible"
      [defaultCollapsed]="Config.defaultCollapsed"
    >
    <!-- previously <zc-card-header *ngIf="CardNode.header">{{ Config.header }}</zc-card-header> -->
    <zc-card-header *ngIf="Config.header">{{ Config.header }}</zc-card-header>
      <zc-card-body>
        <zc-dynamic-node-item
          *ngFor="let item of Nodes"
          [ApiMessages]="ApiMessages"
          [Busy]="Busy"
          [Config]="item"
          [Form]="Form"
          [Layout]="Layout"
        >
        </zc-dynamic-node-item>
        <zc-busy *ngIf="IsBusy" [centered]="true"></zc-busy>
      </zc-card-body>
    </zc-card>
  </div>

  <!-- TODO: EJA: This is what it was previously, it seemed wrong...is it? (see the Collection section just below)-->
  <!-- collection -->
  <!-- <div *ngSwitchCase="'collection'" [ngClass]="Config.css">
    <zc-dynamic-collection
      [Form]="Form"
      [PushToFormArray]="FormControl"
      [Config]="Config.allowedStaticCodes"
      [ApiMessages]="ApiMessages"
      [Busy]="Busy"
      [Layout]="Layout"
    >
    </zc-dynamic-collection>
  </div> -->

  <!-- collection -->
  <div *ngSwitchCase="'collection'" [ngClass]="Config.css">
    <zc-dynamic-collection
      [Form]="Form"
      [PushToFormArray]="FormControl"
      [ApiMessages]="ApiMessages"
      [Busy]="Busy"
      [Layout]="Layout"
    >
    </zc-dynamic-collection>
  </div>

  <!-- input -->
  <zc-dynamic-form-field
    *ngSwitchCase="'input'"
    [ngClass]="Config.css"
    [Layout]="Layout"
    [id]="Id"
  >
    <zc-dynamic-label
      *ngIf="Label"
      [Busy]="Busy"
      [For]="Id"
      [Required]="IsRequired"
    >
      {{ Label }}
    </zc-dynamic-label>
    <zc-dynamic-badge
      *ngFor="let badge of Config.badges"
      class="mr-05"
      [Color]="badge.color"
      [Icon]="badge.icon"
      [tooltip]="badge.tooltip"
    >
    </zc-dynamic-badge>
    <zc-dynamic-input
      [formControl]="FormControl"
      [disabled]="Config.disabled"
      [Placeholder]="Placeholder"
      [Readonly]="Config.readonly"
      [Type]="Config.type"
      [zcDisableCopyPaste]="Config.disableCopyPaste"
      [zcDisableScrolling]="Config.disableScrolling"
    >
    </zc-dynamic-input>
    <zc-dynamic-help-text *ngIf="HelpText">
      {{ HelpText }}
    </zc-dynamic-help-text>
    <zc-dynamic-validation-messages
      [ApiMessages]="ApiMessages"
      [AlternativeNames]="Config.alternativeNames"
      [Control]="FormControl"
      (OnIgnore)="OnMessagedIgnore($event)"
    >
    </zc-dynamic-validation-messages>
  </zc-dynamic-form-field>

  <!-- checkbox -->
  <zc-dynamic-form-field
    *ngSwitchCase="'checkbox'"
    [ngClass]="Config.css"
    [Layout]="Layout"
    [Id]="Id"
  >
    <zc-dynamic-label
      *ngIf="Label"
      [Busy]="Busy"
      [For]="Id"
      [Required]="IsRequired"
    >
      {{ Label }}
    </zc-dynamic-label>
    <zc-dynamic-badge
      *ngFor="let badge of Config.badges"
      class="mr-05"
      [Color]="badge.color"
      [Icon]="badge.icon"
      [tooltip]="badge.tooltip"
    >
    </zc-dynamic-badge>
    <zc-dynamic-checkbox
      [formControl]="FormControl"
      [Disabled]="Config.disabled"
      [Readonly]="Config.readonly"
    >
      {{ CheckboxLabel }}
    </zc-dynamic-checkbox>
    <zc-dynamic-help-text *ngIf="HelpText">
      {{ HelpText }}
    </zc-dynamic-help-text>
    <zc-dynamic-validation-messages
      [ApiMessages]="ApiMessages"
      [AlternativeNames]="Config.alternativeNames"
      [Control]="FormControl"
      (OnIgnore)="OnMessagedIgnore($event)"
    >
    </zc-dynamic-validation-messages>
  </zc-dynamic-form-field>

  <!-- checkbox group -->
  <div
    *ngSwitchCase="'checkbox-group'"
    role="radiogroup"
    [attr.aria-labelledby]="field.AriaLabeledById"
  >
    <zc-dynamic-form-field
      [ngClass]="Config.css"
      [Layout]="Layout"
      [Id]="Id"
      #field="zcFormField"
    >
      <zc-dynamic-label
        *ngIf="Label"
        [Busy]="Busy"
        [Required]="IsRequired"
      >
        {{ Label }}
      </zc-dynamic-label>
      <zc-dynamic-badge
        *ngFor="let badge of Config.badges"
        class="mr-05"
        [Color]="badge.color"
        [Icon]="badge.icon"
        [tooltip]="badge.tooltip"
      >
      </zc-dynamic-badge>
      <zc-dynamic-checkbox-group [Options]="Config.checkBoxOptions"> </zc-dynamic-checkbox-group>
      <zc-dynamic-help-text *ngIf="HelpText">
        {{ HelpText }}
      </zc-dynamic-help-text>
      <zc-dynamic-validation-messages [Control]="FormControl"> </zc-dynamic-validation-messages>
    </zc-dynamic-form-field>
  </div>

  <!-- date parts -->
  <zc-dynamic-form-field
    *ngSwitchCase="'date-parts'"
    [ngClass]="Config.css"
    [Layout]="Layout"
    [Id]="Id"
  >
    <zc-dynamic-label
      *ngIf="Label"
      [Busy]="Busy"
      [For]="Id"
      [Required]="IsRequired"
    >
      {{ Label }}
    </zc-dynamic-label>
    <zc-dynamic-badge
      *ngFor="let badge of Config.badges"
      class="mr-05"
      [Color]="badge.color"
      [Icon]="badge.icon"
      [tooltip]="badge.tooltip"
    >
    </zc-dynamic-badge>
    <zc-dynamic-date-parts
      [Parts]="Config.parts"
      [Readonly]="Config.readonly"
      [zcDisableCopyPaste]="Config.disableCopyPaste"
      [zcDisableScrolling]="Config.disableScrolling"
    >
    </zc-dynamic-date-parts>
    <zc-dynamic-help-text *ngIf="HelpText">
      {{ HelpText }}
    </zc-dynamic-help-text>
    <zc-dynamic-validation-messages
      [ApiMessages]="ApiMessages"
      [AlternativeNames]="Config.alternativeNames"
      [Control]="FormControl"
      (OnIgnore)="OnMessagedIgnore($event)"
    >
    </zc-dynamic-validation-messages>
  </zc-dynamic-form-field>

  <!-- select -->
  <zc-dynamic-form-field
    *ngSwitchCase="'select'"
    [ngClass]="Config.css"
    [Layout]="Layout"
    [Id]="Id"
  >
    <zc-dynamic-label
      *ngIf="Label"
      [Busy]="Busy"
      [For]="Id"
      [Required]="IsRequired"
    >
      {{ Label }}
    </zc-dynamic-label>
    <zc-dynamic-badge
      *ngFor="let badge of Config.badges"
      class="mr-05"
      [Color]="badge.color"
      [Icon]="badge.icon"
      [tooltip]="badge.tooltip"
    >
    </zc-dynamic-badge>
    <zc-dynamic-select
      #input
      [formControl]="FormControl"
      [Disabled]="Config.disabled"
      [OptionsAsync]="Config.optionsAsync"
      [Options]="Config.options"
      [Deselect]="Deselect"
      [Placeholder]="Placeholder"
      [Readonly]="Config.readonly"
    >
    </zc-dynamic-select>
    <zc-dynamic-help-text *ngIf="HelpText">
      {{ HelpText }}
    </zc-dynamic-help-text>
    <zc-dynamic-validation-messages
      [ApiMessages]="ApiMessages"
      [AlternativeNames]="Config.alternativeNames"
      [Control]="FormControl"
      (OnIgnore)="OnMessagedIgnore($event)"
    >
    </zc-dynamic-validation-messages>
  </zc-dynamic-form-field>

  <!-- radio group -->
  <div
    *ngSwitchCase="'radio-group'"
    role="radiogroup"
  >
    <!-- TODO: EJA: The following line was in the div just above. It was causing an Angular exception to be thrown. -->
    <!-- [attr.aria-labelledby]="field.AriaLabeledById" -->
    <zc-dynamic-form-field
      [ngClass]="Config.css"
      [Layout]="Layout"
      [Id]="Id"
      #field="zcFormField"
    >
      <zc-dynamic-label
        *ngIf="Label"
        [Busy]="Busy"
        [Required]="IsRequired"
      >
        {{ Label }}
      </zc-dynamic-label>
      <zc-dynamic-badge
        *ngFor="let badge of Config.badges"
        class="mr-05"
        [Color]="badge.color"
        [Icon]="badge.icon"
        [tooltip]="badge.tooltip"
      >
      </zc-dynamic-badge>
      <zc-dynamic-radio-group
        #input
        [formControl]="FormControl"
        [Disabled]="Config.disabled"
        [Readonly]="Config.readonly"
        [OptionsAsync]="Config.optionsAsync"
        [Options]="Config.options"
        [Layout]="Config.groupLayout"
      >
      </zc-dynamic-radio-group>
      <zc-dynamic-help-text *ngIf="HelpText">
        {{ HelpText }}
      </zc-dynamic-help-text>
      <zc-dynamic-validation-messages
        [ApiMessages]="ApiMessages"
        [AlternativeNames]="Config.alternativeNames"
        [Control]="FormControl"
        (OnIgnore)="OnMessagedIgnore($event)"
      >
      </zc-dynamic-validation-messages>
    </zc-dynamic-form-field>
  </div>

  <!-- fuzzy date -->
  <zc-dynamic-form-field
    *ngSwitchCase="'fuzzy-date'"
    [ngClass]="Config.css"
    [Layout]="Layout"
    [Id]="Id"
  >
    <zc-dynamic-label
      *ngIf="Label"
      [Busy]="Busy"
      [Required]="IsRequired"
    >
      {{ Label }}
    </zc-dynamic-label>
    <zc-dynamic-badge
      *ngFor="let badge of Config.badges"
      class="mr-05"
      [Color]="badge.color"
      [Icon]="badge.icon"
      [tooltip]="badge.tooltip"
    >
    </zc-dynamic-badge>
    <zc-dynamic-fuzzy-date
      [formControl]="FormControl"
      [Disabled]="Config.disabled"
      [Readonly]="Config.readonly"
      [Label]="Label"
      [AllowedStaticCodes]="Config.allowedStaticCodes"
      [Min]="Config.min"
      [Max]="Config.max"
    >
    </zc-dynamic-fuzzy-date>
    <zc-dynamic-help-text *ngIf="HelpText">
      {{ HelpText }}
    </zc-dynamic-help-text>
    <zc-dynamic-validation-messages
      [ApiMessages]="ApiMessages"
      [AlternativeNames]="Config.alternativeNames"
      [Control]="FormControl"
      (OnIgnore)="OnMessagedIgnore($event)"
    >
    </zc-dynamic-validation-messages>
  </zc-dynamic-form-field>

  <!-- html -->
  <div
    *ngSwitchCase="'html'"
    [ngClass]="Config.css"
    [innerHTML]="Config.content"
  ></div>

  <!-- component -->
  <ng-container *ngSwitchCase="'component'">
    <ng-template
      [zcDynamicComponent]="Config.component"
      [Props]="Config.props"
      [Context]="FormGroup"
    >
    </ng-template>
  </ng-container>
</div>
