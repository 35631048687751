/* eslint-disable @typescript-eslint/naming-convention */

// this file will be generated in the future; for now it is manually maintained
// https://targetprocess.carcogroup.com/entity/70553

export type IBlurb = string; // IBlurbObject

export interface IBlurbObject {
  StringResource?: string;
  ResourceCode?: string;
  Values?: any[];
}

export interface ICertification {
  ID: number;
  CertifyingSource: string;
  FieldName: string;
}

export interface ICountry {
  ID: number;
  Code: string;
  CustomCode: string;
  Blurb: IBlurb;
  IsInUse: boolean;
}

export interface IDob {
  ID: number;
  Month: number;
  Day: number;
  Year: number;
  UiMeta?: IUiMetaRecord;
}

export interface INationalID {
  Code: string;
  Blurb: IBlurb;
  NationalIDs: Array<{
    Code: string;
    Blurb: IBlurb;
    Format: string;
    IssuingCountryDescription?: string;
    SetCode?: string;
    IsDoNotCollect?: boolean;
  }>;
}

export interface IRegion {
  Code: string;
  Blurb: IBlurb;
}

export interface IOptionSet {
  Value: string;
  Blurb: IBlurb;
  IsDeprecated: boolean;
}

export interface IAddress {
  ID: number;
  StreetName: string;
  Address2: string;
  City: string;
  State: string;
  ZipCode: string;
  CountryCode: string;
  ReportedFromDate: string;
  ReportedToDate: string;
  IsCurrent: boolean;
  UpdateKey?: string;
  UpdateVersion?: string;
  Certifications?: IBoList<ICertification>;
  UiMeta?: IUiMetaRecord;
}

export interface IAssociation {
  ObjectClassName: string;
  ObjectID: string;
  PurposeCode: string;
}

export interface ICase {
  CaseNum: string;
  DueOn: string;
  ID: number;
  IsHidden: boolean;
  IsPinned: boolean;
  Leads: IBoList<ILead>;
  Status: string;
  Submitted: string;
  LastReported: ILead;
  Category: string;
  Index: number;
  HasJobs: boolean;
  CaseType: string;
  Owner: IContact;
  Subject: ISubject;
}

export interface ICaseType {
  Name: string;
  CaseTypeCode: string;
  CaseTypeName: string;
  LeadTypeList: ILeadType[];
  Blurb: IBlurb;
  Code: string;
  AnaGroups: IBoList<IAnaGroup>;
  Options: any;
}

export interface IAnaGroup {
  Code: string;
  Options: {
    InclMulti: string;
  };
  IsSelected: boolean;
  Cost: number;
  IsReadOnly: boolean;
  FullDescriptionBlurb: IBlurb;
  UnitBlurb: IBlurb;
  RateBlurb: IBlurb;
  DescriptionBlurb: IBlurb;
  LeadClass: string;
}

export interface IReferenceField {
  ID: number;
  LabelBlurb: IBlurb;
  HelpTextBlurb: IBlurb;
  MaxLength: number;
  TypeCode: 'Text' | 'Number' | 'Boolean' | 'Date';
  UsageCode: 'ReadOnly' | 'ReadWrite' | 'Hide' | 'List';
  Value: any;
  Options: { [key: string]: any };
}

export interface IContactInfo {
  ID: number;
  Value: string;
  Category:
    | 'Unspecified'
    | 'Home'
    | 'Work'
    | 'Day'
    | 'Evening'
    | 'Emergency'
    | string;
  Type: 'Phone' | 'Mobile' | 'Email' | 'Sms' | 'SocialNet' | string;
  IsDeleted?: boolean;
}

export interface ICredential {
  ID: number;
  Credential: string;
  Authority: string;
  Location: string;
  IssueDate: string;
  ExpirationDate: string;
  LicenseNumber: string;
}

export interface IDisclosure {
  OccurrenceDate: string;
  ConvictedDate: string;
  State: string;
  City: string;
  County: string;
  CountryCode: string;
  ActionType: string;
  ID: number;
  IsVisible: boolean;
  CourtName: string;
  Agency: string;
  Status: string;
  Outcome: string;
  CourtAttendeeType: string;
  Details: string;
  DisclosureType?: string;
}

export interface IEducation {
  SchoolName: string;
  SchoolType: string;
  Major: string;
  Degree: string;
  StartDate: string;
  EndDate: string;
  GraduationDate: string;
  HasGraduated: boolean;
  GPAScale: string;
  ID: number;
  Address: IAddress;
  Certifications?: IBoList<ICertification>;
  Other?: string;
}

export interface IEmployment {
  CompanyName: string;
  ContactNumber: string;
  HireDate: string;
  SeparationDate: string;
  LastPosition: string;
  FinalSalary: string;
  FinalCommision: string;
  FinalBonus: string;
  Supervisor: string;
  ReasonForLeaving: string;
  IsCurrent: boolean;
  IsNotContactable: boolean;
  IsMilitary: boolean;
  Is3rdParty: boolean;
  FinalWorkAssignmentCompany: string;
  IsOnUnemployment: boolean;
  EligibleForRehire: 'Y' | 'N';
  EmployerID: string;
  Address: IAddress;
  ID: number;
  Certifications?: IBoList<ICertification>;
}

export interface ILeadRequest {
  ID: number;
  LeadTypeName: string;
  LeadTypeCode: string;
  SubType: string;
  SubType2: string;
  IsRequested: boolean;
  IsReadOnly: boolean;
  Description: string;
  Price: number;
  Processed: string;
  GovernmentFee: number;
  TotalFees: number;
  IsNonLinear: boolean;
  IsAlreadyOrdered: boolean;
  Associations: IAssociation[];
}

export interface ILeadType {
  LeadTypeCode: string;
  LeadTypeName: string;
  Description: string;
  Cost: number;
  ChildTable: string;
  RawCode: string;
  Code: IBlurb;
  TinyName: IBlurb;
  Name: IBlurb;
}

export interface IMvr {
  LicenseClass: 'NonCDL' | 'CDL' | 'Unknown';
  LicenseNumber: string;
  CountryCode: string;
  LicenseState: string;
  ID: number;
  IsCurrent: boolean;
  IsForced: boolean;
  UiMeta?: IUiMetaRecord;
}

export interface IName {
  ID: number;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  Gender: string;
  FirstUsed: string;
  IsCurrent: boolean;
  HasDriversLicense: string;
  FullName: string;
  UiMeta?: IUiMetaRecord;
}

export interface IReference {
  FullName: string;
  Address: IAddress;
  Phone1: string;
  Phone1Type: string;
  Phone2: string;
  Phone2Type: string;
  ReferenceType: string;
  Relationship: string;
  KnownSince: string;
  ID: number;
  InitAsTouched: boolean;
}

export interface ISsn {
  SSN: string;
  Last4: string;
  IDType: string;
  ID: number;
  CountryCode?: string;
  ExpiresOn?: string;
}

export interface IContactAddress {
  ID: number;
  Address1: string;
  Address2?: string;
  Address3?: string;
  City: string;
  State: string;
  ZipCode: string;
  CountryCode: string;
  LocationType: 'Home' | 'Business';
  Purpose:
    | 'Unknown'
    | 'General'
    | 'Mailing'
    | 'Shipping'
    | 'Billing'
    | 'SAML'
    | 'Remittance';
}

export interface IContactEmail {
  ID: number;
  Value: string;
  Purpose: 'Primary' | 'Secondary';
}

export interface IContactName {
  ID: number;
  FullName: string;
  FirstName: string;
  MiddleName?: string;
  LastName: string;
  Suffix?: string;
}

export interface IContactNumber {
  ID: number;
  Purpose: 'Primary' | 'Secondary' | 'Emergency' | 'SecureFax' | 'Fax';
  PhoneType: 'Home' | 'Business' | 'Mobile' | 'Fax';
  Availability: 'Anytime' | 'Daytime' | 'Evening';
  Value: string;
}

export interface IContact {
  ID: number;
  ClientCode: string;
  Status: 'Active' | 'Deleted' | 'Inactive' | 'Unverified';
  ExternalAccess: IExternalAccess;
  Name: IContactName;
  Emails: IBoList<IContactEmail>;
  ContactNumbers: IBoList<IContactNumber>;
  Addresses: IBoList<IContactAddress>;
  Domains: IBoList<IDomain>;
  PrimaryEmail?: IContactEmail;
  PrimaryNumber?: IContactNumber;
}

export interface IDomainRole {
  ID: number;
  Name: string;
  Blurb: IBlurb;
}

export interface IDomain {
  ID: number;
  ClientID: number;
  Name: string;
  Roles: IBoList<IDomainRole>;
  ClientCode?: string;
}

export interface IExternalAccess {
  Username: string;
  LastLogin: string;
}

export interface IInvoice {
  AddressNumber: string;
  Company: string;
  InvoiceAmount: number;
  InvoiceDate: string;
  InvoiceNumber: string;
  LongAddressNumber: string;
  OpenAmount: number;
}

export interface ILead {
  Description: IBlurb;
  DescriptionLong: IBlurb;
  Detail: IBlurb;
  DetailLong: IBlurb;
  EndDate: string;
  ID: number;
  IsClear: boolean;
  IsBlocked: boolean;
  LeadNum: string;
  StartDate: string;
  Completed: string;
  Status: string;
  Codes: string[];
  LeadType: ILeadType;
  Report?: ILeadReport;
}

export type ILeadReportFormat = 'Unknown' | 'Text' | 'Email';

export interface ILeadReport {
  Format: ILeadReportFormat;
  Content: string;
}

export interface IEmail {
  SubjectID: number;
  Value: string;
}

export type IMiscAttributeName =
  | 'None'
  | 'MothersMaidenName'
  | 'Phone'
  | 'HomePhone'
  | 'WorkPhone'
  | 'DayPhone'
  | 'EveningPhone'
  | 'EmergencyPhone'
  | 'MobilePhone'
  | 'HomeMobilePhone'
  | 'WorkMobilePhone'
  | 'DayMobilePhone'
  | 'EveningMobilePhone'
  | 'EmergencyMobilePhone'
  | 'EmailAddress'
  | 'HomeEmailAddress'
  | 'WorkEmailAddress'
  | 'DayEmailAddress'
  | 'EveningEmailAddress'
  | 'EmergencyEmailAddress'
  | 'BirthCity'
  | 'BirthCountry'
  | 'BirthState'
  | 'MothersName'
  | 'FathersName'
  | 'HusbandName'
  | 'Citizenship'
  | 'NMLSNumber'
  | 'Ntionlty'
  | 'Title'
  | 'BorninUK'
  | 'Position'
  | 'MidNameReq'
  | 'LNUntilReq'
  | 'Maiden'
  | 'DCWIDReq';

export interface IMiscAttribute {
  ID: number;
  Value: string;
  Code: IMiscAttributeName;
  CountryCode?: string;
}

export interface ISubject {
  ID: number;
  ClientCode: string;
  ClientReference: string;
  Gender: IGender;
  SubjectType: string;
  Names?: IBoList<IName>;
  Ssns?: IBoList<ISsn>;
  Addresses?: IBoList<IAddress>;
  Dobs?: IBoList<IDob>;
  Mvrs?: IBoList<IMvr>;
  EducationHistory?: IBoList<IEducation>;
  EmploymentHistory?: IBoList<IEmployment>;
  Credentials?: IBoList<ICredential>;
  Disclosures?: IBoList<IDisclosure>;
  References?: IBoList<IReference>;
  MotherMaidenName?: IMiscAttribute;
  MiscAttributes?: IBoList<IMiscAttribute>;
}

export type IGender = 'Male' | 'Female' | 'Other' | 'Unknown';

export interface ICaseSummary {
  ID: number;
  DateSubmitted: string;
  CaseType: string;
  CaseNum: string;
  Case: ICase;
}

export interface ISchemata {
  ID: number;
  ClientCode: string;
  FileCode: string;
  IsComposite: boolean;
  Added: string;
  AddedBy: string;
  Schema: ISchema;
}

export interface ISchema {
  [key: string]: ISchemaField;
}

export interface ISchemaField {
  SourceIndex?: number;
  AlternateNames?: string[];
  IsRequired?: boolean;
  MaxLength?: number;
  MinLength?: number;
  AllowedValues?: {
    [key: string]: any[];
  };
}

export interface IWorkOrder {
  AnaSpec: string;
  CaseNum: string;
  CaseType: string;
  ClientCode: string;
  DateAnalyzed: string;
  DateProcessed: string;
  DateSubmitted: string;
  ID: number;
  IsFCRA: boolean;
  IsMasterRecord: boolean;
  IsSubjectCopy: boolean;
  IsWages: boolean;
  RecruiterID: number;
  SubjectID: number;
}

export interface IAttestOption {
  ID: number;
  OptionBlurb: IBlurb;
  SortOrder: number | null;
  ValidationRuleID: number;
}

export interface IValidationRule {
  ID: number;
  Blurb: IBlurb;
  Category: string;
  ErrorNID: number;
  ErrorName: IValidationRuleName;
  Impact: number;
  IsFailure: boolean;
  ObjectScopeType1: string;
  ParamDefinition: string;
  RuleParameters: string;
  ScopeColName1: string;
  ShortDescript?: string;
  TypeName: string;
  ValidationSetID: number;
  ValidationTypeID: number;
  ValidationSetName: string;
  ObjectScopeType2: string;
  ScopeColName2: string;
  ValidationSetRuleJoinID?: number;
}

export type IValidationRuleName =
  | 'AddrCurrentRequired'
  | 'AddrHistoryComplete'
  | 'CitizenshipConfirmation'
  | 'CityOfBirthReq'
  | 'CrimHistoryReq'
  | 'DOBRequired'
  | 'EduReqMet'
  | 'EmpCurrentRequired'
  | 'EmpHistoryComplete'
  | 'FatherOrHusbandReq'
  | 'FathersNameReq'
  | 'HusbandsNameReq'
  | 'MVRRequired'
  | 'MothersMaidenReq'
  | 'MothersNameReq'
  | 'NMLSNumberReq'
  | 'NationalIDReq'
  | 'NationalIDorPassport'
  | 'NoAddtionalNames'
  | 'NoNames'
  | 'PassportReq'
  | 'RefReqMet'
  | 'SSNMissing'
  | 'MiddleNameReq'
  | 'NationalityReq'
  | 'TitleReq'
  | 'PositionReq'
  | 'BorninUKReq'
  | 'DiscConsumerStatement'
  | 'SurnameAtBirthUsedUntilReq'
  | 'MaidenReq'
  | 'DCWIDNumReq';

export type IUiClassName =
  | 'Default'
  | 'AcknowledgeDisclaimer'
  | 'EditForm_Client'
  | 'SSP_AccountActivation'
  | 'SSP_SU_FormCollection'
  | 'SSP_SU_SelectAccountOptions'
  | 'SSP_SU_WhoSigns'
  | 'eSignFormClient'
  | 'VerifyEmail'
  | 'C2H_CTConfig'
  | 'UploadDocumentGen';

export type IJobCommandCode =
  | 'AssignContact'
  | 'AssignNewContact'
  | 'FormSigned'
  | 'GetContactOptions'
  | 'GetForm4Signing'
  | 'GetPINExpiration'
  | 'PINUpdateEmail'
  | 'ResendPIN'
  | 'SaveDocument'
  | 'ValidatePIN';

export type IJobStatus = 'Blocked' | 'Incomplete' | 'Complete';

export type IJobUserRight = 'CanBeClaimed' | 'CanBeCompleted';

export interface IJob {
  AssociatedObjects: IAssociatedObject[];
  Class: string;
  ClassHierarchy: string;
  Dependencies?: IJobDependency[];
  DescriptionBlurb: IBlurb;
  HelpTextBlurb: IBlurb;
  ID: number;
  Status: IJobStatus;
  UiClassName: IUiClassName;
  UserRights: IJobUserRight[];
}

export interface IAssociatedObject {
  ID: number;
  Class: string;
}

export interface IJobDependency {
  ID: number;
  IsBlocking: boolean;
  JobID: number;
}

export interface IBoList<T> {
  Items: T[];
  UiMeta?: IUiMeta;
}

export interface IUiMetaBase {
  Rights?: string[];
  Rules?: string[];
}

export interface IUiMeta extends IUiMetaBase {
  CountRemaining?: number;
  CountSkipped?: number;
  SelectedIndex?: number;
  Fields?: IUiMetaFields;
  QueryCode?: string;
  Sections?: IUiMetaSections;
}

/* Note: While this inherits from IUiMetaBase
   without any additions, the intent is to
   clearly define the purpose in other code
   and make it easier to extend later
*/
export interface IUiMetaRecord extends IUiMetaBase {
  IsReadOnly(): () => boolean;
}

export interface IUiMetaFields {
  [name: string]: IUiMetaField;
}

export interface IUiMetaField extends IUiMetaBase {
  Min?: number;
  Max?: number;
}

export interface IUiMetaSections {
  [name: string]: IUiMetaSection;
}

export interface IUiMetaSection extends IUiMetaBase {
  IsRequired?: boolean;
  SortOrder?: number;
}

export interface IUiField {
  Data: {
    [key: string]: IUiFieldData;
  };
}

export interface IUiFieldData {
  FieldName: string;
  ObjectID: number;
  Severity: string;
  Impact: number;
  AttestOptionID: number | null;
  AttestOptionName?: string | null;
  AttestedOn: string | null;
  ValidationID: number;
  RuleName?: string;
  ValidationSetRuleJoinID?: number;
}

export interface IUiMetaBo {
  [name: string]: IUiMetaBoItem;
}

export type IUiMetaBoItem = IUiMetaBoItemBase & IUiMetaBoItemFields;

export interface IUiMetaBoItemBase {
  FieldSpecNames?: string[];
  FilterSpecNames?: string[];
}

export interface IUiMetaBoItemFields {
  [field: string]: IUiMetaBoField;
}

export interface IUiMetaBoField {
  IsRequired?: boolean;
  IsHidden?: boolean;
  MinLength?: number;
  MaxLength?: number;
  IsNav?: boolean;
  IsList?: boolean;
  Content?: string;
}

export type IFormTypeCode = 'Form';

export interface IForm {
  ID: number;
  TemplateID: number;
  PageCount: number;
  Name: string;
  Type: IFormTypeCode;
  Version: string;
  UpdateVersion: number;
  Signatures?: IBoList<ISignature>;
  SignatureOnFile?: ISignatureImage;
}

export type ISignatureAuthTypeCode = 'None' | 'Password' | 'PIN';

export interface ISignature {
  Name: string;
  SectionName: string;
  IsWitnessAllowed: boolean;
  CanBeReused: boolean;
  AuthorizationType: ISignatureAuthTypeCode;
  Page: number;
  LocationAsPercent: IRectangleF;
}

export interface IRectangleF {
  Location: {
    X: number;
    Y: number;
    IsEmpty: boolean;
  };
  Size: string;
  X: number;
  Y: number;
  Width: number;
  Height: number;
  Left: number;
  Top: number;
  Right: number;
  Bottom: number;
  IsEmpty: boolean;
}

export type IFileTypeCode = 'Png';

export type IEncodingTypeCode = 'Base64';

export interface ISignatureImage {
  FileType: IFileTypeCode;
  Encoding: IEncodingTypeCode;
  Data: string;
}

export interface IPrediction {
  Description: string;
  DistanceMeters: number;
  PlaceCode: string;
  Terms: ITerm[];
  Types: string[];
}

export interface IGeolocationDetails {
  Status: string;
  HtmlAttributions: Array<string>;
  Result: IGeolocationResult;
}

export interface IGeolocationResult {
  AddressComponents: Array<IAddressComponent>;
  AdrAddress: string;
  FormattedAddress: string;
  Geometry: IGeometry;
  Name: string;
  PlaceCode: string;
  Types: Array<string>;
  Vicinity: string;
}

export interface IAddressComponent {
  LongName: string;
  ShortName: string;
  Types: Array<string>;
}

export interface IGeometry {
  Location: IGeometryLocation;
  Viewport: IGeometryViewPort;
}

export interface IGeometryLocation {
  Lat: number;
  Lng: number;
}

export interface IGeometryViewPort {
  Northeast: IGeometryLocation;
  Southwest: IGeometryLocation;
}

export interface ITerm {
  Value: string;
  Offset: Number;
}

export interface IBasicAuth {
  grant_type: string;
  scope: string;
}

export interface TypeaheadSearch<T> {
  Items: T[];
}

export interface CredentialType {
  ID: number;
  Name?: string;
  NAICSCodeName?: string;
  IssuingAuthoritySource?: string;
  AuthorityType?: string;
  CommonCode?: string;
  IssuingAuthority?: string;
  CredentialUiMeta?: {
    Fields: { [key: string]: Field };
  };
}

export interface Field {
  IsRequired?: boolean;
  LabelBlurb?: string;
  Display?: string;
}

export type CredentialUiMetaKeys =
  | 'Authority'
  | 'ExpirationDate'
  | 'IssueDate'
  | 'LicenseNumber';

export const GB_COUNTRIES: ReadonlyArray<string> = [
  'GB',
  'GB1',
  'GB2',
  'GB3',
  'GB4',
  'GB5',
  'GB6',
  'GB7',
  'GB8',
  'GB9',
];
