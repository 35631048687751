import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { WindowRefService } from '../window-ref/window-ref.service';

/**
 * Display an image from the shared assets directory.
 *
 * ```html
 * <zc-image file="AwesomePhoto.png" alt="Awesome Photo"></zc-image>
 * ```
 */
@Component({
  selector: 'zc-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnChanges {
  constructor(private windowRef: WindowRefService) {}

  @Input() file: string;
  @Input() alt: string;
  src: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.file) {
      const dir = (this.windowRef.nativeWindow.ASSETS_URL || '/assets') + '/';
      this.src = dir + this.file;
    }
  }
}
