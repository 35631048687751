<div class="row">
  <div class="col-xs-6">
    <h4>
      <b>{{ 'Common.lblInvoices' | translate }}</b>
    </h4>
  </div>
</div>

<!-- invoices -->
<zc-table #table [data]="invoices" [busy]="busy">
  <zc-column headerL10nKey="Common.lblInvoiceDate">
    <ng-template zcCell let-item="row">{{
      item.InvoiceDate | date: 'mediumDate'
    }}</ng-template>
  </zc-column>
  <zc-column headerL10nKey="Common.lblViewInvoice">
    <ng-template zcCell let-item="row">
      <a (click)="downloadInvoice(item)">{{
        'Common.lblInvoicePdf' | translate
      }}</a>
    </ng-template>
  </zc-column>
  <zc-column headerL10nKey="Common.lblInvoice">
    <ng-template zcCell let-item="row">{{ item.InvoiceNumber }}</ng-template>
  </zc-column>
  <zc-column headerL10nKey="Common.lblInvoiceAmount">
    <ng-template zcCell let-item="row">{{
      item.InvoiceAmount | currency
    }}</ng-template>
  </zc-column>
</zc-table>

<!-- empty -->
<zc-empty
  *ngIf="table.isEmpty"
  file="img/no-invoices.svg"
  titleL10nKey="Common.lblNoInvoices"
  descriptionL10nKey="Common.lblNoInvoicesDescription"
>
</zc-empty>
