import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalWithResult, SaveSpec, WorkOrderService } from '@zipcrim/common';
import { AnaGroup } from '@zipcrim/common/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize, map } from 'rxjs/operators';

import { CheckToHireSvcService } from '../../common/check-to-hire-svc.service';
import { ReportService } from '../report.service';

@Component({
  templateUrl: './prepare-order-modal.component.html',
})
export class PrepareOrderModalComponent
  extends ModalWithResult
  implements OnInit
{
  constructor(
    protected bsModalRef: BsModalRef,
    private _WorkOrder: WorkOrderService,
    private _checkToHireSvc: CheckToHireSvcService
  ) {
    super(bsModalRef);
  }

  /**
   * Report service.
   *
   * Note: Service reference must be passed via `initialValues` when opening modal!
   */
  ReportService: ReportService;

  /**
   * Ana groups.
   */
  AnaGroupList: AnaGroup[];

  /**
   * Busy saving indicator.
   */
  BusySaving: boolean;

  /**
   * Busy loading indicator.
   */
  BusyLoading: boolean;

  /**
   * Indicates if at least one checkbox is selected.
   */
  IsOneSelected = true;

  /**
   * On init.
   */
  ngOnInit() {
    this.BusyLoading = true;
    this._checkToHireSvc
      .GetAnaGroups()
      .pipe(
        map((res) =>
          res.Items.map(
            (item) => new AnaGroup({ ...item, Code: item.LeadClass })
          )
        ),
        finalize(() => (this.BusyLoading = false))
      )
      .subscribe((res) => {
        const items = this.ReportService.NextStepAnaGroups || [];
        const codes = items.map((x) => x.LeadClass);

        this.AnaGroupList =
          codes.length > 0
            ? res.filter((x) => codes.indexOf(x.Code) !== -1)
            : res;
      });
  }

  /**
   * On cancel.
   */
  OnCancel() {
    this.dismiss();
  }

  /**
   * On submit.
   */
  OnSubmit(form: NgForm) {
    const value = form.value;
    const groups = Object.keys(value)
      .filter((key) => value[key] && this.ReportService.LeadNames.includes(key))
      .map(
        (item) =>
          new AnaGroup({
            LeadClass: item,
            IsSelected: value[item],
          })
      );

    this.IsOneSelected = groups.length > 0;

    if (!this.IsOneSelected) {
      return;
    }

    const spec = new SaveSpec(
      'WorkOrder',
      {
        CaseType: this.ReportService.CaseType,
        AnaGroups: groups,
      },
      true
    );

    this.BusySaving = true;
    this._WorkOrder
      .save(this.ReportService.WorkOrderId, spec)
      .pipe(finalize(() => (this.BusySaving = false)))
      .subscribe(() => this.close());
  }
}
