import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  IAnaGroup,
  ModalWithResult,
  SaveSpec,
  WorkOrderService,
} from '@zipcrim/common';
import { AnaGroup } from '@zipcrim/common/models';
import { OptionConfig } from '@zipcrim/forms/forms.interfaces';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { CheckToHireSvcService } from '../../common/check-to-hire-svc.service';
import { ReportService } from '../report.service';

@Component({
  selector: 'c2h-next-step-modal',
  templateUrl: './next-step-modal.component.html',
})
export class NextStepModalComponent extends ModalWithResult implements OnInit {
  constructor(
    protected bsModalRef: BsModalRef,
    private _CheckToHireSvc: CheckToHireSvcService,
    private _WorkOrder: WorkOrderService
  ) {
    super(bsModalRef);
  }

  /**
   * Report service.
   *
   * Note: Service reference must be passed via `initialValues` when opening modal!
   */
  ReportService: ReportService;

  /**
   * Busy loading indicator.
   */
  BusyLoading: boolean;

  /**
   * Busy saving indicator.
   */
  BusySaving: boolean;

  /**
   * Stage options.
   */
  StageOptions: OptionConfig[] = [];

  /**
   * Ana groups.
   */
  AnaGroups: AnaGroup[];

  /**
   * Form reference.
   */
  @ViewChild('Form')
  Form: FormGroup;

  /**
   * Used to store status of at least one checkbox is selected or not.
   */
  IsAtLeastOneCBSelected = true;

  /**
   * Selected stage.
   */
  CaseType: string;

  /**
   * on init.
   */
  ngOnInit() {
    this.CaseType = this.ReportService.CaseType;

    this.BusyLoading = true;
    combineLatest([
      this.ReportService.GetStageOptions().pipe(
        tap((res) => (this.StageOptions = res))
      ),
      this._CheckToHireSvc.GetAnaGroups().pipe(
        map((res) =>
          res.Items.map(
            (item) => new AnaGroup({ ...item, Code: item.LeadClass })
          )
        ),
        tap((res) => (this.AnaGroups = res))
      ),
    ])
      .pipe(finalize(() => (this.BusyLoading = false)))
      .subscribe(() => this._SetAnaGroupForStage());
  }

  /**
   * On submit.
   */
  OnSubmit() {
    const value = this.Form.value;
    const groups = Object.keys(value)
      .filter((key) => value[key] && this.ReportService.LeadNames.includes(key))
      .map(
        (key) =>
          new AnaGroup({
            LeadClass: key,
            IsSelected: value[key],
          })
      );

    this.IsAtLeastOneCBSelected = groups.length !== 0;

    if (!this.IsAtLeastOneCBSelected) {
      return;
    }

    const spec = new SaveSpec(
      'WorkOrder',
      {
        CaseType: value.CaseType,
        CaseNum: this.ReportService.CaseNum,
        AnaGroups: groups,
      },
      true
    );

    this.BusySaving = true;
    this._WorkOrder
      .save(0, spec)
      .pipe(finalize(() => (this.BusySaving = false)))
      .subscribe(() => {
        this.close();
      });
  }

  /**
   * Used to set current selected stage.
   */
  OnStageChange() {
    this._SetAnaGroupForStage();
  }

  /**
   * Used to hide the modal
   */
  OnDismiss() {
    this.dismiss();
  }

  /**
   * Set properties on the ana groups for the currently selected stage.
   */
  private _SetAnaGroupForStage() {
    const stage = this.StageOptions.find((x) => x.value === this.CaseType);
    const groups: IAnaGroup[] = stage.AnaGroups.Items || [];

    this.AnaGroups.forEach((item) => {
      item.IsSelected = false;

      const match = groups.find((x) => x.Code === item.Code);

      if (match) {
        item.IsSelected = true;
        item.IsReadOnly = match.IsReadOnly;
      }
    });
  }
}
