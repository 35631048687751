<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ 'Common.lblAttestTitle' | translate }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <form #form="ngForm" (ngSubmit)="onSubmit()">
      <!-- loading -->
      <zc-skeleton *ngIf="busyLoading; else content" preset="list">
      </zc-skeleton>

      <!-- attestations -->
      <ng-template #content>
        <ng-container
          *ngFor="let item of items; let isLast = last; let index = index">
          <div class="form-group">
            <label>{{ item.Description }}</label>

            <!-- options -->
            <div class="radio" *ngFor="let option of item.Options">
              <label>
                <input
                  type="radio"
                  [name]="'attest-' + index"
                  [value]="option.ID"
                  [(ngModel)]="item.AttestOptionID"
                  required />
                {{ option.Text }}
              </label>
            </div>
          </div>
          <hr [hidden]="isLast" />
        </ng-container>
      </ng-template>
    </form>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button color="info" outline class="me-1" (click)="onCancel()">
      {{ 'Common.lblCancel' | translate }}
    </zc-button>
    <zc-button
      color="primary"
      (click)="onSubmit()"
      [busy]="busySaving"
      [disabled]="form.invalid">
      {{ 'Common.cmdSubmit' | translate }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
