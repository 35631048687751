import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Validator options object.
 */
export interface RequireControlsOptions {
  min?: number;
  max?: number;
}

/**
 * Validates that other controls have values.
 *
 * @param options Validation options.
 * @param controls Associated controls to validate.
 */
export function RequireControlsValidator(
  options: RequireControlsOptions,
  controls: AbstractControl[]
) {
  const { min, max } = options;

  return (): ValidationErrors | null => {
    const checked = controls.filter((item) => !!item.value);
    const actual = checked.length;

    if (min && max && (actual < min || actual > max)) {
      return {
        requiredControls: { min, max, actual },
      };
    }

    if (min && actual < min) {
      return {
        requiredMinControls: { min, actual },
      };
    }

    if (max && actual > max) {
      return {
        requiredMaxControls: { max, actual },
      };
    }

    return null;
  };
}
