import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalWithResult } from './modal-with-result';

@Component({
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent extends ModalWithResult {
  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  title: string;
  message: string;
  noLabel: string;
  yesLabel: string;

  onCancel() {
    this.dismiss();
  }

  onContinue() {
    this.close();
  }
}
