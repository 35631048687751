import { IContactName } from '../generated';

/**
 * Contact name.
 */
export class ContactName {
  constructor(source?: Partial<IContactName>) {
    this._Init(source);
  }

  ID: number;
  FullName: string;
  FirstName: string;
  MiddleName?: string;
  LastName: string;
  Suffix?: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IContactName>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.FullName = source.FullName;
    this.FirstName = source.FirstName;
    this.MiddleName = source.MiddleName;
    this.LastName = source.LastName;
    this.Suffix = source.Suffix;
  }
}
