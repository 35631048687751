<!-- cases -->
<zc-table #table [data]="Cases" [busy]="Busy" color="transparent">
  <zc-column headerL10nKey="Common.lblName">
    <ng-template zcCell let-row="row">
      <a (click)="Navigate(row)">{{ FullName(row) }}</a>
      <zc-busy class="ms-1" size="xs" *ngIf="BusyNavigating[row.ID]"></zc-busy>
    </ng-template>
  </zc-column>
  <zc-column headerL10nKey="Common.lblNationalId">
    <ng-template zcCell let-row="row">{{ Ssn(row) }}</ng-template>
  </zc-column>
  <zc-column headerL10nKey="CheckToHire.lblReports">
    <ng-template zcCell let-row="row">
      <c2h-lead-tags
        class="mt-1"
        [CaseNumber]="row.CaseNum"
        [Leads]="row.Leads"
        (OnTagClick)="Navigate(row, $event)">
      </c2h-lead-tags>
    </ng-template>
  </zc-column>
  <zc-column headerL10nKey="CheckToHire.lblLastCompleted">
    <ng-template zcCell let-row="row">
      <a (click)="NavigateToLastCompleted(row)">
        {{ LastLeadTinyName(row) }}
      </a>
    </ng-template>
  </zc-column>
  <zc-column headerL10nKey="CheckToHire.lblOrderedBy">
    <ng-template zcCell let-row="row">{{ OwnerName(row) }}</ng-template>
  </zc-column>
  <zc-column headerL10nKey="CheckToHire.lblLastReported">
    <ng-template zcCell let-row="row">{{
      Completed(row) | date : 'MM/dd/yyyy'
    }}</ng-template>
  </zc-column>
  <zc-column *ngIf="!HideActions" class="no-wrap">
    <ng-template zcCell let-row="row">
      <zc-button
        *ngIf="CanTogglePinned"
        color="transparent"
        class="text-gray me-1"
        [buttonClasses]="['btn-condensed']"
        (click)="TogglePinned(row)">
        <fa-icon [icon]="['far', 'star']"></fa-icon>
      </zc-button>
      <zc-button
        *ngIf="!CanTogglePinned"
        color="transparent"
        class="text-gray me-1"
        [buttonClasses]="['btn-condensed']"
        [popover]="'CheckToHire.lblPinnedLimitExceeds' | translate"
        triggers="mouseenter:mouseleave">
        <fa-icon [icon]="['far', 'star']"></fa-icon>
      </zc-button>
      <zc-button
        color="transparent"
        class="text-gray"
        [buttonClasses]="['btn-condensed']"
        [title]="'CheckToHire.cmdArchive' | translate"
        (click)="ToggleHiddenWithConfirm(row)">
        <fa-icon icon="archive"></fa-icon>
      </zc-button>
    </ng-template>
  </zc-column>
</zc-table>

<!-- empty -->
<p class="text-muted mb-4" *ngIf="table.isEmpty">
  {{ 'CheckToHire.lblNothingToShow' | translate }}
</p>
