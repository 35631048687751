import { IEnvironment } from '@zipcrim/common';
import config from './../../assets/config.json';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment: IEnvironment = {
//   production: false,
//   showDebugLogs: true,
//   eulaUrl: 'https://devadmin.carcogroup.com/SSP/eula.html', //Temporary until we get the actual URL configured
// };

declare global {
  interface Window {
    AZURE_PIPELINE_VARIABLES?: {
      [key: string]: string;
    };
  }
}

function getWindowVariable(variableName: string, defaultValue: string): string {
  if (typeof window !== 'undefined' && window.AZURE_PIPELINE_VARIABLES && window.AZURE_PIPELINE_VARIABLES[variableName]) {
    return window.AZURE_PIPELINE_VARIABLES[variableName];
  }
  return defaultValue;
}

export const environment: IEnvironment = {
  production: false,
  showDebugLogs: true,
  enableLoginPicker: true,
  testEnvironmentVariable: (window as any).MY_APP_SETTING1,
  testEnvironmentVariable1: process.env.MY_APP_SETTING1,
  configConnection: getWindowVariable('app_config_checktohire_connection', 'notworking'),
  appConfigConnection: config.appConfigConnection,
  appConfigConnectionToken: config.appConfigConnectionTokenization,
  skipColdFusion: true,
  eulaUrl: '',
  mockSession: {
    Url: {
      API: 'https://cisiveapidev.carcogroup.com/ws/',
      Session: 'https://cisiveapidev.carcogroup.com/ws/zsession/auth',
      Login: '/login-picker',
      Logout: '/login-picker',
    },
    PostData: {
      UserName: '',
      NewSessionGuidString: '',
    },
    AppData: {
      Locale: 'en-US',
      Target: 'dashboard',
    },
  },
  featureFlags: {
    'oauth-migration-107055': true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
