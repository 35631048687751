import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalWithResult } from './modal-with-result';

@Component({
  templateUrl: './info-modal.component.html',
})
export class InfoModalComponent extends ModalWithResult {
  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  title: string;
  message: string;
  doneLabel: string;

  onDone() {
    this.close();
  }
}
