import { ILead } from '../generated';
import { LeadReport } from './lead-report';
import { LeadType } from './lead-type';

/**
 * Lead.
 */
export class Lead {
  constructor(source?: Partial<ILead>) {
    this._Init(source);
  }

  Description: string;
  DescriptionLong: string;
  Detail: string;
  DetailLong: string;
  EndDate: string;
  ID: number;
  IsBlocked: boolean;
  IsClear: boolean;
  LeadNum: string;
  StartDate: string;
  Completed: string;
  Status: string;
  Codes: string[];
  LeadType: LeadType;
  Report?: LeadReport;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<ILead>) {
    if (!source) {
      return;
    }

    this.EndDate = source.EndDate;
    this.ID = source.ID;
    this.IsBlocked = source.IsBlocked;
    this.IsClear = source.IsClear;
    this.LeadNum = source.LeadNum;
    this.StartDate = source.StartDate;
    this.Completed = source.Completed;
    this.Status = source.Status;
    this.Codes = source.Codes ? source.Codes : [];
    this.LeadType = new LeadType(source.LeadType);

    if (source.Description) {
      this.Description = source.Description;
    }

    if (source.DescriptionLong) {
      this.DescriptionLong = source.DescriptionLong;
    }

    if (source.Detail) {
      this.Detail = source.Detail;
    }

    if (source.DetailLong) {
      this.DetailLong = source.DetailLong;
    }

    if (source.Report) {
      this.Report = new LeadReport(source.Report);
    }
  }
}
