import { Component, Input, OnChanges } from '@angular/core';

import { NavDirection, NavVariant } from './nav.interfaces';

/**
 * Navigation.
 *
 * ```html
 * <zc-nav>
 *   <zc-nav-link [link]="/home">Home</zc-nav-link>
 *   <zc-nav-link [link]="/about">About</zc-nav-link>
 *   <zc-nav-link [link]="/contact">Contact</zc-nav-link>
 *   <zc-nav-href href="https://www.google.com" target="_blank">Google</zc-nav-href>
 *   <zc-nav-item (click)="OnLogout()">Logout</zc-nav-item>
 * <zc-nav>
 * ```
 */
@Component({
  selector: 'zc-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnChanges {
  @Input() variant: NavVariant;
  @Input() direction: NavDirection;
  css: string;

  ngOnChanges() {
    this.setCss();
  }

  private setCss() {
    const css: string[] = [];

    if (this.direction) {
      css.push(`nav--${this.direction}`);
    }

    this.css = css.join(' ');
  }
}
