import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches a valid name.
 */
const NAME_REGEX = /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%ˆ^&*(){}|~<>;:[\]]{0,100}$/i;

/**
 * Max length value.
 */
const MAX_LENGTH = 100;

/**
 * Validate a name segment (first, last, middle).
 */
export function NameValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (!value || NAME_REGEX.test(value)) {
    if (value && value.length && value.length === 1) {
      const stValue = value as string;

      if (stValue.startsWith('.') || stValue.startsWith('-')) {
        return {
          name: true,
        };
      }
    }

    return null;
  }

  if (value.length >= MAX_LENGTH) {
    return {
      maxlength: {
        requiredLength: MAX_LENGTH,
      },
    };
  } else {
    return {
      name: true,
    };
  }
}
