import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { DynamicFormFieldComponent } from '../form-field/form-field.component';
import { FormService } from '@zipcrim/forms/form.service';

/**
 * Form input.
 *
 * ```html
 * <zc-dynamic-input Type="number" [(ngModel)]="myValue"></zc-dynamic-input>
 * ```
 */
@Component({
  selector: 'zc-dynamic-input',
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicInputComponent),
      multi: true,
    },
  ],
})
export class DynamicInputComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  constructor(
    private _FormService: FormService,
    @Optional() private _FormField: DynamicFormFieldComponent
  ) {}

  /**
   * Input element setter.
   */
  @ViewChild('input', { read: ViewContainerRef })
  set Input(value: ViewContainerRef) {
    this._Input = value;
  }

  /**
   * Input value getter / setter.
   */
  get Value() {
    return this._Value;
  }

  set Value(value) {
    this._Value = value;
    this.onChange(value);
  }

  /**
   * Input type.
   */
  @Input()
  Type = 'text';

  /**
   * Input id.
   */
  @Input()
  Id = '';

  /**
   * Indicated if the input is readonly.
   */
  @Input()
  Readonly = false;

  /**
   * Indicates if the input is disabled.
   */
  @Input()
  Disabled = false;

  /**
   * Input placeholder text.
   */
  @Input()
  Placeholder: string;

  /**
   * Input size.
   */
  @Input()
  Size: 'sm' | 'lg';

  /**
   * Custom CSS class to add to input.
   */
  @Input()
  Css: string;

  ////////////////////////////////////
  // TODO: EJA: Does this do anything?
  @Input()
  zcDisableCopyPaste: boolean;

  @Input()
  zcDisableScrolling: boolean;
  ////////////////////////////////////

  /**
   * Calculated CSS classes for input element.
   */
  InputCss: string[] = ['form-control'];

  HasRequired$: Observable<boolean>;

  /**
   * Input value.
   */
  private _Value: any;

  /**
   * Input element reference.
   */
  private _Input: ViewContainerRef;

  /**
   * On init.
   */
  ngOnInit() {
    this.HasRequired$ = this._FormField?.HasRequired ?? of(false);

    if (!this.Id && this._FormField) {
      this.Id = this._FormField.Id;
    }
  }

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.Type || changes.Size) {
      this._SetInputCss();
    }
  }

  /**
   * Set focus to current input.
   */
  Focus() {
    if (this._Input) {
      this._Input.element.nativeElement.focus();
    }
  }

  /**
   * Copy the current input value to the clipboard.
   */
  Copy() {
    if (this._Input) {
      this._Input.element.nativeElement.select();
      document.execCommand('copy');
    }
  }

  /**
   * On blur.
   */
  OnBlur() {
    this.onTouched();
  }

  /**
   * On change.
   */
  onChange = (_: any) => {
    // empty
  };

  /**
   * On touched.
   */
  onTouched = () => {
    // empty
  };

  /**
   * Write value.
   */
  writeValue(value: any) {
    if (this._FormService.HasValue(value)) {
      this.Value = value;
    }
  }

  /**
   * Register on change.
   */
  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  /**
   * Register on touched.
   */
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  /**
   * Set disabled state.
   */
  setDisabledState(isDisabled: boolean) {
    this.Disabled = isDisabled;
  }

  /**
   * Calculate needed CSS classes.
   */
  private _SetInputCss() {
    this.InputCss = [];

    switch (this.Type) {
      case 'checkbox':
      case 'file':
      case 'hidden':
      case 'radio':
        // no css class
        break;
      default:
        this.InputCss.push('form-control');
        break;
    }

    if (this.Size) {
      this.InputCss.push(`input-${this.Size}`);
    }

    if (this.Css) {
      this.InputCss.push(this.Css);
    }
  }
}
