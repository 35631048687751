import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { NavComponent } from './nav.component';

@Component({
  selector: 'zc-nav-href',
  templateUrl: './nav-href.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavHrefComponent implements OnInit, OnChanges {
  constructor(private nav: NavComponent) {}

  @Input() active: boolean;
  @Input() href: string;
  @Input() target = '_self';
  css: string;

  ngOnInit() {
    this.setCss();
  }

  ngOnChanges() {
    this.setCss();
  }

  private setCss() {
    const css: string[] = [];

    if (this.nav.variant) {
      css.push(`nav__item--${this.nav.variant}`);
    }

    if (this.nav.direction) {
      css.push(`nav__item--${this.nav.direction}`);
    }

    if (this.active) {
      css.push('nav__item--active');
    }

    this.css = css.join(' ');
  }
}
