<label
  class="control-label"
  [for]="For || ''"
  [id]="Id || ''"
  [ngClass]="{
    'control-label--required': Required,
    'control-label--busy': Busy
  }"
>
  <!-- busy -->
  <zc-skeleton *ngIf="Busy" [fixed]="true"> </zc-skeleton>

  <!-- content -->
  <span class="control-label__content">
    <ng-content></ng-content>
    <span class="asterisk" *ngIf="Required"></span>
  </span>
</label>
