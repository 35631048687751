<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ title }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <p class="text-center mt-2 mb-2">{{ message }}</p>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button color="success" (click)="onDone()">
      {{ doneLabel }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
