import { Component, Input, OnInit, Optional } from '@angular/core';

import { DynamicFormFieldComponent } from '../form-field/form-field.component';

/**
 * Form input label.
 *
 * ```html
 * <zc-dynamic-label>Hello World!</zc-dynamic-label>
 * ```
 */
@Component({
  selector: 'zc-dynamic-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class DynamicLabelComponent implements OnInit {
  constructor(@Optional() private _FormField: DynamicFormFieldComponent) {}

  /**
   * Id of associated input.
   */
  @Input()
  For: string;

  /**
   * Indicates if the associated input is required.
   */
  @Input()
  Required: boolean;

  /**
   * Busy indicator.
   */
  @Input()
  Busy: boolean;

  /**
   * Label id.
   */
  Id: string;

  /**
   * On init.
   */
  ngOnInit() {
    if (!this.For && this._FormField) {
      this.For = this._FormField.Id;
    }

    if (this._FormField) {
      this.Id = this._FormField.AriaLabeledById;
    }

    if (this._FormField && this.Required == null) {
      this._FormField.HasRequired.subscribe((value) => (this.Required = value));
    }
  }
}
