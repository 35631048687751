<div class="document-signing">
  <!-- Sidebar -->
  <zc-sidebar>
    <zc-sidebar-body>
      <h1 class="header-1 mt-0">
        {{ 'Common.lblDocumentSignHeading' | translate }}
      </h1>
      <h2 class="header-2">{{ 'Common.lblKey' | translate }}</h2>
      <dl>
        <dt class="d-inline-block">
          <fa-icon icon="edit" class="me-1"></fa-icon>
        </dt>
        <dd class="d-inline-block">
          {{ 'Common.lblSignAvailableSubHead' | translate }}
        </dd>
        <div class="w-100"></div>
        <dt class="d-inline-block">
          <fa-icon icon="check-circle" class="me-1 text-green"></fa-icon>
        </dt>
        <dd class="d-inline-block">
          {{ 'Common.lblPageSignIndication' | translate }}
        </dd>
      </dl>

      <!-- available documents -->
      <accordion [closeOthers]="true">
        <div
          class="available-documents"
          *ngIf="availableDocuments; else loadingDocuments">
          <accordion-group
            *ngFor="let document of availableDocuments; let i = index"
            [isOpen]="document?.IsOpen"
            [isDisabled]="document?.Status === 'Complete'"
            (isOpenChange)="isOpenChange($event, i, document)"
            (click)="getCurrentWorkingPage(i, 0, document)">
            <h2 class="header-2 mb-0" accordion-heading>
              <p
                class="document-signing__sidebar-item mb-0"
                [ngClass]="{
                  'document-signing__sidebar-current':
                    currentlyWorkingDocumentIndex === i
                }">
                <!-- If any problem face like redundant API move click event from accordion-group and apply to p -->
                <span class="document-signing__page-number">
                  <span class="badge" *ngIf="document.Status !== 'Complete'">
                    {{ i + 1 }}
                  </span>
                  <span class="badge" *ngIf="document.Status === 'Complete'">
                    <fa-icon icon="check"></fa-icon>
                  </span>
                </span>
                <span class="document-signing__page-title">
                  {{ document?.Name }}
                  <span
                    class="text-green"
                    *ngIf="document.Status === 'Complete'">
                    ( {{ 'Common.lblSignedDocument' | translate }} )
                  </span>
                </span>
              </p>
              <div class="row" *ngIf="document.Status === 'Complete'">
                <div class="col-md-12">
                  <zc-button
                    color="primary"
                    (click)="download(document)"
                    class="margin-bottom mt-1"
                    block="true"
                    [busy]="busy">
                    <fa-icon icon="cloud-download-alt"></fa-icon>
                    {{ 'Common.lblDownloadDocument' | translate }}
                  </zc-button>
                </div>
              </div>
            </h2>
            <div class="thumbs">
              <ng-container
                *ngIf="isPagesReady(document.Pages); else loadingPages">
                <a
                  (click)="
                    getCurrentWorkingPage(i, docIndex, document);
                    $event.stopPropagation()
                  "
                  class="thumb"
                  *ngFor="let page of document.Pages; let docIndex = index"
                  [ngClass]="{
                    'thumb--on': currentlyWorkingPageIndex === docIndex,
                    'thumb--disabled':
                      nextPageIndex !== docIndex &&
                      currentlyWorkingPageIndex !== docIndex &&
                      prevPageIndex !== docIndex &&
                      !page.PageVisited,
                    'thumb--off':
                      currentlyWorkingPageIndex !== docIndex &&
                      !(
                        nextPageIndex !== docIndex &&
                        currentlyWorkingPageIndex !== docIndex &&
                        prevPageIndex !== docIndex &&
                        !page.PageVisited
                      )
                  }">
                  <span
                    *ngIf="
                      nextPageIndex !== docIndex &&
                      currentlyWorkingPageIndex !== docIndex &&
                      prevPageIndex !== docIndex &&
                      !page.PageVisited
                    "
                    [ngClass]="{
                      thumb__message:
                        nextPageIndex !== docIndex &&
                        currentlyWorkingPageIndex !== docIndex &&
                        prevPageIndex !== docIndex &&
                        !page.PageVisited
                    }">
                    Please review preceding page
                  </span>
                  <img class="thumb__image" [src]="page.PageImage" />
                  <div class="thumb__page">
                    {{ page?.index }}
                    <fa-icon
                      icon="edit"
                      *ngIf="
                        page.IsSignatureAvailable && !page.SignatureStatus
                      "></fa-icon>
                    <fa-icon
                      icon="check-circle"
                      *ngIf="page.IsSignatureAvailable && page.SignatureStatus"
                      class="text-green"></fa-icon>
                  </div>
                </a>
              </ng-container>

              <ng-template #loadingPages>
                <div class="thumb" *ngFor="let item of [1, 2, 3]">
                  <zc-skeleton
                    preset="page"
                    [responsive]="true"
                    class="thumb__preview"></zc-skeleton>
                  <div class="thumb__page">
                    <zc-skeleton
                      [rows]="[[{ size: 2, offset: 5 }]]"
                      [responsive]="true"></zc-skeleton>
                  </div>
                </div>
              </ng-template>
            </div>
          </accordion-group>
        </div>

        <!-- loading -->
        <ng-template #loadingDocuments>
          <accordion-group [isOpen]="true">
            <div class="d-flex align-items-center" accordion-heading>
              <span class="document-signing__page-number me-1">
                <span class="badge">&nbsp;</span>
              </span>
              <div class="flex-grow-1">
                <zc-skeleton
                  [rows]="[[{ size: 8 }], [{ size: 5 }]]"
                  [responsive]="true"></zc-skeleton>
              </div>
            </div>
            <div class="thumbs">
              <div class="thumb" *ngFor="let item of [1, 2, 3]">
                <zc-skeleton
                  preset="page"
                  [responsive]="true"
                  class="thumb__preview"></zc-skeleton>
                <div class="thumb__page">
                  <zc-skeleton
                    [rows]="[[{ size: 2, offset: 5 }]]"
                    [responsive]="true"></zc-skeleton>
                </div>
              </div>
            </div>
          </accordion-group>
        </ng-template>
      </accordion>
    </zc-sidebar-body>
  </zc-sidebar>

  <!-- current document -->
  <section class="p-2">
    <ng-container *ngIf="isCurrentReady(); else loadingDocument">
      <div class="d-flex justify-content-between document-signing__controls">
        <p>
          <strong
            >Page {{ currentlyWorkingPageIndex + 1 }} of
            {{ totalPageCount }}</strong
          >
        </p>
        <nav class="document-signing__nav">
          <zc-button
            color="info"
            outline
            *ngIf="currentlyWorkingPageIndex !== 0"
            [disabled]="currentlyWorkingPageIndex === 0"
            (click)="navigatePrevious()">
            {{ 'Common.cmdScreenPrevious' | translate }}
          </zc-button>
          <zc-button
            color="info"
            outline
            *ngIf="
              currentlyWorkingPageIndex === 0 &&
              currentlyWorkingDocumentIndex !== 0
            "
            (click)="
              navigateLastPageOfPreviousDoc(currentlyWorkingDocumentIndex)
            ">
            {{ 'Common.cmdScreenPrevious' | translate }}
          </zc-button>
          <zc-button
            color="primary"
            *ngIf="currentlyWorkingPageIndex !== totalPageCount - 1"
            [disabled]="currentlyWorkingPageIndex === totalPageCount - 1"
            (click)="navigateNext()">
            {{ 'Common.cmdNext' | translate }}
          </zc-button>
          <zc-button
            color="primary"
            *ngIf="
              currentlyWorkingPageIndex === totalPageCount - 1 &&
              currentlyWorkingDocumentIndex !== availableDocuments.length - 1
            "
            (click)="navigateFirstPageOfNextDoc(currentlyWorkingDocumentIndex)">
            {{ 'Common.cmdNext' | translate }}
          </zc-button>
        </nav>
      </div>

      <alert type="dark" *ngIf="currentlyWorkingPage.IsSignatureAvailable">
        <fa-icon class="me-1" icon="edit"> </fa-icon>
        {{ 'Common.lblSignAvailable' | translate }}
      </alert>

      <div class="document-signing__viewer">
        <img
          class="document-signing__image"
          [src]="currentlyWorkingPage.PageImage" />
        <div class="document-signing__sign-blocks">
          <button
            *ngIf="currentlyWorkingPage.IsSignatureAvailable"
            class="btn btn-sign"
            (click)="sign(currentlyWorkingPage)"
            [ngStyle]="{
              'left.px':
                currentlyWorkingPage.SignatureInfo.LocationAsPercent.Location
                  .X * 10,
              'top.px':
                currentlyWorkingPage.SignatureInfo.LocationAsPercent.Location
                  .Y * 10
            }">
            <span
              *ngIf="
                currentlyWorkingPage.IsSignatureAvailable &&
                currentlyWorkingPage.SignatureStatus
              "
              >{{ 'Common.lblReview' | translate }}</span
            >
            <span
              *ngIf="
                currentlyWorkingPage.IsSignatureAvailable &&
                !currentlyWorkingPage.SignatureStatus
              ">
              <fa-icon icon="edit"></fa-icon>
              <span class="ms-1">{{ 'Common.lblSignHere' | translate }}</span>
            </span>
          </button>
        </div>
      </div>
    </ng-container>

    <!-- loading -->
    <ng-template #loadingDocument>
      <div class="document-signing__controls">
        <zc-skeleton
          [rows]="[
            [{ size: 3 }, { size: 2, offset: 5 }, { size: 2 }]
          ]"></zc-skeleton>
      </div>
      <div class="document-signing__viewer">
        <div class="document-signing__document">
          <zc-skeleton preset="page"></zc-skeleton>
        </div>
      </div>
    </ng-template>
  </section>
</div>
