import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import {
  FilterSpecGroupType,
  FilterSpecOperator,
  FilterSpecSortDirection,
} from './filter-spec.enums';
import {
  FilterSpecCondition,
  FilterSpecGroup,
  FilterSpecSortConfig,
} from './filter-spec.interfaces';

/**
 * Filter spec implementation.
 */
export class FilterSpec {
  constructor();
  constructor(field: string, operator: FilterSpecOperator, value: any);
  constructor(field?: string, operator?: FilterSpecOperator, value?: any) {
    if (field && operator && value) {
      this.add(field, operator, value);
    }
  }

  /**
   * Current filter conditions.
   */
  private conditions: Array<string | FilterSpecGroup | FilterSpecCondition> =
    [];

  /**
   * Sort option.
   */
  private _sort: Array<string | FilterSpecSortConfig>;

  /**
   * Pagination option.
   */
  private _paged: { skip: number; take: number };

  /**
   * Query id option.
   */
  private _queryId: number;

  /**
   * Sort the results by specific properties.
   *
   * @param names Property names to sort by.
   */
  sort(names: Array<string | FilterSpecSortConfig>) {
    this._sort = names;
    return this;
  }

  /**
   * Paginate the results.
   *
   * @param skip Number of records to skip.
   * @param take Number of record to return.
   */
  paged(skip: number, take: number) {
    this._paged = { skip, take };
    return this;
  }

  /**
   * Query id provided in response of original request.
   *
   * @param id Query id to use.
   */
  queryId(id: number) {
    this._queryId = id;
    return this;
  }

  /**
   * Include the server defined default fields.
   */
  default() {
    this.conditions.push('#');
    return this;
  }

  /**
   * Include a server defined named spec.
   *
   * @param name Field spec name.
   */
  named(name: string) {
    this.conditions.push(`#${name}`);
    return this;
  }

  /**
   * Match a search term.
   *
   * @param term Term to search for.
   */
  match(term: string) {
    this.conditions.push(`@MATCH("${term}")`);
    return this;
  }

  /**
   * Add an equals condition.
   *
   * @param name Property name used to filter.
   * @param value Value used to filter.
   */
  equals(name: string, value: boolean | number | string) {
    return this.add(name, FilterSpecOperator.Equals, value);
  }

  /**
   * Add a greater than condition.
   *
   * @param name Property name used to filter.
   * @param value Value used to filter.
   */
  greaterThan(name: string, value: number | string) {
    return this.add(name, FilterSpecOperator.GreaterThan, value);
  }

  /**
   * Add a greater than or equals condition.
   *
   * @param name Property name used to filter.
   * @param value Value used to filter.
   */
  greaterThanOrEquals(name: string, value: number | string) {
    return this.add(name, FilterSpecOperator.GreaterThanOrEquals, value);
  }

  /**
   * Add an in condition.
   *
   * @param name Property name used to filter.
   * @param value Value used to filter.
   */
  in(name: string, value: Array<number | string>) {
    return this.add(name, FilterSpecOperator.In, value);
  }

  /**
   * Add a less than condition.
   *
   * @param name Property name used to filter.
   * @param value Value used to filter.
   */
  lessThan(name: string, value: number | string) {
    return this.add(name, FilterSpecOperator.LessThan, value);
  }

  /**
   * Add a less than or equals condition.
   *
   * @param name Property name used to filter.
   * @param value Value used to filter.
   */
  lessThanOrEquals(name: string, value: number | string) {
    return this.add(name, FilterSpecOperator.LessThanOrEquals, value);
  }

  /**
   * Add a not equals condition.
   *
   * @param name Property name used to filter.
   * @param value Value used to filter.
   */
  notEquals(name: string, value: boolean | number | string) {
    return this.add(name, FilterSpecOperator.NotEquals, value);
  }

  /**
   * Add a condition to the filter spec.
   *
   * @param name Property name used to filter.
   * @param operator Operator used to filter.
   * @param value Value used to filter.
   */
  add(name: string, operator: FilterSpecOperator, value: any) {
    this.conditions.push({
      name,
      operator,
      value,
    });

    return this;
  }

  /**
   * Add grouped AND conditions to the filter spec.
   *
   * @param fn Function executed with spec parameter.
   */
  and(fn: (spec: FilterSpec) => void) {
    const group = new FilterSpec();
    fn.call(null, group);

    this.conditions.push({
      type: FilterSpecGroupType.And,
      conditions: [...group.conditions],
    } as FilterSpecGroup);

    return this;
  }

  /**
   * Add grouped OR conditions to the filter spec.
   *
   * @param fn Function executed with spec parameter.
   */
  or(fn: (spec: FilterSpec) => void) {
    const group = new FilterSpec();
    fn.call(null, group);

    this.conditions.push({
      type: FilterSpecGroupType.Or,
      conditions: [...group.conditions],
    } as FilterSpecGroup);

    return this;
  }

  /**
   * To string.
   */
  toString() {
    const options: string[] = [];

    if (this._paged) {
      const { skip, take } = this._paged;
      const paged = skip ? `${skip}:${take}:` : `${take}:`;

      options.push(paged);
    }

    if (this._queryId) {
      options.push(`@Q[${this._queryId}]:`);
    }

    if (this._sort) {
      const fields = this._sort
        .map((item) => {
          if (isString(item)) {
            return item;
          }

          return item.direction === FilterSpecSortDirection.Desc
            ? `${item.name}:d`
            : item.name;
        })
        .join(',');

      options.push(`@SORT[${fields}]:`);
    }

    const conditions = this.conditions
      .map((item) => this.convertToString(item))
      .join(FilterSpecGroupType.And);

    return `[${options.join('')}${conditions}]`;
  }

  /**
   * Convert a group or condition to a string.
   *
   * @param item Object to convert to string.
   */
  private convertToString(
    item: string | FilterSpecGroup | FilterSpecCondition
  ): string {
    if (isString(item)) {
      // named query
      return item;
    } else if ('type' in item) {
      // spec group, recursively process nested items
      const group = item.conditions
        .map((x) => this.convertToString(x))
        .join(item.type);

      return `{${group}}`;
    } else {
      // spec condition, render output
      const value = this.renderValue(item.value);
      return `${item.name}${item.operator}${value}`;
    }
  }

  /**
   * Safely render a value.
   *
   * @param value Value to render.
   */
  private renderValue(value: any): string {
    if (isString(value)) {
      return `"${value}"`;
    } else if (isArray(value)) {
      const values: string = value
        .map((item) => this.renderValue(item))
        .join(',');

      return `(${values})`;
    } else {
      return value;
    }
  }
}
