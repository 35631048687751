import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiMessage } from '../api/api-message';
import { L10nService } from '../l10n/l10n.service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { NotificationService } from './notification.service';

/**
 * Notification display component.
 *
 * ```html
 * <zc-notification></zc-notification>
 * ```
 */
@Component({
  selector: 'zc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  constructor(
    private l10n: L10nService,
    private notification: NotificationService,
    private router: Router,
    private windowRef: WindowRefService
  ) {}

  /**
   * Collection of currently displayed notifications.
   */
  notifications: Array<{ severity: string; description: string }> = [];

  /**
   * Indicates if all notifications should be visible.
   */
  showAll = false;

  /**
   * On add subscription reference.
   */
  private onAddSubscription: Subscription;

  /**
   * On remove all subscription reference.
   */
  private onRemoveAllSubscription: Subscription;

  ngOnInit() {
    this.onAddSubscription = this.notification.onAdd.subscribe((message) => {
      this.add(message);
      this.windowRef.nativeWindow.scrollTo(0, 0);
    });

    this.onRemoveAllSubscription = this.notification.onRemoveAll.subscribe(
      () => this.removeAll()
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.removeAll();
      }
    });
  }

  ngOnDestroy() {
    if (this.onAddSubscription) {
      this.onAddSubscription.unsubscribe();
    }

    if (this.onRemoveAllSubscription) {
      this.onRemoveAllSubscription.unsubscribe();
    }
  }

  onToggle() {
    this.showAll = !this.showAll;
  }

  onDismiss() {
    this.removeAll();
  }

  private add(message: ApiMessage) {
    let severity: string;
    let description: string;

    if (message) {
      severity = message.severity;
      description = message.blurb;
    } else {
      severity = 'Error';
      description = this.l10n.instant('Common.msgNoApiResultFail');
    }

    const exists = this.notifications.some(
      (item) => item.severity === severity && item.description === description
    );

    if (exists) {
      return;
    }

    this.notifications.push({ severity, description });

    if (message && message.innerMessages) {
      message.innerMessages.forEach((item) => this.add(item));
    }
  }

  private removeAll() {
    this.notifications = [];
  }
}
