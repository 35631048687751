import { NgModule } from '@angular/core';
import {
  FormsModule as NgFormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@zipcrim/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';

// import { DynamicDateComponent } from './date/date.component';
// import { DynamicErrorComponent } from './error/error.component';
import { FORM_ICONS } from '@zipcrim/forms/form.icons';
import { DynamicBadgeComponent } from './badge/badge.component';
import { DynamicCheckboxComponent } from './checkbox/checkbox.component';
import { DynamicDatePartsComponent } from './date-parts/date-parts.component';
import { DynamicCollectionComponent } from './dynamic-collection/dynamic-collection.component';
import { DynamicFormFieldComponent } from './form-field/form-field.component';
//import { DynamicFuzzyDateModalComponent } from './fuzzy-date/fuzzy-date-modal.component';
//import { FuzzyDateComponent } from './fuzzy-date/fuzzy-date.component';
import { DynamicHelpTextComponent } from './help-text/help-text.component';
import { DynamicInputComponent } from './input/input.component';
import { DynamicLabelComponent } from './label/label.component';
import { DynamicNodeComponent } from './dynamic-node/dynamic-node.component';
import { DynamicNodeItemComponent } from './dynamic-node/dynamic-node-item.component';
//import { DynamicRadioButtonComponent } from './radio-group/radio-button.component';
import { DynamicRadioGroupComponent } from './radio-group/radio-group.component';
import { DynamicSelectComponent } from './select/select.component';
import { DynamicErrorComponent } from './error/error.component';
import { DynamicErrorsComponent } from './error/errors.component';
import { DynamicCheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { DynamicFuzzyDateComponent } from './fuzzy-date/fuzzy-date.component';
import { DynamicFuzzyDateModalComponent } from './fuzzy-date/fuzzy-date-modal.component';
import { DynamicValidationMessagesComponent } from './validation-messages/validation-messages.component';
// import { SummaryGroupComponent } from './summary/summary-group.component';
// import { SummaryComponent } from './summary/summary.component';
// import { TextareaComponent } from './textarea/textarea.component';
// import { PasswordDirective } from './validators/password.directive';
// import { RequireCheckedDirective } from './validators/require-checked.directive';
// import { SpanComponent } from './span/span.component';
// import { AriaLabelComponent } from './aria-label/aria-label.component';
// import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
// import { DynamicInputPhoneComponent } from './input-phone/input-phone.component';
// import { NativeElementInjectorDirective } from './input-phone/tel-input/directives/native-element-injector.directive';
// import { NgxIntlTelInputComponent } from './input-phone/tel-input/ngx-intl-tel-input.component';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    CommonModule,
    NgFormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    // BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxMaskDirective,
  ],
  declarations: [
    //DynamicDateComponent,
    //DynamicErrorComponent,
    DynamicBadgeComponent,
    DynamicCheckboxComponent,
    DynamicCheckboxGroupComponent,
    DynamicCollectionComponent,
    DynamicDatePartsComponent,
    DynamicErrorComponent,
    DynamicErrorsComponent,
    DynamicFormFieldComponent,
    DynamicFuzzyDateComponent,
    DynamicFuzzyDateModalComponent,
    DynamicHelpTextComponent,
    DynamicInputComponent,
    DynamicLabelComponent,
    DynamicNodeComponent,
    DynamicNodeItemComponent,
    //DynamicPasswordDirective,
    //DynamicRadioButtonComponent,
    DynamicRadioGroupComponent,
    //DynamicRequireCheckedDirective,
    DynamicSelectComponent,
    DynamicValidationMessagesComponent,
    // SummaryComponent,
    // SummaryGroupComponent,
    // TextareaComponent,
    // SpanComponent,
    // AriaLabelComponent,
    // AutoCompleteComponent,
    //DynamicInputPhoneComponent,
    // NativeElementInjectorDirective,
    // NgxIntlTelInputComponent,
  ],
  exports: [
    CommonModule,
    //DynamicDateComponent,
    //DynamicErrorComponent,
    DynamicBadgeComponent,
    DynamicCheckboxComponent,
    DynamicCheckboxGroupComponent,
    DynamicCollectionComponent,
    DynamicDatePartsComponent,
    DynamicFormFieldComponent,
    DynamicFuzzyDateComponent,
    DynamicFuzzyDateModalComponent,
    DynamicHelpTextComponent,
    DynamicInputComponent,
    DynamicLabelComponent,
    DynamicNodeComponent,
    DynamicNodeItemComponent,
    NgFormsModule,
    //PasswordDirective,
    //DynamicRadioButtonComponent,
    DynamicRadioGroupComponent,
    DynamicErrorsComponent,
    ReactiveFormsModule,
    DynamicSelectComponent,
    DynamicValidationMessagesComponent,
    // RequireCheckedDirective,
    // SummaryComponent,
    // SummaryGroupComponent,
    // TextareaComponent,
    // SpanComponent,
    // AutoCompleteComponent,
    // InputPhoneComponent,
    // NgxIntlTelInputComponent,
  ],
  providers: [provideEnvironmentNgxMask()],
})
export class FormsModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(...FORM_ICONS);
  }
}
