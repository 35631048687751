import { IAssociation } from '../generated';

/**
 * Association.
 */
export class Association {
  constructor(source?: Partial<IAssociation>) {
    this._Init(source);
  }

  ObjectClassName: string;
  ObjectID: string;
  PurposeCode: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IAssociation>) {
    if (!source) {
      return;
    }

    this.ObjectClassName = source.ObjectClassName;
    this.ObjectID = source.ObjectID;
    this.PurposeCode = source.PurposeCode;
  }
}
