<article class="walkthrough-overlay" [hidden]="IsClosed">
  <zc-image file="img/magnify-glass.svg" class="margin-bottom"></zc-image>
  <h1 class="walkthrough-overlay__title">
    {{ 'CheckToHire.lblRunFirstBackground' | translate }}
  </h1>
  <p class="walkthrough-overlay__description">
    {{ 'CheckToHire.lblYourReadyForFirstBackground' | translate }}
  </p>
  <button type="button" class="btn btn-light" (click)="OnClose()">
    {{ 'CheckToHire.cmdGotIt' | translate }}
  </button>
</article>
