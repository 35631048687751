import { Injectable } from '@angular/core';

import { SidebarComponent } from './sidebar.component';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  /**
   * Default sidebar id.
   */
  readonly defaultSidebarId = 'main';

  /**
   * Currently registered sidebar components.
   */
  private components = new Map<string, SidebarComponent>();

  /**
   * Register a sidebar component by id.
   *
   * @param id Sidebar id.
   * @param component Sidebar component.
   */
  register(id: string, component: SidebarComponent) {
    if (!id) {
      console.warn(`Id is required to register a sidebar instance!`);
      return;
    }

    if (this.components.has(id)) {
      console.warn(`Sidebar instance with id "${id}" is already registered!`);
      return;
    }

    this.components.set(id, component);
  }

  /**
   * Register a sidebar component by id.
   *
   * @param id Sidebar id.
   * @param component Sidebar component.
   */
  unregister(id: string) {
    if (!id) {
      return;
    }

    this.components.delete(id);
  }

  /**
   * Get sidebar instance by id.
   *
   * @param id Sidebar id.
   */
  getById(id?: string) {
    return this.components.get(id || this.defaultSidebarId);
  }

  /**
   * Toggle sidebar.
   *
   * @param id Sidebar id.
   */
  toggle(id?: string) {
    const component = this.getById(id);

    if (component) {
      component.toggle();
    }
  }

  /**
   * Open sidebar.
   *
   * @param id Sidebar id.
   */
  open(id?: string) {
    const component = this.getById(id);

    if (component) {
      component.open();
    }
  }

  /**
   * Close sidebar.
   *
   * @param id Sidebar id.
   */
  close(id?: string) {
    const component = this.getById(id);

    if (component) {
      component.close();
    }
  }
}
