import { AbstractControl, ValidationErrors } from '@angular/forms';
import { differenceInYears, isValid, parse } from 'date-fns';

/**
 * Validator options object.
 */
export interface AgeOptions {
  min?: number;
  max?: number;
}

/**
 * Validate a age.
 */
export function AgeValidator(options: AgeOptions) {
  const { min, max } = options;

  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    const date = parse(value, 'MM/dd/yyyy', new Date());

    if (!isValid(date)) {
      return null;
    }

    const diff = differenceInYears(new Date(), date);

    if (min && max && (diff < min || diff > max)) {
      return {
        age: { min, max, diff },
      };
    }

    if (min && diff < min) {
      return {
        minAge: { min, diff },
      };
    }

    if (max && diff > max) {
      return {
        maxAge: { max, diff },
      };
    }

    return null;
  };
}
