import { ILeadRequest } from '../generated';
import { Association } from './association';

/**
 * Lead request.
 */
export class LeadRequest {
  constructor(source?: Partial<ILeadRequest>) {
    this._Init(source);
  }

  ID: number;
  LeadTypeName: string;
  LeadTypeCode: string;
  SubType: string;
  SubType2: string;
  IsRequested: boolean;
  IsReadOnly: boolean;
  Description: string;
  Price: number;
  Processed: string;
  GovernmentFee: number;
  TotalFees: number;
  IsNonLinear: boolean;
  Associations: Association[];

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<ILeadRequest>) {
    if (!source) {
      this.Associations = [];
      return;
    }

    this.ID = source.ID;
    this.LeadTypeName = source.LeadTypeName;
    this.LeadTypeCode = source.LeadTypeCode;
    this.SubType = source.SubType;
    this.SubType2 = source.SubType2;
    this.IsRequested = !!source.IsRequested;
    this.IsReadOnly = !!source.IsReadOnly;
    this.Description = source.Description;
    this.Price = source.Price;
    this.Processed = source.Processed;
    this.GovernmentFee = source.GovernmentFee;
    this.TotalFees = source.TotalFees;
    this.IsNonLinear = !!source.IsNonLinear;

    const associations = source.Associations;

    this.Associations = associations
      ? associations.map((item) => new Association(item))
      : [];
  }
}
