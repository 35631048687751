<footer class="footer" *ngIf="State.root">
  <ul class="footer__links" *ngIf="State.nav">
    <li class="footer__link-item">
      <a
        href="https://www.driveriq.com/why-driver-iq/"
        target="blank"
        class="footer__link"
      >
        {{ 'CheckToHire.lblAboutUs' | translate }}
      </a>
    </li>
    <li class="footer__link-item">
      <a routerLink="/dashboard" class="footer__link">
        {{ 'CheckToHire.lblDashboard' | translate }}
      </a>
    </li>
    <li class="footer__link-item">
      <a routerLink="/account" class="footer__link">
        {{ 'CheckToHire.lblAccount' | translate }}
      </a>
    </li>
    <li class="footer__link-item">
      <a
        href="https://www.driveriq.com/contact/"
        target="blank"
        class="footer__link"
      >
        {{ 'Common.lblContactUs' | translate }}
      </a>
    </li>
  </ul>
  <p class="mb-0">
    <a href="http://www.cisive.com" class="footer__link">©{{ Year }} Cisive..</a>
    {{ 'CheckToHire.lblRightsReserved' | translate }}
  </p>
</footer>
