import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { ModalWithResult } from '@zipcrim/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * Post save confirmation modal.
 */
@Component({
  templateUrl: './contact-post-save-modal.component.html',
})
export class ContactPostSaveComponent extends ModalWithResult {
  constructor(protected bsModalRef: BsModalRef, private clipboard: Clipboard) {
    super(bsModalRef);
  }

  /**
   * Auth code to display.
   */
  AuthCode: string;

  /**
   * On copy.
   */
  onCopy() {
    this.clipboard.copy(this.AuthCode);
  }

  /**
   * On close.
   */
  onClose() {
    this.close();
  }
}
