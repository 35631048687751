import { IAnaGroup } from '../generated';

/**
 * Ana group.
 */
export class AnaGroup {
  constructor(source?: Partial<IAnaGroup>) {
    this._Init(source);
  }

  LeadClass: string;
  Code: string;
  Cost: number;
  Description: string;
  FullDescription: string;
  Rate: string;
  Unit: string;
  Options: { [key: string]: any };
  IsSelected: boolean;
  IsReadOnly: boolean;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IAnaGroup>) {
    if (!source) {
      this.Options = {};
      return;
    }

    this.LeadClass = source.LeadClass;
    this.Code = source.Code;
    this.Cost = source.Cost;
    this.Options = source.Options || {};
    this.IsReadOnly = source.IsReadOnly;
    this.IsSelected = source.IsSelected;

    if (source.DescriptionBlurb) {
      this.Description = source.DescriptionBlurb;
    }

    if (source.FullDescriptionBlurb) {
      this.FullDescription = source.FullDescriptionBlurb;
    }

    if (source.RateBlurb) {
      this.Rate = source.RateBlurb;
    }

    if (source.UnitBlurb) {
      this.Unit = source.UnitBlurb;
    }
  }
}
