import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalWithResult } from '../modal/modal-with-result';

@Component({
  templateUrl: './l10n-picker.component.html',
  styleUrls: ['./l10n-picker.component.scss'],
})
export class L10nPickerComponent extends ModalWithResult<string> {
  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  locales: string[];
  selected: string;

  onSelect(locale: string) {
    this.selected = locale;
  }

  onCancel() {
    this.dismiss();
  }

  onDone() {
    this.close(this.selected);
  }
}
