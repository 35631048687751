import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import isArray from 'lodash/isArray';

import { AccessRight } from './auth.interfaces';
import { AuthService } from './auth.service';

/***
 * Toggle a template based on the current user's rights.
 *
 * ```html
 * <div *zcHasRights="'Contact'">
 *   <p>Top secret!</p>
 * </div>
 *
 * <div *zcHasRights="['Contact', 'Add']">
 *   <p>Super top secret!</p>
 * </div>
 * ```
 */
@Directive({
  selector: '[zcHasRights]',
})
export class HasRightsDirective implements OnInit {
  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input('zcHasRights') hasRights: [string, AccessRight] | string;

  ngOnInit() {
    if (!this.hasRights) {
      return;
    }

    let objectClass: string;
    let right: AccessRight;

    if (!isArray(this.hasRights)) {
      // string value of object name
      objectClass = this.hasRights;
    } else {
      // array with object name and access right
      [objectClass, right] = this.hasRights;
    }

    this.auth
      .hasRight(objectClass, right)
      .subscribe((result) => this.updateView(result));
  }

  /**
   * Update view.
   *
   * @para show True if the template should be shown.
   */
  private updateView(shown: boolean) {
    if (shown) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
