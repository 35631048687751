<textarea
  class="textarea"
  [ngClass]="'textarea--width-' + width"
  (blur)="onBlur()"
  [(ngModel)]="value"
  [attr.aria-describedby]="id + '__errors'"
  [attr.aria-required]="required"
  [attr.id]="id"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [readonly]="readonly"
></textarea>
