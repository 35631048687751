import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import { empty, of, Subject, Subscription, timer } from 'rxjs';
import { expand, skip } from 'rxjs/operators';

import { L10nService } from '../l10n/l10n.service';

/**
 * Display the time difference between the provided date and now.
 *
 * ```html
 * <p>{{myDate | timeFromNow}}</p>
 * ```
 */
@Pipe({
  name: 'timeFromNow',
  pure: false,
})
export class TimeFromNowPipe implements PipeTransform, OnDestroy {
  constructor(private ref: ChangeDetectorRef, private l10n: L10nService) {
    this.stateChange.subscribe(() => {
      this.value = this.getValue(this.date);
      this.ref.markForCheck();
    });
  }

  /**
   * Last provided date.
   */
  private date: Date;

  /**
   * Last transformed value.
   */
  private value: string;

  /**
   * State change.
   */
  private stateChange = new Subject<void>();

  /**
   * Clock subscription reference.
   */
  private clockSubscription: Subscription;

  /**
   * Transform a resource key to a localized string.
   *
   * @param key Resource key to load.
   * @param args Optional arguments to pass to string.
   */
  transform(date: Date) {
    if (this.date === date) {
      return this.value;
    }

    this.date = date;

    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe();
      this.clockSubscription = null;
    }

    this.clockSubscription = this.getClock(date).subscribe(() => {
      this.stateChange.next();
    });

    this.stateChange.next();
    this.value = this.getValue(date);

    return this.value;
  }

  ngOnDestroy() {
    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe();
    }

    this.stateChange.complete();
  }

  private getValue(date: Date) {
    if (!date) {
      return this.l10n.instant('Common.lblTimeFromNowNever');
    }

    const now = new Date();
    const seconds = differenceInSeconds(now, date);
    const minutes = differenceInMinutes(now, date);
    const hours = differenceInHours(now, date);

    if (seconds <= 45) {
      // a few seconds ago
      return this.l10n.instant('Common.lblTimeFewSecondAgo');
    } else if (seconds <= 90) {
      // a minute ago
      return this.l10n.instant('Common.lblTimeMinuteAgo');
    } else if (minutes <= 45) {
      // X minutes ago
      return this.l10n.instant('Common.lblTimeMinutesAgo', { minutes });
    } else if (minutes <= 90) {
      // an hour ago
      return this.l10n.instant('Common.lblTimeHourAgo');
    } else if (hours <= 22) {
      // X hours ago
      return this.l10n.instant('Common.lblTimeHoursAgo', { hours });
    } else if (hours <= 36) {
      // a day ago
      return this.l10n.instant('Common.lblTimeDayAgo');
    } else {
      // a while ago
      return this.l10n.instant('Common.lblTimeWhile');
    }
  }

  private getClock(date: Date) {
    return of(0).pipe(
      expand(() => {
        const now = new Date();
        const seconds = differenceInSeconds(now, date);
        const hours = differenceInHours(now, date);
        let period: number;

        if (seconds < 60) {
          // less than 1 min, update every 2 secs
          period = 2000;
        } else if (hours < 1) {
          // less than an hour, update every 30 secs
          period = 30000;
        } else if (hours < 24) {
          // less then a day, update every 5 mins
          period = 300000;
        } else if (hours < 36) {
          // less than "a while", update every hour
          period = 3600000;
        } else {
          period = 0;
        }

        return period ? timer(period) : empty();
      }),
      skip(1)
    );
  }
}
