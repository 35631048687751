import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm, Validators } from '@angular/forms';
import { IDob, IUiMetaField } from '@zipcrim/common';
import { format } from 'date-fns';

/**
 * Component for entering subject dobs.
 */
@Component({
  selector: 'c2h-data-entry-dob',
  templateUrl: './data-entry-dob.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
})
export class DataEntryDobComponent implements OnInit {
  /**
   * Data to hydrate form.
   */
  @Input()
  Data: IDob[];

  /**
   * UiMeta field spec.
   */
  @Input()
  UiMeta: IUiMetaField;

  // todo: replace with reactive form
  Config: any;

  /**
   * Transformed data for use with forms.
   */
  FormData: Array<{ ID: number; FullDate: string }>;

  /**
   * On init.
   */
  ngOnInit() {
    this.FormData = this.Data.map((item) => ({
      ID: item.ID,
      FullDate: format(
        new Date(item.Year, item.Month - 1, item.Day),
        'MM/dd/yyyy'
      ),
    }));

    this._SetConfig();
  }

  /**
   * Set form configuration.
   */
  private _SetConfig() {
    this.Config = {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'collection',
          name: 'Dobs',
          titleL10nKey: 'Common.lblDob',
          require: {
            min: this.UiMeta.Min,
            max: this.UiMeta.Max,
          },
          nodes: [
            {
              nodeType: 'hidden',
              name: 'ID',
            },
            {
              nodeType: 'input',
              name: 'FullDate',
              labelL10nKey: 'Common.lblDob',
              validators: [Validators.required],
            },
          ],
        },
      ],
    };
  }
}
