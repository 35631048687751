import { ILeadType } from '../generated';

/**
 * Lead type.
 */
export class LeadType {
  constructor(source?: Partial<ILeadType>) {
    this._Init(source);
  }

  ChildTable: string;
  Code: string;
  Name: string;
  RawCode: string;
  TinyName: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<ILeadType>) {
    if (!source) {
      return;
    }

    this.ChildTable = source.ChildTable;
    this.RawCode = source.RawCode;

    if (source.Code) {
      this.Code = source.Code;
    }

    if (source.Name) {
      this.Name = source.Name;
    }

    if (source.TinyName) {
      this.TinyName = source.TinyName;
    }
  }
}
