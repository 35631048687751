<!-- item content -->
<ng-template #content>
  <zc-timeline-dot [state]="state"></zc-timeline-dot>
  <div class="timeline__marker">
    <div class="timeline__content">
      <ng-content></ng-content>
    </div>

    <!-- recursive nested components -->
    <ng-content select="zc-timeline-item"></ng-content>
  </div>
</ng-template>

<!-- static -->
<ng-template #static>
  <div class="timeline__item" [ngClass]="css">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<!-- link -->
<ng-container *ngIf="link; else static">
  <a
    class="timeline__item timeline__item--link"
    [ngClass]="css"
    [routerLink]="link"
    [queryParams]="queryParams"
    routerLinkActive="timeline__item--active"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>
