<span (click)="onClick($event)">
  <button #btn [type]="type" [ngClass]="css" [disabled]="disabled || busy">
    <!-- busy -->
    <ng-container *ngIf="busy; else content">
      <zc-busy size="xs" class="me-1"></zc-busy>
      <span *ngIf="busyText">{{ busyText }}...</span>
    </ng-container>

    <!-- content -->
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  </button>
</span>
