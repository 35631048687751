import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches a valid state name.
 */
const STATE_REGEX = /^[a-zA-Z]{2,3}$/i;

/**
 * Validate a state name.
 */
export function StateValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (!value || STATE_REGEX.test(value)) {
    return null;
  }

  return {
    state: true,
  };
}
