import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches number characters.
 */
const NUMERIC_ONLY_REGEX = /^[0-9]*$/;

/**
 * Validate numeric characters only.
 */
export function NumericOnlyValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (!value || NUMERIC_ONLY_REGEX.test(value)) {
    return null;
  }

  return {
    numericOnly: true,
  };
}
