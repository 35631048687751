import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CanDeactivateComponent, CaseService, SaveSpec } from '@zipcrim/common';
import { AnaGroup, CaseType } from '@zipcrim/common/models';
import { forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { CheckToHireSvcService } from '../../common/check-to-hire-svc.service';

/**
 * Workflow configuration.
 */
@Component({
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
})
export class WorkflowComponent
  extends CanDeactivateComponent
  implements OnInit
{
  constructor(
    private _Route: ActivatedRoute,
    private _Router: Router,
    private _CaseService: CaseService,
    private _CheckToHireSvc: CheckToHireSvcService
  ) {
    super();
  }

  /**
   * Currently applied stages.
   */
  Stages: CaseType[] = [];

  /**
   * Available items, not currently applied.
   */
  Available: AnaGroup[] = [];

  /**
   * Indicates if stages have been changed.
   */
  IsStagesDirty = false;

  /**
   * Indicates if a crim is active.
   */
  HasCrim = true;

  /**
   * User selected crim level value.
   */
  InclMulti: boolean;

  /**
   * Busy indicator for loading.
   */
  BusyLoading: boolean;

  /**
   * Busy indicator for save.
   */
  BusySaving: boolean;

  /**
   * Used to store the maximum limit of stages.
   */
  readonly MaxStageLimit: number = 9;

  /**
   * On init.
   */
  ngOnInit(): void {
    this._GetStages();
  }

  /**
   * On destroy.
   */
  canDeactivate() {
    return !this.IsStagesDirty;
  }

  /**
   * On drop.
   */
  OnDrop(event: CdkDragDrop<AnaGroup[]>) {
    const { container, currentIndex, previousContainer, previousIndex } = event;

    if (previousContainer === container) {
      moveItemInArray(container.data, previousIndex, currentIndex);
    } else {
      transferArrayItem(
        previousContainer.data,
        container.data,
        previousIndex,
        currentIndex
      );
    }

    this.IsStagesDirty = true;
    this._SetHasCrim();
  }

  /**
   * Add a new stage.
   */
  OnAddStage() {
    if (this.Stages.length >= this.MaxStageLimit) {
      return;
    }

    const index = this.Stages.length + 1;
    const stage = new CaseType({
      Code: `>${index}`,
      Name: `Stage${index}`,
    });

    this.Stages.push(stage);
    this.IsStagesDirty = true;
  }

  /**
   * Remove stage.
   */
  OnRemoveStage(index: number) {
    if (this.Stages[index]) {
      this.IsStagesDirty = true;
      this.Stages.splice(index, 1);
    }
  }

  /**
   * Remove empty stages and save.
   */
  OnSave() {
    // remove empty stages
    this.Stages = this.Stages.filter((stage) => stage.AnaGroups.length > 0);

    const caseTypes = this.Stages.map((stage) => {
      const anaGroups = stage.AnaGroups.map((lead) => ({ Code: lead.Code }));
      return {
        Code: stage.Code,
        AnaGroups: anaGroups,
      };
    });

    const spec = new SaveSpec('CaseTypes', caseTypes, true);

    if (this.HasCrim) {
      spec.addNamed('AnaGroupDefs', [
        {
          Code: 'CRIM',
          GroupOptions: [
            {
              Name: 'InclMulti',
              Value: this.InclMulti,
            },
          ],
        },
      ]);
    }

    this.BusySaving = true;
    this._CaseService
      .saveCaseTypes(spec)
      .pipe(finalize(() => (this.BusySaving = false)))
      .subscribe(() => {
        const redirect = this._Route.snapshot.queryParamMap.get('redirect');
        this.IsStagesDirty = false;

        if (redirect) {
          this._Router.navigate([redirect]);
        }
      });
  }

  /**
   * Get current and available stage items.
   */
  private _GetStages() {
    const anaGroups$ = this._CheckToHireSvc.GetAnaGroups().pipe(
      map((res) => res.Items),
      map((data) =>
        data.map((item) => new AnaGroup({ ...item, Code: item.LeadClass }))
      )
    );

    const caseTypes$ = this._CheckToHireSvc.GetCaseTypes().pipe(
      map((res) => res.Items),
      map((data) => data.map((item) => new CaseType(item)))
    );

    this.BusyLoading = true;
    forkJoin([anaGroups$, caseTypes$])
      .pipe(finalize(() => (this.BusyLoading = false)))
      .subscribe((data) => {
        const [anaGroups, caseTypes] = data;

        if (caseTypes.length === 0) {
          const defaultGroups = ['MVR', 'CDLIS'];
          const type = new CaseType({ Code: '>1', Name: 'Stage1' });
          type.AnaGroups = anaGroups.filter(
            (item) => defaultGroups.indexOf(item.Code) !== -1
          );
          caseTypes.push(type);
        }

        // case types API does not return descriptions
        caseTypes.forEach((caseType) => {
          caseType.AnaGroups.forEach((group) => {
            const match = anaGroups.find((item) => item.Code === group.Code);

            if (match) {
              if (!group.Description) {
                group.Description = match.Description;
              } else {
                group.Description = match.Description;
                group.FullDescription = match.FullDescription;
                group.Unit = match.Unit;
                group.Rate = match.Rate;
              }
            }
          });
        });

        this.Stages = caseTypes;
        this.Available = anaGroups.filter(
          (item) =>
            !caseTypes.some((x) => x.AnaGroupCodes.indexOf(item.Code) !== -1)
        );

        this._SetHasCrim();
        this._SetInclMulti();
      });
  }

  /**
   * Update `HasCrim` variable based on current stages.
   */
  private _SetHasCrim() {
    this.HasCrim = this.Stages.some((stage) =>
      stage.AnaGroups.some((lead) => lead.Code === 'CRIM')
    );

    if (!this.HasCrim) {
      this._SetInclMulti();
    }
  }

  /**
   * Set `InclMulti` variable based on current stages.
   */
  private _SetInclMulti() {
    if (!this.HasCrim) {
      return;
    }

    for (const item of this.Stages) {
      const crim = item.AnaGroups.find((x) => x.Code === 'CRIM');

      if (crim) {
        // api sends string instead of boolean value
        if (crim.Options.InclMulti) {
          this.InclMulti = crim.Options.InclMulti === 'True';
        }

        return;
      }
    }
  }
}
