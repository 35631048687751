import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import uniqueId from 'lodash/uniqueId';

@Injectable({ providedIn: 'root' })
export class FormService {
  /**
   * Check if a form control has a required validator.
   *
   * @param control Form control to check.
   */
  hasRequired(control: AbstractControl) {
    if (!control || !control.validator) {
      return false;
    }

    const validator = control.validator({} as AbstractControl);
    return !!(validator && validator.required);
  }

  /**
   * Get a unique business object ID for safe use as an id attribute value.
   *
   * @param prefix BO name.
   * @param id Object ID.
   */
  getBoId(prefix: string, id?: number) {
    if (!prefix || !id) {
      return undefined;
    }

    return `${prefix}_#${id}`;
  }

  ///////////////////////////////////////////////////////////////
  // TODO: Old code that may need to be replaced
  /**
   * Check if the provided `value` is a valid value to write.
   *
   * @param value Value to check.
   */
  HasValue(value: any) {
    return value != null;
  }

  /**
   * Get a unique form anchor string that can safely be used as an id attribute value.
   *
   * @param type BO name.
   * @param name Object property name.
   * @param id Object ID.
   */
    GetAnchorId(type?: string, name?: string, id?: number) {
      const result = ['FormAnchor'];
  
      if (type) {
        result.push(`BO:${type}`);
      }
  
      if (name) {
        result.push(`Name:${name}`);
      }
  
      if (id) {
        result.push(`ID:${id}`);
      } else {
        const uid = uniqueId();
        result.push(`UID:${uid}`);
      }
  
      return result.join('_');
    }
    
    /**
     * Get the form control name used for the current control.
     *
     * @param control Form control to get name of.
     */
    GetControlName(control: AbstractControl) {
      const parent = control.parent;
  
      if (!parent || !(parent instanceof FormGroup)) {
        return null;
      }
  
      const names = Object.keys(parent.controls);
      return names.find((name) => control === parent.controls[name]);
    }
  
  /**
   * Safely get the ID value from a form group, if available.
   *
   * @param group Form group to get id from.
   */
    GetIdValue(group: FormGroup) {
      const control = this.GetIdControl(group);
  
      if (!control) {
        return null;
      }
  
      return this.ParseIdValue(control.value);
    }
  
    /**
     * Safely get the ID control from a form group, if available.
     *
     * @param group Form group to get id from.
     */
    GetIdControl(group: FormGroup) {
      if (!group || !(group instanceof FormGroup)) {
        return null;
      }
  
      return group.get('ID') || null;
    }
    /**
   * Safely parse a control value for the ID value.
   *
   * @param value Raw control value.
   */
    ParseIdValue(value: any) {
      const result = parseInt(value, 10);
  
      return !isNaN(result) ? result : null;
    }
  ///////////////////////////////////////////////////////////////
}
