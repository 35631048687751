<div class="field" [ngClass]="{ 'field--horizontal': horizontal }">
  <!-- label -->
  <div class="field__header" *ngIf="label">
    <label class="field__label" [for]="id" [attr.id]="id + '__label'">
      <zc-skeleton *ngIf="busy" [fixed]="true"> </zc-skeleton>
      <span [ngClass]="{ 'field__text--busy': busy }">
        <ng-content select="zc-label"></ng-content>
        <span class="field__asterisk" *ngIf="required"></span>
      </span>
    </label>
  </div>

  <!-- span -->
  <div class="field__header" *ngIf="span">
    <span class="field__label">
      <zc-skeleton *ngIf="busy" [fixed]="true"> </zc-skeleton>
      <span [ngClass]="{ 'field__text--busy': busy }">
        <ng-content select="zc-span"></ng-content>
        <span class="field__asterisk" *ngIf="required"></span>
      </span>
    </span>
  </div>

  <div class="field__body">
    <ng-content></ng-content>

    <!-- errors -->
    <div *ngIf="showErrors" aria-live="assertive" [attr.id]="id + '__errors'">
      <zc-error *ngFor="let error of errors">
        {{ error.message | translate: error.params }}
      </zc-error>
      <ng-content select="zc-error"></ng-content>
    </div>

    <!--described by-->
    <div
      *ngIf="!showErrors && ariaDescription.length > 0"
      aria-live="assertive"
      [attr.id]="id + '__errors'"
    >
      <zc-aria-label>
        {{ ariaDescription | translate }}
      </zc-aria-label>
      <ng-content select="zc-aria-label"></ng-content>
    </div>
  </div>
</div>
