<c2h-drug-and-alcohol></c2h-drug-and-alcohol>

<!-- busy -->
<zc-card title="&nbsp;" *ngIf="IsLoading; else content">
  <zc-card-body>
    <div class="mb-4">
      <zc-skeleton
        [rows]="[[{ size: 3 }, { size: 3 }], [{ size: 5 }]]"></zc-skeleton>
    </div>
    <zc-skeleton [rows]="[[{ size: 2 }], [{ size: 5 }]]"></zc-skeleton>
  </zc-card-body>
</zc-card>

<!-- content -->
<ng-template #content>
  <zc-card *ngFor="let item of Leads" [title]="item.LeadType.Name">
    <zc-card-body>
      <ng-container
        *ngIf="item.Completed && item.Report.Content"
        [ngSwitch]="item.Report.Format">
        <pre class="p-2" *ngSwitchCase="'Text'">{{ item.Report.Content }}</pre>
        <zc-html
          *ngSwitchCase="'Html'"
          [content]="item.Report.Content"></zc-html>
      </ng-container>
      <div *ngIf="!item.Completed">
        {{ 'CheckToHire.searchInProgress' | translate }}
      </div>
      <div *ngIf="item.IsBlocked">
        <fa-icon icon="exclamation-triangle" class="me-1"></fa-icon>
        {{ 'CheckToHire.blockedReport' | translate }}
      </div>
    </zc-card-body>
  </zc-card>
</ng-template>
