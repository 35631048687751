import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ApiMessage, L10nService } from '@zipcrim/common';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  shareReplay,
  startWith,
  take,
} from 'rxjs/operators';

import {
  DynamicCollectionComponent,
} from '../dynamic-collection/dynamic-collection.component';
import { DynamicFormFieldLayout } from '../form-field/form-field.interfaces';
import { FormService } from '@zipcrim/forms/form.service';
import { OptionConfig } from '@zipcrim/forms/forms.interfaces';
import { DynamicRadioGroupComponent } from '../radio-group/radio-group.component';
import { DynamicSelectComponent } from '../select/select.component';
import { DynamicNodeComponent } from './dynamic-node.component';
import {
  AbstractNode,
  CheckboxGroupNode,
  FormFieldConfig,
  FormFieldListener,
  FormListener,
  IterableNode,
  NodeData,
  NodePatchConfig,
  NodeUiMetaConfig,
  ValidatorConfig,
} from './dynamic-node.interfaces';
import { DynamicNodeService } from './dynamic-node.service';

/**
 * Dynamic node item.
 *
 * Note: For internal use only! This component is not exported from the module.
 */
@Component({
  selector: 'zc-dynamic-node-item',
  templateUrl: './dynamic-node-item.component.html',
})
export class DynamicNodeItemComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit
{
  constructor(
    private _L10n: L10nService,
    private _NodeService: DynamicNodeService,
    private _FormService: FormService,
    private _RootNode: DynamicNodeComponent,
    @Optional() private _ParentCollection: DynamicCollectionComponent
  ) {}

  /**
   * Optional parent form group.
   */
  @Input()
  Form: FormGroup;

  // TODO: EJA: Is this the correct approach?
  @Input()
  set AddToFormGroup(value: AbstractControl<any, any>) {
    this.Form.addControl("test", value);
  }

/**
   * Dynamic node configuration.
   */
  @Input()
  set Config(value: AbstractNode) {
    /**
     * Note:
     * A copy of the original configuration is required to avoid affecting other nodes, particularly nodes in a
     * collection that share a configuration object. Copy must be shallow due to non-plain objects (e.g. observables).
     */
    this._Config = { ...value };
  }

  get Config() {
    return this._Config;
  }

  // TODO: EJA: Attempt at getting around issue with variations of "Config" classes
  CastConfig<T>() {
    return this._Config as T;
 }

  /**
   * Form data object.
   */
  @Input()
  Data: NodeData;

  /**
   * Collection of validation messages from an API response.
   */
  @Input()
  ApiMessages: ApiMessage[];

  /**
   * Busy indicator.
   */
  @Input()
  Busy: boolean;

  /**
   * Current node layout, provided to recursive tree.
   */
    @Input()
    Layout: DynamicFormFieldLayout;

  /**
   * True if all form logic should be bypassed. This is used to create a busy indicator form based on a configuration,
   * without the overhead of the actual form and node features.
   */
  @Input()
  NonFunctional: boolean;

  /**
   * Indicates if the node layout is `horizontal` AND this node is the top most node for this layout.
   */
  IsHorizontal = false;

  /**
   * Form group reference.
   */
  FormGroup: FormGroup;

  /**
   * Form control reference.
   */
  FormControl: FormControl;

  /**
   * Indicates if the required validator is present.
   */
  IsRequired: boolean;

  /**
   * Title value.
   */
  Title: string;

  /**
   * Label value.
   */
  Label: string;

  /**
   * Help text value.
   */
  HelpText: string;

  /**
   * Placeholder value.
   */
  Placeholder: string;

  /**
   * Deselect value.
   */
  Deselect: string;

  /**
   * Checkbox label value.
   */
  CheckboxLabel: string;

  /**
   * Form field id.
   */
  Id: string;

  /**
   * Children nodes, if available.
   */
  Nodes: AbstractNode[];

  /**
   * Busy indicator for async nodes.
   */
  IsBusy: boolean;

  /**
   * Input reference.
   */
  @ViewChild('input')
  private _InputRef: DynamicSelectComponent | DynamicRadioGroupComponent;

  /**
   * Internal deep copy of the input value.
   */
  private _Config: AbstractNode;

  /**
   * Collection of ids for ignored validation messages.
   */
  private _Ignored: string[] = [];

  /**
   * Listen subscriptions.
   */
  private _ListenSubscriptions: Subscription[] = [];

  /**
   * UI meta configuration.
   */
  private _UiMeta: NodeUiMetaConfig;

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.Config) {
      this._SetNodes();
    }
  }

  /**
   * On init.
   */
  ngOnInit() {
    if (this.NonFunctional) {
      // empty form elements to satisfy template
      this.FormGroup = new FormGroup({});
      this.FormControl = new FormControl();
      this._Render();
      return;
    }

    this._SetFormRefs();
    this._AppendNodesAsync();
    this._Render();
    this._Listen();

    const name = this._GetNodeName();
    const mapTo = this._GetMapToName();

    // ui meta observable is replayed to patch UI meta config to future items
    this._RootNode.OnUiMeta.pipe(
      // also apply ui meta to inputs that `mapTo` a field defined in ui meta
      map((res) => res[name] || res[mapTo]),
      filter((meta) => !!meta)
    ).subscribe((meta) => {
      this._UiMeta = meta;

      if (this._UiMeta?.validators) {
        this._SetValidators();
      }
    });

    this._RootNode.OnPatch.pipe(
      map((res) => res[name]),
      filter((patch) => !!patch)
    ).subscribe((patch) => this._PatchConfig(patch));
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    this._ListenSubscriptions.forEach((item) => {
      item.unsubscribe();
    });
  }

  /**
   * After view init.
   */
  ngAfterViewInit() {
    if (this.NonFunctional) {
      // skip form logic
      return;
    }

    // wait for view to insure `_InputRef` will be available to subscribers
    this._NodeService.ControlItems.get(this.FormControl)?.next(this);
  }

  /**
   * On validation message ignored.
   *
   * @param id Unique id of associated validator config.
   */
  OnMessagedIgnore(id: string) {
    this._Ignored.push(id);

    const config = this.Config as FormFieldConfig;
    const updated = this._NodeService.TransformValidators(
      config.validators,
      this._Ignored
    );

    this.FormControl.clearValidators();
    this.FormControl.setValidators(updated);
    this.FormControl.updateValueAndValidity();
    this._SetIsRequired();
  }

  /**
   * Render.
   */
  private _Render() {
    const config = this.Config as any;

    this.Title = config.titleL10nKey
      ? this._L10n.instant(config.titleL10nKey)
      : config.title;
    this.Label = config.labelL10nKey
      ? this._L10n.instant(config.labelL10nKey)
      : config.label;
    this.HelpText = config.helpTextL10nKey
      ? this._L10n.instant(config.helpTextL10nKey)
      : config.helpText;
    this.Placeholder = config.placeholderL10nKey
      ? this._L10n.instant(config.placeholderL10nKey)
      : config.placeholder;
    this.Deselect = config.deselectL10nKey
      ? this._L10n.instant(config.deselectL10nKey)
      : config.deselect;
    this.CheckboxLabel = config.checkboxLabelL10nKey
      ? this._L10n.instant(config.checkboxLabelL10nKey)
      : config.checkboxLabel;

    if (!this.Layout && config.layout) {
      this.Layout = config.layout;
      this.IsHorizontal = true;
    }

    this._SetIsRequired();
  }

  /**
   * Set form references.
   */
  private _SetFormRefs() {
    const { name } = this.Config as FormFieldConfig;

    if (!name) {
      this.FormGroup = this.Form;
      return;
    }

    this.Id = this._FormService.getBoId(name);

    const groupOnly = this.Config.nodeType === 'date-parts';
    const { formControl, formGroup } = this._NodeService.ParseName(
      this.Form,
      name,
      groupOnly
    );
    this.FormGroup = formGroup as FormGroup;
    this.FormControl = formControl as FormControl;
  }

  /**
   * Set nodes.
   */
  private _SetNodes() {
    const { nodes } = this.Config as IterableNode;

    // filter our falsey values to allow ternary operators inline while constructing configuration
    this.Nodes = nodes ? nodes.filter((item) => !!item) : [];
  }

  /**
   * Append async nodes, if available.
   */
  private _AppendNodesAsync() {
    const { nodesAsync } = this.Config as IterableNode;

    if (nodesAsync) {
      this.IsBusy = true;

      nodesAsync.subscribe((res) => {
        // stop busy indicator after first value to allow for open ended observables
        this.IsBusy = false;

        if (res) {
          // TODO: EJA This is what it was previously
          // res.forEach((item) => this._NodeService.Build(this.Form, item));
          // this.Nodes.push(...res);
          res.forEach((item) => this._NodeService.Build(this.Form, item as IterableNode));

          var resAbstractNode = res as AbstractNode[];
          this.Nodes.push(...resAbstractNode);
        }

        if (this.FormGroup) {
          const tracker = this._NodeService.Containers.get(this.FormGroup);
          tracker.next();
        }
      });
    }
  }

  /**
   * Set required based on validators.
   */
  private _SetIsRequired() {
    const { validateSelection } = this.Config as CheckboxGroupNode;
    this.IsRequired =
      !!validateSelection || this._FormService.hasRequired(this.FormControl);
  }

  /**
   * Set form control validators.
   */
  private _SetValidators() {
    const { validators } = this.Config as FormFieldConfig;
    const items: Array<ValidatorFn | ValidatorConfig> = [];

    if (validators) {
      items.push(...validators);
    }

    if (this._UiMeta?.validators) {
      // always inject ui meta, so listener events do not override it
      items.push(...this._UiMeta.validators);
    }

    const newValidators = this._NodeService.TransformValidators(items);

    this.FormControl.clearValidators();
    this.FormControl.setValidators(newValidators);
    this._SetIsRequired();
    this.FormControl.updateValueAndValidity();
  }

  /**
   * Set form control value.
   */
  private _SetValue() {
    const { value } = this.Config as FormFieldConfig;

    this.FormControl.setValue(value);
  }

  /**
   * Toggle form control enabled/disabled state based on the current visibility.
   */
  private _SetControlState() {
    // toggle control disabled state to prevent validation on hidden fields
    if (this.FormControl) {
      const { disabled, isHidden } = this.Config as FormFieldConfig;

      // if the initial state is disabled, no need to toggle
      if (!disabled) {
        if (isHidden) {
          this.FormControl.disable();
        } else {
          this.FormControl.enable();
        }
      }
    } else {
      // todo (jbl): recursively enable/disable form controls for iterable nodes
      // issue: need to track previous disabled state per control so we can revert when group become visible again
    }
  }

  /**
   * Set listeners.
   */
  private _Listen() {
    let { listen } = this.Config as FormFieldConfig;

    if (!listen) {
      return;
    }

    if (!isArray(listen)) {
      listen = [listen];
    }

    listen.forEach((item) => {
      if (!item) {
        return;
      } else if ('onReady' in item) {
        this._AddFormListener(item);
      } else if ('field' in item) {
        this._AddFieldListener(item);
      }
    });
  }

  /**
   * Add a form listener.
   *
   * @param config Listener configuration.
   */
  private _AddFormListener(config: FormListener) {
    this._RootNode.OnFormReady.pipe(take(1)).subscribe(() => {
      const patch = config.onReady();
      this._PatchConfig(patch);
    });
  }

  /**
   * Add a field listener.
   *
   * @param config Listener configuration.
   */
  private _AddFieldListener(config: FormFieldListener) {
    // todo (jbl): `relativeTo` option? (to support array group controls listening to root form control)
    const control = this.Form.get(config.field);

    if (!control) {
      // field not found
      return;
    }

    let optionsChange$: Observable<OptionConfig>;

    if (control instanceof FormControl) {
      optionsChange$ = this._NodeService.ControlItems.get(control)
        .asObservable()
        .pipe(
          flatMap((item) =>
            item._InputRef
              ? item._InputRef.OptionChange
              : of<OptionConfig>(undefined)
          )
        );
    } else {
      // some nodes map to a FormGroup which do not exist in `ControlItems`
      optionsChange$ = of<OptionConfig>(undefined);
    }

    /**
     * Listen callback should be execute under the following scenarios:
     *
     * 1) Initial load of this component.
     * 2) Value change on the target form control.
     * 3) State of this component changes from hidden to visible.
     *
     * However, execution should be deferred until all of the following have completed:
     *
     * - Target form control's associated component is available.
     * - Target component's `asyncOptions` (if available) has emitted a value.
     * - Both the target's form control and the target component's option have emitted the same value.
     */
    const subscription = combineLatest([
      control.valueChanges.pipe(
        startWith(control.value),
        distinctUntilChanged()
      ),
      optionsChange$,
    ])
      .pipe(
        filter((res) => {
          const [value, option] = res;

          // wait for `OptionChange` and `valueChanges` values to match (one emits right before the other)
          return !option || option.value === value;
        }),
        // replay last value for subscribed fields that also toggle visibility
        shareReplay(1)
      )
      .subscribe((res) => {
        const [value, option] = res;
        const patch = config.onChange(value, option);

        this._PatchConfig(patch);
      });

    this._ListenSubscriptions.push(subscription);
  }

  /**
   * Safely updates the configuration object.
   *
   * @param patch Patch configuration.
   */
  private _PatchConfig(patch: NodePatchConfig) {
    if (!patch) {
      return;
    }

    const hasValue = !isUndefined(patch.value);
    const hasValidators = !isUndefined(patch.validators);
    const hasState = !isUndefined(patch.isHidden);
    const overrides: NodePatchConfig = {};

    if (hasValidators && patch.preserveExistingValidators) {
      const existing = (this.Config as FormFieldConfig).validators;

      if (existing && existing.length > 0) {
        overrides.validators = [...existing, ...patch.validators];
      }
    }

    Object.assign(this.Config, patch, overrides);

    if (hasValue) {
      this._SetValue();
    }

    if (hasValidators) {
      this._SetValidators();
    }

    if (hasState && !patch.skipState) {
      this._SetControlState();
    }

    this._Render();
  }

  /**
   * Get the current node full name.
   */
  private _GetNodeName() {
    const { name } = this.Config as FormFieldConfig;

    if (!name) {
      return null;
    }

    return this._ParentCollection
      ? `${this._ParentCollection.FormArrayName}.${name}`
      : name;
  }

  /**
   * Get the map to full name.
   */
  private _GetMapToName() {
    const { mapTo } = this.Config as FormFieldConfig;

    if (!mapTo) {
      return null;
    }

    return this._ParentCollection
      ? `${this._ParentCollection.FormArrayName}.${mapTo}`
      : mapTo;
  }
}
