import {
  faCheck,
  faCheckCircle,
  faCloudDownloadAlt,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';

export const DOCUMENT_SIGNING_ICONS = [
  faCheck,
  faCheckCircle,
  faCloudDownloadAlt,
  faEdit,
];
