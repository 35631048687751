import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { SaveSpec } from '../../api/save-spec';

/**
 * Subject contact info API interactions.
 */
@Injectable({ providedIn: 'root' })
export class SubjectContactInfoService {
  constructor(private api: ApiService) {}

  /**
   * Save subject contact info.
   *
   * @param id Subject contact info id.
   * @param spec Subject contact info.
   */
  save(id: number, spec: SaveSpec) {
    return this.api
      .post<number>(`subjectContactInfo/${id}`, spec)
      .pipe(map((res) => res.payload));
  }

  /**
   * Remove subject contact info.
   *
   * @param id Subject contact info id.
   */
  remove(subjectId: number, id: number) {
    return this.api
      .delete<number>(`subject/${subjectId}/contactInfo/${id}`)
      .pipe(map((res) => res.payload));
  }
}
