import { Directive, HostListener } from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class CanDeactivateComponent {
  @HostListener('window:beforeunload', ['$event'])
  onBeforeunload($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

  abstract canDeactivate(): boolean;
}
