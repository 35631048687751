import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm, Validators } from '@angular/forms';
import { IMvr, IUiMetaField } from '@zipcrim/common';
import { OptionsService } from '@zipcrim/forms';

/**
 * Component for entering subject motor vehicle licenses.
 */
@Component({
  selector: 'c2h-data-entry-mvr',
  templateUrl: './data-entry-mvr.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
})
export class DataEntryMvrComponent implements OnInit {
  constructor(private _Options: OptionsService) {}

  /**
   * Data to hydrate form.
   */
  @Input()
  Data: IMvr[];

  /**
   * UiMeta field spec.
   */
  @Input()
  UiMeta: IUiMetaField;

  // todo: replace with reactive form
  Config: any;

  /**
   * On init.
   */
  ngOnInit() {
    this._SetConfig();
  }

  /**
   * Set form configuration.
   */
  private _SetConfig() {
    this.Config = {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'collection',
          name: 'Mvrs',
          titleL10nKey: 'CheckToHire.lblDataEntryMvrs',
          require: {
            min: this.UiMeta.Min,
            max: this.UiMeta.Max,
          },
          nodes: [
            {
              nodeType: 'hidden',
              name: 'ID',
            },
            {
              nodeType: 'radio-group',
              name: 'LicenseCountry',
              value: 'US',
              options: [
                { value: 'US', labelL10nKey: 'CheckToHire.lblUSA' },
                { value: 'CA', labelL10nKey: 'CheckToHire.lblCAN' },
              ],
              validators: [Validators.required],
            },
            {
              nodeType: 'container',
              css: 'row',
              nodes: [
                {
                  nodeType: 'select',
                  name: 'LicenseState',
                  labelL10nKey: 'CheckToHire.lblLicenseStateProvince',
                  css: 'col-sm-4',
                  listen: {
                    field: 'LicenseCountry',
                    onChange: (value: string) => ({
                      optionsAsync: this._Options.getRegions(value),
                    }),
                  },
                  validators: [Validators.required],
                },
                {
                  nodeType: 'input',
                  name: 'LicenseNumber',
                  labelL10nKey: 'CheckToHire.lblDriverLicense',
                  css: 'col-sm-4',
                  validators: [Validators.required],
                },
                {
                  nodeType: 'select',
                  name: 'LicenseClass',
                  labelL10nKey: 'CheckToHire.lblType',
                  css: 'col-sm-4',
                  options: [
                    { value: 'CDL', labelL10nKey: 'CheckToHire.lblCDL' },
                    { value: 'NONCDL', labelL10nKey: 'CheckToHire.lblNonCDL' },
                  ],
                  validators: [Validators.required],
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
