import { Component, Input } from '@angular/core';
import { AnaGroup } from '@zipcrim/common/models';

/**
 * Ana group display card.
 */
@Component({
  selector: 'c2h-ana-group',
  templateUrl: './ana-group.component.html',
  styleUrls: ['./ana-group.component.scss'],
})
export class AnaGroupComponent {
  /**
   * Source data.
   */
  @Input()
  Data: AnaGroup;

  /**
   * Indicates if the group should be faded.
   */
  @Input()
  Faded: boolean;

  /**
   * Indicates if busy indicator is visible.
   */
  @Input()
  Busy: boolean;
    
  showPopover = false;

}
