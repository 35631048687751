import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiService,
  IBoList,
  IForm,
  IJob,
  IJobCommandCode,
  SaveSpec,
} from '@zipcrim/common';
import { map } from 'rxjs/operators';

import { AssignNewContactParam, FormSignedParam } from './job.interfaces';

/**
 * Work flow job API service.
 */
@Injectable({ providedIn: 'root' })
export class JobService {
  constructor(private api: ApiService) {}

  /**
   * Get all jobs related to account activation for the current user.
   */
  getAccountActivationJobs() {
    return this.api
      .get<IBoList<IJob>>('clients/accountActivationsByCurrentUser')
      .pipe(map((res) => res.payload));
  }

  /**
   * Get jobs by class name.
   *
   * @param className Class name.
   */
  getJobsByClass(className: string) {
    const params = new HttpParams().append('className', className);

    return this.api
      .get<IBoList<IJob>>('jobs/byClass', params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get jobs by class name and object.
   *
   * @param objectClassName Object class name.
   * @param objectID Object id.
   * @param className Class name.
   */
  getJobsByClassAndObject(
    objectClassName: string,
    objectID: string,
    className = ''
  ) {
    const params = new HttpParams()
      .append('objectClassName', objectClassName)
      .append('objectID', objectID)
      .append('className', className);

    return this.api
      .get<IBoList<IJob>>('jobs/byClassAndObject', params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get a job by id.
   *
   * @param id Job id.
   */
  getJobById(id: number) {
    const url = `job/${id}`;

    return this.api.get<IJob>(url).pipe(map((res) => res.payload));
  }

  /**
   * Get a job's dependencies by id.
   *
   * @param id Job id.
   */
  getJobDependencies(id: number) {
    const url = `job/${id}/dependencies`;

    return this.api
      .get<IBoList<IJob>>(url)
      .pipe(map((res) => res.payload.Items));
  }

  /**
   * Get a job's parent jobs.
   *
   * @param id Job id.
   */
  getJobParents(id: number) {
    const url = `job/${id}/parentJobs`;
    const params = new HttpParams().append('JobClassCode', 'FormCollection');

    return this.api
      .get<IBoList<IJob>>(url, params)
      .pipe(map((res) => res.payload.Items));
  }

  /**
   * Update a contact's email address.
   *
   * @param clientCode Contact's client code.
   * @param contactId Contact id.
   * @param emailId Contact email id.
   * @param emailValue New email value.
   */
  updateEmail(
    clientCode: string,
    contactId: number,
    emailId: number,
    emailValue: string
  ) {
    const url = `contact/${contactId}`;
    const spec = new SaveSpec('Contact', {
      ClientCode: clientCode,
      Emails: [
        {
          ID: emailId,
          Value: emailValue,
          Purpose: 'Primary',
        },
      ],
    });

    return this.api.post(url, spec);
  }

  /**
   * Validate PIN.
   *
   * @param jobId Job id.
   * @param pin PIN to validate.
   */
  validatePIN(jobId: number, pin: string) {
    return this.executeCommand<boolean>(jobId, 'ValidatePIN', {
      parameters: { PIN: pin },
    });
  }

  /**
   * Resend PIN.
   *
   * @param jobId Job id.
   */
  resendPIN(jobId: number) {
    return this.executeCommand<boolean>(jobId, 'ResendPIN');
  }

  /**
   * Get form for signing.
   *
   * @param jobId Job id.
   */
  getForm4Signing(jobId: number) {
    return this.executeCommand<IForm>(jobId, 'GetForm4Signing');
  }

  /**
   * Sign a form.
   *
   * @param jobId Job id.
   * @param param Signature to save.
   */
  formSigned(jobId: number, param: FormSignedParam) {
    return this.executeCommand<boolean>(jobId, 'FormSigned', {
      parameters: param,
    });
  }

  /**
   * Assign current user as contact.
   *
   * @param jobId Job id.
   */
  assignContact(jobId: number) {
    return this.executeCommand<boolean>(jobId, 'AssignContact');
  }

  /**
   * Assign a new contact.
   *
   * @param jobId Job id.
   * @param param Contact information.
   */
  assignNewContact(jobId: number, param: AssignNewContactParam) {
    return this.executeCommand<boolean>(jobId, 'AssignNewContact', {
      parameters: param,
    });
  }

  /**
   * Upload document
   *
   * @param jobId Job id.
   * @param param file information.
   */
  saveDocument(jobId: number, param: any) {
    return this.executeCommand<boolean>(jobId, 'SaveDocument', param);
  }

  /**
   * Execute a job command.
   *
   * @param jobId Job id.
   * @param commandCode Job command code.
   * @param params Optional parameters.
   */
  private executeCommand<T>(
    jobId: number,
    commandCode: IJobCommandCode,
    params?: any
  ) {
    const url = `wfesvc/cmd/${jobId}/${commandCode}`;

    return this.api.post<T>(url, params);
  }
}
