import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { L10nService } from '@zipcrim/common';

import { FormService } from '@zipcrim/forms/form.service';
import { CheckboxOptionConfig } from '../forms.interfaces';

/**
 * Form checkbox group.
 *
 * ```html
 * <zc-dynamic-checkbox-group [Options]="myOptions"></zc-dynamic-checkbox-group>
 * ```
 */
@Component({
  selector: 'zc-dynamic-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
})
export class DynamicCheckboxGroupComponent implements OnChanges {
  constructor(private _FormService: FormService, private _L10n: L10nService) {}

  /**
   * Options.
   */
  @Input()
  Options: CheckboxOptionConfig[];

  /**
   * Event emitted when any of the checkbox change value.
   */
  @Output()
  ValueChanges = new EventEmitter();

  /**
   * All available options.
   */
  AllOptions: any[];

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.Options) {
      this._SetAllOptions();
    }
  }

  /**
   * On change.
   */
  OnChange() {
    const values = this.AllOptions.reduce((obj, item) => {
      obj[item.name] = item.value;
      return obj;
    }, {});

    this.ValueChanges.emit(values);
  }

  /**
   * Combine options and async options.
   */
  private _SetAllOptions() {
    this.AllOptions = this.Options.map((item) => {
      const { labelL10nKey, descriptionL10nKey } = item;
      let { label, description } = item;

      label = labelL10nKey ? this._L10n.instant(labelL10nKey) : label;

      description = descriptionL10nKey
        ? this._L10n.instant(descriptionL10nKey)
        : description;

      return {
        ...item,
        description,
        label,
        id: this._FormService.GetAnchorId(null, item.name),
      };
    });
  }
}
