<div class="d-flex align-items-center">
  <input
    class="input"
    [(ngModel)]="value"
    [ngClass]="'input--width-' + width"
    [typeahead]="suggestions$"
    [typeaheadAsync]="true"
    [typeaheadOptionField]="typeaheadOptionField"
    [attr.aria-describedby]="id + '__errors'"
    [attr.aria-required]="required"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [typeaheadSelectFirstItem]="false"
    [typeaheadMinLength]="2"
    [typeaheadHideResultsOnBlur]="true"
    [typeaheadIsFirstItemActive]="false"
    (typeaheadOnSelect)="onSelectEvent($event)"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (typeaheadLoading)="isLoading = $event" />

  <!-- loading -->
  <zc-busy *ngIf="isLoading" size="xs" class="ms-1"> </zc-busy>
</div>
