import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthenticationModule } from 'ps-angular-authentication';
import {
  CommonModule,
  GlobalErrorHandler,
  HttpLoaderFactory,
  IdleDetectionService,
} from '@zipcrim/common';
import { Job } from '@zipcrim/common/models';
import { ContactsModule } from '@zipcrim/contacts/contacts.module';
import { JwtInterceptor } from '@zipcrim/core/interceptors/jwt.interceptor';
import { DocumentSigningModule } from '@zipcrim/document-signing';
import { FinancialModule } from '@zipcrim/financial';
import { FormsModule } from '@zipcrim/forms';
import { FormsModule as FormsModuleDynamic } from "@zipcrim/dynamic-forms/forms.module";
import {
  ActionRoute,
  DefaultModalComponent,
  VerifyEmailModalComponent,
  WhoSignModalComponent,
  WorkFlowModule,
} from '@zipcrim/work-flow';
import { FileUploadModule } from 'ng2-file-upload';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { AccountComponent } from '../account/account.component';
import { SideMenuInAccountComponent } from '../account/side-menu/side-menu.component';
import { UserListComponent } from '../account/user-list/user-list.component';
import { UserComponent } from '../account/user/user.component';
import { AnaGroupComponent } from '../account/workflow/ana-group.component';
import { WorkflowComponent } from '../account/workflow/workflow.component';
import { CaseCardComponent } from '../case-card/case-card.component';
import { CaseListComponent } from '../case-list/case-list.component';
import { BreadcrumbComponent } from '../common/breadcrumb/breadcrumb.component';
import { FooterComponent } from '../common/footer/footer.component';
import { HeaderComponent } from '../common/header/header.component';
import { DashboardV2Component } from '../dashboard/dashboard.component';
import { Stage1Component } from '../dashboard/stage1/stage1.component';
import { WalkthroughComponent } from '../dashboard/walk-through/walk-through.component';
import { DocumentHomeComponent } from '../document-home/document-home.component';
import { environment } from '../environments/environment';
import { LeadTagsComponent } from '../lead-tags/lead-tags.component';
import { OnboardComponent } from '../onboard/onboard.component';
import { CheckOptionComponent } from '../report/add-another/add-another.component';
import { BlockedLeadComponent } from '../report/blocked-leads/blocked-leads.component';
import { DataEntryAddressComponent } from '../report/data-entry/data-entry-address.component';
import { DataEntryDobComponent } from '../report/data-entry/data-entry-dob.component';
import { DataEntryMvrComponent } from '../report/data-entry/data-entry-mvr.component';
import { DataEntryNameComponent } from '../report/data-entry/data-entry-name.component';
import { DataEntrySsnComponent } from '../report/data-entry/data-entry-ssn.component';
import { DrugAndAlcoholComponent } from '../report/drug-and-alcohol/drug-and-alcohol.component';
import { FinalizeAndOrderComponent } from '../report/finalize-and-order/finalize-and-order.component';
import { NextStepModalComponent } from '../report/next-step-modal/next-step-modal.component';
import { PrepareOrderModalComponent } from '../report/prepare-order-modal/prepare-order-modal.component';
import { PrepareOrderComponent } from '../report/prepare-order/prepare-order.component';
import { ReportResultsComponent } from '../report/report-results/report-results.component';
import { ReportComponent } from '../report/report.component';
import { SubjectSummaryComponent } from '../report/subject-summary/subject-summary.component';
import { CompanyInfoComponent } from '../signup/company-info/company-info.component';
import { HomeComponent } from '../signup/home.component';
import { PennsylvaniaInfoComponent } from '../signup/pennsylvania-info/pennsylvania-info.component';
import { SignupComponent } from '../signup/signup/signup.component';
import { AppComponent } from './app.component';
import { APP_ICONS } from './app.icons';
import { ROUTES } from './app.routes';
import { NgForm } from '@angular/forms';
// import { DynamicNodeComponent } from '@zipcrim/dynamic-forms/dynamic-node/dynamic-node.component';
// import { DynamicNodeItemComponent } from '@zipcrim/dynamic-forms/dynamic-node/dynamic-node-item.component';
// import { DynamicCollectionComponent } from '@zipcrim/dynamic-forms/dynamic-collection/dynamic-collection.component';

export function eSignFormClient(job: Job): ActionRoute {
  return {
    type: 'route',
    commands: ['documents', job.ID],
  };
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AccountComponent,
        AnaGroupComponent,
        AppComponent,
        BlockedLeadComponent,
        BreadcrumbComponent,
        CaseCardComponent,
        CaseListComponent,
        CheckOptionComponent,
        CompanyInfoComponent,
        DashboardV2Component,
        DataEntryAddressComponent,
        DataEntryDobComponent,
        DataEntryMvrComponent,
        DataEntryNameComponent,
        DataEntrySsnComponent,
        DocumentHomeComponent,
        DrugAndAlcoholComponent,
        // DynamicNodeComponent,
        // DynamicNodeItemComponent,
        // DynamicCollectionComponent,
        FinalizeAndOrderComponent,
        FooterComponent,
        HeaderComponent,
        HomeComponent,
        LeadTagsComponent,
        NextStepModalComponent,
        OnboardComponent,
        PennsylvaniaInfoComponent,
        PrepareOrderComponent,
        PrepareOrderModalComponent,
        ReportComponent,
        ReportResultsComponent,
        SideMenuInAccountComponent,
        SignupComponent,
        Stage1Component,
        SubjectSummaryComponent,
        UserComponent,
        UserListComponent,
        WalkthroughComponent,
        WorkflowComponent,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        NgForm
    ],
    imports: [
        CommonModule.forRoot({
            showDebugLogs: environment.showDebugLogs,
            mockSession: environment.mockSession,
            skipColdFusion: environment.skipColdFusion,
            featureFlags: environment.featureFlags,
        }),
        AuthenticationModule.forRoot({
            appCode: 'CheckToHire',
            enableLoginPicker: environment.enableLoginPicker,
        }),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        FormsModuleDynamic,
        RouterModule.forRoot(ROUTES, {
            useHash: true,
            preloadingStrategy: PreloadAllModules,
            scrollPositionRestoration: 'enabled',
        }),
        DragDropModule,
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        FileUploadModule,
        DocumentSigningModule,
        ContactsModule,
        WorkFlowModule.forRoot({
            actions: {
                eSignFormClient,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                SSP_SU_SelectAccountOptions: {
                    type: 'route',
                    commands: ['/signup/more-info'],
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                C2H_CTConfig: {
                    type: 'route',
                    commands: ['/account/workflow'],
                    extras: {
                        queryParams: {
                            redirect: '/onboard',
                        },
                    },
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                SSP_SU_WhoSigns: {
                    type: 'modal',
                    component: WhoSignModalComponent,
                },
                VerifyEmail: {
                    type: 'modal',
                    component: VerifyEmailModalComponent,
                },
                Default: {
                    type: 'modal',
                    component: DefaultModalComponent,
                },
            },
        }),
        FinancialModule,
        CollapseModule.forRoot(),
        AlertModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'en-US',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        })
    ]
})
export class AppModule {
  constructor(
    _FaIconLibrary: FaIconLibrary,
    _IdleDetection: IdleDetectionService
  ) {
    _FaIconLibrary.addIcons(...APP_ICONS);
    _IdleDetection.init();
  }
}
