import { Component, Input, OnInit } from '@angular/core';

import { NavComponent } from './nav.component';

/**
 * Navigation router link.
 */
@Component({
  selector: 'zc-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavLinkComponent implements OnInit {
  constructor(private nav: NavComponent) {}

  @Input() link: any[] | string;
  @Input() queryParams: { [key: string]: any };
  @Input() activeOptions = { exact: false };
  css: string;

  ngOnInit() {
    this.setCss();
  }

  private setCss() {
    const css: string[] = [];

    if (this.nav.variant) {
      css.push(`nav__item--${this.nav.variant}`);
    }

    if (this.nav.direction) {
      css.push(`nav__item--${this.nav.direction}`);
    }

    this.css = css.join(' ');
  }
}
