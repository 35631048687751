<div class="card" [ngClass]="css">
  <!-- header -->
  <div class="card__header">
    <button
      type="button"
      class="card__toggle"
      *ngIf="isCollapsible; else titleRef"
      (click)="bsCollapse.toggle()"
    >
      <fa-icon
        class="card__collapse-icon"
        size="xs"
        [icon]="bsCollapse.isCollapsed ? 'plus' : 'minus'"
      >
      </fa-icon>
      <ng-container *ngTemplateOutlet="titleRef"></ng-container>
    </button>

    <ng-template #titleRef>
      <h2 class="card__title" *ngIf="title">{{ title }}</h2>
    </ng-template>

    <ng-content select="zc-card-header"></ng-content>
  </div>

  <!-- body -->
  <div class="card__body" [collapse] #bsCollapse="bs-collapse">
    <ng-content select="zc-card-body"></ng-content>
  </div>

  <!-- footer -->
  <div class="card__footer">
    <ng-content select="zc-card-footer"></ng-content>
  </div>
</div>
