import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches a valid telephone.
 *
 * Allowed formats:
 * - 9999999999
 * - 999 999 9999
 * - 999-999-9999
 * - (999) 999-9999
 */
const TELEPHONE_LOCAL_REGEX =
  /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/i;

/**
 * Matches a valid international telephone.
 *
 * Note: Because of the wide variety of international formats, only characters and length are checked, not format.
 */
const TELEPHONE_I18N_REGEX = /^[-\+\s\.\(\)0-9]{8,30}$/i;

/**
 * Validate a telephone number.
 *
 * @param i18n Indicates if country codes are allowed. Default is false.
 */
export function TelephoneValidator(i18n?: boolean) {
  const pattern = i18n ? TELEPHONE_I18N_REGEX : TELEPHONE_LOCAL_REGEX;

  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    if (!value || pattern.test(value)) {
      return null;
    }

    return {
      telephone: true,
    };
  };
}
