import { IDisclosure } from '../generated';
import { L10nService } from '../l10n/l10n.service';

/**
 * Disclosure.
 */
export class Disclosure {
  constructor();
  constructor(source: Partial<IDisclosure>, l10n: L10nService);
  constructor(source?: Partial<IDisclosure>, l10n?: L10nService) {
    this._Init(source, l10n);
  }

  OccurrenceDate: string;
  ConvictedDate: string;
  State: string;
  City: string;
  County: string;
  CountryCode: string;
  ActionType: string;
  ID: number;
  CourtName: string;
  Agency: string;
  Status: string;
  Outcome: string;
  CourtAttendeeType: string;
  Details: string;
  DisclosureType?: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<IDisclosure>, l10n?: L10nService) {
    const defaultCountryCode = l10n ? l10n.currentCountry : undefined;

    if (!source) {
      this.CountryCode = defaultCountryCode;
      return;
    }

    this.OccurrenceDate = source.OccurrenceDate;
    this.ConvictedDate = source.ConvictedDate;
    this.State = source.State;
    this.City = source.City;
    this.County = source.County;
    this.CountryCode = source.CountryCode || defaultCountryCode;
    this.ActionType = source.ActionType;
    this.CourtName = source.CourtName;
    this.Agency = source.Agency;
    this.Status = source.Status;
    this.Outcome = source.Outcome;
    this.CourtAttendeeType = source.CourtAttendeeType;
    this.ID = source.ID;
    this.Details = source.Details;
  }
}
