import isString from 'lodash/isString';

import { IBlurb, IUiField } from '../generated';
import {
  MessageSeverity,
  MessageTarget,
  ResponseMessage,
} from './api.interfaces';

/**
 * Api response result.
 */
export class ApiMessage {
  constructor(source: ResponseMessage | string) {
    if (isString(source)) {
      source = { Blurb: source };
    }

    this.init(source);
  }

  /**
   * Message text.
   */
  blurb: IBlurb;

  /**
   * Message severity.
   */
  severity: MessageSeverity;

  /**
   * Message target.
   */
  target: MessageTarget;

  /**
   * Message source.
   */
  source: string;

  /**
   * Additional messages.
   */
  innerMessages: ApiMessage[];

  /**
   * Optional associated UI field.
   */
  uiField?: IUiField;

  /**
   * Get ui field data by property name.
   *
   * @param name Property name.
   */
  getUiField(name: string) {
    if (!this.uiField) {
      return null;
    }

    return this.uiField.Data[name] || null;
  }

  /**
   * Get the first available ui field data.
   */
  getFirstUiField() {
    if (!this.uiField) {
      return null;
    }

    const name = Object.keys(this.uiField.Data)[0];
    const field = this.uiField.Data[name];

    return field ? { ...field, name } : null;
  }

  /**
   * Recursively flattens `InnerMessages`.
   */
  flattenInnerMessages() {
    return this._flattenInnerMessages(this);
  }

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private init(source: ResponseMessage) {
    this.blurb = source.Blurb;
    this.severity = source.Severity || 'Error';
    this.target = source.Target || 'User';
    this.source = source.Source || 'Zipcrim Client';
    this.uiField = source.UiField;
    this.innerMessages = source.InnerMessages
      ? source.InnerMessages.map((item) => new ApiMessage(item))
      : [];
  }

  /**
   * Recursively flattens `InnerMessages`.
   *
   * @param message Root level message.
   */
  private _flattenInnerMessages(message: ApiMessage) {
    const output: ApiMessage[] = [];

    if (!message) {
      return output;
    }

    output.push(message);

    message.innerMessages.forEach((item) => {
      const inner = this._flattenInnerMessages(item);
      output.push(...inner);
    });

    return output;
  }
}
