import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, shareReplay, tap } from 'rxjs/operators';

import { SessionPostData } from '../api/api.interfaces';
import { ApiService } from '../api/api.service';
import { SaveSpec } from '../api/save-spec';
import { IBoList, IContact, IDomain } from '../generated';
import { Contact, Domain } from '../models';
import { WindowRefService } from '../window-ref/window-ref.service';
import { AccessRight } from './auth.interfaces';
import { SessionService } from './session.service';

/**
 * Information about the currently logged in user.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private api: ApiService,
    private session: SessionService,
    private windowRef: WindowRefService
  ) {}

  private currentUserCache: Observable<Contact>;
  private domainsRightCache = new Map<string, Domain[]>();

  createAccount(spec: SaveSpec) {
    return this.api.post<SessionPostData>('sessions/createAccount', spec).pipe(
      map((res) => res.payload),
      flatMap((payload) =>
        this.api.convertSession(payload).pipe(map(() => payload))
      )
    );
  }

  isAuthenticated() {
    return this.getCurrentUser().pipe(
      map((user) => !!user),
      catchError(() => of(false))
    );
  }

  getCurrentUser() {
    if (!this.currentUserCache) {
      this.currentUserCache = this.api
        .get<IContact>('contact/byZSession')
        .pipe(
          map((res) => new Contact(res.payload)),
          shareReplay(1),
          catchError((error) => {
            // clear cache so next attempt can get user after login
            this.currentUserCache = null;
            return throwError(error);
          })
        );
    }

    return this.currentUserCache;
  }

  /**
   * Get available domains by right.
   *
   * @param objectClass Object class name.
   * @param right Access right.
   */
  getDomainsByRight(objectClass: string, right: AccessRight = 'View') {
    return this._getDomainsByRight(objectClass, right);
  }

  /**
   * Checks if the current user has rights.
   *
   * @param objectClass Object class name.
   * @param right Access right.
   */
  hasRight(objectClass: string, right: AccessRight = 'View') {
    return this._getDomainsByRight(objectClass, right).pipe(
      map((res) => res.length > 0)
    );
  }

  logout() {
    this.api.clearTokens();
    this.currentUserCache = null;
    this.domainsRightCache = null;

    const logoutUrl = this.session.data.Url.Logout;

    if (logoutUrl) {
      this.windowRef.nativeWindow.location.href = logoutUrl;
    }
  }

  private _getDomainsByRight(objectClass: string, right: AccessRight = 'View') {
    const url = 'domains/byCurrentUser';
    const cacheKey = `${objectClass}|${right}`;
    const cached = this.domainsRightCache.get(cacheKey);
    let params: HttpParams;

    if (cached) {
      return of(cached);
    }

    if (objectClass) {
      params = new HttpParams()
        .append('objectClass', objectClass)
        .append('right', right);
    }

    return this.api.get<IBoList<IDomain>>(url, params).pipe(
      map((res) => res.payload.Items),
      map((res) => res.map((item) => new Domain(item))),
      tap((res) => this.domainsRightCache.set(cacheKey, res))
    );
  }
}
