import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Country code / passport mappings.
 */
interface PassportMap {
  [country: string]: Array<{ regex: RegExp; description: string }> | null;
}

/**
 * Expressions to match a valid passport formats by country.
 */
const PASSPORT_REGEX: PassportMap = {
  // Austria
  AT: [
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // Australia
  AU: [
    {
      regex: /^[A-Z]{1}[0-9]{7}$/,
      description: '1 letter followed by 7 numbers',
    },
  ],
  // Bangladesh
  BD: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Belgium
  BE: [
    {
      regex: /^[A-Z]{2}[0-9]{6}$/,
      description: '2 letter followed by 6 numbers',
    },
  ],
  // Bulgaria
  BG: [
    {
      regex: /^[0-9]{10}$/,
      description: '10 numbers',
    },
  ],
  // Brazil
  BR: [
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // Bahamas
  BS: [
    {
      regex: /^[A-Z]{2}[0-9]{8}$/,
      description: '2 letter followed by 8 numbers',
    },
  ],
  // Belarus
  BY: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Canada
  CA: [
    {
      regex: /^[A-Z]{2}[0-9]{6}$/,
      description: '2 letter followed by 6 numbers',
    },
  ],
  // Switzerland
  CH: [
    {
      regex: /^[A-Z]{1}[0-9]{7}$/,
      description: '1 letter followed by 7 numbers',
    },
  ],
  // China
  CN: [
    {
      regex: /^[A-Z]{1}[0-9]{8}$/,
      description: '1 letter followed by 8 numbers',
    },
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // Cyprus
  CY: [
    {
      regex: /^[A-Z]{1}[0-9]{7}$/,
      description: '1 letter followed by 7 numbers',
    },
  ],
  // Czechia
  CZ: [
    {
      regex: /^[0-9]{9}$/,
      description: '8 numbers',
    },
  ],
  // Germany
  DE: [
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // Denmark
  DK: [
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // 	Dominican Republic
  DR: null,
  // Estonia
  EE: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Egypt
  EG: [
    {
      regex: /^[A-Z]{2}[0-9]{8}$/,
      description: '2 letter followed by 8 numbers',
    },
  ],
  // Spain
  ES: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Finland
  FI: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // France
  FR: [
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // Greece
  GR: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Guam
  GU: [
    {
      regex: /^[0-9]{8}$/,
      description: '8 numbers',
    },
  ],
  // Hong Kong
  HK: [
    {
      regex: /^[A-Z]{1}[0-9]{8}$/,
      description: '1 letter followed by 8 numbers',
    },
  ],
  // Croatia
  HR: [
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // Haiti
  HT: null,
  // Hungary
  HU: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Indonesia
  ID: [
    {
      regex: /^[A-Z]{1}[0-9]{7}$/,
      description: '1 letter followed by 7 numbers',
    },
  ],
  // Ireland
  IE: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // India
  IN: [
    {
      regex: /^[J][0-9]{7}$/,
      description: '"J" followed by 7 numbers',
    },
  ],
  // Iceland
  IS: [
    {
      regex: /^[A-Z]{1}[0-9]{6}$/,
      description: '1 letter followed by 6 numbers',
    },
  ],
  // Italy
  IT: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Jamaica
  JM: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Japan
  JP: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Cambodia
  KH: [
    {
      regex: /^[A-Z]{1}[0-9]{7}$/,
      description: '1 letter followed by 7 numbers',
    },
  ],
  // Sri Lanka
  LK: [
    {
      regex: /^[A-Z]{1}[0-9]{6}$/,
      description: '1 letter followed by 6 numbers',
    },
  ],
  // Lithuania
  LT: [
    {
      regex: /^[0-9]{11}$/,
      description: '11 numbers',
    },
  ],
  // Luxembourg
  LU: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Latvia
  LV: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Myanmar
  MM: [
    {
      regex: /^[A-Z]{2}[0-9]{6}$/,
      description: '2 letter followed by 6 numbers',
    },
  ],
  // Malta
  MT: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letter followed by 7 numbers',
    },
  ],
  // Mexico
  MX: [
    {
      regex: /^[0-9]{11}$/,
      description: '11 numbers',
    },
  ],
  // Malaysia
  MY: [
    {
      regex: /^[A-Z]{1}[0-9]{7,8}$/,
      description: '1 letter followed by 7-8 numbers',
    },
  ],
  // Netherlands
  NL: [
    {
      regex: /^[A-Z0-9]{9}$/,
      description: '9 letters or numbers',
    },
  ],
  // Norway
  NO: [
    {
      regex: /^[A-Z0-9]{9}$/,
      description: '9 letters or numbers',
    },
  ],
  // New Zealand
  NZ: [
    {
      regex: /^[A-Z]{2}[0-9]{6}$/,
      description: '2 letters followed by 6 numbers',
    },
  ],
  // Philippines
  PH: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letters followed by 7 numbers',
    },
  ],
  // Pakistan
  PK: null,
  // Poland
  PL: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letters followed by 7 numbers',
    },
  ],
  // Puerto Rico
  PR: [
    {
      regex: /^[0-9]{8}$/,
      description: '8 numbers',
    },
  ],
  // Portugal
  PT: [
    {
      regex: /^[0-9]{7}$/,
      description: '7 numbers',
    },
  ],
  // Romania
  RO: [
    {
      regex: /^[0-9]{8}$/,
      description: '8 numbers',
    },
  ],
  // Russia
  RU: [
    {
      regex: /^[A-Z0-9]{10}$/,
      description: '10 letters or numbers',
    },
  ],
  // Sweden
  SE: [
    {
      regex: /^[0-9]{8}$/,
      description: '8 numbers',
    },
  ],
  // Singapore
  SG: [
    {
      regex: /^[A-Z]{1}[0-9]{7}[A-Z]{1}$/,
      description: '1 letter followed by 7 numbers followed by 1 letter',
    },
  ],
  // Slovenia
  SI: null,
  // Slovakia
  SK: [
    {
      regex: /^[A-Z]{1}[0-9]{7}$/,
      description: '1 letters followed by 7 numbers',
    },
  ],
  // Thailand
  TH: [
    {
      regex: /^[A-Z]{2}[0-9]{7}$/,
      description: '2 letters followed by 7 numbers',
    },
  ],
  // Taiwan
  TW: [
    {
      regex: /^[A-Z]{1}[0-9]{8}$/,
      description: '1 letter followed by 8 numbers',
    },
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // Ukraine
  UA: [
    {
      regex: /^[A-Z]{2}[0-9]{6}$/,
      description: '2 letters followed by 6 numbers',
    },
  ],
  // United Kingdom
  UK: [
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // United States
  US: [
    {
      regex: /^[0-9]{9}$/,
      description: '9 numbers',
    },
  ],
  // Virgin Islands
  VI: [
    {
      regex: /^[0-9]{8}$/,
      description: '8 numbers',
    },
  ],
  // Vietnam
  VN: [
    {
      regex: /^[A-Z]{1}[0-9]{7}$/,
      description: '1 letters followed by 7 numbers',
    },
  ],
};

/**
 * Validate a passport number.
 */
export function PassportValidator(country: string) {
  const regex = PASSPORT_REGEX[country];

  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    if (!value || !regex || regex.some((item) => item.regex.test(value))) {
      return null;
    }

    return {
      passport: true,
    };
  };
}
