import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Display raw html.
 *
 * ```html
 * <zc-html [Content]="MyHtmlString"></zc-html>
 * ```
 */
@Component({
  selector: 'zc-html',
  templateUrl: './html.component.html',
})
export class HtmlComponent implements OnChanges {
  constructor(private sanitized: DomSanitizer) {}

  @Input() content: string;
  html: SafeHtml;

  ngOnChanges() {
    if (this.content) {
      const html = this.content.replace('"', `'`);
      this.html = this.sanitized.bypassSecurityTrustHtml(html);
    } else {
      this.html = '';
    }
  }
}
