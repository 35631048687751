import { ModuleWithProviders, NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@zipcrim/common';
import { FormsModule } from '@zipcrim/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { DefaultModalComponent } from './default-modal/default-modal.component';
import { JobTreeComponent } from './job-tree/job-tree.component';
import { VerifyEmailModalComponent } from './verify-email/verify-email-modal.component';
import { WhoSignModalComponent } from './who-sign/who-sign-modal.component';
import { WorkFlowModuleConfig } from './work-flow.interface';
import { WORKFLOW_ICONS } from './workflow.icons';

@NgModule({
  imports: [CommonModule, FormsModule, PopoverModule.forRoot()],
  declarations: [
    JobTreeComponent,
    VerifyEmailModalComponent,
    DefaultModalComponent,
    WhoSignModalComponent,
  ],
  exports: [CommonModule, JobTreeComponent, VerifyEmailModalComponent],
})
export class WorkFlowModule {
  static forRoot(
    config: WorkFlowModuleConfig
  ): ModuleWithProviders<WorkFlowModule> {
    return {
      ngModule: WorkFlowModule,
      providers: [
        {
          provide: 'workFlowConfig',
          useValue: config,
        },
      ],
    };
  }

  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(...WORKFLOW_ICONS);
  }
}
