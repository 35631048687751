<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ title }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <p class="text-center mt-2 mb-2">{{ message }}</p>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button color="secondary" outline class="me-1" (click)="onCancel()">
      {{ noLabel }}
    </zc-button>
    <zc-button color="primary" (click)="onContinue()">
      {{ yesLabel }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
