import { IValidationRule, IValidationRuleName } from '../generated';
import { AttestPrompt } from './attest-prompt';
import { ValidationRuleCategory } from './validation-rule-category';
import { ValidationRuleLevel } from './validation-rule.enums';

/**
 * Validation rule.
 */
export class ValidationRule {
  constructor(source?: Partial<IValidationRule>) {
    this._Init(source);
  }

  ID: number;
  Blurb: string;
  Category: ValidationRuleCategory;
  ErrorNID: number;
  ErrorName: IValidationRuleName;
  Impact: number;
  IsFailure: boolean;
  ObjectScopeType1: string;
  ObjectScopeType2: string;
  ParamDefinition: string;
  RuleParameters: string;
  ScopeColName1: string;
  ScopeColName2: string;
  TypeName: string;
  ValidationSetID: number;
  ValidationSetName: string;
  ValidationTypeID: number;

  /**
   * Unique reference to associated validation error.
   */
  ValidationSetRuleJoinID?: number;

  /**
   * Reference to the associated attestation prompt, if available.
   */
  Prompt: AttestPrompt;

  get IsRequirement() {
    return this.Category === 'Rqmnt';
  }

  get Level() {
    const name = this.ValidationSetName;
    const workOrderExp = /WO[\d]+$/i;

    if (name.toLowerCase() === 'universal') {
      return ValidationRuleLevel.Universal;
    } else if (name.indexOf(':') !== -1) {
      return ValidationRuleLevel.Client;
    } else if (workOrderExp.test(name)) {
      return ValidationRuleLevel.WorkOrder;
    } else {
      return null;
    }
  }

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IValidationRule>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Blurb = source.Blurb;
    this.Category = source.Category as ValidationRuleCategory;
    this.ErrorNID = source.ErrorNID;
    this.ErrorName = source.ErrorName;
    this.Impact = source.Impact;
    this.IsFailure = source.IsFailure;
    this.ObjectScopeType1 = source.ObjectScopeType1;
    this.ObjectScopeType2 = source.ObjectScopeType2;
    this.ParamDefinition = source.ParamDefinition;
    this.RuleParameters = source.RuleParameters;
    this.ScopeColName1 = source.ScopeColName1;
    this.ScopeColName2 = source.ScopeColName2;
    this.TypeName = source.TypeName;
    this.ValidationSetID = source.ValidationSetID;
    this.ValidationSetName = source.ValidationSetName;
    this.ValidationTypeID = source.ValidationTypeID;
    this.ValidationSetRuleJoinID = source.ValidationSetRuleJoinID;
  }
}
