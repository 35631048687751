import { ICase } from '../generated';
import { L10nService } from '../l10n/l10n.service';
import { Contact } from './contact';
import { Lead } from './lead';
import { Subject } from './subject';

/**
 * Case.
 */
export class Case {
  constructor();
  constructor(source: Partial<ICase>, l10n: L10nService);
  constructor(source?: Partial<ICase>, l10n?: L10nService) {
    this._Init(source, l10n);
  }

  CaseNum: string;
  DueOn: string;
  ID: number;
  IsHidden: boolean;
  IsPinned: boolean;
  Leads: Lead[];
  Status: string;
  Submitted: string;
  Category: string;
  Index: number;
  HasJobs: boolean;
  CaseType: string;
  Subject: Subject | null;
  Owner: Contact | null;
  LastReported: Lead | null;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<ICase>, l10n?: L10nService) {
    if (!source) {
      this.Leads = [];
      this.Subject = null;
      this.Owner = null;
      this.LastReported = null;
      return;
    }

    this.CaseNum = source.CaseNum;
    this.DueOn = source.DueOn;
    this.ID = source.ID;
    this.IsHidden = source.IsHidden;
    this.IsPinned = source.IsPinned;
    this.Status = source.Status;
    this.Submitted = source.Submitted;
    this.Category = source.Category;
    this.Index = source.Index;
    this.HasJobs = source.HasJobs;
    this.CaseType = source.CaseType;

    this.Owner = source.Owner ? new Contact(source.Owner) : null;

    this.Subject = source.Subject ? new Subject(source.Subject, l10n) : null;

    this.LastReported = source.LastReported
      ? new Lead(source.LastReported)
      : null;

    this.Leads = source.Leads ? source.Leads.Items.map((x) => new Lead(x)) : [];
  }
}
