<h1 class="header-1 text-center">
  <span translate [translateParams]="{ name: UserName }">
    CheckToHire.lblCompanyInfoMsg1
  </span>
  <br />
  {{ 'CheckToHire.lblCompanyInfoMsg2' | translate }}
</h1>

<div class="row">
  <div class="col-sm-8 col-sm-offset-2">
    <form class="home-form" (ngSubmit)="OnSubmit()">
      <h2 class="header-2 text-green text-center">
        {{ 'CheckToHire.lblCompanyInfoSubhead' | translate }}
      </h2>
      <ng-container [formGroup]="FormGroup">
        <zc-form-field>
          <zc-label>{{ 'CheckToHire.lblBusinessTypes' | translate }}</zc-label>
          <zc-select
            formControlName="NatureOfBusiness"
            [optionsAsync]="NatureOfBusinessOptions$"
          ></zc-select>
        </zc-form-field>

        <ng-container formGroupName="FCRAPurpose">
          <zc-form-field>
            <zc-label>{{ 'CheckToHire.lblFCRAPurpose' | translate }}</zc-label>
            <zc-checkbox
              *ngFor="let option of FCRAPurposeOptions"
              [(ngModel)]="option.name"
            >
              {{ option.label }}
              <zc-help-text *ngIf="option.description">
                {{ option.description }}
              </zc-help-text>
            </zc-checkbox>
          </zc-form-field>
        </ng-container>
      </ng-container>

      <div class="text-center">
        <zc-button type="submit" color="primary" [busy]="BusySaving">
          {{ 'Common.cmdContinue' | translate }}
        </zc-button>
      </div>
    </form>
  </div>
</div>
