import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { SaveSpec } from '../../api/save-spec';
import { IBoList, ICase, ISubject } from '../../generated';

/**
 * Case API interactions.
 */
@Injectable({ providedIn: 'root' })
export class CaseService {
  constructor(private api: ApiService) {}

  /**
   * List available cases.
   *
   * @param params Http params.
   */
  list(params?: HttpParams) {
    return this.api
      .get<IBoList<ICase>>(`cases`, params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get case by case num.
   *
   * @param caseNum Case num.
   * @param params Http params.
   */
  getByNum(caseNum: string, params?: HttpParams) {
    if (!params) {
      params = new HttpParams();
    }

    params = params.append('caseNum', caseNum);

    return this.api
      .get<{
        ID: number;
        CaseNum: string;
        SubjectID: number;
        Subject?: ISubject;
      }>('case/byCaseNum', params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Toggle case pin.
   *
   * @param caseNum Case num.
   * @param pin True if the case should be pinned.
   */
  togglePin(caseNum: string, pin: boolean) {
    return this.api
      .post(`case/${caseNum}/pinForUser/${pin}`, {})
      .pipe(map((res) => res.payload));
  }

  /**
   * Toggle case hide.
   *
   * @param caseNum Case num.
   * @param hide True if the case should be hidden.
   */
  toggleHide(caseNum: string, hide: boolean) {
    return this.api
      .post(`case/${caseNum}/hideForUser/${hide}`, {})
      .pipe(map((res) => res.payload));
  }

  /**
   * Get rights by case.
   *
   * @param id Case id.
   */
  getRights(id: number) {
    return this.api
      .get<void>(`authorization/authorizeUserForObject/case/${id}/list`)
      .pipe(map((res) => res.payload));
  }

  /**
   * Save case types configuration.
   */
  saveCaseTypes(spec: SaveSpec) {
    return this.api
      .post('caseTypes/config', spec)
      .pipe(map((res) => res.payload));
  }
}
