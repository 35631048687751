import { IMvr, IUiMetaRecord } from '../generated';
import { L10nService } from '../l10n/l10n.service';

/**
 * Mvr.
 */
export class Mvr {
  constructor();
  constructor(source: Partial<IMvr>, l10n: L10nService);
  constructor(source?: Partial<IMvr>, l10n?: L10nService) {
    this._Init(source, l10n);
  }

  LicenseClass: 'NonCDL' | 'CDL' | 'Unknown';
  LicenseNumber: string;
  CountryCode: string;
  LicenseState: string;
  ID: number;
  IsCurrent: boolean;
  IsForced: boolean;
  UiMeta?: IUiMetaRecord;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<IMvr>, l10n?: L10nService) {
    const defaultCountryCode = l10n ? l10n.currentCountry : undefined;

    if (!source) {
      this.CountryCode = defaultCountryCode;
      return;
    }

    this.LicenseClass = source.LicenseClass;
    this.LicenseNumber = source.LicenseNumber;
    this.CountryCode = source.CountryCode || defaultCountryCode;
    this.IsCurrent = !!source.IsCurrent;
    this.LicenseState = source.LicenseState;
    this.ID = source.ID;
    this.IsForced = !!source.IsForced;
    this.UiMeta = source.UiMeta;
  }
}
