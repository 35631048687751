import { Component, OnInit } from '@angular/core';
import { AuthService } from '@zipcrim/common';
import { Invoice } from '@zipcrim/common/models';
import { finalize, flatMap, tap } from 'rxjs/operators';

import { InvoiceService } from '../invoice.service';

/**
 * Invoice list display.
 *
 * ```html
 * <zc-invoice-list></zc-invoice-list>
 * ```
 */
@Component({
  selector: 'zc-invoice-list',
  templateUrl: './invoice-list.component.html',
})
export class InvoiceListComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private invoice: InvoiceService
  ) {}

  /**
   * Collection of invoices.
   */
  invoices: Invoice[];

  /**
   * Busy indicator.
   */
  busy: boolean;

  /**
   * Client code.
   */
  private clientCode: string;

  /**
   * On init.
   */
  ngOnInit() {
    this.busy = true;
    this.auth
      .getDomainsByRight('Invoice')
      .pipe(
        tap((data) => (this.clientCode = data[0].ClientCode)),
        flatMap(() => this.invoice.getInvoices(this.clientCode)),
        finalize(() => (this.busy = false))
      )
      .subscribe((data) => (this.invoices = data));
  }

  /**
   * Download invoice.
   *
   * @param item Invoice record.
   */
  downloadInvoice(item: Invoice) {
    this.invoice.downloadInvoice(this.clientCode, item).subscribe();
  }
}
