import { Inject, Injectable } from '@angular/core';

import { CommonModuleConfig } from '../common.interfaces';
import { WindowRefService } from '../window-ref/window-ref.service';
import { SessionData } from './auth.interfaces';

/**
 * Server session control service.
 */
@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(
    @Inject('commonModuleConfig') private config: CommonModuleConfig,
    private windowRef: WindowRefService
  ) {
    this.init();
  }

  /**
   * Server session control value.
   */
  data: SessionData;

  /**
   * Hydrate session data from window object.
   */
  private init() {
    this.data =
      this.windowRef.nativeWindow.ZServerSessionControl ||
      this.config.mockSession ||
      ({} as SessionData);

    // ensure object structure is defined to avoid reference errors
    if (!this.data.AppData) {
      this.data.AppData = {};
    }

    if (!this.data.PostData) {
      this.data.PostData = {};
    }

    if (!this.data.Url) {
      this.data.Url = {};
    }
  }
}
