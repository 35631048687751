<div class="dashboard">
  <!-- sidebar -->
  <c2h-stage1 *ngIf="CanAddCase"></c2h-stage1>

  <div class="dashboard-main">
    <zc-nav class="mb-2" *ngIf="HasAny">
      <zc-nav-link [link]="[]" [activeOptions]="{ exact: true }">
        {{ 'CheckToHire.lblAllActive' | translate }}
      </zc-nav-link>
      <zc-nav-link [link]="[]" [queryParams]="{ filter: 'Recent' }">
        {{ 'CheckToHire.lblRecentlyCompleted' | translate }}
      </zc-nav-link>
      <zc-nav-link [link]="[]" [queryParams]="{ filter: 'Pending' }">
        {{ 'CheckToHire.lblInProgress' | translate }}
      </zc-nav-link>
      <zc-nav-link [link]="[]" [queryParams]="{ searchAll: true }">
        {{ 'CheckToHire.lblSearchAll' | translate }}
      </zc-nav-link>
    </zc-nav>

    <!-- filters -->
    <form #Form="ngForm" [hidden]="!HasAny">
      <div class="dashboard-search">
        <div class="dashboard-search__item">
          <fa-icon icon="search"></fa-icon>
        </div>
        <div class="dashboard-search__item">
          <zc-input
            name="search"
            type="search"
            [(ngModel)]="Filter.search"
            placeholder="Find on this page"
          >
          </zc-input>
        </div>
        <div class="dashboard-search__item">
          <zc-select
            name="sort"
            [(ngModel)]="Filter.sort"
            [placeholder]="'Common.lblSortBy' | translate"
            [options]="SortOptions"
          >
          </zc-select>
        </div>
      </div>
    </form>

    <!-- empty -->
    <ng-template #empty>
      <div class="mt-5">
        <c2h-walkthrough *ngIf="CanAddCase"></c2h-walkthrough>
        <zc-empty
          file="img/graphic-trusted.png"
          [titleL10nKey]="
            CanAddCase
              ? 'CheckToHire.lblOrderAReportDescription'
              : 'CheckToHire.lblDontHaveRightToOrderReport'
          "
        >
        </zc-empty>
      </div>
    </ng-template>

    <ng-container *ngIf="HasAny; else empty">
      <!-- pinned cases -->
      <section class="mt-2" *ngIf="ShowGroups">
        <h2 class="header-2">{{ 'CheckToHire.lblStarred' | translate }}</h2>

        <!-- busy -->
        <div class="d-flex flex-wrap" *ngIf="BusyLoading; else pinned">
          <c2h-case-card [Busy]="BusyLoading"></c2h-case-card>
        </div>

        <ng-template #pinned>
          <div class="d-flex flex-wrap">
            <c2h-case-card
              *ngFor="let item of Pinned"
              [Case]="item"
              [@slideFadeAnimation]="'in'"
              (OnPinnedChange)="OnCaseChange()"
              (OnHiddenChange)="OnCaseChange()"
            >
            </c2h-case-card>
          </div>

          <zc-empty
            *ngIf="Pinned.length === 0"
            icon="star"
            descriptionL10nKey="CheckToHire.lblStarEmpty"
          >
          </zc-empty>
        </ng-template>
      </section>

      <!-- today's cases -->
      <section class="mt-2" *ngIf="ShowGroups">
        <h2 class="header-2">{{ 'CheckToHire.lblToday' | translate }}</h2>

        <!-- busy -->
        <div class="d-flex flex-wrap" *ngIf="BusyLoading; else today">
          <c2h-case-card [Busy]="BusyLoading"></c2h-case-card>
          <c2h-case-card [Busy]="BusyLoading"></c2h-case-card>
        </div>

        <ng-template #today>
          <div class="d-flex flex-wrap">
            <c2h-case-card
              *ngFor="let item of Today"
              [Case]="item"
              [@slideFadeAnimation]="'in'"
              (OnPinnedChange)="OnCaseChange()"
              (OnHiddenChange)="OnCaseChange()"
            >
            </c2h-case-card>
          </div>

          <p *ngIf="Today.length === 0" class="text-muted mb-4">
            {{ 'CheckToHire.lblNothingToShow' | translate }}
          </p>
        </ng-template>
      </section>

      <!-- this week section -->
      <section class="mt-2" *ngIf="ShowGroups">
        <h2 class="header-2">{{ 'CheckToHire.lblThisWeek' | translate }}</h2>
        <c2h-case-list
          [Cases]="ThisWeek"
          [Busy]="BusyLoading"
          [CanTogglePinned]="ThisWeek.length < 10"
          (OnPinnedChange)="OnCaseChange()"
          (OnHiddenChange)="OnCaseChange()"
        >
        </c2h-case-list>
      </section>

      <!-- earlier section -->
      <section class="mt-2" *ngIf="ShowGroups">
        <h2 class="header-2">{{ 'CheckToHire.lblEarlier' | translate }}</h2>
        <c2h-case-list
          [Cases]="Other"
          [Busy]="BusyLoading"
          [CanTogglePinned]="Other.length < 10"
          (OnPinnedChange)="OnCaseChange()"
          (OnHiddenChange)="OnCaseChange()"
        >
        </c2h-case-list>
      </section>

      <!-- search all -->
      <section class="mt-2" *ngIf="!ShowGroups">
        <c2h-case-list
          [Cases]="AllCases"
          [Busy]="BusyLoading"
          [HideActions]="true"
        >
        </c2h-case-list>
      </section>

      <!-- load more -->
      <div class="text-center" *ngIf="HasMore">
        <zc-button color="primary" (click)="OnLoadMore()" [busy]="BusyMore">
          {{ 'CheckToHire.cmdShowMore' | translate }}
        </zc-button>
      </div>
    </ng-container>
  </div>
</div>
