<ng-container *ngIf="BusyLoading; else content">
  <div class="text-center p-2">
    <zc-busy></zc-busy>
  </div>
</ng-container>

<ng-template #content>
  <zc-modal>
    <zc-modal-header (dismiss)="OnCancel()">
      <h4 class="header-1">
        {{ 'CheckToHire.lblOrderAnotherReport' | translate }}
      </h4>
    </zc-modal-header>
    <zc-modal-body>
      <form #form="ngForm" (ngSubmit)="OnSubmit(form)">
        <table class="table mb-0">
          <thead>
            <tr>
              <th>{{ 'CheckToHire.lblReport' | translate }}</th>
              <th class="text-end" style="min-width: 150px">
                {{ 'CheckToHire.lblCost' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of AnaGroupList; let i = index">
              <td>
                <h3 class="header-2">
                  <zc-checkbox [(ngModel)]="item.IsSelected" [name]="item.Code">
                    <span class="header-2">{{ item.Code }}</span>
                  </zc-checkbox>
                </h3>
                <h4 class="header-2 text-gray">{{ item.Description }}</h4>
                <p>{{ item.FullDescription }}</p>
              </td>
              <td class="text-end">
                <b>{{ item.Rate }}</b>
                <br />
                <b class="text-green">{{ item.Unit }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <alert type="danger" *ngIf="!IsOneSelected">
        <fa-icon icon="info-circle" class="me-1"></fa-icon>
        <span translate [translateParams]="{ min: 1 }">
          Common.msgRequiredMinControls
        </span>
      </alert>
    </zc-modal-body>

    <zc-modal-footer>
      <zc-button color="info" outline class="me-1" (click)="OnCancel()">
        {{ 'Common.lblCancel' | translate }}
      </zc-button>
      <zc-button
        color="primary"
        type="submit"
        [form]="form"
        [busy]="BusySaving">
        {{ 'Common.cmdDone' | translate }}
      </zc-button>
    </zc-modal-footer>
  </zc-modal>
</ng-template>
