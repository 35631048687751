import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CollapseDirective } from 'ngx-bootstrap/collapse';

import { CardColor } from './card.interfaces';

/**
 * Card component.
 *
 * ```html
 * <zc-card title="Info">
 *   <zc-card-body>
 *     <p>Blah blah blah ...</p>
 *   </zc-card-body>
 * </zc-card>
 * ```
 */
@Component({
  selector: 'zc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnChanges, OnInit {
  @Input() name: string;
  @Input() title: string;
  @Input() color: CardColor;
  @Input() isCollapsible: boolean;
  @Input() defaultCollapsed: boolean;
  @ViewChild('bsCollapse', { static: true }) bsCollapseRef: CollapseDirective;
  css: string[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.color) {
      this.setCss();
    }
  }

  ngOnInit() {
    if (this.isCollapsible && this.defaultCollapsed) {
      setTimeout(() => this.bsCollapseRef.hide());
    }
  }

  private setCss() {
    const css = [];

    if (this.color) {
      css.push(`card--${this.color}`);
    }

    this.css = css;
  }
}
