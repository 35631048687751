import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiService,
  FieldSpec,
  IBoList,
  IContact,
  IDomainRole,
  SaveSpec,
} from '@zipcrim/common';
import { Contact, ContactName, DomainRole } from '@zipcrim/common/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Contacts service.
 */
@Injectable({ providedIn: 'root' })
export class ContactsService {
  constructor(private api: ApiService) {}

  /**
   * Get available domain roles by client.
   *
   * @param clientCode Client code.
   */
  getDomainRoles(clientCode: string) {
    const fieldSpec = new FieldSpec().named('WithRoles');
    const params = new HttpParams()
      .append('clientCode', clientCode)
      .append('fieldSpec', fieldSpec.toString());

    return this.api
      .get<{ Roles: IBoList<IDomainRole> }>('domain/byclientcode', params)
      .pipe(
        map((res) =>
          res.payload.Roles.Items.map((item) => new DomainRole(item))
        )
      );
  }

  /**
   * Get available security questions.
   */
  getQuestions() {
    return this.api
      .get<{ [key: string]: string }>('checkToHireSvc/questionKeyCodes')
      .pipe(
        map((res) => {
          const items = res.payload;

          return Object.keys(items).map((key) => ({
            value: key,
            label: items[key],
          }));
        })
      );
  }

  /**
   * Get all available contacts.
   */
  getContacts(): Observable<Contact[]> {
    const fieldSpec = new FieldSpec().named('Summary');
    const params = new HttpParams().append('fieldSpec', fieldSpec.toString());

    return this.api
      .get<IBoList<IContact>>('contacts', params)
      .pipe(map((res) => res.payload.Items.map((item) => new Contact(item))));
  }

  /**
   * Get contact by id.
   *
   * @param id Contact id.
   */
  getContact(id: number): Observable<Contact> {
    return this.api
      .get<IContact>(`contact/${id}`)
      .pipe(map((res) => new Contact(res.payload)));
  }

  /**
   * Save contact record by id.
   *
   * @param id Contact id to save.
   * @param spec Save spec object.
   */
  saveContact(id: number, spec: SaveSpec) {
    return this.api
      .post<IContact>(`contact/${id ? id : ''}`, spec)
      .pipe(map((res) => new Contact(res.payload)));
  }

  /**
   * Activate a contact record by id.
   *
   * @param id Contact id to activate.
   * @param clientCode Contact's client code.
   * @param name Contact's name.
   */
  activateContact(id: number, clientCode: string, name: ContactName) {
    const spec = new SaveSpec('Contact', {
      ClientCode: clientCode,
      Name: name,
      Status: 'Active',
    });

    return this.saveContact(id, spec);
  }

  /**
   * Deactivate a contact record by id.
   *
   * @param id Contact id to deactivate.
   * @param clientCode Contact's client code.
   * @param name Contact's name.
   */
  deactivateContact(id: number, clientCode: string, name: ContactName) {
    const spec = new SaveSpec('Contact', {
      ClientCode: clientCode,
      Name: name,
      Status: 'Inactive',
    });

    return this.saveContact(id, spec);
  }
}
