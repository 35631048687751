import { Directive, HostListener } from '@angular/core';

import { StepperComponent } from './stepper.component';

@Directive({
  selector: '[zcStepperPrevious]',
})
export class StepperPreviousDirective {
  constructor(private stepper: StepperComponent) {}

  @HostListener('click')
  onClick() {
    this.stepper.previous();
  }
}
