<div class="checkbox" [class.disabled]="disabled">
  <label>
    <input
      type="checkbox"
      (blur)="onBlur()"
      [attr.aria-describedby]="id + '__errors'"
      [attr.aria-required]="required"
      [attr.id]="id"
      [readonly]="readonly"
      [disabled]="disabled"
      [checked]="value"
      (change)="value = !value"
    />
    <ng-content></ng-content>
  </label>
  <ng-content select="zc-help-text"></ng-content>
</div>
