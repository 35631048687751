<div class="modal-header">
  <!-- close -->
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    *ngIf="showClose"
    (click)="onDismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>

  <ng-content></ng-content>
</div>
