import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, of, Subscription, timer } from 'rxjs';
import { concatMap, delayWhen, finalize, repeat, tap } from 'rxjs/operators';

import { CheckToHireSvcService } from '../common/check-to-hire-svc.service';
import { CaseSummary } from '../model/case-summary';
import { NextStepModalComponent } from './next-step-modal/next-step-modal.component';
import { NextStepStatus } from './report.enums';
import { ReportService } from './report.service';

/**
 * Root report component.
 */
@Component({
  templateUrl: './report.component.html',
  providers: [ReportService],
})
export class ReportComponent implements OnInit, OnDestroy {
  constructor(
    private _ActivatedRoute: ActivatedRoute,

    private _CheckToHireSvc: CheckToHireSvcService,
    private _CurrentRoute: ActivatedRoute,
    private _ModalService: BsModalService,
    private _ReportService: ReportService,
    private _Router: Router
  ) {}

  /**
   * Used to store status of next step.
   */
  IsNextStepAvailable = false;

  /**
   * Busy loading indicator.
   */
  BusyLoading: boolean;

  /**
   * True if the case refresh is in progress.
   */
  IsRefreshing: boolean;

  /**
   * Case number.
   */
  get CaseNumber() {
    return this._ReportService.CaseNum;
  }

  /**
   * Case id.
   */
  get CaseId() {
    return this._ReportService.CaseId;
  }

  /**
   * Case summary.
   */
  CaseDetails: CaseSummary[];

  /**
   * Used to store next step status.
   */
  NextStepStatus = NextStepStatus;

  /**
   * Used to store work order status.
   */
  WorkOrderStatus: NextStepStatus;

  /**
   * Last refreshed date.
   */
  LastRefresh: Date;

  /**
   * Automatic refresh subscription.
   */
  private _RefreshSubscription: Subscription;

  /**
   * On init.
   */
  ngOnInit() {
    const params = this._CurrentRoute.snapshot.paramMap;
    this._ReportService.CaseNum = params.get('caseNumber');
    this._ReportService.CaseId = +params.get('caseId');

    this.BusyLoading = true;
    combineLatest([this._GetCaseSummary(), this._GetNextStep()])
      .pipe(finalize(() => (this.BusyLoading = false)))
      .subscribe(() => this._AutoRefresh());
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    if (this._RefreshSubscription) {
      this._RefreshSubscription.unsubscribe();
    }
  }

  /**
   * On refresh.
   */
  OnRefresh() {
    // todo (jbl): also update the auto refresh increment to prevent duplicate calls?
    this._GetCaseSummary().subscribe();
  }

  /**
   * Manually refresh the next step.
   */
  RefreshNextStep() {
    return this._GetNextStep();
  }

  /**
   * Open next step modal.
   */
  OpenNextStepModal() {
    const modalRef = this._ModalService.show(NextStepModalComponent, {
      initialState: {
        ReportService: this._ReportService,
      },
    });

    const component = modalRef.content as NextStepModalComponent;
    component.result
      .then(() => {
        this.RefreshNextStep()
          .subscribe(() => {
            this._Router.navigate(['prepare-order'], {
              relativeTo: this._ActivatedRoute,
            });
        });
      })
      .catch(() => {
        // empty
      });
  }

  /**
   * Get case summary data.
   */
  private _GetCaseSummary() {
    this.IsRefreshing = true;

    return this._CheckToHireSvc.GetCaseSummary(this.CaseNumber).pipe(
      tap((res) => {
        this.CaseDetails = res;
        this.LastRefresh = new Date();
      }),
      finalize(() => (this.IsRefreshing = false))
    );
  }

  /**
   * Get next step data.
   */
  private _GetNextStep() {
    return this._CheckToHireSvc.GetNextStep(this.CaseNumber).pipe(
      tap((res) => {
        if (res) {
          this.WorkOrderStatus =
            res.ID === 0
              ? NextStepStatus.NewWorkOrder
              : NextStepStatus.CreatedWorkOrder;
        } else {
          this.WorkOrderStatus = NextStepStatus.OlderWorkOrder;
        }

        this._ReportService.SetNextStep(res);
        this.IsNextStepAvailable =
          this.WorkOrderStatus === NextStepStatus.NewWorkOrder;
      })
    );
  }

  /**
   * Automatically get data in intervals.
   */
  private _AutoRefresh() {
    let count = 1;

    if (this._RefreshSubscription) {
      this._RefreshSubscription.unsubscribe();
    }

    if (!this._HasPending()) {
      return;
    }

    this._RefreshSubscription = of(true)
      .pipe(
        delayWhen(() => timer(this._GetDelay(count))),
        concatMap(() => this._GetCaseSummary()),
        tap(() => ++count),
        repeat()
      )
      .subscribe(() => {
        if (!this._HasPending()) {
          this._RefreshSubscription.unsubscribe();
        }
      });
  }

  /**
   * Checks if any incomplete
   */
  private _HasPending() {
    if (!this.CaseDetails) {
      // assume pending to until data is retrieved
      return true;
    }

    return this.CaseDetails.some((item) => item.Status !== 'Complete');
  }

  /**
   * Get auto refresh delay in milliseconds.
   */
  private _GetDelay(count: number) {
    const max = 120000; // 2m
    const min = 10000; // 10s
    let delay = Math.pow(2, count) * 1000;

    if (delay > max) {
      delay = max;
    } else if (delay < min) {
      delay = min;
    }

    return delay;
  }
}
