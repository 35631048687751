import { Injector } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import {
  endOfMonth,
  endOfYear,
  format,
  getDaysInMonth,
  isAfter,
  isBefore,
  startOfMonth,
  startOfYear,
} from 'date-fns';

import { DateService } from '../date/date.service';

/**
 * Validator options object.
 */
interface DateOptions {
  monthRequired?: boolean;
  dayRequired?: boolean;
  min?: Date;
  max?: Date;
  locale?: string;
}

/**
 * Validate date.
 */
export function DateValidator(options: DateOptions) {
  const { monthRequired, dayRequired, min, max, locale } = options;

  const injector = Injector.create({
    providers: [
      {
        provide: DateService,
        useClass: DateService,
      },
    ],
  });
  const service = injector.get(DateService);

  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    const { year, month, day } = service.fromIso(value);
    const hasYear = year != null;
    const hasMonth = month != null;
    const hasDay = day != null;

    if ((monthRequired && !hasMonth) || (dayRequired && !hasDay)) {
      return { required: true };
    }

    if (!hasYear && !hasMonth && !hasDay) {
      return null;
    }

    const errors: { [index: string]: any } = {};
    const maxDays =
      hasYear && hasMonth ? getDaysInMonth(new Date(year, month - 1)) : 31;

    if (hasMonth && (month < 1 || month > 12)) {
      errors.dateMonth = { min: 1, max: 12 };
    }

    if (hasDay && (day < 1 || day > maxDays)) {
      errors.dateDay = { min: 1, max: maxDays };
    }

    if (hasYear && year.toString().length !== 4) {
      errors.dateYear = true;
    }

    if (hasYear) {
      const monthIndex = hasMonth ? month - 1 : 0;
      const date = hasDay
        ? new Date(year, monthIndex, day)
        : new Date(year, monthIndex);

      if (min) {
        let targetMin: Date;

        if (hasDay) {
          targetMin = min;
        } else {
          targetMin = hasMonth ? startOfMonth(min) : startOfYear(min);
        }

        if (isBefore(date, targetMin)) {
          errors.dateMin = { min: formatDate(targetMin, locale) };
        }
      }

      if (max) {
        let targetMax: Date;

        if (hasDay) {
          targetMax = max;
        } else {
          targetMax = hasMonth ? endOfMonth(max) : endOfYear(max);
        }

        if (isAfter(date, targetMax)) {
          errors.dateMax = { max: formatDate(targetMax, locale) };
        }
      }
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };
}

/**
 * Formate date.
 *
 * @param date Source date.
 * @param locale target locale.
 */
function formatDate(date: Date, locale: string) {
  try {
    const formatter = new Intl.DateTimeFormat(locale);
    return formatter.format(date);
  } catch (error) {
    return format(date, 'P');
  }
}
