<section class="dashboard">
  <zc-sidebar>
    <zc-sidebar-body>
      <h1 class="header-1 text-green m-0">
        {{ 'CheckToHire.lblResults' | translate }}
      </h1>

      <!-- refresh -->
      <div class="d-flex justify-content-between align-items-center">
        <div>
          {{ 'CheckToHire.lblLastRefreshed' | translate }}
          {{ LastRefresh | timeFromNow }}
        </div>
        <zc-button
          color="link"
          [buttonClasses]="['btn-condensed']"
          [disabled]="IsRefreshing"
          (click)="OnRefresh()"
        >
          <fa-icon icon="redo" [spin]="IsRefreshing"></fa-icon>
        </zc-button>
      </div>
    </zc-sidebar-body>

    <!-- case leads -->
    <zc-timeline>
      <zc-timeline-item
        *ngFor="let case of CaseDetails"
        [state]="
          case.Status === 'Pending'
            ? 'pending'
            : case.Status === 'Complete'
            ? 'complete'
            : null
        "
        [link]="['/reports', CaseId, case.CaseNum, 'show-report']"
        [queryParams]="{ leadNum: case.LeadNum }"
      >
        <zc-timeline-item
          *ngFor="let item of case.Case.Leads"
          [state]="
            item.Status === 'Pending'
              ? 'pending'
              : item.Completed
              ? 'complete'
              : null
          "
        >
          <zc-timeline-title>{{ item.LeadType.TinyName }}</zc-timeline-title>
          <zc-timeline-caption>
            <ng-container *ngIf="item.IsBlocked">
              <fa-icon class="text-red" icon="exclamation-triangle"></fa-icon>
              {{ 'CheckToHire.lblActionRequired' | translate }}
            </ng-container>
            <ng-container *ngIf="!item.IsBlocked">
              {{ item.Status }}
            </ng-container>
          </zc-timeline-caption>
        </zc-timeline-item>
      </zc-timeline-item>
    </zc-timeline>

    <ng-container [ngSwitch]="WorkOrderStatus">
      <zc-timeline *ngSwitchCase="NextStepStatus.CreatedWorkOrder">
        <zc-timeline-item [link]="['prepare-order']">
          <zc-timeline-title>
            {{ 'CheckToHire.lblUnsubmitted' | translate }}
          </zc-timeline-title>
          <zc-timeline-caption>
            {{ 'CheckToHire.lblUnsubmitted' | translate }}
          </zc-timeline-caption>
        </zc-timeline-item>
      </zc-timeline>
      <div class="p-2" *ngSwitchCase="NextStepStatus.NewWorkOrder">
        <zc-button color="primary" block="true" (click)="OpenNextStepModal()">
          {{ 'CheckToHire.lblOrderAnotherReport' | translate }}
        </zc-button>
      </div>
    </ng-container>
  </zc-sidebar>

  <div class="w-100">
    <!-- summary -->
    <c2h-subject-summary [CaseNumber]="CaseNumber"></c2h-subject-summary>

    <div class="dashboard-container dashboard-container--constrained-sidebar">
      <!-- busy -->
      <zc-card title="&nbsp;" *ngIf="BusyLoading; else content">
        <zc-card-body>
          <div class="mb-4">
            <zc-skeleton
              [rows]="[[{ size: 3 }, { size: 3 }], [{ size: 5 }]]"
            ></zc-skeleton>
          </div>
          <zc-skeleton [rows]="[[{ size: 2 }], [{ size: 5 }]]"></zc-skeleton>
        </zc-card-body>
      </zc-card>

      <!-- content -->
      <ng-template #content>
        <router-outlet></router-outlet>
        <c2h-add-another *ngIf="IsNextStepAvailable"></c2h-add-another>
      </ng-template>
    </div>
  </div>
</section>
