import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CaseService, ModalService } from '@zipcrim/common';
import { Case, Lead } from '@zipcrim/common/models';
import max from 'date-fns/max';
import get from 'lodash/get';
import { finalize } from 'rxjs/operators';

import { CheckToHireSvcService } from '../common/check-to-hire-svc.service';

/**
 * Case list.
 *
 * ```html
 * <c2h-case-list [Cases]="MyCases"></c2h-case-list>
 * ```
 */
@Component({
  selector: 'c2h-case-list',
  templateUrl: './case-list.component.html',
})
export class CaseListComponent {
  constructor(
    private _Router: Router,
    private _Case: CaseService,
    private _Modal: ModalService,
    private _C2HService: CheckToHireSvcService
  ) {}

  /**
   * Collection of cases to display.
   */
  @Input()
  Cases: Case[];

  /**
   * Busy indicator.
   */
  @Input()
  Busy: boolean;

  /**
   * Indicated if toggle pinned is available.
   */
  @Input()
  CanTogglePinned: boolean;

  /**
   * Indicated if actions should be available.
   */
  @Input()
  HideActions: boolean;

  /**
   * Event emitted after pinned state is changed.
   */
  @Output()
  OnPinnedChange = new EventEmitter<Case>();

  /**
   * Event emitted after hidden state is changed.
   */
  @Output()
  OnHiddenChange = new EventEmitter<Case>();

  /**
   * Busy indicators for navigating to case details.
   */
  BusyNavigating: { [caseId: number]: boolean } = {};

  /**
   * Get full name for display.
   *
   * @param item Case record.
   */
  FullName(item: Case) {
    return get(item, 'Subject.Names[0].FullName', '-');
  }

  /**
   * Get ssn for display.
   *
   * @param item Case record.
   */
  Ssn(item: Case) {
    const value = get(item, 'Subject.Ssns[0].SSN');
    const lastFour = value ? value.slice(-4) : 'xxxx';

    return `xxx-xx-${lastFour}`;
  }

  /**
   * Get owner name for display.
   *
   * @param item Case record.
   */
  OwnerName(item: Case) {
    return get(item, 'Owner.Name.FullName', '-');
  }

  /**
   * Get last lead's tiny name for display.
   *
   * @param item Case record.
   */
  LastLeadTinyName(item: Case) {
    const length = item.Leads.length;

    return length > 0 ? item.Leads[length - 1].LeadType.TinyName : '-';
  }

  /**
   * Get complete date for display.
   *
   * @param item Case record.
   */
  Completed(item: Case) {
    const leadList = item.Leads;
    if (!leadList) {
      return '';
    }

    const completedLeadList = leadList.filter(
      (leadDetails) => leadDetails.Completed
    );

    if (completedLeadList.length === 0) {
      return '';
    }

    return max(completedLeadList.map((e) => new Date(e.Completed)));
  }

  /**
   * Navigate to a given case.
   *
   * @param item Case record.
   * @param lead Optional lead to include in route url.
   */
  Navigate(item: Case, lead?: Lead) {
    const id = item.ID;
    const caseNum = item.CaseNum;
    this.BusyNavigating[id] = true;

    this._C2HService
      .GetCaseSummary(caseNum)
      .pipe(finalize(() => (this.BusyNavigating[id] = false)))
      .subscribe((res) => {
        const index = this._C2HService.FindCaseSummaryIndex(res, lead?.LeadNum);
        const leadNum = res[index].LeadNum;

        this._Router
          .navigate(['reports', id, caseNum, 'show-report'], {
            queryParams: { leadNum },
          })
          .then((value) => {
            if (value) {
              return;
            }

            this._Modal.confirm({
              titleL10nKey: 'Common.lblAccessDenied',
              messageL10nKey: 'CheckToHire.msgUnauthorizedToAccessCase',
            });
          });
      });
  }

  /**
   * Navigate to the last completed lead.
   *
   * @param item Case record.
   */
  NavigateToLastCompleted(item: Case) {
    const tinyName = this.LastLeadTinyName(item);
    const items = item.Leads;

    if (items.length === 0) {
      return;
    }

    const lead = items.find((x) => x.LeadType.TinyName === tinyName);
    this.Navigate(item, lead);
  }

  /**
   * Toggle pinned.
   *
   * @param item Case record.
   */
  TogglePinned(item: Case) {
    const caseNum = item.CaseNum;
    const isPinned = !item.IsPinned;

    item.IsPinned = isPinned;

    this._Case
      .togglePin(caseNum, isPinned)
      .subscribe(() => this.OnPinnedChange.emit(item));
  }

  /**
   * prompt user and toggle hidden.
   *
   * @param item Case record.
   */
  ToggleHiddenWithConfirm(item: Case) {
    this._Modal
      .confirm({
        messageL10nKey: 'Common.lblAreYouSureArchive',
      })
      .then(
        () => {
          this._ToggleHidden(item);
        },
        () => {
          // empty
        }
      );
  }

  /**
   * Toggle hidden.
   *
   * @param item Case record.
   */
  private _ToggleHidden(item: Case) {
    const caseNum = item.CaseNum;
    const isHidden = !item.IsHidden;

    item.IsHidden = isHidden;

    this._Case
      .toggleHide(caseNum, isHidden)
      .subscribe(() => this.OnHiddenChange.emit(item));
  }
}
