import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { MediaQueryService } from '../media-query/media-query.service';
import { SidebarState } from './sidebar.interfaces';
import { SidebarService } from './sidebar.service';

/**
 * Mobile responsive sidebar.
 *
 * ```html
 * <zc-sidebar>
 *   <zc-sidebar-body>
 *     <h1>Hello World!</h1>
 *   <zc-sidebar-body>
 * </zc-sidebar>
 *
 * <zc-button zcSidebarToggle>
 *   Toggle Sidebar!
 * </zc-button>
 * ```
 */
@Component({
  selector: 'zc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  constructor(
    private elementRef: ElementRef,
    private mediaQuery: MediaQueryService,
    private sidebar: SidebarService
  ) {
    this.stateChange = this._stateChange.asObservable();
  }

  @Input() sidebarId = this.sidebar.defaultSidebarId;
  stateChange: Observable<SidebarState>;

  get isOpen() {
    return this._isOpen;
  }

  get isFixed() {
    return this._isFixed;
  }

  private _isOpen = true;

  private _isFixed = true;
  private _stateChange = new ReplaySubject<SidebarState>(1);

  toggle() {
    this.setOpen(!this._isOpen);
  }

  open() {
    this.setOpen(true);
  }

  close() {
    this.setOpen(false);
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:orientationchange', ['$event'])
  onResize() {
    const isMdOrLarger = this.mediaQuery.isSizeOrLarger('md');

    // on larger screens, the sidebar is open and can not be closed
    this._isOpen = isMdOrLarger;
    this._isFixed = isMdOrLarger;
    this.emitState();
  }

  ngOnInit() {
    this.sidebar.register(this.sidebarId, this);
    this.onResize();
  }

  ngOnDestroy() {
    this.sidebar.unregister(this.sidebarId);
  }

  private setOpen(isOpen: boolean) {
    if (this._isFixed) {
      return;
    }

    this._isOpen = isOpen;
    this.emitState();

    if (this._isOpen) {
      setTimeout(() => {
        const selectors = [
          'button',
          '[href]',
          'input',
          'select',
          'textarea',
          '[tabindex]:not([tabindex="-1"]):not(.cdk-focus-trap-anchor)',
        ];
        const elm = this.elementRef.nativeElement;
        const target = elm.querySelector(selectors.join(','));

        if (target) {
          target.focus();
        }
      });
    }
  }

  private emitState() {
    this._stateChange.next({
      isOpen: this._isOpen,
      isFixed: this._isFixed,
    });
  }
}
