import { Component } from '@angular/core';

/**
 * Timeline service.
 *
 * ```html
 * <zc-timeline>
 *   <zc-timeline-item>
 *     <span>Step 1</span>
 *   <zc-timeline-item>
 *   <zc-timeline-item>
 *     <span>Step 2</span>
 *   <zc-timeline-item>
 * </zc-timeline>
 * ```
 */
@Component({
  selector: 'zc-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent {}
