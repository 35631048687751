import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[zcFocusInvalidInput]',
})
export class FocusInvalidInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('submit')
  onFormSubmit() {
    const invalidEls = this.el.nativeElement.querySelectorAll('.ng-invalid');

    if (!invalidEls || invalidEls.length <= 0) {
      return;
    }

    for (const invalid of invalidEls) {
      if (!invalid.localName || !invalid.localName.includes('zc-')) {
        continue;
      }

      let elementFound = false;
      let evaluatedElement = invalid;
      do {
        try {
          evaluatedElement.focus();
        } catch {
          // If not able to focus an element is normally because it is null so we break the loop
          elementFound = true;
        }

        if (elementFound) {
          return;
        }

        elementFound =
          document.activeElement.id.length > 0 &&
          document.activeElement.id.includes(evaluatedElement.id);

        if (evaluatedElement.name && !elementFound) {
          // For radio group elements works different since no id assigned
          elementFound = (evaluatedElement.name as string).includes(
            'radio-group'
          );
        }

        if (evaluatedElement.type && !elementFound) {
          // For button elements like calendars works different since no id assigned
          elementFound = (evaluatedElement.type as string).includes('button');
        }

        if (!elementFound) {
          evaluatedElement = evaluatedElement.firstElementChild;
        }
      } while (!elementFound);

      break;
    }
  }
}
