import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'zc-aria-label',
  templateUrl: './aria-label.component.html',
  styleUrls: ['./aria-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AriaLabelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
