<input
  *ngIf="!hasMask"
  autocomplete="off"
  class="input"
  [ngClass]="'input--width-' + width"
  (blur)="onBlur()"
  (focus)="onFocus()"
  [(ngModel)]="value"
  [attr.aria-describedby]="id + '__errors'"
  [attr.aria-required]="required"
  [attr.id]="id"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [type]="type"
/>

<input
  *ngIf="hasMask"
  autocomplete="off"
  class="input"
  [ngClass]="'input--width-' + width"
  (blur)="onBlur()"
  (focus)="onFocus()"
  [(ngModel)]="value"
  [attr.aria-describedby]="id + '__errors'"
  [attr.aria-required]="required"
  [attr.id]="id"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [type]="type"
  [mask]="maskPattern"
/>
