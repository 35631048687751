<div class="py-2">
  <h1 class="header-1 text-center text-green">
    {{ 'CheckToHire.lblSignupTasks' | translate }}
  </h1>
  <div class="container">
    <!-- jobs -->
    <zc-job-tree
      #tree
      [busy]="BusyLoading"
      [jobs]="Jobs"
      (jobsChange)="GetJobs()"
    >
    </zc-job-tree>

    <!-- empty -->
    <zc-empty
      *ngIf="tree.isEmpty"
      file="img/graphic-trusted.png"
      titleL10nKey="CheckToHire.msgAllTaskCompleted"
    >
      <div class="mt-1">
        <a routerLink="/dashboard" class="btn btn-primary">
          {{ 'CheckToHire.lblDashboard' | translate }}
        </a>
      </div>
    </zc-empty>
  </div>
</div>
