import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  FileService,
  IAssociatedObject,
  IJob,
  ISignature,
} from '@zipcrim/common';
import { Job } from '@zipcrim/common/models';
import { JobModal, JobService } from '@zipcrim/work-flow';
import { BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

import { DocumentPage } from '../document-signing.interfaces';
import { Document } from '../models';
import { SignatureModalComponent } from '../signature-modal/signature-modal.component';

@Component({
  selector: 'zc-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent extends JobModal implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private jobService: JobService,
    private bsModal: BsModalService,
    private router: Router,
    private fileService: FileService
  ) {
    super();
  }

  /**
   * Busy indicator.
   */
  busy: boolean;

  /**
   * Used to store list of documents
   */
  availableDocuments: Document[] = null;

  /**
   * Used to store job id
   */
  childJobId: number;

  /**
   * Used to store blob from currently working page
   */
  currentlyWorkingPage: DocumentPage;

  /**
   * Used to store blob from currently working page
   */
  currentlyWorkingDocumentIndex = 0;

  /**
   * Used to store available document list
   */
  documentList: IJob[] = [];

  /**
   * used to store the status of page
   */
  isPageAvailable = false;

  /**
   * Used to store authorization type either password or pin
   */
  authorizationType = '';

  /**
   * Used to store currently working page index
   */
  currentlyWorkingPageIndex = 0;

  /**
   * Used to store first page number
   */
  nextPageIndex = 1;

  /**
   * Used to store second page number
   */
  prevPageIndex = 0;

  /**
   * Used to store total page count
   */
  totalPageCount = 0;

  /**
   * Current signature data
   */
  currentSignatureBase64 = '';

  /**
   * Is manually previous document loaded
   */
  isPreviousDocumentLoaded = true;

  /**
   * On init.
   */
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.childJobId = +params.jobId;
      this.getListOfAvailableDocumentByChildId(this.childJobId);
    });
  }

  /**
   * Indicates if the current document is ready for display.
   */
  isCurrentReady() {
    if (this.currentlyWorkingPage) {
      if (this.currentlyWorkingPage.PageImage) {
        return this.isPageAvailable && this.currentlyWorkingPage.PageImage;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Indicates if a collection of document pages is ready for display.
   *
   * @param pages Document pages.
   */
  isPagesReady(pages: DocumentPage[]) {
    if (!pages || !pages.length) {
      return false;
    }

    return pages.every((item) => !!item.PageImage);
  }

  /**
   * Used to load document page
   */
  getDocumentDetailsById(jobId: number, index?: number) {
    this.isPageAvailable = false;

    this.jobService.getForm4Signing(jobId).subscribe((res) => {
      const document = new Document(res.payload);

      if (document.PageCount) {
        for (let i = 0; i < document.PageCount; i++) {
          const pageObject: DocumentPage = {
            index: i + 1,
            PageImage: null,
            SignatureStatus: false,
            IsSignatureAvailable: false,
            SignatureInfo: null,
            PageVisited: false,
          };
          const signatureInfo: ISignature[] = document.Signatures.Items.filter(
            (signature) => signature.Page === i + 1
          );
          if (signatureInfo.length !== 0) {
            pageObject.SignatureStatus = false;
            pageObject.IsSignatureAvailable = true;
            pageObject.SignatureInfo = signatureInfo[0];
            document.Pages.push(pageObject);
          } else {
            pageObject.SignatureStatus = true;
            pageObject.IsSignatureAvailable = false;
            document.Pages.push(pageObject);
          }
        }

        document.Pages.forEach((page: DocumentPage) => {
          this.loadPageImage(document.ID, document.UpdateVersion, page, jobId);
        });
        document.JobId = jobId;
        this.availableDocuments[index] = document;
        this.availableDocuments[index].IsOpen = true;
        if (this.availableDocuments[index]) {
          if (this.availableDocuments[index].PageCount) {
            this.totalPageCount = this.availableDocuments[index].PageCount;
          }
        }
        this.currentlyWorkingPage = this.availableDocuments[index].Pages[0];
        this.isPageAvailable = true;
        this.setPageVisited(index, 0);
      }
    });
  }

  /**
   * @param documentId used to store document id
   * @param updateVersion used to store update version
   * @param page used to store object of type IPage
   * @param jobId used to store jobId
   */
  loadPageImage(
    documentId: number,
    updateVersion: number,
    page: DocumentPage,
    jobId: number
  ) {
    const url = `form/${documentId}/renderedpage/${page.index}`;
    const params = new HttpParams()
      .append('version', updateVersion.toString())
      .append('jobid', jobId.toString());

    this.fileService.download(url, params).subscribe((res) => {
      page.PageImage = this.domSanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(res.payload.Data)
      );
    });
  }

  /**
   * Used to get object of currently working page.
   *
   * @param documentIndex Used to store current document index
   * @param pageIndex used to store current page index
   */
  getCurrentWorkingPage(
    documentIndex: number,
    pageIndex: number,
    document: Document,
    event?: Event
  ) {
    if (event) {
      event.stopPropagation();
      return;
    }
    if (document.Status !== 'Complete') {
      const nextPageIndex: number = this.currentlyWorkingPageIndex + 1;
      const prevPageIndex: number = this.currentlyWorkingPageIndex - 1;
      let pageVisited = false;
      if (this.availableDocuments[documentIndex].Pages.length !== 0) {
        pageVisited =
          this.availableDocuments[documentIndex].Pages[pageIndex].PageVisited;
      }
      if (
        this.currentlyWorkingPageIndex === pageIndex ||
        nextPageIndex === pageIndex ||
        prevPageIndex === pageIndex ||
        pageVisited
      ) {
        this.currentlyWorkingDocumentIndex = documentIndex;
        this.currentlyWorkingPageIndex = pageIndex;
        this.setNextNadPrevPageIndex(this.currentlyWorkingPageIndex);

        this.isPageAvailable = false;
        const docObject = this.availableDocuments.find(
          (x) => x.JobId === document.JobId
        );
        if (docObject) {
          if (docObject.Pages.length === 0) {
            this.getDocumentDetailsById(document.JobId, documentIndex);
          } else {
            this.currentlyWorkingPage =
              this.availableDocuments[documentIndex].Pages[pageIndex];
            this.setPageVisited(documentIndex, pageIndex);
            // AvailableDocuments[documentIndex].Pages[pageIndex].PageVisited = true;
            this.totalPageCount =
              this.availableDocuments[documentIndex].PageCount;
            this.isPageAvailable = true;
          }
        }
      }
    }
  }

  /**
   * Used to navigate Next page
   */
  navigateNext() {
    this.setPageVisited(
      this.currentlyWorkingDocumentIndex,
      this.currentlyWorkingPageIndex
    );
    this.currentlyWorkingPageIndex++;
    this.setNextNadPrevPageIndex(this.currentlyWorkingPageIndex);
    this.currentlyWorkingPage =
      this.availableDocuments[this.currentlyWorkingDocumentIndex].Pages[
        this.currentlyWorkingPageIndex
      ];
  }

  /**
   * Used to navigate previous page
   */
  navigatePrevious() {
    this.setPageVisited(
      this.currentlyWorkingDocumentIndex,
      this.currentlyWorkingPageIndex
    );
    this.currentlyWorkingPageIndex--;
    this.setNextNadPrevPageIndex(this.currentlyWorkingPageIndex);
    this.currentlyWorkingPage =
      this.availableDocuments[this.currentlyWorkingDocumentIndex].Pages[
        this.currentlyWorkingPageIndex
      ];
  }

  /**
   * Used to get object of currently working page.
   *
   * @param documentIndex Used to store current document index
   * @param pageIndex used to store current page index
   */
  loadInitialPage() {
    const document: Document = this.availableDocuments.find(
      (x) => x.Status === 'Incomplete'
    );

    if (this.childJobId === document.JobId) {
      if (document) {
        const docIndex: number = this.availableDocuments.findIndex(
          (x) => x === document
        );
        if (docIndex !== -1) {
          this.getDocumentDetailsById(document.JobId, docIndex);
        }
      } else {
        if (this.availableDocuments.length !== 0) {
          this.getDocumentDetailsById(this.availableDocuments[0].JobId, 0);
        }
      }
    } else {
      this.router.navigate(['/documents', document.JobId]);
    }
  }

  /**
   * Used to open signature modal
   */
  sign(pageDetails: DocumentPage) {
    const currentDocObj: Document =
      this.availableDocuments[this.currentlyWorkingDocumentIndex];

    if (currentDocObj.SignatureOnFile) {
      if (currentDocObj.SignatureOnFile.Data) {
        this.currentSignatureBase64 = currentDocObj.SignatureOnFile.Data;
      } else {
        this.currentSignatureBase64 = '';
      }
    } else {
      this.currentSignatureBase64 = '';
    }

    if (pageDetails) {
      this.authorizationType = pageDetails.SignatureInfo.AuthorizationType;
    }

    this.showModal();
  }

  /**
   * Method used to show modal
   */
  showModal() {
    const modalRef = this.bsModal.show(SignatureModalComponent, {
      ignoreBackdropClick: true,
    });

    const content: SignatureModalComponent = modalRef.content;
    const document =
      this.availableDocuments[this.currentlyWorkingDocumentIndex];
    const signatureInfo = document.Signatures.Items.find(
      (item) => item.Page === this.currentlyWorkingPage.index
    );

    content.authorizationType = this.authorizationType;
    content.previousSignature = this.currentSignatureBase64;
    content.document = document;
    content.signatureInfo = signatureInfo;
    content.onComplete.subscribe(() => {
      const page = document.Pages[this.currentlyWorkingPageIndex];

      if (page) {
        page.SignatureStatus = true;
      }

      this.setDocumentComplete(document);
      this.navigateToNext();
    });
  }

  /**
   * Used to load parent document list from child job id.
   *
   * @param childJobId used to store child job id
   */
  getListOfAvailableDocumentByChildId(childJobId: number) {
    this.jobService.getJobParents(childJobId).subscribe((jobs) => {
      this.availableDocuments = [];

      if (jobs) {
        this.documentList = jobs.filter((documentTypeJob: IJob) => {
          const job = new Job(documentTypeJob);
          return (
            documentTypeJob.UiClassName === 'eSignFormClient' &&
            documentTypeJob.Status !== 'Blocked' &&
            job.CanBeCompleted
          );
        });

        this.documentList.forEach((element, index) => {
          const documentObject = new Document({
            ID: 0,
            IsOpen: false,
            JobId: element.ID,
            Name: element.DescriptionBlurb,
            PageCount: 0,
            Version: '',
            TemplateID: 0,
            UpdateVersion: 0,
            Status: element.Status,
          });

          if (index === this.documentList.length - 1) {
            this.availableDocuments.push(new Document(documentObject));
            this.loadInitialPage();
          } else {
            this.availableDocuments.push(new Document(documentObject));
          }
        });
      }
    });
  }

  /**
   * Used to get status of accordion (open / closed).
   *
   * @param eventData used to store the status of accordion is it open or not
   */
  isOpenChange(eventData: boolean, docIndex: number, document: Document) {
    if (document.Status !== 'Complete') {
      if (eventData) {
        // todo: here we are checking whether page image is already in object or not
        if (document.Pages.find((x) => x.PageImage == null)) {
          document.Pages.forEach(() => {
            this.getCurrentWorkingPage(docIndex, 0, document);
            //  todo: need to check in all scenarios
            // this.LoadPageImage(document.ID, document.UpdateVersion, page, document.JobId);
          });
        } else {
          this.isPageAvailable = true;
        }
      } else {
        const documentIndex = this.availableDocuments.findIndex(
          (x) => x === document
        );
        if (documentIndex !== -1) {
          this.availableDocuments[documentIndex].IsOpen = eventData;
        }
        this.isPageAvailable = false;
      }
    }
  }

  /**
   * used to change job object
   */
  reloadJobs() {
    this.documentList = [];
    this.availableDocuments = null;
    this.isPageAvailable = false;
    this.nextPageIndex = 0;
    this.prevPageIndex = 0;
    this.totalPageCount = 0;
    this.currentlyWorkingPageIndex = 0;
  }

  /**
   * Used to set previous page index and next page index.
   *
   * @param currentlyWorkingPageIndex used to store currently page Index.
   */
  setNextNadPrevPageIndex(currentlyWorkingPageIndex: number) {
    this.nextPageIndex = currentlyWorkingPageIndex + 1;
    this.prevPageIndex = currentlyWorkingPageIndex - 1;
  }

  /**
   * Used to navigate a user to last page of previous document.
   *
   * @param currentlyWorkingDocumentIndex Used to store currently working document index.
   */
  navigateLastPageOfPreviousDoc(currentlyWorkingDocumentIndex: number) {
    if (currentlyWorkingDocumentIndex !== 0) {
      const destinationDocumentIndex: number =
        currentlyWorkingDocumentIndex - 1;
      if (this.availableDocuments[destinationDocumentIndex]) {
        this.availableDocuments[currentlyWorkingDocumentIndex].IsOpen = false;
        this.availableDocuments[destinationDocumentIndex].IsOpen = true;
        const pageIndex =
          this.availableDocuments[destinationDocumentIndex].PageCount - 1;
        this.currentlyWorkingPageIndex = pageIndex;
        this.getCurrentWorkingPage(
          destinationDocumentIndex,
          pageIndex,
          this.availableDocuments[destinationDocumentIndex]
        );
        this.isPreviousDocumentLoaded = true;
      }
    }
  }

  /**
   * Used to navigate a user to first page of next document.
   *
   * @param currentlyWorkingDocumentIndex Used to store currently working document index.
   */
  navigateFirstPageOfNextDoc(currentlyWorkingDocumentIndex: number) {
    if (currentlyWorkingDocumentIndex !== this.availableDocuments.length - 1) {
      const destinationDocumentIndex: number =
        currentlyWorkingDocumentIndex + 1;
      if (this.availableDocuments[destinationDocumentIndex]) {
        this.availableDocuments[currentlyWorkingDocumentIndex].IsOpen = false;
        this.availableDocuments[destinationDocumentIndex].IsOpen = true;
        this.currentlyWorkingPageIndex = 0;
        this.getCurrentWorkingPage(
          destinationDocumentIndex,
          0,
          this.availableDocuments[destinationDocumentIndex]
        );
      }
    }
  }

  /**
   * Used to mark page from document as visited.
   *
   * @param documentIndex Used to store currently working document index.
   * @param pageIndex Used to store page index.
   */
  setPageVisited(documentIndex: number, pageIndex: number) {
    const document = this.availableDocuments[documentIndex];
    if (document) {
      if (document.Pages.length !== 0) {
        const pageVisited = document.Pages[pageIndex].PageVisited;
        if (!pageVisited) {
          document.Pages[pageIndex].PageVisited = true;
        }
      }
    }
  }

  /**
   * Used to download document.
   */
  download(documentDetails: Document) {
    const targetDocument: IJob = this.documentList.find(
      (document) => document.ID === documentDetails.JobId
    );
    if (targetDocument) {
      const associatedObjectList: IAssociatedObject[] =
        targetDocument.AssociatedObjects;
      const targetFormObject: IAssociatedObject = associatedObjectList.find(
        (object) => object.Class === 'Form'
      );
      if (targetFormObject) {
        const url = `form/${targetFormObject.ID}/renderedpdf`;
        const params = new HttpParams().append(
          'jobid',
          documentDetails.JobId.toString()
        );

        this.busy = true;
        this.fileService
          .download(url, params, true)
          .pipe(finalize(() => (this.busy = false)))
          .subscribe();
      }
    }
  }

  /**
   * Update document job status to complete.
   */
  private setDocumentComplete(document: Document) {
    const job = this.documentList.find((item) => item.ID === document.JobId);

    if (job) {
      job.Status = 'Complete';
    }
  }

  /**
   * Navigate to next available document or onboarding.
   */
  private navigateToNext() {
    const incomplete = this.documentList.find((x) => x.Status === 'Incomplete');
    if (incomplete) {
      this.reloadJobs();
      this.router.navigate(['/documents', incomplete.ID]);
    } else {
      this.router.navigate(['/onboard']);
    }
  }
}
