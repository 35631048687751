import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { IJob } from '@zipcrim/common';
import { Job } from '@zipcrim/common/models';
import isFunction from 'lodash/isFunction';
import { BsModalService } from 'ngx-bootstrap/modal';

import { JobModal } from '../job-modal';
import { WorkFlowModuleConfig } from '../work-flow.interface';
import { JobTreeService } from './job-tree.service';

@Component({
  selector: 'zc-job-tree',
  templateUrl: './job-tree.component.html',
  styleUrls: ['./job-tree.component.scss'],
})
/**
 * Dynamically display a work flow job hierarchy.
 *
 * ```html
 * <zc-job-tree [jobs]="myJobs" [jobsChange]="refreshJobs()"></zc-job-tree>
 * ```
 */
export class JobTreeComponent implements OnChanges {
  constructor(
    private job: JobTreeService,
    private bsModal: BsModalService,
    private router: Router,
    @Inject('workFlowConfig') private workFlowConfig: WorkFlowModuleConfig
  ) {}

  /**
   * Busy indicator.
   */
  @Input()
  busy: boolean;

  /**
   * Work flow jobs source objects.
   */
  @Input()
  jobs: IJob[];

  /**
   * True to enable the ability to toggle dependencies view.
   */
  @Input()
  enableAccordion = false;

  /**
   * Event emitted when any job has changed.
   */
  @Output()
  jobsChange = new EventEmitter<void>();

  /**
   * First level jobs to display.
   */
  jobSummaries: Job[] = [];

  /**
   * Hash table of JobID and toggle state.
   */
  toggleState: { [key: number]: boolean } = {};

  /**
   * Indicates if no jobs are available.
   */
  isEmpty = true;

  /**
   * True if the table has data.
   */
  private get hasData() {
    return this.jobs && this.jobs.length > 0;
  }

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobs) {
      // data changed, not waiting on busy
      this.isEmpty = !this.hasData;
    }

    if (changes.jobs && changes.jobs.currentValue) {
      this.hydrateJobs();
    }
  }

  /**
   * Toggle the view of dependencies for a given job.
   *
   * @param job Job to toggle dependencies for.
   */
  onToggleDependencies(job: Job) {
    if (!this.enableAccordion) {
      return;
    }

    this.toggleState[job.ID] = !this.toggleState[job.ID];
  }

  /**
   * Get the current toggle state for a given job.
   *
   * @param job Job to check state for.
   */
  getToggleState(job: Job) {
    if (!this.enableAccordion) {
      return true;
    }

    return !!this.toggleState[job.ID];
  }

  /**
   * Execute a job's configured action.
   *
   * @param job Select job to execute action for.
   */
  onJobAction(job: Job) {
    const name = job.UiClassName;
    let action = this.workFlowConfig.actions[name];

    if (!action) {
      throw new Error(
        `No work flow action found for "${name}". Did you add it to WorkFlowModule.forRoot()?.`
      );
    }

    if (isFunction(action)) {
      action = action(job);
    }

    switch (action.type) {
      case 'route':
        this.router.navigate(action.commands, action.extras);
        break;
      case 'modal':
        const modalRef = this.bsModal.show(action.component);
        const content: JobModal = modalRef.content;
        content.job = job;
        content.jobChange.subscribe(() => {
          this.jobsChange.next();
        });
        content.onJobAvailable();
        break;
      default:
        throw new Error(`Invalid work flow action for "${name}"!`);
    }
  }

  /**
   * Hydrate available dependencies for all root level jobs.
   */
  private hydrateJobs() {
    const roots = this.job.GetIndependentJobs(this.jobs);
    const jobs: Job[] = [];

    roots.forEach((job) => {
      const dependencies = this.job.GetJobDependencies(this.jobs, job);
      jobs.push(...dependencies);
    });

    this.jobSummaries = jobs;
  }
}
