import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
} from '@angular/core';

import { TimelineDotState } from '../timeline/timeline.interfaces';
import { StepComponent } from './step.component';

/**
 * Stepper component.
 *
 * ```html
 * <form (ngSubmit)="OnSubmit()>
 *   <zc-stepper>
 *     <zc-step>
 *       <zc-input [(ngModel)]="example" name="example"></zc-input>
 *       <zc-button zcStepperNext>Next</zc-button>
 *     </zc-step>
 *     <zc-step>
 *       <zc-button zcStepperPrevious>Previous</zc-button>
 *     </zc-step>
 *   </zc-stepper>
 * </form>
 * ```
 */
@Component({
  selector: 'zc-stepper',
  templateUrl: './stepper.component.html',
})
export class StepperComponent implements AfterContentInit {
  @ContentChildren(StepComponent)
  steps: QueryList<StepComponent>;

  activeStep: StepComponent;

  ngAfterContentInit() {
    this.activeStep = this.steps.first;
    this.syncStepStates();
  }

  next() {
    const steps = this.steps.toArray();
    const index = steps.findIndex((x) => x === this.activeStep);
    const target = index + 1;

    if (target < steps.length) {
      this.activeStep = steps[target];
      this.syncStepStates();
    }
  }

  previous() {
    const steps = this.steps.toArray();
    const index = steps.findIndex((x) => x === this.activeStep);
    const target = index - 1;

    if (target >= 0) {
      this.activeStep = steps[target];
      this.syncStepStates();
    }
  }

  private syncStepStates() {
    const steps = this.steps.toArray();
    const activeIndex = steps.findIndex((x) => x === this.activeStep);

    steps.forEach((step, index) => {
      let state: TimelineDotState;

      if (step === this.activeStep) {
        state = 'active';
      } else if (index < activeIndex) {
        state = 'complete';
      }

      step.state = state;
    });
  }
}
