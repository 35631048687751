import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches a valid US zip code.
 */
const US_ZIP_CODE_REGEX = /^[0-9]{1,5}$/i;

/**
 * Matches a valid CA zip code.
 */
const CA_ZIP_CODE_REGEX = /^[a-zA-Z0-9]{6}$/i;

/**
 * Matches a general valid zip code.
 */
const ZIP_CODE_REGEX = /^[a-zA-Z0-9]{1,10}$/i;

/**
 * Matches a India valid zip code.
 */
const IN_ZIP_CODE_REGEX = /^[0-9]{6}$/i;

/**
 * Validate a zip code.
 */
export function ZipCodeValidator(country: string) {
  let regex: RegExp;

  switch (country) {
    case 'CA':
      regex = CA_ZIP_CODE_REGEX;
      break;
    case 'US':
      regex = US_ZIP_CODE_REGEX;
      break;
    case 'IN':
      regex = IN_ZIP_CODE_REGEX;
      break;
    default:
      regex = ZIP_CODE_REGEX;
      break;
  }

  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    if (!value || regex.test(value)) {
      return null;
    }

    return {
      zipCode: true,
    };
  };
}
