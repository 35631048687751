import {
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  NgForm,
} from '@angular/forms';
import { startWith, tap } from 'rxjs/operators';

import { ERROR_MESSAGES } from './error-messages';
import { DynamicErrorComponent } from './error.component';

/**
 * Form field error messages.
 *
 * ```html
 * <form>
 *   <zc-input formControlName="FirstName"></zc-input>
 *   <zc-errors ControlName="FirstName"></zc-errors>
 * </form>
 * ```
 */
@Component({
  selector: 'zc-dynamic-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss'],
})
export class DynamicErrorsComponent implements OnInit {
  constructor(
    private _ControlContainer: ControlContainer,
    private _NgForm: NgForm
  ) {}

  /**
   * Associated control name.
   */
  @Input()
  ControlName: string;

  /**
   * Associated control reference.
   */
  @Input()
  Control: AbstractControl;

  /**
   * Child error components.
   */
  @ContentChildren(DynamicErrorComponent)
  Children: QueryList<DynamicErrorComponent>;

  /**
   * Collection of active error messages.
   */
  Errors: { message: string; params?: Record<string, any> }[] = [];

  /**
   * Indicates if errors should be visible.
   */
  get ShowErrors() {
    return (
      (this.Errors.length > 0 || this.Children.length > 0) &&
      (this.IsTouched || this._NgForm.submitted)
    );
  }

  /**
   * Indicates if the control of whole group is touched.
   */
  get IsTouched() {
    if (this.Control instanceof FormGroup) {
      // when showing errors for a group, wait for all controls to be touched
      return Object.values(this.Control.controls)
        .filter((x) => x.enabled)
        .every((x) => x.touched);
    }

    return this.Control.touched;
  }

  /**
   * On init.
   */
  ngOnInit() {
    if (!this.Control && this.ControlName) {
      this.Control = this._ControlContainer.control.get(this.ControlName);
    }

    this.Control.statusChanges
      .pipe(
        startWith([]),
        tap(() => this._SetErrors())
      )
      .subscribe();
  }

  /**
   * Set error messages based on control errors.
   */
  private _SetErrors() {
    const errors = this.Control.errors;

    if (!errors) {
      this.Errors = [];
      return;
    }

    this.Errors = Object.keys(errors).map((key) => {
      const params = this.Control.errors[key];
      const message = ERROR_MESSAGES[key];

      return {
        message,
        params,
      };
    });
  }
}
