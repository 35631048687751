import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatchControlsValidator } from '@zipcrim/forms/validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

import { JobModal } from '../job-modal';
import { JobService } from '../job.service';

/**
 * Modal for verify email job.
 */
@Component({
  templateUrl: './who-sign-modal.component.html',
})
export class WhoSignModalComponent extends JobModal {
  constructor(
    private fb: FormBuilder,
    private jobService: JobService,
    private bsModalRef: BsModalRef
  ) {
    super();
  }

  /**
   * Authorization form reference.
   */
  authorizationForm = this.fb.group({
    AuthorizationOption: this.fb.control('authorized', [Validators.required]),
  });

  /**
   * Indicates currently working form.
   */
  currentlyWorkingForm = 'VerifyEmail';

  /**
   * Indicates the user form status.
   */
  isUnauthorizedFormEnable = false;

  /**
   * Indicates if invitation has been sent.
   */
  isInvitationSent = false;

  /**
   * Unauthorized form reference.
   */
  unauthorizedUserForm = this.fb.group({
    FirstName: this.fb.control(null, [
      Validators.pattern(/^[a-zA-Z'-.\s]*$/),
      Validators.required,
      Validators.maxLength(100),
    ]),
    LastName: this.fb.control(null, [
      Validators.required,
      Validators.pattern(/^[a-zA-Z'-.\s]*$/),
      Validators.maxLength(100),
    ]),
    Title: this.fb.control(null, [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9'-.\s]*$/),
      Validators.maxLength(100),
    ]),
    Email: this.fb.control(null, [Validators.required, Validators.email]),
    ConfirmEmail: this.fb.control(null, [
      Validators.required,
      Validators.email,
      MatchControlsValidator(['Email']),
    ]),
  });

  /**
   * Used to store New Email.
   */
  newEmail: string;

  /**
   * Busy indicator.
   */
  busy: boolean;

  authOptions = [
    {
      value: 'authorized',
      labelL10nKey: 'Common.lblAuthorizedPersonToSign',
    },
    {
      value: 'unauthorized',
      labelL10nKey: 'Common.lblUnauthorizedPersonToSign',
    },
  ];

  /**
   * On job cancel.
   */
  onCancel() {
    this.bsModalRef.hide();
  }

  /**
   * On invitation send successfully.
   */
  onOk() {
    this.jobEmitter.next(this.job);
    this.bsModalRef.hide();
  }

  /**
   * Used to call api or change form on click of next
   */
  next(isUnauthorizedForm = false) {
    if (!isUnauthorizedForm) {
      const authorizationOption =
        this.authorizationForm.value.AuthorizationOption;

      if (authorizationOption === 'unauthorized') {
        this.isUnauthorizedFormEnable = !this.isUnauthorizedFormEnable;
      } else if (authorizationOption === 'authorized') {
        this.busy = true;
        this.jobService
          .assignContact(this.job.ID)
          .pipe(finalize(() => (this.busy = false)))
          .subscribe(() => {
            // need to check property in deep but still property is not confirmed so its temporary
            this.jobEmitter.next(this.job);
            this.bsModalRef.hide();
          });
      }
    } else {
      const { FirstName, LastName, Email, Title } =
        this.unauthorizedUserForm.value;
      this.newEmail = Email;
      const contact = {
        FirstName,
        LastName,
        Email,
        AuthCode: 'Success',
        Title,
      };

      this.busy = true;
      this.jobService
        .assignNewContact(this.job.ID, contact)
        .pipe(finalize(() => (this.busy = false)))
        .subscribe(() => {
          this.isUnauthorizedFormEnable = !this.isUnauthorizedFormEnable;
          this.isInvitationSent = !this.isInvitationSent;
        });
    }
  }
}
