<div role="alert" class="group" [ngClass]="'group--' + type">
  <!-- header -->
  <div class="group__header">
    <fa-icon icon="info-circle" size="lg"></fa-icon>
    <div class="group__inner">
      <h2 class="group__title">{{ title }}</h2>
      <p class="group__description">{{ description }}</p>
    </div>
  </div>

  <!-- body -->
  <div class="group__body">
    <ul class="group__list">
      <li
        class="group__item"
        *ngFor="let item of apiMessages"
        [ngClass]="{ 'group__item--with-border': showSource }">
        <fa-icon
          icon="info-circle"
          [tooltip]="
            getFirstObjectKey(item) + ' requires your attention'
          "></fa-icon>
        <ng-container *ngIf="hasAnchorId(item); else readonly">
          <ng-container *ngIf="!showSource; else withShowSource">
            <button class="group__text link" (click)="scrollToAnchorId(item)">
              {{ item.blurb }}
            </button>
          </ng-container>
          <ng-template #withShowSource>
            <span class="group__text">{{ item.blurb }}</span>
            <button
              class="group__text--message link text-muted ms-auto"
              *ngIf="showSource"
              (click)="scrollToAnchorId(item)">
              <span
                translate
                [translateParams]="{ section: getSectionLocaleString(item) }">
                SmartClient.lblSectionRequiresYourAttention
              </span>
            </button>
          </ng-template>
        </ng-container>
        <ng-template #readonly>
          <span class="group__text">{{ item.blurb }}</span>
          <button
            class="group__text--message link text-muted ms-auto"
            *ngIf="showSource"
            (click)="scrollToAnchorId(item)">
            <span
              translate
              [translateParams]="{ section: getSectionLocaleString(item) }">
              SmartClient.lblSectionRequiresYourAttention
            </span>
          </button>
        </ng-template>
      </li>
    </ul>
    <ng-content></ng-content>
  </div>
</div>
