import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm, Validators } from '@angular/forms';
import { IName, IUiMetaField } from '@zipcrim/common';
import { NameValidator } from '@zipcrim/forms/validators';

/**
 * Component for entering subject names.
 */
@Component({
  selector: 'c2h-data-entry-name',
  templateUrl: './data-entry-name.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
})
export class DataEntryNameComponent implements OnInit {
  /**
   * Data to hydrate form.
   */
  @Input()
  Data: IName[];

  /**
   * UiMeta field spec.
   */
  @Input()
  UiMeta: IUiMetaField;

  // todo: replace with reactive form
  Config: any;

  /**
   * On init.
   */
  ngOnInit() {
    this._SetConfig();
  }

  /**
   * Set form configuration.
   */
  private _SetConfig() {
    this.Config = {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'collection',
          name: 'Names',
          titleL10nKey: 'Common.lblName',
          require: {
            min: this.UiMeta.Min,
            max: this.UiMeta.Max,
          },
          nodes: [
            {
              nodeType: 'hidden',
              name: 'ID',
            },
            {
              nodeType: 'container',
              css: 'row',
              nodes: [
                {
                  nodeType: 'input',
                  name: 'FirstName',
                  labelL10nKey: 'Common.lblFirstName',
                  css: 'col-sm-4',
                  validators: [Validators.required, NameValidator],
                },
                {
                  nodeType: 'input',
                  name: 'MiddleName',
                  labelL10nKey: 'Common.lblMiddleName',
                  css: 'col-sm-4',
                  validators: [NameValidator],
                },
                {
                  nodeType: 'input',
                  name: 'LastName',
                  labelL10nKey: 'Common.lblLastName',
                  css: 'col-sm-4',
                  validators: [Validators.required, NameValidator],
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
