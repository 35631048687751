<zc-card
  *ngIf="!IsIgnored && Jobs && Jobs.length > 0"
  color="primary"
  [title]="'Common.lblNewInfoAvailable' | translate">
  <zc-card-body>
    <h1 class="header-2 text-green">
      {{ 'Common.lblDrugAndAlcoholRelease' | translate }}
    </h1>
    <p>{{ 'Common.msgForDrugAlcohol' | translate }}</p>
    <div class="row p-2">
      <div class="col-sm-4" *ngFor="let job of Jobs">
        <h2 class="header-2">
          <span>{{ job.DescriptionBlurb }}</span>
        </h2>
        <zc-file-upload
          (fileChange)="OnFileChange(job, $event)"></zc-file-upload>
      </div>
    </div>
  </zc-card-body>
  <zc-card-footer class="text-end">
    <a class="btn btn-link me-1" (click)="OnIgnore()">
      {{ 'Common.lblIgnore' | translate }}
    </a>
    <zc-button color="primary" (click)="OnSubmit()" [busy]="BusySaving">
      {{ 'Common.lblGetResult' | translate }}
    </zc-button>
  </zc-card-footer>
</zc-card>
