import {
  SaveSpecFieldData,
  SaveSpecFieldName,
  SaveSpecFields,
} from './save-spec.interfaces';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';

/**
 * Save spec implementation.
 */
export class SaveSpec {
  /**
   * Sanitize an object to remove unneeded properties.
   *
   * @param data Data object to sanitize.
   * @param props Collection of prop names to remove from data.
   */
  static sanitize(data: SaveSpecFieldData, props?: string[]) {
    let target = Object.assign({}, data);

    if (target.ID === null) {
      delete target.ID;
    }

    if (props && props.length > 0) {
      target = omit(target, props);
    }

    return target;
  }

  constructor();
  constructor(
    name: SaveSpecFieldName,
    data: SaveSpecFieldData | SaveSpecFieldData[],
    named?: boolean
  );
  constructor(
    name?: SaveSpecFieldName,
    data?: SaveSpecFieldData | SaveSpecFieldData[],
    named?: boolean
  ) {
    if (name && data) {
      if (named) {
        this.SaveSpec = {};
        this.addNamed(name, data);
      } else {
        this.SaveSpec = data;
      }
    } else {
      this.SaveSpec = {};
    }
  }

  /**
   * Data structure.
   */
  SaveSpec: SaveSpecFields | SaveSpecFields[];

  /**
   * Add object to save spec.
   *
   * @param name Field name to add.
   * @param data Data object to add.
   */
  addNamed(
    name: SaveSpecFieldName,
    data: SaveSpecFieldData | SaveSpecFieldData[]
  ) {
    if (!isPlainObject(this.SaveSpec)) {
      throw new Error('Can not add named spec to a non-object!');
    }

    if ((this.SaveSpec as SaveSpecFields)[name]) {
      throw new Error(`Save spec already contains a field for '${name}'!`);
    }

    (this.SaveSpec as SaveSpecFields)[name] = data;
    return this;
  }
}
