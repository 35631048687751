import {
  IAddress,
  ICredential,
  IDisclosure,
  IDob,
  IEducation,
  IEmployment,
  IGender,
  IMiscAttribute,
  IMvr,
  IName,
  IReference,
  ISsn,
  ISubject,
} from '../generated';
import { L10nService } from '../l10n/l10n.service';
import { Address } from './address';
import { Credential } from './credential';
import { Disclosure } from './disclosure';
import { Dob } from './dob';
import { Education } from './education';
import { Employment } from './employment';
import { MiscAttribute } from './misc-attribute';
import { Mvr } from './mvr';
import { Name } from './name';
import { Reference } from './reference';
import { Ssn } from './ssn';

/**
 * Subject.
 */
export class Subject {
  constructor();
  constructor(source: Partial<ISubject>, l10n: L10nService);
  constructor(source?: Partial<ISubject>, l10n?: L10nService) {
    this._Init(source, l10n);
  }

  ID: number;
  ClientCode: string;
  ClientReference: string;
  Gender: IGender;
  SubjectType: string;
  Names: Name[];
  Ssns: Ssn[];
  Addresses: Address[];
  Dobs: Dob[];
  Mvrs: Mvr[];
  EducationHistory: Education[];
  EmploymentHistory: Employment[];
  Credentials: Credential[];
  Disclosures: Disclosure[];
  References: Reference[];
  MiscAttributes: MiscAttribute[];
  MotherMaidenName: MiscAttribute | null;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<ISubject>, l10n?: L10nService) {
    if (!source) {
      this.Names = [];
      this.Ssns = [];
      this.Addresses = [];
      this.Dobs = [];
      this.Mvrs = [];
      this.EducationHistory = [];
      this.EmploymentHistory = [];
      this.Credentials = [];
      this.Disclosures = [];
      this.References = [];
      this.MiscAttributes = [];
      this.MotherMaidenName = null;
      return;
    }

    const names: IName[] = source.Names?.Items || [];
    const ssns: ISsn[] = source.Ssns?.Items || [];
    const addresses: IAddress[] = source.Addresses?.Items || [];
    const dobs: IDob[] = source.Dobs?.Items || [];
    const mvrs: IMvr[] = source.Mvrs?.Items || [];
    const education: IEducation[] = source.EducationHistory?.Items || [];
    const employment: IEmployment[] = source.EmploymentHistory?.Items || [];
    const credentials: ICredential[] = source.Credentials?.Items || [];
    const disclosures: IDisclosure[] = source.Disclosures?.Items || [];
    const references: IReference[] = source.References?.Items || [];
    const misc: IMiscAttribute[] = source.MiscAttributes?.Items || [];

    this.ID = source.ID;
    this.ClientCode = source.ClientCode;
    this.ClientReference = source.ClientReference;
    this.Gender = source.Gender;
    this.SubjectType = source.SubjectType;
    this.Names = names.map((item) => new Name(item));
    this.Ssns = ssns.map((item) => new Ssn(item));
    this.Addresses = addresses.map((item) => new Address(item, l10n));
    this.Dobs = dobs.map((item) => new Dob(item));
    this.Mvrs = mvrs.map((item) => new Mvr(item, l10n));
    this.EducationHistory = education.map((item) => new Education(item, l10n));
    this.EmploymentHistory = employment.map(
      (item) => new Employment(item, l10n)
    );
    this.Credentials = credentials.map((item) => new Credential(item));
    this.Disclosures = disclosures.map((item) => new Disclosure(item, l10n));
    this.References = references.map((item) => new Reference(item, l10n));
    this.MiscAttributes = misc.map((item) => new MiscAttribute(item));
    this.MotherMaidenName = source.MotherMaidenName
      ? new MiscAttribute(source.MotherMaidenName)
      : null;
  }
}
