import { IReferenceField } from '../generated';

/**
 * Reference field.
 */
export class ReferenceField {
  constructor(source?: Partial<IReferenceField>) {
    this._Init(source);
  }

  HelpText: string;
  ID: number;
  Label: string;
  MaxLength: number;
  TypeCode: 'Text' | 'Number' | 'Boolean' | 'Date';
  UsageCode: 'ReadOnly' | 'ReadWrite' | 'Hide' | 'List';
  Value: string;
  Options: { [key: string]: any };

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IReferenceField>) {
    if (!source) {
      this.Options = {};
      return;
    }

    this.HelpText = source.HelpTextBlurb;
    this.ID = source.ID;
    this.Label = source.LabelBlurb;
    this.MaxLength = source.MaxLength;
    this.Options = source.Options || {};
    this.TypeCode = source.TypeCode;
    this.UsageCode = source.UsageCode;
    this.Value = source.Value;
  }
}
