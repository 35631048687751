<ng-container [ngSwitch]="Type">
  <!-- textarea -->
  <textarea
    *ngSwitchCase="'textarea'"
    zcFormFieldAriaDescribedBy
    (blur)="OnBlur()"
    [attr.aria-required]="HasRequired$ | async"
    [ngClass]="InputCss"
    [id]="Id || ''"
    [readonly]="Readonly"
    [disabled]="Disabled"
    [placeholder]="Placeholder || ''"
    [(ngModel)]="Value"
  >
  </textarea>

  <!-- date -->
  <div class="input-group" *ngSwitchCase="'date'">
    <input
      type="text"
      autocomplete="off"
      zcFormFieldAriaDescribedBy
      (blur)="OnBlur()"
      [attr.aria-required]="HasRequired$ | async"
      [ngClass]="InputCss"
      [id]="Id || ''"
      [readonly]="Readonly"
      [disabled]="Disabled"
      [placeholder]="Placeholder || ''"
      [(ngModel)]="Value"
      #dp="bsDatepicker"
      bsDatepicker
      triggers="none"
      [disabled]="Disabled"
    />
    <span class="input-group-addon" (click)="dp.toggle()">
      <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
    </span>
  </div>

  <!-- default -->
  <input
    #input
    *ngSwitchDefault
    autocomplete="off"
    zcFormFieldAriaDescribedBy
    (blur)="OnBlur()"
    [attr.aria-required]="HasRequired$ | async"
    [ngClass]="InputCss"
    [type]="Type || 'text'"
    [id]="Id || ''"
    [readonly]="Readonly"
    [disabled]="Disabled"
    [placeholder]="Placeholder || ''"
    [(ngModel)]="Value"
  />
</ng-container>
