<header>
  <h1 class="header-1">{{ 'CheckToHire.msgWorkflowPageTitle' | translate }}</h1>
  <h2 class="header-2 text-green">
    {{ 'CheckToHire.msgWorkflowHowHead' | translate }}
  </h2>
  <p>{{ 'CheckToHire.msgWorkflowHowDes' | translate }}</p>
</header>

<!-- tutorial -->
<div class="row">
  <div class="col-sm-6">
    <!-- todo: replace "c2h-workflow-tutorial.json" with gif -->
  </div>
</div>

<!-- loading -->
<div class="row" *ngIf="BusyLoading; else loaded">
  <div class="col-sm-6">
    <h3 class="header-2 text-green" translate [translateParams]="{ stage: 1 }">
      CheckToHire.lblStage
    </h3>
    <c2h-ana-group *ngFor="let item of [1, 2]" [Busy]="true"></c2h-ana-group>
  </div>
  <div class="col-sm-6">
    <h3 class="header-2 mb-0">
      {{ 'CheckToHire.lblCustomWorkflowNot' | translate }}
    </h3>
    <p>{{ 'CheckToHire.msgCustomWorkflowNotDesc' | translate }}</p>
    <c2h-ana-group *ngFor="let item of [1, 2, 3]" [Busy]="true"></c2h-ana-group>
  </div>
</div>

<ng-template #loaded>
  <div class="row margin-bottom" cdkDropListGroup>
    <div class="col-sm-6">
      <ng-container
        *ngFor="let stage of Stages; let i = index; let isLast = last">
        <h3
          class="header-2 text-green"
          translate
          [translateParams]="{ stage: i + 1 }">
          CheckToHire.lblStage
        </h3>
        <div
          cdkDropList
          [cdkDropListData]="stage.AnaGroups"
          (cdkDropListDropped)="OnDrop($event)">
          <c2h-ana-group
            *ngFor="let item of stage.AnaGroups; let t = index"
            [Data]="item"
            cdkDrag>
          </c2h-ana-group>

          <!-- remove -->
          <div class="text-center" *ngIf="stage.AnaGroups.length === 0">
            <zc-button color="link" (click)="OnRemoveStage(i)">
              <fa-icon icon="trash-alt" class="me-1"></fa-icon>
              {{ 'CheckToHire.cmdRemoveStage' | translate }}
            </zc-button>
          </div>

          <!-- pause -->
          <div class="text-center text-muted" *ngIf="!isLast">
            <fa-icon icon="pause" class="me-1"></fa-icon>
            {{ 'CheckToHire.msgWorkflowPauseDes' | translate }}
          </div>
        </div>
      </ng-container>

      <!-- add stage -->
      <ng-container *ngIf="Stages.length < MaxStageLimit; else stageLimit">
        <zc-button color="info" outline (click)="OnAddStage()">
          <fa-icon icon="plus" class="me-1"></fa-icon>
          {{ 'CheckToHire.cmdAddAnotherStage' | translate }}
        </zc-button>
      </ng-container>

      <ng-template #stageLimit>
        <zc-button
          color="info"
          outline
          [popover]="'CheckToHire.msgStageLimitExceed' | translate"
          [outsideClick]="true"
          container="body"
          placement="right">
          <fa-icon icon="plus" class="me-1"></fa-icon>
          {{ 'CheckToHire.cmdAddAnotherStage' | translate }}
        </zc-button>
      </ng-template>
    </div>

    <!-- not included -->
    <div class="col-md-6">
      <h3 class="header-2 mb-0">
        {{ 'CheckToHire.lblCustomWorkflowNot' | translate }}
      </h3>
      <p>{{ 'CheckToHire.msgCustomWorkflowNotDesc' | translate }}</p>
      <div
        cdkDropList
        [cdkDropListData]="Available"
        (cdkDropListDropped)="OnDrop($event)">
        <c2h-ana-group
          *ngFor="let item of Available; let i = index"
          [Data]="item"
          [Faded]="true"
          cdkDrag>
        </c2h-ana-group>

        <!-- empty -->
        <div class="text-center text-muted" *ngIf="Available.length === 0">
          {{ 'CheckToHire.msgWorkflowNoSearches' | translate }}
        </div>
      </div>
    </div>
  </div>

  <hr />

  <!-- criminal level -->
  <div *ngIf="HasCrim">
    <h1 class="header-2 text-green mb-0">
      {{ 'CheckToHire.lblSelCRIMBkgLvlHeading' | translate }}
    </h1>
    <p>{{ 'CheckToHire.lblSelCRIMBkgLvlSubHeading' | translate }}</p>
    <zc-card>
      <zc-card-body>
        <form #AnaGroupForm="ngForm" (ngSubmit)="OnSave()">
          <div class="row">
            <div class="col-sm-6">
              <h2 class="header-2 text-green">
                <fa-icon icon="check" class="me-1"></fa-icon>
                {{ 'CheckToHire.msgRecommended' | translate }}
              </h2>
              <div class="radio">
                <label>
                  <input
                    type="radio"
                    name="InclMulti"
                    [(ngModel)]="InclMulti"
                    [value]="true"
                    required />
                  <b>{{
                    'CheckToHire.lblSelCRIMBkgLvlCountryRes' | translate
                  }}</b>
                </label>
              </div>
              <p>{{ 'CheckToHire.msgSelCRIMBkgLvlCountryRes' | translate }}</p>
              <p>{{ 'CheckToHire.msgSelCRIMBkgLvlCountryRes1' | translate }}</p>
            </div>
            <div class="col-sm-6">
              <h2 class="header-2 text-green">
                {{ 'CheckToHire.msgBasic' | translate }}
              </h2>
              <div class="radio">
                <label>
                  <input
                    type="radio"
                    name="InclMulti"
                    [(ngModel)]="InclMulti"
                    [value]="false"
                    required />
                  <b>{{
                    'CheckToHire.lblSelCRIMBkgLvlSexOffender' | translate
                  }}</b>
                </label>
              </div>
              <p>
                {{ 'CheckToHire.lblSelCRIMBkgLvlSexOffenderDes' | translate }}
              </p>
            </div>
          </div>
          <hr />
          <p class="mb-0">
            {{ 'CheckToHire.msgSelCRIMBkgLvlDes' | translate }}
          </p>
        </form>
      </zc-card-body>
    </zc-card>

    <zc-button
      color="primary"
      (click)="OnSave()"
      [form]="AnaGroupForm"
      [busy]="BusySaving"
      [disabled]="AnaGroupForm.invalid || !IsStagesDirty">
      {{ 'Common.cmdSave' | translate }}
    </zc-button>
  </div>

  <zc-button
    *ngIf="!HasCrim"
    [disabled]="!IsStagesDirty"
    color="primary"
    (click)="OnSave()"
    [busy]="BusySaving">
    {{ 'Common.cmdSave' | translate }}
  </zc-button>
</ng-template>
