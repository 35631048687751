<ng-container>
  <h1 class="header-1">
    {{ contact?.Name?.FirstName }} {{ contact?.Name?.LastName }}
    <zc-skeleton preset="single" *ngIf="!contact"></zc-skeleton>
  </h1>

  <form [formGroup]="formGroup" (ngSubmit)="onSave()">
    <div class="row">
      <div class="col-sm-4">
        <h2 class="header-2 text-green">
          {{ 'Common.lblInformation' | translate }}
        </h2>

        <ng-container formGroupName="Name">
          <zc-form-field>
            <zc-label>{{ 'Common.lblFirstName' | translate }}</zc-label>
            <zc-input formControlName="FirstName"></zc-input>
          </zc-form-field>

          <zc-form-field>
            <zc-label>{{ 'Common.lblLastName' | translate }}</zc-label>
            <zc-input formControlName="LastName"></zc-input>
          </zc-form-field>
        </ng-container>

        <ng-container formGroupName="ExternalAccess">
          <zc-form-field>
            <zc-label>{{ 'Common.lblUsername' | translate }}</zc-label>
            <zc-input formControlName="Username"></zc-input>
          </zc-form-field>
        </ng-container>

        <ng-container formGroupName="PrimaryEmail">
          <zc-form-field>
            <zc-label>{{ 'Common.lblEmail' | translate }}</zc-label>
            <zc-input formControlName="Value"></zc-input>
          </zc-form-field>
        </ng-container>

        <ng-container formGroupName="PrimaryNumber">
          <zc-form-field>
            <zc-label>{{ 'Common.lblPhone' | translate }}</zc-label>
            <zc-input formControlName="Value"></zc-input>
          </zc-form-field>
        </ng-container>

        <zc-form-field>
          <zc-label>{{
            'Common.lblIdentityConfirmationCode' | translate
          }}</zc-label>
          <zc-checkbox formControlName="ShowAuthCode">
            Create new identity confirmation code
          </zc-checkbox>
        </zc-form-field>

        <zc-form-field *ngIf="showAuthCode">
          <zc-label>{{
            'Common.lblIdentityConfirmationCode' | translate
          }}</zc-label>
          <zc-help-text>
            {{ 'Common.lblAuthenticationCodeHelpText' | translate }}
          </zc-help-text>
          <zc-input formControlName="AuthCode"></zc-input>
        </zc-form-field>
      </div>
      <div class="col-sm-8">
        <!-- permissions -->
        <!-- todo -->
        <!-- <zc-contact-roles
          #roles
          [Contact]="contact">
          <h2 class="header-2 text-green">{{ 'Common.lblPermissions' | translate }}</h2>
        </zc-contact-roles> -->

        <!-- security -->
        <!-- <div *ngIf="!isCreate" [class.mt-4]="roles.HasOptions">
          <h2 class="header-2 text-green">{{'Common.lblSecurity' | translate}}</h2>
          <div class="row" *ngIf="IsMyAccount">
            <div class="col-sm-6" formGroupName="Password">
              <h3 class="header-2">{{ 'Common.lblChangePassword' | translate }}</h3>

              <zc-form-field>
                <zc-label>{{ 'Common.lblCurrentPassword' | translate }}</zc-label>
                <zc-input type="password" formControlName="Current"></zc-input>
              </zc-form-field>

              <zc-form-field>
                <zc-label>{{ 'Common.lblNewPassword' | translate }}</zc-label>
                <zc-input type="password" formControlName="New"></zc-input>
              </zc-form-field>

              <zc-form-field>
                <zc-label>{{ 'Common.lblConfirmPassword' | translate }}</zc-label>
                <zc-input type="password" formControlName="Confirm"></zc-input>
              </zc-form-field>
            </div>
          </div>

          <div *ngIf="!isMyAccount">
            <ng-container *ngIf="IsActive; else inactive">
              <zc-button color="link" [busy]="BusyStatus" (click)="OnDeactivate()">
                <fa-icon icon="ban"></fa-icon>
                <span class="ms-1">{{ 'Common.cmdDeactivateAccount' | translate }}</span>
              </zc-button>
            </ng-container>
            <ng-template #inactive>
              <zc-button color="link" [busy]="BusyStatus" (click)="OnActivate()">
                <fa-icon icon="check"></fa-icon>
                <span class="ms-1">{{ 'Common.cmdActivateAccount' | translate }}</span>
              </zc-button>
            </ng-template>
          </div>
        </div> -->
      </div>
    </div>
    <hr class="hr-dark" />
    <zc-button type="submit" color="success" [busy]="busySaving">
      {{ 'Common.cmdSaveSettings' | translate }}
    </zc-button>
  </form>
</ng-container>
