<div class="form-group">
  <!-- label -->
  <div [ngClass]="LabelCss">
    <ng-content select="zc-label"></ng-content>
  </div>

  <!-- control -->
  <div [ngClass]="FieldCss">
    <div class="d-flex align-items-center">
      <ng-content select="zc-badge"></ng-content>
      <div class="flex-grow">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
