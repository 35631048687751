import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { SaveSpec } from '../../api/save-spec';
import {
  IAddress,
  IBoList,
  ICertification,
  IContactInfo,
  ICredential,
  IDisclosure,
  IDob,
  IEducation,
  IEmployment,
  IMvr,
  IName,
  IReference,
  ISsn,
  ISubject,
} from '../../generated';
import { SubjectPropertyParam } from './subject.service.interfaces';

@Injectable({ providedIn: 'root' })
export class SubjectService {
  constructor(private api: ApiService) {}

  /**
   * Get subject.
   *
   * @param id Subject id.
   * @param params Optional params.
   */
  get(id: number, params?: HttpParams) {
    return this.api
      .get<ISubject>(`subject/${id}`, params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get subject property.
   *
   * @param id Subject id.
   * @param property Property name.
   * @param params Optional params.
   */
  getProperty(
    id: number,
    property: 'Addresses',
    params?: HttpParams
  ): Observable<IBoList<IAddress>>;
  getProperty(
    id: number,
    property: 'ContactInfo',
    params?: HttpParams
  ): Observable<IBoList<IContactInfo>>;
  getProperty(
    id: number,
    property: 'Certifications',
    params?: HttpParams
  ): Observable<IBoList<ICertification>>;
  getProperty(
    id: number,
    property: 'Credentials',
    params?: HttpParams
  ): Observable<IBoList<ICredential>>;
  getProperty(
    id: number,
    property: 'Disclosures',
    params?: HttpParams
  ): Observable<IBoList<IDisclosure>>;
  getProperty(
    id: number,
    property: 'Dobs',
    params?: HttpParams
  ): Observable<IBoList<IDob>>;
  getProperty(
    id: number,
    property: 'EducationHistory',
    params?: HttpParams
  ): Observable<IBoList<IEducation>>;
  getProperty(
    id: number,
    property: 'EmploymentHistory',
    params?: HttpParams
  ): Observable<IBoList<IEmployment>>;
  getProperty(
    id: number,
    property: 'Mvrs',
    params?: HttpParams
  ): Observable<IBoList<IMvr>>;
  getProperty(
    id: number,
    property: 'Names',
    params?: HttpParams
  ): Observable<IBoList<IName>>;
  getProperty(
    id: number,
    property: 'References',
    params?: HttpParams
  ): Observable<IBoList<IReference>>;
  getProperty(
    id: number,
    property: 'Ssns',
    params?: HttpParams
  ): Observable<IBoList<ISsn>>;
  getProperty(id: number, property: SubjectPropertyParam, params?: HttpParams) {
    return this.api
      .get<IBoList<unknown>>(`subject/${id}/${property}`, params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get subject validation errors.
   *
   * @param id Subject id.
   */
  getValidationErrors(id: number) {
    return this.api.get<void>(`subject/${id}/validationErrors`);
  }

  /**
   * Save subject.
   *
   * @param id Subject id.
   * @param spec Subject data.
   */
  save(id: number, spec: SaveSpec) {
    return this.api.post<number>(`subject/${id}`, spec);
  }

  /**
   * Delete BO for the specified Subject
   *
   * @param subjectId Subject id.
   * @param zObject Object to be soft deleted (Address, SSN, EmploymentHistory, etc)
   * @param objectId Record to be deleted
   */
  deleteSubjectObject(subjectId: number, zObject: string, objectId: number) {
    return this.api.delete(`subject/${subjectId}/${zObject}/${objectId}`);
  }
}
