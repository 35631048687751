import { Component, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ApiMessage } from '@zipcrim/common';

import { FormService } from '../form.service';

@Component({
  selector: 'zc-summary-group',
  templateUrl: './summary-group.component.html',
  styleUrls: ['./summary-group.component.scss'],
  providers: [TranslatePipe],
})
export class SummaryGroupComponent {
  constructor(
    private form: FormService,
    private translatePipe: TranslatePipe
  ) {}

  @Input() type: 'error' | 'warning' | 'info' | 'message';
  @Input() title: string;
  @Input() description: string;
  @Input() apiMessages: ApiMessage[] = [];
  @Input() showSource = false;

  hasAnchorId(message: ApiMessage) {
    const field = message.getFirstUiField();

    return field && field.ObjectID;
  }

  scrollToAnchorId(message: ApiMessage) {
    if (this.showSource) {
      const sectionTarget = document.getElementById(
        this.resolveIdByField(message)
      );

      if (sectionTarget) {
        sectionTarget.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }

    const field = message.getFirstUiField();
    const id = this.form.getBoId(field?.name, field?.ObjectID);

    if (!id) {
      return;
    }

    const target = document.getElementById(id);

    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
      target.focus();
    }
  }
  resolveIdByField(message: ApiMessage) {
    switch (this.getFirstObjectKey(message)) {
      case 'Dob':
        return 'BasicInfo';
      case 'ContactInfo':
        return 'ContactInfo';
      case 'Name':
        return 'Names';
      case 'Address':
        return 'Addresses';
      case 'Education':
        return 'EducationHistory';
      case 'Employment':
        return 'EmploymentHistory';
      case 'Mvr':
        return 'Mvrs';
      case 'Credential':
        return 'Credentials';
      case 'Disclosure':
        return 'Disclosures';
      case 'Reference':
        return 'References';
      case 'MiscAttribute':
      case 'Ssn':
        return 'AdditionalInfo';
      default:
        return 'BasicInfo';
    }
  }

  getSectionLocaleString(message: ApiMessage) {
    switch (this.getFirstObjectKey(message)) {
      case 'Dob':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleBasicInfo'
        );
      case 'ContactInfo':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleContactInfo'
        );
      case 'Name':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleNames'
        );
      case 'Address':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleAddresses'
        );
      case 'Education':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleEducationHistory'
        );
      case 'Employment':
        return this.translatePipe.transform('Common.lblEmployment');
      case 'Mvr':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleMvrs'
        );
      case 'Credential':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleCredentials'
        );
      case 'Disclosure':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleDisclosures'
        );
      case 'Reference':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleReferences'
        );
      case 'MiscAttribute':
      case 'Ssn':
        return this.translatePipe.transform(
          'SmartClient.lblAppScreenTitleAdditionalInfo'
        );
      default:
        return '';
    }
  }

  getFirstObjectKey(message: ApiMessage) {
    const field = message.getFirstUiField();

    return field?.name;
  }
}
