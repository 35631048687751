import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { SaveSpec } from '../../api/save-spec';

/**
 * Client API interactions.
 */
@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(private api: ApiService) {}

  /**
   * Save client.
   *
   * @param clientCode Client code.
   * @param spec Save spec.
   */
  save(clientCode: string, spec: SaveSpec) {
    return this.api
      .post(`client/${clientCode}`, spec)
      .pipe(map((res) => res.payload));
  }
}
