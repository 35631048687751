/**
 * Common value transformers.
 */
export class Transformers {
  /**
   * Remove all non-numeric characters.
   *
   * @param value Value to transform.
   */
  static numericOnly(value: string) {
    return value ? value.replace(/[^0-9]/g, '') : value;
  }

  /**
   * Remove all non-alpha characters.
   *
   * @param value Value to transform.
   */
  static alphaOnly(value: string) {
    return value ? value.replace(/[^a-zA-Z\s]/g, '') : value;
  }

  
}
