<div class="header" *ngIf="State.root">
  <div class="header__brand">
    <a routerLink="/dashboard">
      <zc-image
        file="img/logo-rev.svg"
        alt="CheckToHire"
        class="logo__wrapper"
      ></zc-image>
    </a>
  </div>
  <zc-nav variant="light" *ngIf="State.nav" class="flex-grow-1">
    <div class="flex-grow-1 d-flex">
      <zc-nav-link link="/dashboard">
        {{ 'CheckToHire.lblDashboard' | translate }}
      </zc-nav-link>
      <zc-nav-href href="https://www.driveriq.com/resource-center/">
        {{ 'CheckToHire.lblResources' | translate }}
      </zc-nav-href>
      <zc-nav-href *ngIf="ImportWizardUrl" [href]="ImportWizardUrl">
        {{ 'CheckToHire.lblImportWizard' | translate }}
      </zc-nav-href>
    </div>
    <zc-nav-link link="/account" *ngIf="State.nav">
      {{ 'CheckToHire.lblAccount' | translate }}
    </zc-nav-link>
    <zc-nav-item (click)="OnLogout()">
      {{ 'Common.cmdSignOut' | translate }}
    </zc-nav-item>
  </zc-nav>
</div>
