<section class="table-container mb-3 pb-1">
  <table [ngClass]="css" class="table" *ngIf="!isEmpty">
    <thead>
      <tr>
        <th
          *ngFor="let column of columns"
          (click)="onColumnSort(column, $event)"
          class="sortable"
          [class.sortable--disabled]="!column.isSortable">
          {{ column.headerText }}
          <fa-icon
            *ngIf="column.isSortable"
            class="sortable__direction"
            [class.sortable__direction--active]="column.sortDirection"
            [icon]="column.sortIcon">
          </fa-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- busy -->
      <ng-container *ngIf="busy; else content">
        <tr *ngFor="let item of [5, 2, 3]">
          <td *ngFor="let column of columns">
            <zc-skeleton [rows]="[[{ size: item }]]"></zc-skeleton>
          </td>
        </tr>
      </ng-container>

      <!-- data -->
      <ng-template #content>
        <tr *ngFor="let row of paginatedData">
          <td *ngFor="let column of columns">
            <ng-container
              *ngTemplateOutlet="
                column.cellTemplate;
                context: { row: row }
              "></ng-container>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</section>

<section *ngIf="enablePagination" class="d-flex">
  <span>
    <pagination
      [itemsPerPage]="pageSize"
      [totalItems]="dataSize"
      [maxSize]="7"
      [boundaryLinks]="true"
      (pageChanged)="setCurrentPage($event)"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"></pagination>
  </span>

  <span>
    <select class="form-select ms-2" [(ngModel)]="pageSize">
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="75">75</option>
      <option value="100">100</option>
      <option value="125">125</option>
      <option value="150">150</option>
      <option value="175">175</option>
      <option value="200">200</option>
      <option value="225">225</option>
      <option value="250">250</option>
      <option value="275">275</option>
      <option value="300">300</option>
      <option value="325">325</option>
      <option value="350">350</option>
      <option value="375">375</option>
      <option value="400">400</option>
      <option value="425">425</option>
      <option value="450">450</option>
      <option value="475">475</option>
      <option value="500">500</option>
    </select>
  </span>
</section>
