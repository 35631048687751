import { IAttestOption } from '../generated';

/**
 * Attest option.
 */
export class AttestOption {
  constructor(source?: Partial<IAttestOption>) {
    this._Init(source);
  }

  ID: number;
  Text: string;
  SortOrder: number;
  ValidationRuleID: number;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IAttestOption>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Text = source.OptionBlurb;
    this.SortOrder = source.SortOrder || 0;
    this.ValidationRuleID = source.ValidationRuleID;
  }
}
