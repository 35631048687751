import { Injectable } from '@angular/core';
import isArray from 'lodash/isArray';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ApiMessage } from '../api/api-message';
import { AttestPrompt } from '../models';
import { AttestModalComponent } from './attest-modal.component';

@Injectable({ providedIn: 'root' })
export class AttestService {
  constructor(private bsModalService: BsModalService) {}

  /**
   * Get prompts from api messages.
   *
   * @param messages Collection of api messages.
   * @param names BO name(s) to limit for.
   */
  getFromApiMessages(messages: ApiMessage[], names: string | string[]) {
    if (!messages || messages.length === 0) {
      return [];
    }

    if (!isArray(names)) {
      names = [names];
    }

    return messages
      .filter((item) => {
        const field = item.getFirstUiField();
        return (
          field &&
          field.Impact === 3 &&
          !field.AttestedOn &&
          names.indexOf(field.name) !== -1
        );
      })
      .map((item) => new AttestPrompt(item));
  }

  /**
   * Find a prompt by `ValidationSetRuleJoinID`.
   *
   * @param messages Collection of api messages.
   * @param id Validation set rule join ID.
   */
  findByJoinID(messages: ApiMessage[], id: number) {
    const match = messages.find((x) => {
      const field = x.getFirstUiField();
      return (
        field && field.Impact === 3 && field.ValidationSetRuleJoinID === id
      );
    });

    if (!match) {
      return null;
    }

    return new AttestPrompt(match);
  }

  /**
   * Open attest modal.
   *
   * @param items Attest prompts.
   * @param required Indicates if all attestations items are required.
   */
  prompt(items: AttestPrompt[]) {
    const modal = this.bsModalService.show(AttestModalComponent);
    const content = modal.content as AttestModalComponent;

    content.items = items;
    content.onModalInit();

    return content.result;
  }
}
