<div class="mb-1">
  <zc-tag
    *ngFor="let lead of Leads"
    [showDot]="true"
    [color]="GetColor(lead)"
    [popover]="popoverContent"
    placement="bottom"
    triggers="mouseenter:mouseleave"
    container="body"
    (onShown)="OnPopoverShown(lead)"
    (onHide)="OnPopoverHide()"
    (click)="OnClick(lead)"
  >
    {{ lead.LeadType.TinyName }}
  </zc-tag>
</div>

<!-- popover content -->
<ng-template #popoverContent>
  <ng-container *ngIf="!IsLoading; else loading">
    <ng-container *ngIf="Current; else empty">
      <h4 class="mb-0 mt-0">{{ Current.heading }}</h4>
      <p class="mb-0">{{ Current.description }}</p>
    </ng-container>
    <ng-template #empty>{{
      'Common.lblConnectionIssues' | translate
    }}</ng-template>
  </ng-container>
  <ng-template #loading>
    <zc-busy size="xs"></zc-busy>
    {{ 'Common.lblLoading' | translate }} ...
  </ng-template>
</ng-template>
