import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterSpec, LeadService, WindowRefService } from '@zipcrim/common';
import { Lead } from '@zipcrim/common/models';
import { finalize, tap, mergeMap } from 'rxjs/operators';

import { ReportService } from '../report.service';

/**
 * Display lead report results.
 */
@Component({
  templateUrl: './report-results.component.html',
})
export class ReportResultsComponent implements OnInit {
  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _Lead: LeadService,
    private _ReportService: ReportService,
    private _WindowRef: WindowRefService
  ) {}

  /**
   * Leads with report data.
   */
  Leads: Lead[];

  /**
   * Busy loading indicator.
   */
  IsLoading: boolean;

  /**
   * On init.
   */
  ngOnInit(): void {
    let leadNums: string[];
    this._WindowRef.nativeWindow.scroll(0, 0);

    this._ActivatedRoute.queryParamMap
      .pipe(
        tap((res) => (leadNums = res.getAll('leadNum'))),
        mergeMap(() => this.GetReports(leadNums))
      )
      .subscribe();
  }

  /**
   * Get lead reports.
   */
  GetReports(leadNums: string[]) {
    let filterSpec: FilterSpec;
    let params: HttpParams;

    if (leadNums && leadNums.length > 0) {
      filterSpec = new FilterSpec().in('LeadNum', leadNums);
      params = new HttpParams().append('filterSpec', filterSpec.toString());
    }

    this.IsLoading = true;

    return this._Lead.getByCaseNum(this._ReportService.CaseNum, params).pipe(
      tap((res) => {
        this.Leads = res.Items.map((item) => new Lead(item));

        const hasContent = this.Leads.some((item) => !!item.Report.Content);
        this._ReportService.HasContent.next(hasContent);
      }),
      finalize(() => (this.IsLoading = false))
    );
  }
}
