/**
 * Error message to l10n string mapping.
 */
export interface ErrorMessages {
  [key: string]: string;
}

/**
 * Error message l10n strings.
 */
export const ERROR_MESSAGES: ErrorMessages = {
  address: 'Common.msgAddressInvalid',
  alphaOnly: 'Common.msgAlphaOnlyRequired',
  between: 'Common.msgBetweenInvalid',
  city: 'Common.msgCityInvalid',
  date: 'Common.msgDateInvalid',
  dateMonth: 'Common.msgDateMonthInvalid',
  dateDay: 'Common.msgDateDayInvalid',
  dateYear: 'Common.msgDateYearInvalid',
  dateMin: 'Common.msgDateMinInvalid',
  dateMax: 'Common.msgDateMaxInvalid',
  driverLicense: 'Common.msgDriverLicenseInvalid',
  email: 'Common.msgEmailInvalid',
  fuzzyDate: 'Common.msgDateInvalid',
  matchControls: 'Common.msgMatchControlsInvalid',
  max: 'Common.msgMaxInvalid',
  maxlength: 'Common.msgMaxLengthInvalid',
  min: 'Common.msgMinInvalid',
  minlength: 'Common.msgMinLengthInvalid',
  name: 'Common.msgNameInvalid',
  numericOnly: 'Common.msgNumericOnlyRequired',
  passport: 'Common.msgPassportInvalid',
  password: 'Common.msgPasswordInvalid',
  pattern: 'Common.msgPatternInvalid',
  required: 'Common.msgRequired',
  requiredControls: 'Common.msgRequiredControls',
  requiredMaxControls: 'Common.msgRequiredMaxControls',
  requiredMinControls: 'Common.msgRequiredMinControls',
  age: 'Common.msgAge',
  minAge: 'Common.msgMinAge',
  maxAge: 'Common.msgMaxAge',
  requiredTrue: 'Common.msgRequiredTrueInvalid',
  state: 'Common.msgStateInvalid',
  telephone: 'Common.msgTelephoneInvalid',
  zipCode: 'Common.msgZipCodeInvalid',
  notEmpty: 'Common.msgNotEmptyInvalid',
};
