<ng-container *ngIf="AllOptions">
  <ng-container *ngFor="let option of AllOptions">
    <div
      *ngIf="!Readonly || Value === option.value"
      [ngClass]="Layout === 'inline' ? 'radio-inline' : 'radio'"
      [class.disabled]="Disabled"
      [class.radio-group__item--readonly]="Readonly"
    >
      <label>
        <input
          type="radio"
          (blur)="OnBlur()"
          [attr.aria-required]="HasRequired$ | async"
          [attr.name]="Name"
          [name]="Name"
          [readonly]="Readonly"
          [disabled]="Disabled"
          [value]="option.value"
          [(ngModel)]="Value"
        />
        {{ option.label }}
      </label>
      <zc-dynamic-help-text *ngIf="option.description">
        {{ option.description }}
      </zc-dynamic-help-text>
    </div>
  </ng-container>
</ng-container>

<!-- loading -->
<zc-busy *ngIf="IsLoading" Size="xs"> </zc-busy>
