import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { IJob, SessionService } from '@zipcrim/common';
import { JobService, JobTreeService } from '@zipcrim/work-flow';
import { map } from 'rxjs/operators';

/**
 * Redirect to the appropriate default page, if needed.
 *
 * | Target      | All Jobs | Prelim Job | Destination  |
 * |-------------|----------|------------|--------------|
 * | `signup`    | n/a      | n/a        | `/signup`    |
 * | `dashboard` | ✘        | ✘         | `/more-info`  |
 * | `dashboard` | ✘        | ✔         | `/onboard`   |
 * | `dashboard` | ✔        | ✔         | `/dashboard` |
 */
@Injectable({ providedIn: 'root' })
export class DefaultPageGuard implements CanActivate {
  constructor(
    private _JobTree: JobTreeService,
    private _JobService: JobService,
    private _Router: Router,
    private _Session: SessionService
  ) {}

  /**
   * Can activate.
   */
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;
    const session = this._Session.data;

    if (!session) {
      return true;
    }

    const target = session.AppData.Target;

    if (target === 'dashboard' && url !== '/dashboard') {
      return this._CheckAccountActivation();
    } else if (target === 'signup' && url !== '/signup') {
      this._Router.navigate(['/signup']);
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check account activation status when target is dashboard. Redirect to appropriate page if needed.
   */
  private _CheckAccountActivation() {
    return this._JobService.getAccountActivationJobs().pipe(
      map((res) => {
        const items = res.Items;

        if (!items || !items.length) {
          // account activation complete (go to dashboard)
          this._Router.navigate(['/dashboard']);
        } else {
          const prelim: IJob = this._JobTree.GetJobWithStatus(
            items,
            'SSP_SU_SelectAccountOptions',
            'Incomplete'
          );
          if (prelim && prelim.ID) {
            this._Router.navigate(['/signup/more-info']);
          } else {
            this._Router.navigate(['/onboard']);
          }
        }

        return false;
      })
    );
  }
}
