import { Component, Input } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-common-types';

import { BadgeColor } from './badge.interfaces';

/**
 * Form badge component.
 *
 * ```html
 * <zc-badge color="success" icon="check"></zc-badge>
 * ```
 */
@Component({
  selector: 'zc-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() icon: IconName;
  @Input() color: BadgeColor;
}
