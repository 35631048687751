import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { SkeletonColumn, SkeletonPreset } from './skeleton.interfaces';

/**
 * Skeleton loading display.
 *
 * ```html
 * <zc-skeleton preset="paragraph"></zc-skeleton>
 * ````
 */
@Component({
  selector: 'zc-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent implements OnChanges, OnInit {
  @Input() preset: SkeletonPreset;
  @Input() rows: SkeletonColumn[][];
  @Input() fixed: boolean;
  @Input() responsive: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preset && !changes.rows) {
      this.build();
    }
  }

  ngOnInit() {
    if (!this.rows) {
      this.build();
    }
  }

  private build() {
    switch (this.preset) {
      case 'list':
        this.rows = [
          [{ size: 10 }],
          [{ size: 7, offset: 1 }],
          [{ size: 5, offset: 1 }],
          [{ size: 10 }],
          [{ size: 6, offset: 1 }],
          [{ size: 3, offset: 1 }],
        ];
        break;
      case 'pair':
        this.rows = [[{ size: 2 }, { size: 4 }]];
        break;
      case 'page':
        this.rows = [
          [{ size: 5 }],
          [],
          [{ size: 11 }],
          [{ size: 12 }],
          [{ size: 6 }],
          [],
          [{ size: 11 }],
          [{ size: 9 }],
          [{ size: 11 }],
          [{ size: 4 }],
        ];
        break;
      case 'paragraph':
        this.rows = [[{ size: 11 }], [{ size: 12 }], [{ size: 6 }]];
        break;
      case 'single':
        this.rows = [[{ size: 3 }]];
        break;
      default:
        this.rows = [[{ size: 12 }]];
        break;
    }
  }
}
