import { IReference } from '../generated';
import { L10nService } from '../l10n/l10n.service';
import { Address } from './address';

/**
 * Reference.
 */
export class Reference {
  constructor();
  constructor(source: Partial<IReference>, l10n: L10nService);
  constructor(source?: Partial<IReference>, l10n?: L10nService) {
    this._Init(source, l10n);
  }

  FullName: string;
  Address: Address;
  Phone1: string;
  Phone1Type: string;
  Phone2: string;
  Phone2Type: string;
  ReferenceType: string;
  Relationship: string;
  KnownSince: string;
  ID: number;
  InitAsTouched: boolean;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<IReference>, l10n?: L10nService) {
    if (!source) {
      this.Address = new Address(null, l10n);
      return;
    }

    this.FullName = source.FullName;
    this.Address = new Address(source.Address, l10n);
    this.Phone1 = source.Phone1;
    this.Phone1Type = source.Phone1Type;
    this.Phone2 = source.Phone2;
    this.Phone2Type = source.Phone2Type;
    this.ReferenceType = source.ReferenceType;
    this.Relationship = source.Relationship;
    this.KnownSince = source.KnownSince;
    this.ID = source.ID;
    this.InitAsTouched = !!source.InitAsTouched;
  }
}
