import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, ValidatorFn } from '@angular/forms';
import {
  FuzzyDateParts,
  FuzzyDateService,
  FuzzyDateStaticCode,
  L10nService,
  ModalWithResult,
  TranslateResult,
} from '@zipcrim/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DateService } from '../date/date.service';
import { OptionConfig } from '../forms.interfaces';
import { RadioChange } from '../radio-group/radio-group.interfaces';
import { DateValidator } from '../validators';
import { FuzzyDateModalOptions } from './fuzzy-date-modal.interfaces';
import { FuzzyDateInputType } from './fuzzy-date.enums';

@Component({
  templateUrl: './fuzzy-date-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuzzyDateModalComponent
  extends ModalWithResult<TranslateResult>
  implements OnInit, OnDestroy
{
  constructor(
    private fb: FormBuilder,
    private date: DateService,
    private fuzzyDate: FuzzyDateService,
    private l10n: L10nService,
    protected bsModalRef: BsModalRef
  ) {
    super(bsModalRef);
    this._onChanges = this.onChanges.asObservable();
  }

  @Input() label: string;
  @Input() initialParts: FuzzyDateParts;
  @Input() options: FuzzyDateModalOptions;

  formGroup = this.fb.group({
    type: null,
    date: null,
  });
  messages: { text: string }[];
  busySaving: boolean;
  typeOptions: OptionConfig[];
  onChanges = new Subject<void>();
  hideDay: boolean;
  monthPicker: boolean;
  get showDate() {
    return this.formGroup.get('type').value !== FuzzyDateInputType.Present;
  }

  private _onChanges: Observable<void>;

  ngOnInit() {
    // wait for modal caller to set properties
    this._onChanges.subscribe(() => {
      const type = this.getType(this.initialParts);

      this.typeOptions = this.getTypeOptions();
      this.setDateConfigAndValidators(type);

      this.formGroup.patchValue({
        type,
        date: this.date.toIso({
          year: this.initialParts?.Year ?? null,
          month: this.initialParts?.Month ?? null,
          day: this.initialParts?.Day ?? null,
        }),
      });
    });
  }

  ngOnDestroy() {
    this.onChanges.complete();
  }

  onCancel() {
    this.dismiss();
  }

  onClear() {
    this.close({
      Parts: null,
      Translation: null,
      Value: '',
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    const value = this.getValue() as string;

    if (!value) {
      this.close(null);
      return;
    }

    this.translateValue(value);
  }

  onTypeChange(event: RadioChange) {
    const type: FuzzyDateInputType = event.value;
    this.setDateConfigAndValidators(type);
  }

  private getValue() {
    const { value } = this.formGroup;

    if (value.type === FuzzyDateInputType.Present) {
      return 'Present';
    }

    if (value.type === FuzzyDateInputType.FuzzyDate) {
      const date = value.date as string;
      const parts = date.split('-');

      if (!parts || parts.length !== 2) {
        return null;
      }

      const year = parts[0];
      const month = parts[1];

      return `${month}/${year}`;
    }

    if (value.type === FuzzyDateInputType.ExactDate) {
      return value.date;
    }

    return null;
  }

  private translateValue(value: string) {
    this.messages = [];

    this.busySaving = true;
    this.fuzzyDate
      .translate({
        fuzzyDateString: value,
        fromLanguageCode: this.fuzzyDate.apiLocale,
        toLanguageCode: this.l10n.currentLocale,
        allowedStaticCodes: this.options.allowedStaticCodes,
      })
      .pipe(finalize(() => (this.busySaving = false)))
      .subscribe(
        (res) => {
          this.close(res);
        },
        () => {
          const text = this.l10n.instant('Common.msgDateInvalidError');
          this.messages = [{ text }];
        }
      );
  }

  private getTypeOptions() {
    const codes = this.options.allowedStaticCodes;
    const options: OptionConfig[] = [
      {
        value: FuzzyDateInputType.FuzzyDate,
        labelL10nKey: 'Common.lblDateEnter',
      },
      {
        value: FuzzyDateInputType.ExactDate,
        labelL10nKey: 'Common.lblDateExact',
      },
    ];

    if (codes && codes.indexOf(FuzzyDateStaticCode.Present) !== -1) {
      options.push({
        value: FuzzyDateInputType.Present,
        labelL10nKey: 'Common.lblPresent',
      });
    }

    return options;
  }

  private getType(parts: FuzzyDateParts) {
    const year = parts?.Year ?? null;
    const month = parts?.Month ?? null;
    const day = parts?.Day ?? null;
    const code = parts?.Static;

    if (code === FuzzyDateStaticCode.Present) {
      return FuzzyDateInputType.Present;
    } else if (year && month && day) {
      return FuzzyDateInputType.ExactDate;
    } else {
      // default
      return FuzzyDateInputType.FuzzyDate;
    }
  }

  private setDateConfigAndValidators(type: FuzzyDateInputType) {
    const validators: ValidatorFn[] = [];

    if (type === FuzzyDateInputType.ExactDate) {
      validators.push(
        DateValidator({
          monthRequired: true,
          dayRequired: true,
          min: this.options.min,
          max: this.options.max,
          locale: this.l10n.currentLocale,
        })
      );

      this.hideDay = false;
      this.monthPicker = true;
    } else if (type === FuzzyDateInputType.FuzzyDate) {
      validators.push(
        DateValidator({
          monthRequired: true,
          min: this.options.min,
          max: this.options.max,
          locale: this.l10n.currentLocale,
        })
      );

      this.hideDay = true;
      this.monthPicker = true;
    }

    const control = this.formGroup.get('date');
    control.setValidators(validators);
    control.updateValueAndValidity();
  }
}
