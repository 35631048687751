import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { L10nService } from '../l10n/l10n.service';

import { WindowRefService } from '../window-ref/window-ref.service';
import { CanDeactivateComponent } from './can-deactivate.component';

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard
  implements CanDeactivate<CanDeactivateComponent>
{
  constructor(
    private l10n: L10nService,
    private windowRef: WindowRefService
  ) {}

  canDeactivate(component: CanDeactivateComponent): boolean {
    const result = component.canDeactivate();

    if (!result) {
      const msg = this.l10n.instant('Common.lblUnsavedChanges');
      return this.windowRef.nativeWindow.confirm(msg);
    }

    return true;
  }
}
