import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  ModalService,
  SaveSpec,
  Transformers,
} from '@zipcrim/common';
import { Contact, Domain } from '@zipcrim/common/models';
import { TelephoneValidator } from '@zipcrim/forms/validators';
import { MatchControlsValidator } from '@zipcrim/forms/validators/match-controls.validator';
import { BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

//import { ContactRolesComponent } from '../contact-roles/contact-roles.component';
import { ContactsService } from '../contacts.service';
import { ContactPostSaveComponent } from './contact-post-save-modal.component';

/**
 * Display and edit a single contact record.
 *
 * ```html
 * <zc-contact-detail></zc-contact-detail>
 * ```
 */
@Component({
  selector: 'zc-contact-detail',
  templateUrl: './contact-detail.component.html',
})
export class ContactDetailComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private contacts: ContactsService,
    private modal: ModalService,
    private bsModal: BsModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  /**
   * Contact record.
   */
  contact: Contact;

  /**
   * Indicates if the current contact is the logged in user.
   */
  isMyAccount = false;

  /**
   * Busy indicator for status changes.
   */
  busyStatus: boolean;

  /**
   * Busy indicator for loading.
   */
  busyLoading: boolean;

  /**
   * Busy indicator for saving.
   */
  busySaving: boolean;

  formGroup = this.fb.group({
    Name: this.fb.group({
      FirstName: this.fb.control(null, [
        Validators.pattern(/^[a-zA-Z'-.\s]*$/),
        Validators.required,
        Validators.maxLength(20),
      ]),
      LastName: this.fb.control(null, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z'-.\s]*$/),
        Validators.maxLength(20),
      ]),
    }),
    ExternalAccess: this.fb.group({
      Username: this.fb.control({ value: null, disdisabled: true }),
    }),
    PrimaryEmail: this.fb.group({
      Value: this.fb.control(null, [Validators.required, Validators.email]),
    }),
    PrimaryNumber: this.fb.group({
      Value: this.fb.control(null, [
        Validators.required,
        TelephoneValidator(true),
      ]),
    }),
    ShowAuthCode: false,
    AuthCode: this.fb.control(null, [Validators.required]),
    QuestionKey: this.fb.control(null),
    QuestionBlurb: this.fb.control(null),
    QuestionAnswer: this.fb.control(null),

    Password: this.fb.group({
      Current: null,
      New: null,
      Confirm: [null, MatchControlsValidator(['Password.New'])],
    }),
  });

  get showAuthCode() {
    return this.formGroup.get('ShowAuthCode').value;
  }

  /**
   * Indicates if the contact is not active.
   */
  get isActive() {
    return this.contact && this.contact.Status === 'Active';
  }

  /**
   * Indicates if the contact is in the create state.
   */
  get isCreate() {
    return !this.contactId && !this.isMyAccount;
  }

  /**
   * Route's contact id.
   */
  private contactId: number;

  /**
   * Contact roles reference.
   */
  //@ViewChild('roles', { static: true })
  //private contactRoles: ContactRolesComponent;

  /**
   * On init.
   */
  ngOnInit() {
    this.isMyAccount = !!this.route.snapshot.data.isMyAccount;
    this.contactId = +this.route.snapshot.params.id || 0;

    this.busyLoading = true;
    this.auth
      .getCurrentUser()
      .pipe(finalize(() => (this.busyLoading = false)))
      .subscribe((user) => {
        if (this.isMyAccount) {
          // my account, no id in url
          this.contactId = user.ID;
        } else if (this.contactId === user.ID) {
          // viewing my own user
          this.isMyAccount = true;
        }

        if (this.isCreate) {
          this.getNewContact();
        } else {
          this.getExistingContact();
        }
      });
  }

  /**
   * On save.
   */
  onSave() {
    if (this.formGroup.invalid)
      return;

    //if(this.contactRoles.hasNoRole)
    //  return;

    const values = this.formGroup.value;
    const isCreate = !this.contactId;
    //const domains = this.contactRoles.getDomainValues();
    const questionKey = values.ShowAuthCode
      ? values.AuthCode
      : values.QuestionKey;
    const questionBlurb = values.QuestionBlurb;
    const questionAnswer = values.QuestionAnswer;
    let externalAccess;

    if (questionKey || questionBlurb || questionAnswer) {
      externalAccess = {
        QuestionKey: questionKey,
        QuestionBlurb: questionBlurb,
        QuestionAnswer: questionAnswer,
      };
    }

    const spec = new SaveSpec('Contact', {
      ClientCode: this.contact.GetClientCode(),
      Name: values.Name,
      Status: this.contact.Status || 'Active',
      Emails: [
        {
          ID: this.contact.PrimaryEmail.ID,
          Value: values.PrimaryEmail.Value,
          Purpose: this.contact.PrimaryEmail.Purpose,
        },
      ],
      //Domains: domains,
      ContactNumbers: [
        {
          ID: this.contact.PrimaryNumber.ID,
          Value: Transformers.numericOnly(values.PrimaryNumber.Value),
          Purpose: this.contact.PrimaryEmail.Purpose,
        },
      ],
      Password: values.Password,
      ExternalAccess: externalAccess,
    });

    this.busySaving = true;
    this.contacts
      .saveContact(this.contactId, spec)
      .pipe(finalize(() => (this.busySaving = false)))
      .subscribe((data) => {
        this.contact = data;

        if (isCreate) {
          this.contactId = this.contact.ID;
          this.showPostSaveModal(values.AuthCode).then(() =>
            this.router.navigate(['./account/user'])
          );
        } else {
          this.router.navigate(['./account/user']);
        }
      });
  }

  /**
   * On deactivate.
   */
  onDeactivate() {
    this.modal.confirm().then(
      () => {
        const clientCode = this.contact.GetClientCode();
        const name = this.contact.Name;
        this.busyStatus = true;
        this.contacts
          .deactivateContact(this.contactId, clientCode, name)
          .pipe(finalize(() => (this.busyStatus = false)))
          .subscribe((data) => (this.contact = data));
      },
      () => {
        // empty
      }
    );
  }

  /**
   * On activate.
   */
  onActivate() {
    const clientCode = this.contact.GetClientCode();
    const name = this.contact.Name;
    this.busyStatus = true;
    this.contacts
      .activateContact(this.contactId, clientCode, name)
      .pipe(finalize(() => (this.busyStatus = false)))
      .subscribe((data) => (this.contact = data));
  }

  /**
   * Create a new contact record.
   */
  private getNewContact() {
    this.busyLoading = true;
    this.auth
      .getDomainsByRight('Contact', 'Add')
      .pipe(finalize(() => (this.busyLoading = false)))
      .subscribe((res) => {
        const domains = res.map((item) => {
          const { ClientID, ClientCode } = item;
          return new Domain({ ClientID, ClientCode });
        });

        this.contact = new Contact().AddEmailIfEmpty().AddNumberIfEmpty();
        this.contact.Domains = domains;

        // add non-contact form values
        Object.assign(this.contact, { ShowAuthCode: true });
      });
  }

  /**
   * Get an existing contact record.
   */
  private getExistingContact() {
    this.busyLoading = true;
    this.contacts
      .getContact(this.contactId)
      .pipe(finalize(() => (this.busyLoading = false)))
      .subscribe((data) => {
        this.contact = data.AddEmailIfEmpty().AddNumberIfEmpty();

        if (this.contact.ExternalAccess.LastLogin) {
          // auth code is not available after user has logged in
          // todo
        }
      });
  }

  /**
   * Show post save modal.
   *
   * @param authCode Auth code to display.
   */
  private showPostSaveModal(authCode: string) {
    const modal = this.bsModal.show(ContactPostSaveComponent);
    const content = modal.content as ContactPostSaveComponent;
    content.AuthCode = authCode;

    return content.result;
  }
}
