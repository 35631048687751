import { IContactNumber } from '../generated';

/**
 * Contact number.
 */
export class ContactNumber {
  constructor(source?: Partial<IContactNumber>) {
    this._Init(source);
  }

  ID: number;
  Purpose: 'Primary' | 'Secondary' | 'Emergency' | 'SecureFax' | 'Fax';
  PhoneType: 'Home' | 'Business' | 'Mobile' | 'Fax';
  Availability: 'Anytime' | 'Daytime' | 'Evening';
  Value: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IContactNumber>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Purpose = source.Purpose;
    this.PhoneType = source.PhoneType;
    this.Availability = source.Availability;
    this.Value = source.Value;
  }
}
