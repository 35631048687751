import { Component, TemplateRef, ViewChild } from '@angular/core';

/**
 * Form field error messages.
 *
 * ```html
 * <form>
 *   <zc-dynamic-input formControlName="FirstName"></zc-dynamic-input>
 *   <zc-dynamic-errors>
 *     <zc-dynamic-error *ngIf="MyError">My custom error</zc-dynamic-error>
 *   </zc-errors>
 * </form>
 * ```
 */
@Component({
  selector: 'zc-dynamic-error',
  templateUrl: './error.component.html',
})
export class DynamicErrorComponent {
  @ViewChild('content')
  Template: TemplateRef<any>;
}
