import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import {
  faArchive,
  faCheck,
  faChevronLeft,
  faDownload,
  faEdit,
  faExclamationTriangle,
  faFilePdf,
  faInfoCircle,
  faMinus,
  faPause,
  faPlus,
  faRedo,
  faSearch,
  faStar,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

export const APP_ICONS = [
  faArchive,
  faCheck,
  faChevronLeft,
  faDownload,
  faEdit,
  faExclamationTriangle,
  farStar,
  faFilePdf,
  faInfoCircle,
  faMinus,
  faPause,
  faPlus,
  faRedo,
  faSearch,
  faStar,
  faTimes,
];
