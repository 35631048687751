<zc-sidebar>
  <zc-sidebar-body>
    <h1 class="header-1 text-green mt-0">
      {{ 'CheckToHire.lblOrderAReport' | translate }}
    </h1>

    <!-- loading -->
    <ng-template #loading>
      <div class="text-center p-2">
        <zc-busy></zc-busy>
      </div>
    </ng-template>

    <!-- client code -->
    <zc-dynamic-form-field *ngIf="ClientCodes.length > 1">
      <zc-dynamic-label>
        {{ 'CheckToHire.lblSelectClient' | translate }}
      </zc-dynamic-label>
      <zc-select
        required
        [(ngModel)]="ClientCode"
        (ngModelChange)="OnClientChange()"
        [options]="ClientCodes"
      >
      </zc-select>
    </zc-dynamic-form-field>

    <ng-container *ngIf="Config || !BusyLoading; else loading">
      <!-- form -->
      <form #form="ngForm" (ngSubmit)="OnSubmit(form)">
        <zc-dynamic-node [Busy]="BusyLoading" [Config]="Config">
        </zc-dynamic-node>
        <zc-button
          color="primary"
          block="true"
          type="submit"
          [busy]="BusySaving"
        >
          {{ 'CheckToHire.cmdGetReport' | translate }}
        </zc-button>
      </form>

      <!-- end user notice -->
      <p class="pt-3" *ngIf="IsEndUser">
        <b>{{ 'CheckToHire.thirdPartyResellerFieldDes' | translate }}</b>
      </p>
    </ng-container>
  </zc-sidebar-body>
</zc-sidebar>
