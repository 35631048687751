import { ISsn } from '../generated';

/**
 * Ssn.
 */
export class Ssn {
  constructor(source?: Partial<ISsn>) {
    this._Init(source);
  }

  SSN: string;
  IDType: string;
  ID: number;
  CountryCode: string;
  ExpiresOn: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<ISsn>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.SSN = source.SSN;
    this.IDType = source.IDType;
    this.CountryCode = source.CountryCode;
    this.ExpiresOn = source.ExpiresOn;
  }
}
