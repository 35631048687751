import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { IUiMetaBo } from '@zipcrim/common';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import uniqueId from 'lodash/uniqueId';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { RequireControlsValidator } from '@zipcrim/forms/validators/require-controls.validator';
import { DynamicNodeItemComponent } from './dynamic-node-item.component';
import {
  AbstractNode,
  CheckboxGroupNode,
  CollectionNode,
  FormFieldConfig,
  IterableNode,
  NodeData,
  UiMetaConfig,
  ValidatorConfig,
} from './dynamic-node.interfaces';

/**
 * Dynamic node service.
 */
@Injectable()
export class DynamicNodeService {
  /**
   * Track form control containers whose children nodes are build later or asynchronously.
   */
  Containers = new Map<FormGroup | FormArray, ReplaySubject<void>>();

  /**
   * Maps a form control to its associated display component. Map values emit when the component has been instantiated.
   */
  ControlItems = new Map<
    AbstractControl,
    ReplaySubject<DynamicNodeItemComponent>
  >();

  /**
   * Remove all controls from a group.
   */
  Clear(form: FormGroup) {
    const keys = Object.keys(form.controls);

    keys.forEach((key) => {
      const control = form.controls[key];
      this.ControlItems.delete(control);
      form.removeControl(key);
    });
  }

  /**
   * Build a form group from a node configuration.
   *
   * @param form Root form group.
   * @param config Node configuration.
   */
  Build(form: FormGroup, config: AbstractNode) {
    if (!config) {
      return;
    }

    switch (config.nodeType) {
      case 'card':
      case 'container':
      case 'fieldset':
        // note: `nodesAsync` is handled in `DynamicNodeItemComponent`
        const { nodes, nodesAsync } = config as IterableNode;

        if (nodesAsync) {
          this.Containers.set(form, new ReplaySubject(1));
        }

        if (nodes) {
          nodes.forEach((item) => this.Build(form, item));
        }
        break;
      case 'collection':
        this._AddArray(form, config as CollectionNode);
        break;
      case 'date-parts':
        this._AddObject(form, config as FormFieldConfig, [
          'Year',
          'Month',
          'Day',
        ]);
        break;
      case 'checkbox':
      case 'fuzzy-date':
      case 'hidden':
      case 'input':
      case 'radio-group':
      case 'select':
        this._AddControl(form, config as FormFieldConfig);
        break;
      case 'checkbox-group':
        const { checkBoxOptions } = config as CheckboxGroupNode;

        checkBoxOptions.forEach((item) =>
          this._AddControl(form, item as FormFieldConfig)
        );
        this._AddCheckboxGroupValidator(form, config as CheckboxGroupNode);
        break;
      default:
        break;
    }
  }

  /**
   * Get a node patch configuration from a UI meta api response.
   *
   * @param meta Ui meta api response.
   * @param metaMap Optional mapping of BO object names to form group names.
   */
  GetUiMetaConfig(meta: IUiMetaBo[], metaMap?: { [name: string]: string }) {
    const patch: UiMetaConfig = {};

    meta.forEach((bo) => {
      Object.keys(bo).forEach((key) => {
        const item = bo[key];

        Object.keys(item).forEach((name) => {
          const field = item[name];

          // UI meta indicates `ID` is required, but we can't require a PK on create
          if (name === 'ID' || !isPlainObject(field)) {
            return;
          }

          const validators: ValidatorFn[] = [];
          const group =
            metaMap && !isUndefined(metaMap[key]) ? metaMap[key] : key;
          const path = group ? `${group}.${name}` : name;

          /**
           * The old form component only used the MaxLength property from UiMeta. To keep consistency during the
           * transition, and while we wait for UiMeta to be more fully defined, all other properties are ignored.
           */
          // if (field.IsRequired) {
          //   validators.push(Validators.required);
          // }

          // if (field.MinLength > 0) {
          //   validators.push(Validators.minLength(field.MinLength));
          // }

          if (field.MaxLength > 0) {
            validators.push(Validators.maxLength(field.MaxLength));
          }

          patch[path] = {
            validators,
          };
        });
      });
    });

    return patch;
  }

  /**
   * Add a group of controls to the `array`.
   *
   * @param array Form array to add to.
   * @param config Collection configuration.
   */
  AddCollectionControl(array: FormArray, config: CollectionNode) {
    const group = new FormGroup({});
    config.nodes.forEach((node) => this.Build(group, node));
    array.push(group);
  }

  /**
   * Patch the value of the provided `form`, including arrays.
   *
   * @parm form Root for group.
   * @param config Node configuration.
   * @param data Data object used to patch form values.
   */
  PatchValue(form: FormGroup, config: AbstractNode, data: NodeData) {
    const prevPristine = form.pristine;

    this._BuildCollection(form, config, data);
    form.patchValue(data);

    if (prevPristine && !form.pristine) {
      form.markAsPristine();
    }
  }

  /**
   * Parse a controls name and get the associated form group and control.
   *
   * @param form Root form group.
   * @param name Dot notation path.
   * @param groupOnly True if the `name` is a reference to a group only.
   */
  ParseName(form: FormGroup, name: string, groupOnly?: boolean) {
    if (!name) {
      return {
        formControl: null,
        formGroup: null,
      };
    }

    const parts = name.split('.');
    let controlName = name;
    let groupName: string;

    if (groupOnly) {
      controlName = name;
      groupName = name;
    } else if (parts.length > 1) {
      parts.pop();
      groupName = parts.join('.');
    }

    return {
      formControl: controlName ? form.get(name) : null,
      formGroup: groupName ? form.get(groupName) : form,
    };
  }

  /**
   * Safely convert validator items to functions.
   *
   * @param items Validator items.
   * @param ids Optional list of validator config ids to exclude.
   */
  TransformValidators(
    items: Array<ValidatorFn | ValidatorConfig>,
    ids?: string[]
  ) {
    if (!items) {
      return null;
    }

    const functions: ValidatorFn[] = [];
    let configs: ValidatorConfig[] = [];

    items.forEach((item) => {
      if (isFunction(item)) {
        functions.push(item);
      } else {
        // a unique id is needed later to track which validator is ignored
        item.id = item.id || uniqueId();
        configs.push(item);
      }
    });

    if (isArray(ids) && ids.length > 0) {
      configs = configs.filter((item) => ids.indexOf(item.id) === -1);
    }

    return [
      ...functions,
      ...configs.map((item) => this._MakeIgnorableValidator(item)),
    ];
  }

  /**
   * Recursively add needed controls to any collections in the provided `config`.
   *
   * Note: Angular's `FormGroup.patchValue()` method will hydrate values for existing FormArray controls, but will not
   * add controls for the FormArray based on the data. Thus, this method should be called before `FormGroup.patchValue()`
   * to ensure FormArray values are patched.
   *
   * @param form Root form group.
   * @param config Node configuration.
   * @param data Data object used to patch form values.
   */
  private _BuildCollection(
    form: FormGroup,
    config: AbstractNode,
    data: NodeData
  ) {
    if (!config) {
      return;
    }

    switch (config.nodeType) {
      case 'card':
      case 'container':
      case 'fieldset':
        // note: `nodesAsync` is handled in `DynamicNodeItemComponent`
        const { nodes } = config as IterableNode;

        if (nodes) {
          nodes.forEach((item) => this._BuildCollection(form, item, data));
        }
        break;
      case 'collection':
        const { name } = config as CollectionNode;
        const values: any[] = get(data, name);
        this._BuildCollectionControls(form, config as CollectionNode, values);
        break;
      default:
        break;
    }
  }

  /**
   * Hydrate or clear a `FormArray` with controls in the provided `config` based on the provided `values`.
   *
   * @param form Root form group.
   * @param config Node configuration.
   * @param values Collection of values to create controls for.
   */
  private _BuildCollectionControls(
    form: FormGroup,
    config: CollectionNode,
    values: any[]
  ) {
    const { name } = config;
    const formArray = form.get(name) as FormArray;
    const container = this.Containers.get(formArray);

    if (!values || values.length === 0) {
      formArray.clear();
      container?.next();
      return;
    }

    // remove existing before adding to prevent duplicates
    formArray.clear();

    values.forEach(() => {
      this.AddCollectionControl(formArray, config);
    });

    container?.next();
  }

  /**
   * Add an array to the `form` using the provided `config`.
   *
   * @param form Form group to add control to.
   * @param config Form configuration to add.
   */
  private _AddArray(form: FormGroup, config: CollectionNode) {
    const parts = config.name.split('.');
    const name = parts.pop();
    const group = this._AddGroup(form, parts);
    const array = new FormArray([]);

    group.addControl(name, array);
    this.Containers.set(array, new ReplaySubject(1));
  }

  /**
   * Recursively add form groups to match the provided `path`.
   *
   * @param form Parent form to add group to.
   * @param path Dot notation path.
   */
  private _AddGroup(form: FormGroup, path: string[]): FormGroup {
    let group = form.get(path.join()) as FormGroup;

    if (group) {
      return group;
    }

    if (path.length > 0) {
      const name = path.shift();
      group = form.get(name) as FormGroup;

      if (!group) {
        group = new FormGroup({});
        form.addControl(name, group);
      }

      return this._AddGroup(group, path);
    } else {
      return form;
    }
  }

  /**
   * Add a control to the `form` using the provided `config`. The control's value is assumed to be an object, whose properties
   * will be mapped to automatically built for controls to represent each property.
   *
   * @param form Form group to add control to.
   * @param config Form configuration to add.
   * @param props Property names expected in the controls value object.
   */
  private _AddObject(
    form: FormGroup,
    config: FormFieldConfig,
    props: string[]
  ) {
    const parts = config.name.split('.');
    const group = this._AddGroup(form, parts);

    if (config.validators) {
      // validators are applied to the group, not the underlying controls
      group.setValidators(this.TransformValidators(config.validators));
    }

    props.forEach((prop) => {
      const value = get(config.value, prop) || null;

      const control = new FormControl({
        value,
        disabled: config.disabled,
      });

      group.addControl(prop, control);
    });

    // only one `dynamic-node-item` will be rendered, even though multiple controls are added above
    this.ControlItems.set(group, new ReplaySubject(1));
  }

  /**
   * Add a control to the `form` using the provided `config`.
   *
   * @param form Form group to add control to.
   * @param config Form configuration to add.
   */
  private _AddControl(form: FormGroup, config: FormFieldConfig) {
    const parts = config.name.split('.');
    const name = parts.pop();
    const group = this._AddGroup(form, parts);
    const control = new FormControl(
      {
        value: !isUndefined(config.value) ? config.value : null,
        disabled: config.disabled,
      },
      this.TransformValidators(config.validators),
      config.validatorsAsync
    );

    group.addControl(name, control);
    this.ControlItems.set(control, new ReplaySubject(1));

    if (config.mapTo) {
      // the mapTo form control must be built BEFORE any controls can map to it
      const mappedControl = group.get(config.mapTo);
      const options = { onlySelf: true, emitEvent: false };

      control.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
        // update the mapTo control when this control changes
        mappedControl.setValue(value, options);
      });

      mappedControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          // update this control when the mapTo control changes (including initial data load)
          control.setValue(value, options);
        });
    }

    return control;
  }

  /**
   * Add a hidden control to track and validate checkbox values.
   *
   * @param form Form group to add control to.
   * @param config Form configuration used to add.
   */
  private _AddCheckboxGroupValidator(
    form: FormGroup,
    config: CheckboxGroupNode
  ) {
    const { validateSelection, checkBoxOptions } = config;
    const controls: AbstractControl[] = [];
    const mapping: Array<{ name: string; control: AbstractControl }> = [];
    let { name } = config;
    let validator;

    if (!checkBoxOptions) {
      return;
    }

    checkBoxOptions.forEach((item) => {
      const optionControl = form.get(item.name);

      controls.push(optionControl);
      mapping.push({ name: item.name, control: optionControl });
    });

    if (!name) {
      // update config object with name for use in component
      config.name = name = uniqueId('checkboxGroup');
    }

    if (validateSelection) {
      validator = RequireControlsValidator(validateSelection, controls);
    }

    // get array of names for all checked options
    const getChecked = () =>
      mapping
        .filter((item) => item.control.value === true)
        .map((item) => item.name);

    const control = new FormControl(getChecked(), validator);
    form.addControl(name, control);

    this.ControlItems.set(control, new ReplaySubject(1));

    // force validation update when associated controls change
    controls.forEach((option) => {
      option.valueChanges.subscribe((value) => {
        if (isUndefined(value)) {
          // don't update control on initial blank values
          return;
        }

        control.setValue(getChecked());
        control.updateValueAndValidity();
        control.markAsTouched();
      });
    });
  }

  /**
   * Convert an ignorable validator configuration to a validator function.
   *
   * @param config Validator configuration.
   */
  private _MakeIgnorableValidator(config: ValidatorConfig) {
    const { id, ignorable, ignoreLabelL10nKey, fn } = config;

    return (...args: any[]) => {
      const result = fn.apply(null, args);

      if (result === null) {
        return result;
      }

      Object.keys(result).forEach((key) => {
        const value = result[key];

        if (isObject(value)) {
          Object.assign(value, { id, ignorable, ignoreLabelL10nKey });
        } else {
          result[key] = { id, ignorable, ignoreLabelL10nKey, value };
        }
      });

      return result;
    };
  }
}
