import { Component, Input } from '@angular/core';
import { IJob } from '@zipcrim/common';

/**
 * Display jobs that are blocking leads.
 */
@Component({
  selector: 'c2h-blocked-leads',
  templateUrl: './blocked-leads.component.html',
})
export class BlockedLeadComponent {
  /**
   * Blocked jobs.
   */
  @Input()
  Blocked: IJob[];
}
