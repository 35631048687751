<div class="empty">
  <div *ngIf="file" class="empty__illustration">
    <zc-image [file]="file"></zc-image>
  </div>
  <div *ngIf="icon" class="empty__icon">
    <fa-icon [icon]="icon" size="5x"></fa-icon>
  </div>
  <h3 class="header-2 text-green mb-0">{{ titleText }}</h3>
  <p *ngIf="descriptionText">{{ descriptionText }}</p>
  <ng-content></ng-content>
</div>
