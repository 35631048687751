import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import uniqueId from 'lodash/uniqueId';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { FormFieldControl } from '../form-field/form-field-control';
import { FormService } from '../form.service';

@Component({
  selector: 'zc-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormFieldControl,
      useExisting: CheckboxComponent,
    },
  ],
})
export class CheckboxComponent
  implements
    ControlValueAccessor,
    FormFieldControl<boolean>,
    OnInit,
    OnChanges,
    OnDestroy
{
  constructor(
    @Self() public ngControl: NgControl,
    private form: FormService,
    private ref: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  @Input() disabled = false;
  @Input() id = uniqueId('control-');
  @Input() placeholder = '';
  @Input() readonly = false;
  required: boolean;
  readonly stateChanges = new Subject<void>();

  private _value: boolean;
  private readonly _destroyed = new Subject<void>();

  ngOnInit() {
    this.setRequired();
    this.ngControl.statusChanges
      .pipe(
        takeUntil(this._destroyed),
        tap(() => this.setRequired())
      )
      .subscribe();
  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this.stateChanges.complete();
  }

  onBlur() {
    this.onTouched();
    this.stateChanges.next();
  }

  onChange = (_: any) => {
    // empty
  };

  onTouched = () => {
    // empty
  };

  writeValue(value: any) {
    this.value = value;
    this.ref.markForCheck();
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.ref.markForCheck();
  }

  private setRequired() {
    const ctrl = this.ngControl.control;

    this.required = this.form.hasRequired(ctrl);
    this.stateChanges.next();
  }
}
