<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ job?.Description }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <p translate [translateParams]="{ email: userEmail }">
      Common.lblVerificationCodeSent
    </p>

    <div class="row">
      <div class="col-md-8">
        <form #form (ngSubmit)="onSubmit()" [ngSwitch]="currentState">
          <!-- enter pin -->
          <ng-container *ngSwitchCase="verificationState.EnterPin">
            <zc-form-field>
              <zc-label>
                {{ 'Common.lblVerificationCode' | translate }}
              </zc-label>
              <zc-input
                name="pin"
                [(ngModel)]="pin"
                required
                minlength="6"
                maxlength="6">
              </zc-input>
              <zc-error *ngIf="message">{{ message }}</zc-error>
              <zc-help-text *ngIf="showCountDown">
                <zc-count-down
                  [duration]="150000"
                  (done)="showCountDown = !showCountDown">
                </zc-count-down>
              </zc-help-text>
            </zc-form-field>

            <ng-container *ngIf="!showCountDown">
              <zc-button
                color="link"
                class="me-1"
                (click)="resendEmail()"
                *ngIf="isCodeAlreadySent">
                {{ 'Common.lblVerifyEmailResend' | translate }} {{ userEmail }}
              </zc-button>
              <br />
            </ng-container>
            <zc-button
              color="link"
              (click)="setState(verificationState.EnterEmail)">
              {{ 'Common.lblVerifyEmailSendDifferent' | translate }}
            </zc-button>
          </ng-container>

          <!-- new email -->
          <ng-container *ngSwitchCase="verificationState.EnterEmail">
            <zc-form-field>
              <zc-label>
                {{ 'Common.lblNewEmail' | translate }}
              </zc-label>
              <zc-input
                #email="ngModel"
                name="email"
                [(ngModel)]="newEmail"
                required></zc-input>
              <zc-error *ngIf="message">{{ message }}</zc-error>
            </zc-form-field>
          </ng-container>
        </form>
      </div>
    </div>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button color="info" outline class="me-1" (click)="onCancel()">
      {{ 'Common.lblCancel' | translate }}
    </zc-button>
    <zc-button
      color="primary"
      [disabled]="form.invalid"
      [busy]="busy"
      (click)="onSubmit()">
      {{ 'Common.cmdSubmit' | translate }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
