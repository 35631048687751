import { Directive, forwardRef } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

/**
 * Required validator for custom checkbox component.
 *
 * https://github.com/angular/angular/issues/17495
 */
@Directive({
  selector:
    '[zcRequiredChecked][formControlName],[zcRequiredChecked][formControl],[zcRequiredChecked][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RequireCheckedDirective),
      multi: true,
    },
  ],
})
export class RequireCheckedDirective implements Validator {
  /**
   * Validate.
   */
  validate(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    if (value === true) {
      return null;
    }

    return {
      required: true,
    };
  }
}
