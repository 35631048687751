import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, FileService, IBoList, IInvoice } from '@zipcrim/common';
import { Invoice } from '@zipcrim/common/models';
import { addDays, format, subYears } from 'date-fns';
import { map } from 'rxjs/operators';

/**
 * Invoice service.
 */
@Injectable({ providedIn: 'root' })
export class InvoiceService {
  constructor(private api: ApiService, private file: FileService) {}

  /**
   * Get invoices by client.
   *
   * @param clientCode Client code.
   */
  getInvoices(clientCode: string) {
    const now = new Date();
    const params = new HttpParams()
      .append('start', format(subYears(now, 1), 'yyyy-MM-dd'))
      .append('end', format(addDays(now, 30), 'yyyy-MM-dd'));
    const url = `invoices/${clientCode}`;

    return this.api
      .get<IBoList<IInvoice>>(url, params)
      .pipe(map((res) => res.payload.Items.map((item) => new Invoice(item))));
  }

  /**
   * Download invoice PDF.
   *
   * @param clientCode Client code.
   * @param invoice Invoice record.
   */
  downloadInvoice(clientCode: string, invoice: Invoice) {
    const params = new HttpParams().append('invoiceDate', invoice.InvoiceDate);
    const url = `invoice/${clientCode}/${invoice.InvoiceNumber}`;

    return this.file.download(url, params, true);
  }
}
