<ng-container [formGroup]="Form">
  <!-- loading -->
  <div
    class="dynamic-collection"
    *ngIf="Busy; else content"
    [ngSwitch]="Config.itemContainer"
  >
    <!-- container: none -->
    <ng-container *ngSwitchCase="'none'">
      <zc-dynamic-node-item
        *ngFor="let item of Config.nodes"
        [Busy]="Busy"
        [Config]="item"
        [Form]="Form"
        [Layout]="Layout"
        [NonFunctional]="true"
      >
      </zc-dynamic-node-item>
    </ng-container>

    <!-- container: card -->
    <zc-card [title]="Title" *ngSwitchDefault>
      <zc-card-body>
        <zc-dynamic-node-item
          *ngFor="let item of Config.nodes"
          [Busy]="Busy"
          [Config]="item"
          [Form]="Form"
          [Layout]="Layout"
          [NonFunctional]="true"
        >
        </zc-dynamic-node-item>
      </zc-card-body>
    </zc-card>
  </div>

  <ng-template #content>
    <div
      class="dynamic-collection"
      [formArrayName]="FormArrayName"
      [ngSwitch]="Config.itemContainer"
    >
      <!-- container: none -->
      <ng-container *ngSwitchCase="'none'">
        <ng-container *ngFor="let control of FormArray.controls; let i = index">
          <div
            class="dynamic-collection__item"
            *ngIf="!IsDeleted(control)"
            [formGroupName]="i"
            [id]="GetAnchorIdFromAbstractControl(control)"
            tabindex="-1"
          >
            <div class="text-right" *ngIf="CanRemove">
              <zc-button
                Color="link"
                [buttonClasses]="['btn-condensed']"
                (click)="OnRemove(i)"
              >
                <fa-icon icon="trash-alt" class="mr-05"></fa-icon>
                {{ 'Common.cmdDelete' | translate }}
              </zc-button>
            </div>
            <zc-dynamic-node-item
              *ngFor="let item of Config.nodes"
              [ApiMessages]="ApiMessages"
              [Busy]="Busy"
              [Config]="item"
              [AddToFormGroup]="control"
              [Layout]="Layout"
            >
            </zc-dynamic-node-item>
            <div class="text-center" *ngIf="CanAdd && ShowFooter(i)">
              <zc-button Color="default" (click)="OnAdd()">
                <fa-icon icon="plus" class="mr-05"></fa-icon>
                {{ 'Common.cmdAddAnother' | translate }}
              </zc-button>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- container: card -->
      <ng-container *ngSwitchDefault>
        <ng-container *ngFor="let control of FormArray.controls; let i = index">
          <div
            class="dynamic-collection__item"
            *ngIf="!IsDeleted(control)"
            [formGroupName]="i"
            [id]="GetAnchorIdFromAbstractControl(control)"
            tabindex="-1"
          >
            <zc-card
              [title]="Title"
              [isCollapsible]="false"
              [color]="HasApiErrorsFromAbstractControl(control) ? 'danger' : null"
            >
              <zc-card-header>
                <zc-button
                  Color="link"
                  [buttonClasses]="['btn-condensed', 'btn-inherit-color']"
                  (click)="OnRemove(i)"
                  *ngIf="CanRemove"
                >
                  <fa-icon icon="trash-alt" class="mr-05"></fa-icon>
                  {{ 'Common.cmdDelete' | translate }}
                </zc-button>
              </zc-card-header>
              <zc-card-body>
                <zc-dynamic-node-item
                  *ngFor="let item of Config.nodes"
                  [ApiMessages]="ApiMessages"
                  [Busy]="Busy"
                  [Config]="item"
                  [AddToFormGroup]="control"
                  [Layout]="Layout"
                >
                </zc-dynamic-node-item>
              </zc-card-body>
              <zc-card-footer Align="center" *ngIf="CanAdd && ShowFooter(i)">
                <zc-button Color="default" (click)="OnAdd()">
                  <fa-icon icon="plus" class="mr-05"></fa-icon>
                  {{ 'Common.cmdAddAnother' | translate }}
                </zc-button>
              </zc-card-footer>
            </zc-card>
          </div>
        </ng-container>
      </ng-container>

      <!-- empty -->
      <zc-empty
        *ngIf="EmptyConfig && IsEmpty"
        [file]="EmptyConfig.file"
        [icon]="EmptyConfig.icon"
        [title]="EmptyConfig.title"
        [titleL10nKey]="EmptyConfig.titleL10nKey"
        [description]="EmptyConfig.description"
        [descriptionL10nKey]="EmptyConfig.descriptionL10nKey"
      >
        <zc-button Color="secondary" (click)="OnAdd()" *ngIf="CanAdd">
          <fa-icon icon="plus" class="mr-05"></fa-icon>
          {{ 'Common.cmdAddOne' | translate }}
        </zc-button>
      </zc-empty>
    </div>
  </ng-template>
</ng-container>
