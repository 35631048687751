import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { IBoList, ISchemata } from '../../generated';

/**
 * Schema API interactions.
 */
@Injectable({ providedIn: 'root' })
export class SchemaService {
  constructor(private api: ApiService) {}

  /**
   * Get schema.
   *
   * @param clientCode Client code.
   */
  getSchemaByClient(clientCode: string) {
    const params = new HttpParams().append('clientCode', clientCode);

    return this.api
      .get<IBoList<ISchemata>>('schemata/byClient', params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get schema.
   *
   * @param clientCode Client code.
   * @param fileTypeCodes File type codes.
   */
  getSchemaByClientAndFileType(clientCode: string, fileTypeCodes: string[]) {
    let params = new HttpParams().append('clientCode', clientCode);

    fileTypeCodes.forEach((code) => {
      params = params.append('fileTypeCode', code);
    });

    return this.api
      .get<ISchemata[]>('schemata/byClientAndFiletype', params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Save schema.
   *
   * @param data Schema data.
   */
  save(data: any) {
    return this.api.post('schemata', data).pipe(map((res) => res.payload));
  }
}
