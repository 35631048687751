import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { IBoList, ILead } from '../../generated';
import { SubmitRequestParam } from './lead.interfaces';

/**
 * Lead API interactions.
 */
@Injectable({ providedIn: 'root' })
export class LeadService {
  constructor(private api: ApiService) {}

  /**
   * Get lead by case num.
   *
   * @param caseNum Case num.
   * @param params Http params.
   */
  getByCaseNum(caseNum: string, params?: HttpParams) {
    return this.api
      .get<IBoList<ILead>>(`leads/byCaseNum/${caseNum}`, params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Submit lead request.
   *
   * @param data Lead requests.
   */
  submitRequest(data: SubmitRequestParam) {
    return this.api.post('leadRequests', data).pipe(map((res) => res.payload));
  }
}
