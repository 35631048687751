import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldSpec, LeadService } from '@zipcrim/common';
import { Lead } from '@zipcrim/common/models';
import { catchError, map, tap } from 'rxjs/operators';

import { LeadTagCache, LeadTagCacheItem } from './lead-tags.interfaces';

/**
 * Lead tags display.
 */
@Component({
  selector: 'c2h-lead-tags',
  templateUrl: './lead-tags.component.html',
  styleUrls: ['./lead-tags.component.scss'],
})
export class LeadTagsComponent {
  constructor(private _LeadService: LeadService) {}

  /**
   * Case number.
   */
  @Input()
  CaseNumber: string;

  /**
   * Collection of leads to display.
   */
  @Input()
  Leads: Lead[];

  /**
   * Event emitted on tag click.
   */
  @Output()
  OnTagClick = new EventEmitter<Lead>();

  /**
   * Indicates if the popover content is loading.
   */
  IsLoading = false;

  /**
   * Current showing lead cache.
   */
  Current: LeadTagCacheItem;

  /**
   * Leads cache.
   */
  private _Cache: LeadTagCache;

  /**
   * Get the appropriate color for a given lead.
   *
   * @param lead Lead record.
   */
  GetColor(lead: Lead) {
    const { Completed: completed, Status: status } = lead;

    if (completed) {
      return 'success';
    } else {
      return status === 'Adverse' ||
        status === 'Error' ||
        status === 'SeeDetail'
        ? 'danger'
        : 'warning';
    }
  }

  /**
   * Emit event on tag click.
   *
   * @param lead Lead that was clicked.
   */
  OnClick(lead: Lead) {
    this.OnTagClick.emit(lead);
  }

  /**
   * On popover show.
   *
   * @param lead Lead that triggered the popover.
   */
  OnPopoverShown(lead: Lead) {
    if (this._Cache) {
      this._SetCurrent(lead.ID);
    } else {
      this._GetLeads().subscribe(() => this._SetCurrent(lead.ID));
    }
  }

  /**
   * On popover hide.
   */
  OnPopoverHide() {
    this.Current = null;
  }

  /**
   * Get lead by case number.
   */
  private _GetLeads() {
    const fieldSpec = new FieldSpec().add(['ID', 'LeadType', 'Detail']);
    const params = new HttpParams()
      .append('fieldSpec', fieldSpec.toString())
      .append('isOptimizeForSpeed', 'true');
    this.IsLoading = true;

    return this._LeadService.getByCaseNum(this.CaseNumber, params).pipe(
      map((res) => res.Items.map((item) => new Lead(item))),
      tap((leads) => {
        this._SetCache(leads);
        this.IsLoading = false;
      }),
      catchError((error) => {
        this.IsLoading = false;
        throw error;
      })
    );
  }

  /**
   * Cache lead blurbs for reuse.
   *
   * @param leads Collection of leads.
   */
  private _SetCache(leads: Lead[]) {
    this._Cache = {};

    leads.forEach((lead) => {
      this._Cache[lead.ID] = {
        heading: lead.LeadType.Name,
        description: lead.Detail,
      };
    });
  }

  /**
   * Set current lead from cache.
   *
   * @param id Lead id to set.
   */
  private _SetCurrent(id: number) {
    this.Current = this._Cache[id];
  }
}
