import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { map } from 'rxjs/operators';

import { JobService } from '../job.service';

/**
 * Prevents navigation to a route when the current user has not completed account activation.
 */
@Injectable({ providedIn: 'root' })
export class AccountActivatedGuard implements CanActivate {
  constructor(private job: JobService) {}

  /**
   * Can activate.
   */
  canActivate() {
    return this.job
      .getAccountActivationJobs()
      .pipe(map((res) => res.Items.length === 0));
  }
}
