<fieldset class="date">
  <ng-container [ngSwitch]="format">
    <!-- month-day-year -->
    <ng-container *ngSwitchCase="'month-day-year'">
      <ng-container *ngTemplateOutlet="monthInput"></ng-container>
      <ng-container *ngTemplateOutlet="dayInput"></ng-container>
      <ng-container *ngTemplateOutlet="yearInput"></ng-container>
    </ng-container>

    <!-- day-month-year -->
    <ng-container *ngSwitchCase="'day-month-year'">
      <ng-container *ngTemplateOutlet="dayInput"></ng-container>
      <ng-container *ngTemplateOutlet="monthInput"></ng-container>
      <ng-container *ngTemplateOutlet="yearInput"></ng-container>
    </ng-container>

    <!-- year-month-day -->
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="yearInput"></ng-container>
      <ng-container *ngTemplateOutlet="monthInput"></ng-container>
      <ng-container *ngTemplateOutlet="dayInput"></ng-container>
    </ng-container>
  </ng-container>

  <!-- month -->
  <ng-template #monthInput>
    <div
      class="date__item date__item--month"
      [ngClass]="{
        'date__item--input': !monthPicker,
        'date__item--select': monthPicker
      }">
      <label class="date__label" [for]="id + '-month'">
        {{ 'Common.lblMonth' | translate }}
      </label>
      <select
        *ngIf="monthPicker"
        class="form-select select--width-auto"
        [(ngModel)]="month"
        (ngModelChange)="onInput()"
        [id]="id + '-month'"
        [attr.aria-describedby]="describedBy"
        [attr.aria-required]="required">
        <option *ngFor="let item of monthOptions" [ngValue]="item.value">
          {{ item.labelL10nKey | translate }}
        </option>
      </select>
      <input
        *ngIf="!monthPicker"
        type="text"
        class="input"
        min="1"
        max="12"
        [(ngModel)]="month"
        (ngModelChange)="onInput()"
        (focusout)="onMonthFocusOut()"
        (keyup)="onMonthKeyUp($event)"
        [attr.id]="id + '-month'"
        [attr.aria-describedby]="describedBy"
        [attr.aria-required]="required"
        [placeholder]="'Common.lblDateFormatMm' | translate"
        [readonly]="readonly"
        zcOnlyNumbers
        pattern="[0-9]*" />
    </div>
  </ng-template>

  <!-- day -->
  <ng-template #dayInput>
    <div *ngIf="!hideDay" class="date__item date__item--day">
      <label class="date__label" [for]="id + '-day'">
        {{ 'Common.lblDay' | translate }}
      </label>
      <input
        type="text"
        class="input"
        min="1"
        max="31"
        [(ngModel)]="day"
        (ngModelChange)="onInput()"
        (focusout)="onDayFocusOut()"
        (keyup)="onDayKeyUp($event)"
        [attr.id]="id + '-day'"
        [attr.aria-describedby]="describedBy"
        [attr.aria-required]="required"
        [placeholder]="'Common.lblDateFormatDd' | translate"
        [readonly]="readonly"
        zcOnlyNumbers
        pattern="[0-9]*" />
    </div>
  </ng-template>

  <!-- year -->
  <ng-template #yearInput>
    <div class="date__item date__item--year">
      <label class="date__label" [for]="id + '-year'">
        {{ 'Common.lblYear' | translate }}
      </label>
      <input
        type="text"
        class="input"
        [(ngModel)]="year"
        (ngModelChange)="onInput()"
        (focusout)="onYearFocusOut()"
        (keyup)="onKeyUp($event)"
        [attr.id]="id + '-year'"
        [attr.aria-describedby]="describedBy"
        [attr.aria-required]="required"
        [placeholder]="'Common.lblDateFormatYyyy' | translate"
        [readonly]="readonly"
        zcOnlyNumbers
        pattern="[0-9]*" />
    </div>
  </ng-template>
</fieldset>
