import { Job } from '@zipcrim/common/models';
import { Observable, Subject } from 'rxjs';

/**
 * Core implementation for all job modal components. Any modal component used in the `WorkFlowModule` must
 * extend `JobModal`.
 */
export class JobModal {
  constructor() {
    this.jobChange = this.jobEmitter.asObservable();
  }

  /**
   * Job in context. Hydrated from calling modal service.
   */
  job: Job;

  /**
   * Job change event. Event should be emitted when job action is complete.
   */
  jobChange: Observable<Job>;

  /**
   * Job change emitter.
   */
  protected jobEmitter: Subject<Job> = new Subject();

  /**
   * On Job Available
   */
  onJobAvailable() {
    // do nothing
  }
}
