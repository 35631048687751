<div class="validation-messages" aria-live="assertive" zcFormFieldAriaId>
  <div class="validation-messages__item" *ngFor="let item of Messages">
    <div class="validation-messages__icon">
      <fa-icon icon="exclamation-triangle"></fa-icon>
    </div>
    <div class="validation-messages__content">
      {{ item.text }}

      <!-- ignore -->
      <a
        class="validation-messages__action"
        *ngIf="item.ignorable"
        (click)="OnClickIgnore(item)"
      >
        {{ item.ignoreLabelL10nKey || 'Common.cmdIgnoreMessage' | translate }}
      </a>
    </div>
  </div>
</div>
