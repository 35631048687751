<div class="d-flex align-items-center">
  <select
    class="form-control"
    zcFormFieldAriaDescribedBy
    (blur)="OnBlur()"
    [attr.aria-required]="HasRequired$ | async"
    [id]="Id || ''"
    [class.readonly]="Readonly"
    [disabled]="Disabled || Readonly"
    [(ngModel)]="Value"
  >
    <!-- placeholder -->
    <ng-container *ngIf="Placeholder">
      <option [ngValue]="undefined" disabled selected hidden>
        {{ Placeholder }}
      </option>
    </ng-container>

    <!-- options -->
    <ng-container *ngIf="AllOptions">
      <option *ngFor="let option of AllOptions" [ngValue]="option.value">
        {{ option.label }}
      </option>
    </ng-container>

    <!-- deselect option -->
    <ng-container *ngIf="Deselect">
      <option value="" disabled>
        &#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;
      </option>
      <option [ngValue]="null">{{ Deselect }}</option>
    </ng-container>
  </select>

  <!-- loading -->
  <zc-busy *ngIf="IsLoading" Size="xs" class="ml-05"> </zc-busy>

  <!-- failed -->
  <zc-button
    Size="sm"
    Color="link"
    [buttonClasses]="['btn-condensed', 'ml-05']"
    [tooltip]="'Common.lblFailedRetry' | translate"
    (click)="OnRetry()"
    *ngIf="IsFailure"
  >
    <fa-icon icon="exclamation-triangle" class="text-danger"></fa-icon>
    {{ 'Common.cmdTryAgain' | translate }}
  </zc-button>
</div>
