import { Routes } from '@angular/router';
import { AuthGuard, HasRightsGuard, NotFoundComponent } from '@zipcrim/common';
import { AccountActivatedGuard } from '@zipcrim/work-flow';

import { AccountComponent } from '../account/account.component';
import { UserListComponent } from '../account/user-list/user-list.component';
import { UserComponent } from '../account/user/user.component';
import { WorkflowComponent } from '../account/workflow/workflow.component';
import { CaseGuard } from '../common/case.guard';
import { DefaultPageGuard } from '../common/default-page.guard';
import { OnboardGuard } from '../common/onboard.guard';
import { DashboardV2Component } from '../dashboard/dashboard.component';
import { DocumentHomeComponent } from '../document-home/document-home.component';
import { OnboardComponent } from '../onboard/onboard.component';
import { DrugAndAlcoholComponent } from '../report/drug-and-alcohol/drug-and-alcohol.component';
import { FinalizeAndOrderComponent } from '../report/finalize-and-order/finalize-and-order.component';
import { PrepareOrderComponent } from '../report/prepare-order/prepare-order.component';
import { ReportResultsComponent } from '../report/report-results/report-results.component';
import { ReportComponent } from '../report/report.component';
import { CompanyInfoComponent } from '../signup/company-info/company-info.component';
import { HomeComponent } from '../signup/home.component';
import { PennsylvaniaInfoComponent } from '../signup/pennsylvania-info/pennsylvania-info.component';
import { SignupComponent } from '../signup/signup/signup.component';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/signup',
  },
  {
    path: 'signup',
    component: HomeComponent,
    data: {
      header: false,
      footer: {
        nav: false,
      },
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: SignupComponent,
        canActivate: [AuthGuard, DefaultPageGuard],
      },
      {
        path: 'more-info',
        component: CompanyInfoComponent,
      },
      {
        path: 'pennsylvania-info',
        component: PennsylvaniaInfoComponent,
      },
    ],
  },
  {
    path: 'onboard',
    component: OnboardComponent,
    canActivate: [AuthGuard, OnboardGuard],
    data: {
      header: {
        nav: false,
      },
      footer: {
        nav: false,
      },
    },
  },
  {
    path: 'documents/:jobId',
    component: DocumentHomeComponent,
    canActivate: [AuthGuard],
    data: {
      header: {
        nav: false,
      },
      footer: {
        nav: false,
      },
    },
  },
  {
    path: 'dashboard',
    component: DashboardV2Component,
    canActivate: [AuthGuard, AccountActivatedGuard],
    // TODO
    // canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'reports/:caseId/:caseNumber',
    component: ReportComponent,
    canActivate: [AuthGuard, AccountActivatedGuard, CaseGuard],
    children: [
      {
        path: 'prepare-order',
        component: PrepareOrderComponent,
      },
      {
        path: 'finalize-order',
        component: FinalizeAndOrderComponent,
      },
      {
        path: 'drug-and-alcohol-doc-upload',
        component: DrugAndAlcoholComponent,
      },
      {
        path: 'show-report',
        component: ReportResultsComponent,
      },
    ],
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'my-account',
        pathMatch: 'full',
      },
      {
        path: 'workflow',
        component: WorkflowComponent,
        // TODO:
        // canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'user',
        component: UserListComponent,
        canActivate: [HasRightsGuard],
        data: {
          hasRights: 'Contact',
        },
      },
      {
        path: 'user/:id',
        component: UserComponent,
        canActivate: [HasRightsGuard],
        data: {
          hasRights: 'Contact',
        },
      },
      {
        path: 'my-account',
        component: UserComponent,
        data: {
          isMyAccount: true,
        },
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
