import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

import { CommonModuleConfig } from '../common.interfaces';
import { WindowRefService } from '../window-ref/window-ref.service';

/**
 * Analytics service for tracking page views and custom events.
 *
 * To enable analytics:
 * 1. Add the `gtag.js` script to the applications `index.html`.
 *   - Reference: https://developers.google.com/analytics/devguides/collection/gtagjs
 * 2. Add the site's tracking id to the CommonModule.
 * ```ts
 * @NgModule({
 *   imports: [
 *     CommonModule.forRoot({
 *       googleAnalyticsId: 'GA_MEASUREMENT_ID'
 *     ])
 *   ]
 * ])
 * ```
 * 3. Inject `AnalyticsService` into your root component.
 * ```ts
 * export class AppComponent {
 *   constructor(analytics: AnalyticsService) { }
 * }
 * ```
 */
@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(
    @Inject('commonModuleConfig') private config: CommonModuleConfig,
    private router: Router,
    private windowRef: WindowRefService
  ) {
    this.init();
  }

  /**
   * Emit a page view.
   */
  pageView() {
    if (!this.isAvailable()) {
      return;
    }

    gtag('config', this.config.googleAnalyticsId, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      page_path: this.router.url,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      page_location: this.windowRef.nativeWindow.location.href,
    });
  }

  /**
   * Emit an event.
   *
   * @param name Event name.
   * @param params Event params.
   */
  event(name: Gtag.EventNames, params: Gtag.EventParams) {
    if (!this.isAvailable()) {
      return;
    }

    gtag('event', name, params);
  }

  /**
   * Listen for router navigation events.
   */
  private init() {
    if (!this.isAvailable()) {
      return;
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => this.pageView())
      )
      .subscribe();
  }

  /**
   * Indicates if analytics are configured and available for use.
   */
  private isAvailable() {
    if (!this.config.googleAnalyticsId) {
      return false;
    } else if (typeof gtag === 'undefined') {
      const message = [
        'Google Analytics is configured but `gtag` is not defined.',
        'Did you forget to include the `gtag.js` script?',
        'See details at https://developers.google.com/analytics/devguides/collection/gtagjs',
      ];

      console.warn(message.join(' '));
      return false;
    }

    return true;
  }
}
