import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { JobModal } from '../job-modal';

/**
 * Modal for `Default` UIClass job.
 */
@Component({
  templateUrl: './default-modal.component.html',
})
export class DefaultModalComponent extends JobModal {
  constructor(private bsModalRef: BsModalRef) {
    super();
  }

  /**
   * On job cancel.
   */
  onCancel() {
    this.bsModalRef.hide();
  }

  /**
   * On job submit.
   */
  onSubmit() {
    // todo (jbl): implement logic
    this.job.Status = 'Complete';
    this.jobEmitter.next(this.job);
    this.bsModalRef.hide();
  }
}
