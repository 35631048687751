import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';

/**
 * Count down timer.
 *
 * ```html
 * <zc-count-down [duration]="10000"></zc-count-down>
 * ```
 */
@Component({
  selector: 'zc-count-down',
  templateUrl: './count-down.component.html',
})
export class CountDownComponent implements OnChanges {
  /**
   * Number of milliseconds to count down.
   */
  @Input() duration: number;

  /**
   * Event emitted when count down is done.
   */
  @Output() done = new EventEmitter<void>();
  timer: Observable<string>;

  ngOnChanges(changes: SimpleChanges) {
    const duration = changes.Duration && changes.Duration.currentValue;

    if (duration) {
      this.start(duration);
    } else {
      this.timer = null;
    }
  }

  /**
   * Start a new timer.
   *
   * @param duration Number of milliseconds.
   */
  private start(duration: number) {
    const seconds = Math.round(duration / 1000);

    /**
     * `interval` emits a value every second. `take` completes the observable when the interval reaches the number of
     * seconds in the `duration`. `interval` does not emit a value until the first second is reached, so `startsWith`
     * provides the starting value for display. `map` decrements the duration by 1 second every emission.
     */
    this.timer = interval(1000).pipe(
      take(seconds),
      map(() => (duration = duration - 1000)),
      startWith(duration),
      tap((x) => {
        if (x === 0) {
          this.done.emit();
        }
      }),
      map((x) => this.format(x))
    );
  }

  /**
   * Format milliseconds to a readable format.
   *
   * @param duration Number of milliseconds.
   */
  private format(duration: number) {
    const minutes = Math.floor(duration / 60000);
    const seconds = (duration % 60000) / 1000;
    const paddedMinutes = (minutes < 10 ? '0' : '') + minutes;
    const paddedSeconds = (seconds < 10 ? '0' : '') + seconds;

    return `${paddedMinutes}:${paddedSeconds}`;
  }
}
