<div class="job-tree__container">
  <!-- busy -->
  <ng-container *ngIf="busy; else content">
    <div class="job-tree__item">
      <div class="job-tree__job">
        <div class="job-tree__info flex-grow-1">
          <h3 class="job-tree__heading">
            <zc-skeleton [rows]="[[{ size: 3 }]]"></zc-skeleton>
          </h3>
        </div>
        <span class="job-tree__circle">
          <fa-icon icon="check"></fa-icon>
        </span>
      </div>
    </div>
    <div class="job-tree__item">
      <div class="job-tree__job">
        <div class="job-tree__info flex-grow-1">
          <h3 class="job-tree__heading">
            <zc-skeleton [rows]="[[{ size: 5 }]]"></zc-skeleton>
          </h3>
        </div>
        <span class="job-tree__circle">
          <fa-icon icon="check"></fa-icon>
        </span>
      </div>
      <ul class="job-tree__dependencies">
        <li class="job-tree__job">
          <div class="job-tree__info flex-grow-1">
            <zc-skeleton [rows]="[[{ size: 3 }]]"></zc-skeleton>
          </div>
          <span class="job-tree__circle">
            <fa-icon icon="check"></fa-icon>
          </span>
        </li>
        <li class="job-tree__job">
          <div class="job-tree__info flex-grow-1">
            <zc-skeleton [rows]="[[{ size: 2 }]]"></zc-skeleton>
          </div>
          <span class="job-tree__circle">
            <fa-icon icon="check"></fa-icon>
          </span>
        </li>
        <li class="job-tree__job">
          <div class="job-tree__info flex-grow-1">
            <zc-skeleton [rows]="[[{ size: 4 }]]"></zc-skeleton>
          </div>
          <span class="job-tree__circle">
            <fa-icon icon="check"></fa-icon>
          </span>
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-template #content>
    <div class="job-tree__item" *ngFor="let job of jobSummaries">
      <!-- job -->
      <div
        class="job-tree__job"
        [ngClass]="{ 'job-tree__job--complete': job.Status === 'Complete' }">
        <div class="job-tree__info">
          <h3 class="job-tree__heading">
            <a *ngIf="job.CanAction; else noLink" (click)="onJobAction(job)">{{
              job.Description
            }}</a>
            <ng-template #noLink>{{ job.Description }}</ng-template>
          </h3>
          <p class="job-tree__text">{{ job.HelpText }}</p>
        </div>
        <div>
          <ng-container
            *ngIf="job.FlatDependencies.length; else independentActions">
            <a
              class="job-tree__action job-tree__action--link"
              (click)="onToggleDependencies(job)"
              *ngIf="enableAccordion">
              {{
                (getToggleState(job)
                  ? 'Common.cmdHideAll'
                  : 'Common.cmdGetStart'
                ) | translate
              }}
            </a>
          </ng-container>
          <ng-template #independentActions>
            <a
              class="job-tree__action job-tree__action--link"
              (click)="onJobAction(job)"
              *ngIf="job.CanAction; else noAction">
              <span class="hide-sm">
                {{ 'Common.cmdGetStart' | translate }}
              </span>
            </a>
            <ng-template #noAction>
              <span
                *ngIf="job.IsPending; else status"
                class="job-tree__action job-tree__action--pending"
                >{{ 'Common.lblPending' | translate }}</span
              >
              <ng-template #status>
                <ng-container [ngSwitch]="job.Status">
                  <ng-container *ngSwitchCase="'Blocked'">
                    <span class="job-tree__action job-tree__action--blocked">
                      {{ 'Common.lblBlocked' | translate }}
                    </span>
                    <a
                      class="job-tree__info"
                      popoverTitle="Blocked By:"
                      [popover]="blockedByTemplate"
                      placement="left"
                      triggers="mouseenter:mouseleave">
                      <fa-icon icon="info-circle" class="ms-1"></fa-icon>
                    </a>
                    <ng-template #blockedByTemplate>
                      <ul class="job-tree__list">
                        <li *ngFor="let item of job.BlockingDependencies">
                          {{ item.Description }}
                        </li>
                      </ul>
                    </ng-template>
                  </ng-container>
                  <span
                    *ngSwitchCase="'Complete'"
                    class="job-tree__circle job-tree__circle--success">
                    <fa-icon icon="check"></fa-icon>
                  </span>
                </ng-container>
              </ng-template>
            </ng-template>
          </ng-template>
        </div>
      </div>

      <!-- dependencies -->
      <ul
        class="job-tree__dependencies"
        *ngIf="job.FlatDependencies.length"
        [hidden]="!getToggleState(job)">
        <li
          class="job-tree__job"
          *ngFor="let dependency of job.FlatDependencies"
          [ngClass]="{
            'job-tree__job--complete': dependency.Status === 'Complete'
          }">
          <div class="job-tree__info">
            <a
              class="job-tree__action job-tree__action--link"
              (click)="onJobAction(dependency)"
              *ngIf="dependency.CanAction; else noLink"
              >{{ dependency.Description }}</a
            >
            <ng-template #noLink>{{ dependency.Description }}</ng-template>
          </div>
          <div [ngSwitch]="dependency.Status" class="text-end ps-1">
            <a
              class="job-tree__action job-tree__action--link"
              (click)="onJobAction(dependency)"
              *ngIf="dependency.CanAction; else noAction">
              <span class="hidden-xs">{{
                'Common.cmdGetStart' | translate
              }}</span>
              <fa-icon icon="chevron-right" class="visible-xs"></fa-icon>
            </a>
            <ng-template #noAction>
              <span
                *ngIf="dependency.IsPending; else status"
                class="job-tree__action job-tree__action--pending"
                >{{ 'Common.lblPending' | translate }}</span
              >
              <ng-template #status>
                <ng-container [ngSwitch]="dependency.Status">
                  <ng-container *ngSwitchCase="'Blocked'" class="text-end">
                    <span class="job-tree__action job-tree__action--blocked">
                      {{ 'Common.lblBlocked' | translate }}
                    </span>
                    <a
                      class="job-tree__info"
                      [popoverTitle]="'Common.lblBlockedBy' | translate"
                      [popover]="blockedByTemplate"
                      placement="left"
                      triggers="mouseenter:mouseleave">
                      <fa-icon icon="info-circle" class="ms-1"></fa-icon>
                    </a>
                    <ng-template #blockedByTemplate>
                      <ul class="job-tree__list">
                        <li
                          *ngFor="let item of dependency.BlockingDependencies">
                          {{ item.Description }}
                        </li>
                      </ul>
                    </ng-template>
                  </ng-container>
                  <span
                    *ngSwitchCase="'Complete'"
                    class="job-tree__circle job-tree__circle--success">
                    <fa-icon icon="check"></fa-icon>
                  </span>
                </ng-container>
              </ng-template>
            </ng-template>
          </div>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
