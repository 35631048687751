import { ISignature, ISignatureImage } from '@zipcrim/common';

import { DocumentPage } from '../document-signing.interfaces';

/**
 * Document model.
 */
export class Document {
  constructor(source: Partial<Document>) {
    this._Init(source);
  }

  ID: number;
  Name: string;
  PageCount: number;
  SignatureOnFile: ISignatureImage;
  Signatures: {
    Items: ISignature[];
  };
  TemplateID: number;
  UpdateVersion: number;
  Version: string;
  Pages: DocumentPage[];
  JobId: number;
  IsOpen: boolean;
  Status: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<Document>) {
    this.ID = source.ID;
    this.Name = source.Name;
    this.PageCount = source.PageCount;
    this.SignatureOnFile = source.SignatureOnFile
      ? source.SignatureOnFile
      : ({} as ISignatureImage);
    this.Signatures = source.Signatures ? source.Signatures : { Items: [] };
    this.TemplateID = source.TemplateID;
    this.UpdateVersion = source.UpdateVersion;
    this.Version = source.Version;
    this.Pages = source.Pages ? source.Pages : [];
    this.JobId = source.JobId;
    this.IsOpen = source.IsOpen;
    this.Status = source.Status;
  }
}
