import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AuthService, L10nService } from '@zipcrim/common';
import { Contact, Domain, DomainRole } from '@zipcrim/common/models';
import { OptionConfig } from '@zipcrim/forms';
import { finalize } from 'rxjs/operators';

import { ContactsService } from '../contacts.service';
import {
  DomainResult,
  DomainSelection,
  MultiDomainSelection,
  RoleResult,
} from './contact-roles.interfaces';

/**
 * Contact roles management.
 *
 * ```html
 * <zc-contact-roles [Contact]="MyContact"></zc-contact-roles>
 * ```
 */
@Component({
  selector: 'zc-contact-roles',
  templateUrl: './contact-roles.component.html',
})
export class ContactRolesComponent implements OnInit, OnChanges {
  constructor(
    private auth: AuthService,
    private contacts: ContactsService,
    private l10n: L10nService
  ) {}

  /**
   * Contact to display roles for.
   */
  @Input()
  Contact: Contact;

  /**
   * Currently selected client.
   */
  clientCode: string;

  /**
   * Available domains.
   */
  domains: Domain[] = [];

  /**
   * Client dropdown options.
   */
  clientOptions: OptionConfig[] = [];

  /**
   * Primary role options.
   */
  radioOptions: OptionConfig[] = [];

  /**
   * Secondary role options.
   */
  checkboxOptions: any[] = [];

  /**
   * Has table of domain / selected roles.
   */
  domainData: MultiDomainSelection = {};

  /**
   * Busy loading indicator.
   */
  busyLoading: boolean;

  /**
   * Currently selected domain.
   */
  get currentDomain() {
    if (!this.clientCode) {
      return null;
    }

    return this.domainData[this.clientCode];
  }

  /**
   * Indicates if any client options are available.
   */
  get hasOptions() {
    return this.clientOptions.length > 0;
  }

  /**
   * Indicates if no valid roles are selected,
   */
  get hasNoRole() {
    if (!this.currentDomain) {
      return false;
    }

    const { primary, secondary } = this.currentDomain;

    // if no primary selected, check if any secondary role is selected
    return (
      primary === '_NONE' &&
      !Object.keys(secondary).some((key) => secondary[key])
    );
  }

  /**
   * Collection of roles that are mutually exclusive; Meaning only one of these should be selected.
   */
  private readonly mutuallyExclusiveRoleIds = [
    'FULL', // View All + DE
    'OWN', // View Own + DE
    'DE', // Data Entry Only
    'VIEWALLNDE', // View All: No DE
  ];

  /**
   * On init.
   */
  ngOnInit() {
    this.busyLoading = true;
    this.auth
      .getDomainsByRight('RoleAssignment', 'Modify')
      .pipe(finalize(() => (this.busyLoading = false)))
      .subscribe((res) => {
        this.domains = res;
        this.setClientOptions();
        this.setDomainData();
      });
  }

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.Contact && changes.Contact.currentValue) {
      this.clientCode = this.Contact.GetClientCode();

      if (this.domains.length > 0) {
        this.setClientOptions();
        this.setDomainData();
      }
    }
  }

  /**
   * Get domain values.
   */
  getDomainValues() {
    return this.getDomainsFromSelection();
  }

  /**
   * On client change.
   */
  onClientChange(clientCode: string) {
    this.getRoles(clientCode);
  }

  /**
   * Get available roles and set role options.
   *
   * @param clientCode Client to get roles for.
   */
  private getRoles(clientCode: string) {
    if (!clientCode) {
      return;
    }

    this.contacts.getDomainRoles(clientCode).subscribe((roles) => {
      const radioOptions: OptionConfig[] = [];
      const checkboxOptions: any[] = [];

      roles.forEach((item) => {
        const label = item.Description;
        const name = item.Name;

        if (this.mutuallyExclusiveRoleIds.indexOf(name) !== -1) {
          radioOptions.push({ label, value: name });
        } else {
          const value = this.domainData[clientCode].secondary[name];
          checkboxOptions.push({ label, name, value });
        }
      });

      // `_NONE` is a fictitious role used for the UI only
      radioOptions.push({ labelL10nKey: 'Common.lblNoRole', value: '_NONE' });

      this.radioOptions = radioOptions;
      this.checkboxOptions = checkboxOptions;
    });
  }

  /**
   * Set client options from available domains.
   */
  private setClientOptions() {
    if (!this.Contact) {
      this.clientOptions = [];
      return;
    }

    this.clientOptions = this.domains.map((domain) => {
      const contactDomain = this.Contact.Domains.find(
        (item) => item.ClientCode === domain.ClientCode
      );
      let numberOfRoles = 0;

      if (contactDomain) {
        numberOfRoles = contactDomain.Roles.length;
      }

      const assigned = this.l10n.instant('Common.lblRolesAssigned', {
        numberOfRoles,
      });

      return {
        label: `${domain.Name} - ${domain.ClientCode} (${assigned})`,
        value: domain.ClientCode,
      };
    });
  }

  /**
   * Set domain data based on the current contact.
   */
  private setDomainData() {
    // reset
    this.domainData = {};

    if (!this.Contact) {
      return;
    }

    this.domains.forEach((domain) => {
      const contactDomain = this.Contact.Domains.find(
        (item) => item.ClientCode === domain.ClientCode
      );
      let primary: DomainRole = null;
      let secondary = {};

      if (contactDomain) {
        primary = contactDomain.Roles.find((item) =>
          this.isExclusive(item.Name)
        );
        secondary = contactDomain.Roles.filter(
          (item) => !this.isExclusive(item.Name)
        ).reduce<{ [key: string]: any }>((obj, item) => {
          obj[item.Name] = true;
          return obj;
        }, {});
      }

      this.domainData[domain.ClientCode] = {
        primary: primary ? primary.Name : 'FULL',
        secondary,
      };
    });
  }

  /**
   * Transform all domain selection objects into an array of domains with roles.
   */
  private getDomainsFromSelection() {
    const domains: DomainResult[] = [];

    Object.keys(this.domainData).forEach((key) => {
      const value = this.domainData[key];
      const contactDomain = this.Contact.Domains.find(
        (item) => item.ClientCode === key
      );
      const contactRoles = contactDomain ? contactDomain.Roles : [];
      const roles = this.getRolesFromSelection(value, contactRoles);

      if (roles.length > 0) {
        domains.push({
          ClientCode: key,
          Roles: roles,
        });
      }
    });

    return domains;
  }

  /**
   * Transform a domain selection object into an array of domain roles.
   *
   * @param data Domain role selection data.
   * @param domainRoles Current domain roles.
   */
  private getRolesFromSelection(
    data: DomainSelection,
    domainRoles: DomainRole[]
  ) {
    const primaryExists = domainRoles.some(
      (item) => item.Name === data.primary
    );
    const previousPrimaries = domainRoles.filter((item) =>
      this.isExclusive(item.Name)
    );
    const roles: RoleResult[] = [];

    if (!primaryExists && data.primary !== '_NONE') {
      // add newly selected "primary" role
      roles.push({ Name: data.primary });
    }

    previousPrimaries.forEach((item) => {
      const name = item.Name;

      if (name !== data.primary) {
        // remove previously selected "primary" roles
        roles.push({ Name: name, IsDeleted: true });
      }
    });

    Object.keys(data.secondary).forEach((key) => {
      const value = data.secondary[key];
      const alreadyExists = domainRoles.some((item) => item.Name === key);

      if (value && !alreadyExists) {
        // add newly selected role
        roles.push({ Name: key });
      } else if (!value && alreadyExists) {
        // remove de-selected existing role
        roles.push({ Name: key, IsDeleted: true });
      }
    });

    return roles;
  }

  /**
   * Determines if a given role name is mutually exclusive.
   *
   * @param name Role name to check.
   */
  private isExclusive(name: string) {
    return this.mutuallyExclusiveRoleIds.indexOf(name) !== -1;
  }
}
