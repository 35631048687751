import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, SessionService } from '@zipcrim/common';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HeaderState } from './header.interfaces';

/**
 * Header component.
 */
@Component({
  selector: 'c2h-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private _Auth: AuthService,
    private _Route: ActivatedRoute,
    private _Router: Router,
    private _Session: SessionService
  ) {}

  /**
   * Header state.
   */
  State: HeaderState;

  /**
   * Import wizard url.
   */
  ImportWizardUrl: string;

  /**
   * Default state values.
   */
  private readonly _DefaultState: HeaderState = {
    root: true,
    nav: true,
  };

  /**
   * Router subscription.
   */
  private _RouterSubscription: Subscription;

  /**
   * On init.
   */
  ngOnInit() {
    const session = this._Session.data;

    this.State = this._DefaultState;
    this.ImportWizardUrl = (session.Url as any).ImportWizard;

    this._RouterSubscription = this._Router.events
      .pipe(filter((evt) => evt instanceof NavigationEnd))
      .subscribe(() => {
        const data = this._Route.root.firstChild.snapshot.data;
        this._SetState(data.header);
      });
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    if (this._RouterSubscription) {
      this._RouterSubscription.unsubscribe();
    }
  }

  /**
   * On logout.
   */
  OnLogout() {
    this._Auth.logout();
  }

  /**
   * Set header state.
   */
  private _SetState(data?: HeaderState | boolean) {
    if (data === false) {
      data = {
        root: false,
      };
    }

    this.State = Object.assign({}, this._DefaultState, data);
  }
}
