import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import {
  FuzzyDateTranslation,
  TranslateParam,
  TranslateResult,
} from './fuzzy-date.interfaces';

/**
 * Fuzzy date API interactions.
 */
@Injectable({ providedIn: 'root' })
export class FuzzyDateService {
  constructor(private api: ApiService) {}

  /**
   * The default locale that is required for getting and sending fuzzy date values to the API.
   */
  readonly apiLocale = 'en-US';

  /**
   * Translate value into a valid date string.
   *
   * @config source Fuzzy date param.
   */
  translate(source: TranslateParam): Observable<TranslateResult> {
    let params: HttpParams;
    const body = {
      fuzzyDateString: source.fuzzyDateString,
      fromLanguageCode: source.fromLanguageCode,
      toLanguageCode: source.toLanguageCode,
      format: source.format,
    };

    if (source.allowedStaticCodes) {
      params = new HttpParams();

      source.allowedStaticCodes.forEach((item) => {
        params = params.append('allowedStaticCodes', item);
      });
    }

    return this.api
      .post<FuzzyDateTranslation>('fuzzyDateSvcs/translate', body, params)
      .pipe(
        map((res) => res.payload),
        map((res) => {
          const value = res[this.apiLocale];
          const translation =
            res[
              source.toLanguageCode || source.fromLanguageCode || this.apiLocale
            ] || value;

          return {
            Value: value,
            Translation: translation,
            Parts: {
              Static: res.Static,
              Year: res.Year,
              Season: res.Season,
              Month: res.Month,
              Day: res.Day,
            },
          };
        })
      );
  }
}
