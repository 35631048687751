import { IInvoice } from '../generated';

/**
 * Invoice.
 */
export class Invoice {
  constructor(source?: Partial<IInvoice>) {
    this._Init(source);
  }

  AddressNumber: string;
  Company: string;
  InvoiceAmount: number;
  InvoiceDate: string;
  InvoiceNumber: string;
  LongAddressNumber: string;
  OpenAmount: number;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IInvoice>) {
    if (!source) {
      return;
    }

    this.AddressNumber = source.AddressNumber;
    this.Company = source.Company;
    this.InvoiceAmount = source.InvoiceAmount;
    this.InvoiceDate = source.InvoiceDate;
    this.InvoiceNumber = source.InvoiceNumber;
    this.LongAddressNumber = source.LongAddressNumber;
    this.OpenAmount = source.OpenAmount;
  }
}
