import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'zc-modal-header',
  templateUrl: './modal-header.component.html',
})
export class ModalHeaderComponent {
  @Input() showClose = true;
  @Output() dismiss = new EventEmitter();

   onDismiss() {
    this.dismiss.emit();
  }
}
