import { Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { DynamicFormFieldComponent } from '../form-field/form-field.component';
import { FormService } from '@zipcrim/forms/form.service';

/**
 * Form checkbox input.
 *
 * ```html
 * <zc-dynamic-checkbox [(ngModel)]="myValue">Be Awesome</zc-dynamic-checkbox>
 * ```
 */
@Component({
  selector: 'zc-dynamic-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicCheckboxComponent),
      multi: true,
    },
  ],
})
export class DynamicCheckboxComponent implements ControlValueAccessor, OnInit {
  constructor(
    private _FormService: FormService,
    @Optional() private _FormField: DynamicFormFieldComponent
  ) {}

  /**
   * Input id.
   */
  @Input()
  Id = '';

  /**
   * Indicated if the input is readonly.
   */
  @Input()
  Readonly = false;

  /**
   * Indicates if the input is disabled.
   */
  @Input()
  Disabled = false;

  HasRequired$: Observable<boolean>;

  /**
   * Input value getter / setter.
   */
  get Value() {
    return this._Value;
  }

  set Value(value) {
    this._Value = value;
    this.onChange(value);
  }

  /**
   * Input value.
   */
  private _Value: boolean;

  /**
   * On init.
   */
  ngOnInit() {
    this.HasRequired$ = this._FormField?.HasRequired ?? of(false);

    if (!this.Id && this._FormField) {
      this.Id = this._FormField.Id;
    }
  }

  /**
   * On blur.
   */
  OnBlur() {
    this.onTouched();
  }

  /**
   * On change.
   */
  onChange = (_: any) => {
    // empty
  };

  /**
   * On touched.
   */
  onTouched = () => {
    // empty
  };

  /**
   * Write value.
   */
  writeValue(value: any) {
    if (this._FormService.HasValue(value)) {
      this.Value = value;
    }
  }

  /**
   * Register on change.
   */
  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  /**
   * Register on touched.
   */
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  /**
   * Set disabled state.
   */
  setDisabledState(isDisabled: boolean) {
    this.Disabled = isDisabled;
  }
}
