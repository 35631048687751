<h1 class="header-2 title">
  {{ JoinOfAllLeadName }}
  <zc-button color="link" (click)="OnEditStage()">
    <fa-icon icon="edit" class="me-1"></fa-icon>
    {{ 'Common.lblEdit' | translate }}
  </zc-button>
</h1>

<!-- tabs -->
<zc-nav class="mb-2">
  <zc-nav-item [active]="true">
    {{ 'CheckToHire.lblPrepareOrder' | translate }}
  </zc-nav-item>
  <zc-nav-item>
    {{ 'CheckToHire.lblFinalizeOrder' | translate }}
  </zc-nav-item>
</zc-nav>

<!-- loading -->
<ng-template #loading>
  <div class="text-center p-2">
    <zc-busy></zc-busy>
  </div>
</ng-template>

<div class="row" *ngIf="Data && !BusyLoading; else loading">
  <div class="col-xs-12 col-sm-9 col-lg-7">
    <form #OrderForm="ngForm" (ngSubmit)="OnSubmit()">
      <!-- ssns -->
      <div class="mb-2" *ngIf="UiMetaFields.Ssns?.Max > 0 && Data.SSNReadOnly">
        <c2h-data-entry-ssn [Data]="Data.Ssns" [UiMeta]="UiMetaFields.Ssns">
        </c2h-data-entry-ssn>
      </div>

      <!-- dobs -->
      <div class="mb-2" *ngIf="UiMetaFields.Dobs?.Max > 0 && Data.DOBReadOnly">
        <h3 class="header-2 text-green">{{ 'Common.lblDob' | translate }}</h3>
        <c2h-data-entry-dob [Data]="Data.Dobs" [UiMeta]="UiMetaFields.Dobs">
        </c2h-data-entry-dob>
      </div>

      <!-- names -->
      <div class="mb-2" *ngIf="UiMetaFields.Names?.Max > 0">
        <h3 class="header-2 text-green">
          {{ 'CheckToHire.lblDataEntryNames' | translate }}
        </h3>
        <c2h-data-entry-name [Data]="Data.Names" [UiMeta]="UiMetaFields.Names">
        </c2h-data-entry-name>
      </div>

      <!-- addresses -->
      <div class="mb-2" *ngIf="UiMetaFields.Addresses?.Max > 0">
        <h3 class="header-2 text-green">
          {{ 'CheckToHire.lblDataEntryAddresses' | translate }}
        </h3>
        <c2h-data-entry-address
          [Data]="Data.Addresses"
          [UiMeta]="UiMetaFields.Addresses">
        </c2h-data-entry-address>
      </div>

      <!-- mvrs -->
      <div class="mb-2" *ngIf="UiMetaFields.Mvrs?.Max > 0">
        <h3 class="header-2 text-green">
          {{ 'CheckToHire.lblDataEntryMvrs' | translate }}
        </h3>
        <c2h-data-entry-mvr [Data]="Data.Mvrs" [UiMeta]="UiMetaFields.Mvrs">
        </c2h-data-entry-mvr>
      </div>
      <!-- actions -->
      <div class="text-end">
        <zc-button
          color="info"
          outline
          class="me-1"
          [busy]="BusyCanceling"
          (click)="OnCancel()">
          {{ 'Common.lblCancel' | translate }}
        </zc-button>
        <zc-button color="primary" type="submit" [busy]="BusySaving">
          {{ 'Common.cmdNext' | translate }}
        </zc-button>
      </div>
    </form>
  </div>
</div>
