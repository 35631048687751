import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { FeatureFlagService } from './feature-flag.service';

/**
 * Route guard to restrict access based on feature flag.
 */
@Injectable({ providedIn: 'root' })
export class FeatureFlagGuard implements CanActivate {
  constructor(private featureFlag: FeatureFlagService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const flag = route.data.featureFlag;

    return this.featureFlag.isOn(flag);
  }
}
