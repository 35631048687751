<div class="row">
  <div class="col-xs-6">
    <h4>
      <b>{{ 'Common.lblContacts' | translate }}</b>
    </h4>
  </div>
  <div class="col-xs-6 text-end" *zcHasRights="['Contact', 'Add']">
    <a [routerLink]="['./', 0]" class="btn btn-primary">
      {{ 'Common.cmdAddNew' | translate }}
    </a>
  </div>
</div>

<!-- contacts -->
<zc-table #table [data]="contacts" [busy]="busy">
  <zc-column headerL10nKey="Common.lblName">
    <ng-template zcCell let-row="row">{{ row.Name.FullName }}</ng-template>
  </zc-column>
  <zc-column headerL10nKey="Common.lblUsername">
    <ng-template zcCell let-row="row">{{
      row.ExternalAccess.Username
    }}</ng-template>
  </zc-column>
  <zc-column headerL10nKey="Common.lblEmail">
    <ng-template zcCell let-row="row">{{
      row.PrimaryEmail?.Value
    }}</ng-template>
  </zc-column>
  <zc-column headerL10nKey="Common.lblLastLoggedIn">
    <ng-template zcCell let-row="row">{{
      row.ExternalAccess.LastLogin | date : 'short'
    }}</ng-template>
  </zc-column>
  <zc-column headerL10nKey="Common.lblActions">
    <ng-template zcCell let-row="row">
      <a [routerLink]="['./', row.ID]">
        <fa-icon icon="edit"></fa-icon>
        <span class="ms-1">{{ 'Common.lblEdit' | translate }}</span>
      </a>
    </ng-template>
  </zc-column>
</zc-table>

<!-- empty -->
<zc-empty
  *ngIf="table.isEmpty"
  file="img/no-users.svg"
  titleL10nKey="Common.lblNoUsers"
  descriptionL10nKey="Common.lblNoUsersDescription">
  <a
    *zcHasRights="['Contact', 'Add']"
    [routerLink]="['./', 0]"
    class="btn btn-primary">
    {{ 'Common.cmdAddNew' | translate }}
  </a>
</zc-empty>
