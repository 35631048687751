import { IDomainRole } from '../generated';

/**
 * Domain role.
 */
export class DomainRole {
  constructor(source?: Partial<IDomainRole>) {
    this._Init(source);
  }

  ID: number;
  Name: string;
  Description: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IDomainRole>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Name = source.Name;

    if (source.Blurb) {
      this.Description = source.Blurb;
    }
  }
}
