import { NgModule } from '@angular/core';
import {
  FormsModule as NgFormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@zipcrim/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';

import { CheckboxComponent } from './checkbox/checkbox.component';
import { DateComponent } from './date/date.component';
import { ErrorComponent } from './error/error.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FORM_ICONS } from './form.icons';
import { FuzzyDateModalComponent } from './fuzzy-date/fuzzy-date-modal.component';
import { FuzzyDateComponent } from './fuzzy-date/fuzzy-date.component';
import { HelpTextComponent } from './help-text/help-text.component';
import { InputComponent } from './input/input.component';
import { LabelComponent } from './label/label.component';
import { RadioButtonComponent } from './radio-group/radio-button.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { SelectComponent } from './select/select.component';
import { SummaryGroupComponent } from './summary/summary-group.component';
import { SummaryComponent } from './summary/summary.component';
import { TextareaComponent } from './textarea/textarea.component';
import { PasswordDirective } from './validators/password.directive';
import { RequireCheckedDirective } from './validators/require-checked.directive';
import { SpanComponent } from './span/span.component';
import { AriaLabelComponent } from './aria-label/aria-label.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { NativeElementInjectorDirective } from './input-phone/tel-input/directives/native-element-injector.directive';
import { NgxIntlTelInputComponent } from './input-phone/tel-input/ngx-intl-tel-input.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    CommonModule,
    NgFormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxMaskDirective,
  ],
  declarations: [
    CheckboxComponent,
    DateComponent,
    ErrorComponent,
    FormFieldComponent,
    FuzzyDateComponent,
    FuzzyDateModalComponent,
    HelpTextComponent,
    InputComponent,
    LabelComponent,
    PasswordDirective,
    RadioButtonComponent,
    RadioGroupComponent,
    RequireCheckedDirective,
    SelectComponent,
    SummaryComponent,
    SummaryGroupComponent,
    TextareaComponent,
    SpanComponent,
    AriaLabelComponent,
    AutoCompleteComponent,
    InputPhoneComponent,
    NativeElementInjectorDirective,
    NgxIntlTelInputComponent,
  ],
  exports: [
    CheckboxComponent,
    CommonModule,
    DateComponent,
    ErrorComponent,
    FormFieldComponent,
    FuzzyDateComponent,
    HelpTextComponent,
    InputComponent,
    LabelComponent,
    NgFormsModule,
    PasswordDirective,
    RadioButtonComponent,
    RadioGroupComponent,
    ReactiveFormsModule,
    RequireCheckedDirective,
    SelectComponent,
    SummaryComponent,
    SummaryGroupComponent,
    TextareaComponent,
    SpanComponent,
    AutoCompleteComponent,
    InputPhoneComponent,
    NgxIntlTelInputComponent,
  ],
  providers: [provideEnvironmentNgxMask()],
})
export class FormsModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(...FORM_ICONS);
  }
}
