import { Component } from '@angular/core';

/**
 * Modal component.
 *
 * ```html
 * <zc-modal>
 *   <zc-modal-header>
 *     Title
 *   </zc-modal-header>
 *   <zc-modal-body>
 *     <p>Modal content goes here!</p>
 *   </zc-modal-body>
 *   <zc-modal-footer>
 *     <zc-button>Done</zc-button>
 *   </zc-modal-footer>
 * <zc-modal>
 * ```
 */
@Component({
  selector: 'zc-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {}
