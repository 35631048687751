import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnDestroy,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { startWith, takeUntil, tap } from 'rxjs/operators';

import { ERROR_MESSAGES } from '../error/error-messages';
import { LabelComponent } from '../label/label.component';
import { SpanComponent } from '../span/span.component';
import { FormFieldControl } from './form-field-control';

interface ErrorMessage {
  message: string;
  params?: Record<string, any>;
}

/**
 * Available form field layouts.
 */
export type FormFieldLayout = 'horizontal';

@Component({
  selector: 'zc-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements AfterContentInit, OnDestroy {
  constructor(private ngForm: NgForm, private ref: ChangeDetectorRef) {}

  @ContentChild(LabelComponent) label: LabelComponent;
  @ContentChild(FormFieldControl)
  get control() {
    return this._control;
  }
  set control(value) {
    this._control = value;
    this.attachControl();
  }
  @ContentChild(SpanComponent) span: SpanComponent;
  @Input() busy = false;
  @Input() horizontal = false;
  @Input() ariaDescription = '';
  id: string;
  required: boolean;
  errors: ErrorMessage[] = [];
  get showErrors() {
    if (this.control?.ngControl?.touched){
      return this.control.ngControl.touched || this.ngForm.submitted;
    }
    else
    {
      return this.ngForm.submitted;
    }
  }

  private stateSubscription: Subscription;
  private _control: FormFieldControl<any>;
  private readonly _destroyed = new Subject<void>();

  ngAfterContentInit() {
    this.ngForm.ngSubmit
      .pipe(
        takeUntil(this._destroyed),
        tap(() => this.ref.markForCheck())
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroyed.next();
  }

  private attachControl() {
    this.stateSubscription?.unsubscribe();
    this.stateSubscription = this.control.stateChanges
      .pipe(
        startWith([]),
        takeUntil(this._destroyed),
        tap(() => {
          this.id = this.control.id;
          this.required = this.control.required;
          this.errors = this.getErrors();
          this.ref.markForCheck();
        })
      )
      .subscribe();
  }

  private getErrors() {
    const { errors } = this.control.ngControl.control;

    if (!errors) {
      return [];
    }

    return Object.keys(errors).map((key) => ({
      message: ERROR_MESSAGES[key],
      params: errors[key],
    }));
  }
}
