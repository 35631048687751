import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Self,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { L10nService } from '@zipcrim/common';
import { toNumber } from 'lodash';
import uniqueId from 'lodash/uniqueId';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { FormFieldControl } from '../form-field/form-field-control';
import { FormService } from '../form.service';
import { OptionConfig } from '../forms.interfaces';
import { DateFormat } from './date.interfaces';
import { DateService } from './date.service';

@Component({
  selector: 'zc-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormFieldControl,
      useExisting: DateComponent,
    },
  ],
})
export class DateComponent
  implements
    ControlValueAccessor,
    FormFieldControl<string>,
    OnInit,
    OnChanges,
    OnDestroy
{
  constructor(
    @Self() public ngControl: NgControl,
    private date: DateService,
    private l10n: L10nService,
    private form: FormService,
    private ref: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;

    const todaysDate = new Date();

    this.todaysYear = todaysDate.getFullYear();
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  get describedBy() {
    return `${this.id}__label ${this.id}__errors`;
  }

  @Input() disabled = false;
  @Input() hideDay = false;
  @Input() id = uniqueId('control-');
  @Input() monthPicker = true;
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() type = 'text';
  required: boolean;
  monthOptions: OptionConfig[] = [
    { value: 1, labelL10nKey: 'Common.lblMonthJan' },
    { value: 2, labelL10nKey: 'Common.lblMonthFeb' },
    { value: 3, labelL10nKey: 'Common.lblMonthMar' },
    { value: 4, labelL10nKey: 'Common.lblMonthApr' },
    { value: 5, labelL10nKey: 'Common.lblMonthMay' },
    { value: 6, labelL10nKey: 'Common.lblMonthJun' },
    { value: 7, labelL10nKey: 'Common.lblMonthJul' },
    { value: 8, labelL10nKey: 'Common.lblMonthAug' },
    { value: 9, labelL10nKey: 'Common.lblMonthSep' },
    { value: 10, labelL10nKey: 'Common.lblMonthOct' },
    { value: 11, labelL10nKey: 'Common.lblMonthNov' },
    { value: 12, labelL10nKey: 'Common.lblMonthDec' },
  ];
  year: number;
  month: number;
  day: number;
  /**
   * Note: The DOM elements need to be physically positioned in the correct order to prevent
   * issues with the <tab> key on form inputs. Flexbox ordering is not respected by the <tab> key.
   * */
  format: DateFormat = 'year-month-day';
  readonly stateChanges = new Subject<void>();

  private _value: string;
  private yearSnapshot = '';
  private daySnapshot = '';
  private monthSnapshot = '';
  private readonly _destroyed = new Subject<void>();
  private readonly todaysYear: number;

  ngOnInit() {
    const locale = this.l10n.currentLocale;
    const order = this.l10n.getDatePartsOrder(locale);

    this.format = order.join('-') as DateFormat;

    this.setRequired();
    this.ngControl.statusChanges
      .pipe(
        takeUntil(this._destroyed),
        tap(() => this.setRequired())
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stateChanges.next();

    if (changes.hideDay && changes.hideDay.currentValue) {
      this.day = null;
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this.stateChanges.complete();
  }

  onInput() {
    this.value = this.date.toIso({
      year: this.year,
      month: this.month,
      day: this.day,
    });
  }

  onBlur() {
    this.onTouched();
    this.stateChanges.next();
  }

  onChange = (_: any) => {
    // empty
  };

  onTouched = () => {
    // empty
  };

  writeValue(value: string) {
    this.value = value;
    const parts = this.date.fromIso(value);

    this.year = parts.year;
    this.month = parts.month;
    this.day = parts.day;

    this.ref.markForCheck();
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.ref.markForCheck();
  }

  onKeyUp(event: any) {
    this.yearSnapshot = event.target.value;
  }

  onDayKeyUp(event: any) {
    this.daySnapshot = event.target.value;
  }

  onMonthKeyUp(event: any) {
    this.monthSnapshot = event.target.value;
  }

  onYearFocusOut() {
    if (`${this.year}`.length !== 2 && this.yearSnapshot.length !== 2) {
      return;
    }

    const yearOperation = `${this.todaysYear - 100}`.substr(-2);
    const yearStringToInt = toNumber(yearOperation) + 10;

    if (this.yearSnapshot.split('')[0] === '0') {
      const convert = `20${this.yearSnapshot}`;
      this.year = toNumber(convert);
    } else if (this.year > yearStringToInt) {
      const convert = `19${this.year}`;
      this.year = toNumber(convert);
    } else {
      const convert = `20${this.year}`;
      this.year = toNumber(convert);
    }

    this.onInput();
  }

  onDayFocusOut() {
    if (!this.day && this.daySnapshot === '0') {
      this.day = 1;

      this.onInput();
    }
  }

  onMonthFocusOut() {
    if (!this.month && this.monthSnapshot === '0') {
      this.month = 1;

      this.onInput();
    }
  }

  private setRequired() {
    const ctrl = this.ngControl.control;

    this.required = this.form.hasRequired(ctrl);
    this.stateChanges.next();
  }
}
