import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches a valid address.
 */
const ADDRESS_REGEX = /^(?!\d+$)[\w\s-.@&$]{1,30}$/i;

/**
 * Validate a street address.
 */
export function AddressValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (!value || ADDRESS_REGEX.test(value)) {
    return null;
  }

  return {
    address: true,
  };
}
