<ul class="errors" *ngIf="ShowErrors" aria-live="assertive" zcFormFieldAriaId>
  <!-- form control errors -->
  <li class="errors__item" *ngFor="let error of Errors">
    <fa-icon icon="exclamation-triangle" class="mr-05"></fa-icon>
    {{ error.message | translate: error.params }}
  </li>

  <!-- children components -->
  <li class="errors__item" *ngFor="let item of Children">
    <fa-icon icon="exclamation-triangle" class="mr-05"></fa-icon>
    <ng-container *ngTemplateOutlet="item.Template"></ng-container>
  </li>
</ul>
