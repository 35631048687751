<a
  class="nav__item"
  [ngClass]="css"
  [routerLink]="link"
  [queryParams]="queryParams"
  routerLinkActive="nav__item--active"
  [routerLinkActiveOptions]="activeOptions"
>
  <ng-content></ng-content>
</a>
