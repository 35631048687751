import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faExclamationTriangle,
  faMinus,
  faPlus,
  faQuestionCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

export const FORM_ICONS = [
  faCalendarAlt,
  faExclamationTriangle,
  faMinus,
  faPlus,
  faQuestionCircle,
  faTrashAlt,
];
