import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CaseService, ILead, ModalService } from '@zipcrim/common';
import { Case, Lead } from '@zipcrim/common/models';
import max from 'date-fns/max';
import get from 'lodash/get';
import { finalize } from 'rxjs/operators';

import { CheckToHireSvcService } from '../common/check-to-hire-svc.service';

/**
 * Case card.
 *
 * ```html
 * <c2h-case-card [Data]="MyCase"></c2h-case-card>
 * ```'
 */
@Component({
  selector: 'c2h-case-card',
  templateUrl: './case-card.component.html',
  styleUrls: ['./case-card.component.scss'],
})
export class CaseCardComponent {
  constructor(
    private _C2HService: CheckToHireSvcService,
    private _Case: CaseService,
    private _Modal: ModalService,
    private _Router: Router
  ) {}

  /**
   * Case data.
   */
  @Input()
  Case: Case;

  /**
   * Busy indicator.
   */
  @Input()
  Busy: boolean;

  /**
   * Event emitted after pinned state is changed.
   */
  @Output()
  OnPinnedChange = new EventEmitter<Case>();

  /**
   * Event emitted after hidden state is changed.
   */
  @Output()
  OnHiddenChange = new EventEmitter<Case>();

  /**
   * Busy indicator for toggle pinned.
   */
  BusySavingPinned: boolean;

  /**
   * Busy indicator for toggle hidden.
   */
  BusySavingHidden: boolean;

  /**
   * Busy indicator for navigating to case details.
   */
  BusyNavigating: boolean;

  /**
   * Get full name for display.
   */
  get FullName() {
    return get(this.Case, 'Subject.Names[0].FullName', '-');
  }

  /**
   * Get ssn for display.
   */
  get Ssn() {
    const value = get(this.Case, 'Subject.Ssns[0].SSN');
    const lastFour = value ? value.slice(-4) : 'xxxx';

    return `xxx-xx-${lastFour}`;
  }

  /**
   * Get owner name for display.
   */
  get OwnerName() {
    return get(this.Case, 'Owner.Name.FullName', '-');
  }

  /**
   * Get last lead's tiny name for display.
   */
  get LastLeadTinyName() {
    const length = this.Case.Leads.length;

    return length > 0 ? this.Case.Leads[length - 1].LeadType.TinyName : '-';
  }

  /**
   * Get complete date for display.
   */
  get Completed() {
    const leadList = this.Case.Leads;

    if (!leadList) {
      return '';
    }

    const completedLeadList = leadList.filter(
      (leadDetails) => leadDetails.Completed
    );

    if (completedLeadList.length === 0) {
      return '';
    }

    return max(completedLeadList.map((e) => new Date(e.Completed)));
  }

  /**
   * Navigate to a given case.
   *
   * @param lead Optional lead to include in route url.
   */
  Navigate(lead?: Lead | ILead) {
    const id = this.Case.ID;
    const caseNum = this.Case.CaseNum;

    this.BusyNavigating = true;
    this._C2HService
      .GetCaseSummary(caseNum)
      .pipe(finalize(() => (this.BusyNavigating = false)))
      .subscribe((res) => {
        const index = this._C2HService.FindCaseSummaryIndex(res, lead?.LeadNum);
        const leadNum = res[index].LeadNum;

        this._Router
          .navigate(['reports', id, caseNum, 'show-report'], {
            queryParams: { leadNum },
          })
          .then((value) => {
            if (value) {
              return;
            }

            this._Modal.confirm({
              titleL10nKey: 'Common.lblAccessDenied',
              messageL10nKey: 'CheckToHire.msgUnauthorizedToAccessCase',
            });
          });
      });
  }

  /**
   * Navigate to the last completed lead.
   */
  NavigateToLastCompleted() {
    const tinyName = this.LastLeadTinyName;
    const items = this.Case.Leads;

    if (items.length === 0) {
      return;
    }

    const lead = items.find((item) => item.LeadType.TinyName === tinyName);
    this.Navigate(lead);
  }

  /**
   * Toggle pinned.
   */
  TogglePinned() {
    const caseNum = this.Case.CaseNum;
    const isPinned = !this.Case.IsPinned;

    this.Case.IsPinned = isPinned;

    this.BusySavingPinned = true;
    this._Case
      .togglePin(caseNum, isPinned)
      .pipe(finalize(() => (this.BusySavingPinned = false)))
      .subscribe(() => this.OnPinnedChange.emit(this.Case));
  }

  /**
   * prompt user and toggle hidden.
   */
  ToggleHiddenWithConfirm() {
    this._Modal
      .confirm({
        messageL10nKey: 'Common.lblAreYouSureArchive',
      })
      .then(
        () => {
          this._ToggleHidden();
        },
        () => {
          // empty
        }
      );
  }

  /**
   * Toggle hidden.
   */
  private _ToggleHidden() {
    const caseNum = this.Case.CaseNum;
    const isHidden = !this.Case.IsHidden;

    this.Case.IsHidden = isHidden;

    this.BusySavingHidden = true;
    this._Case
      .toggleHide(caseNum, isHidden)
      .pipe(finalize(() => (this.BusySavingHidden = false)))
      .subscribe(() => this.OnHiddenChange.emit(this.Case));
  }
}
