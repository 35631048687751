import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FieldSpec,
  IUiMetaFields,
  ModalService,
  SaveSpec,
  SubjectService,
  WorkOrderService,
} from '@zipcrim/common';
import { getDate, getMonth, getYear, parse } from 'date-fns';
import get from 'lodash/get';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { PrepareOrderModalComponent } from '../prepare-order-modal/prepare-order-modal.component';
import { ReportComponent } from '../report.component';
import { ReportService } from '../report.service';

@Component({
  templateUrl: './prepare-order.component.html',
  styleUrls: ['./prepare-order.component.scss'],
})
export class PrepareOrderComponent implements OnInit {
  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _BsModalService: BsModalService,
    private _Modal: ModalService,
    private _Parent: ReportComponent,
    private _ReportService: ReportService,
    private _Router: Router,
    private _SubjectService: SubjectService,
    private _WorkOrder: WorkOrderService
  ) {}

  /**
   * Order form reference.
   */
  @ViewChild('OrderForm')
  OrderForm: NgForm;

  /**
   * Subject data.
   */
  Data: any;

  /**
   * Case number.
   */
  CaseNumber: string;

  /**
   * Ui meta fields.
   */
  UiMetaFields: IUiMetaFields;

  /**
   * Busy loading indicator.
   */
  BusyLoading: boolean;

  /**
   * Busy saving indicator.
   */
  BusySaving: boolean;

  /**
   * Busy canceling indicator.
   */
  BusyCanceling: boolean;

  /**
   * Used to return join of all lead name
   */
  get JoinOfAllLeadName() {
    return this._ReportService.NextStepTitle;
  }

  /**
   * On init.
   */
  ngOnInit() {
    this.BusyLoading = true;
    this._LoadData()
      .pipe(finalize(() => (this.BusyLoading = false)))
      .subscribe();
  }

  /**
   * On submit.
   */
  OnSubmit() {
    if (this.OrderForm.invalid) {
      return;
    }

    const data = this.OrderForm.value;
    data.ID = this._ReportService.SubjectId;
    data.WorkOrderContext = {
      ID: this._ReportService.WorkOrderId,
    };

    if (data.Dobs) {
      data.Dobs = data.Dobs.map((item: any) => {
        const date = parse(item.FullDate, 'MM/dd/yyyy', new Date());

        return {
          ...item,
          Month: getMonth(date) + 1,
          Day: getDate(date),
          Year: getYear(date),
        };
      });
    }

    const spec = new SaveSpec('Subject', data);

    this.BusySaving = true;
    this._SubjectService
      .save(this._ReportService.SubjectId, spec)
      .pipe(finalize(() => (this.BusySaving = false)))
      .subscribe(() => {
        this._Router.navigate(['../finalize-order'], {
          relativeTo: this._ActivatedRoute,
        });
      });
  }

  /**
   * Open modal to edit stage.
   */
  OnEditStage() {
    this._BsModalService.show(PrepareOrderModalComponent, {
      initialState: {
        ReportService: this._ReportService,
      },
    });
  }

  /**
   * On cancel.
   */
  OnCancel() {
    this._Modal
      .confirm({
        messageL10nKey: 'Common.lblAreYouSure',
      })
      .then(
        () => {
          this._Remove();
        },
        () => {
          // empty
        }
      );
  }

  /**
   * Load needed all data.
   */
  private _LoadData() {
    const fieldSpec = new FieldSpec()
      .default()
      .add(['Ssns', 'Names', 'Dobs', 'Addresses', 'Mvrs']);
    const params = new HttpParams().append('fieldSpec', fieldSpec.toString());

    return combineLatest([
      this._WorkOrder.getUiMeta(this._ReportService.WorkOrderId),
      this._SubjectService.get(this._ReportService.SubjectId, params),
    ]).pipe(
      tap((res) => {
        const fields = get(res[0], 'UiMeta.Fields.Subject.Fields', {});
        const { Ssns, Names, Dobs, Addresses, Mvrs } = res[1];

        this.Data = {
          Ssns: Ssns.Items,
          Names: Names.Items,
          Dobs: Dobs.Items,
          Addresses: Addresses.Items,
          Mvrs: Mvrs.Items,
        };

        const ssns = Ssns.Items.filter((item) => (item as any).UiMeta);
        const dobs = Dobs.Items.filter((item) => (item as any).UiMeta);
        this.Data.DOBReadOnly =
          dobs.length === 0 || get(dobs[0], 'UiMeta.Display') === 'ReadOnly';
        this.Data.SSNReadOnly =
          ssns.length === 0 || get(ssns[0], 'UiMeta.Display') === 'ReadOnly';
        this.UiMetaFields = fields;
      })
    );
  }

  /**
   * Remove work order and redirect.
   */
  private _Remove() {
    this.BusyCanceling = true;
    this._WorkOrder
      .remove(this._ReportService.WorkOrderId)
      .pipe(finalize(() => (this.BusyCanceling = false)))
      .subscribe(() => {
        this._Parent.RefreshNextStep()
          .subscribe(() => {
            this._Router.navigate([
              'reports',
              this._ReportService.CaseId,
              this.CaseNumber,
              'show-report',
            ]);
          });
      });
  }
}
