import {
  AfterViewInit,
  Component,
  HostListener,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiResult, ISignature, NotificationService } from '@zipcrim/common';
import { JobService } from '@zipcrim/work-flow';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Document, Signature } from '../models';
import { SignatureCompleteData } from './signature-modal.interfaces';

/**
 * Document signature modal.
 */
@Component({
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss'],
})
export class SignatureModalComponent implements AfterViewInit {
  constructor(
    private jobService: JobService,
    private bsModalRef: BsModalRef,
    private notification: NotificationService
  ) {
    this.onComplete = this.completeEmitter.asObservable();
  }

  /**
   * Signature pad options.
   */
  signaturePadOptions = {
    minWidth: 2,

    // height/width must match CSS properties
    canvasHeight: 285,
    canvasWidth: 500,
  };

  /**
   * Authorization type.
   */
  authorizationType = 'Password';

  /**
   * User input authorization value.
   */
  authValue: string;

  /**
   * Previous signature base64 string.
   */
  previousSignature: string;

  /**
   * Signature complete event.
   */
  onComplete: Observable<SignatureCompleteData>;

  /**
   * Indicates if the signature is empty.
   */
  isSignatureEmpty = true;

  /**
   * Current document to sign for.
   */
  document: Document;

  /**
   * Current signature info to sign for.
   */
  signatureInfo: ISignature;

  /**
   * Busy indicator.
   */
  busy: boolean;

  /**
   * Custom validation message.
   */
  message: string;

  /**
   * Store the status of previous signature.
   */
  usePreviousSignature = false;

  /**
   * Signature complete emitter.
   */
  protected completeEmitter = new Subject<SignatureCompleteData>();

  /**
   * Signature pad reference.
   *
   * TODO: `angular2-signaturepad` was not compatible with newer Angular version. Need to find
   * something new to use.
   */
  @ViewChild('pad', { static: true })
  private signaturePad: any;

  /**
   * Form reference.
   */
  @ViewChild('form', { static: true })
  private form: NgForm;

  /**
   * Base64 image prefix.
   */
  private readonly base64prefix = 'data:image/png;base64,';

  /**
   * Scale canvas to handle high DPI screens.
   */
  @HostListener('window:resize')
  onWindowResize() {
    this.signaturePad.resizeCanvas();
  }

  /**
   * After view init.
   */
  ngAfterViewInit() {
    setTimeout(() => this.signaturePad.resizeCanvas());
  }

  /**
   * Load previous signature.
   *
   * @param value Checkbox value.
   */
  loadSignature(value: boolean) {
    if (value) {
      const data = this.base64prefix + this.previousSignature;
      this.signaturePad.clear();
      this.signaturePad.fromDataURL(data);
      this.isSignatureEmpty = false;
      // todo: focus password input
      this.signaturePad.off();
    } else {
      this.signaturePad.on();
      this.clearSignature();
    }
  }

  /**
   * Clear signature.
   */
  clearSignature() {
    this.signaturePad.clear();
    this.isSignatureEmpty = true;
  }

  /**
   * On draw complete.
   */
  onDrawComplete() {
    this.isSignatureEmpty = this.signaturePad.isEmpty();
  }

  /**
   * On complete.
   */
  onSubmit() {
    if (this.form.invalid || this.isSignatureEmpty) {
      return;
    }

    const data = this.signaturePad.toDataURL();
    const image = data.substring(this.base64prefix.length);
    const authKey = this.authorizationType.toLowerCase();
    const signature = new Signature({
      [authKey]: this.authValue,
      image,
      sectionName: this.signatureInfo.SectionName,
      signatureName: this.signatureInfo.Name,
      version: this.document.UpdateVersion,
    });

    // reset
    this.message = null;

    this.busy = true;
    this.jobService
      .formSigned(this.document.JobId, signature)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        () => {
          this.completeEmitter.next({ image });
          this.bsModalRef.hide();
          this.notification.removeAll();
        },
        (error) => {
          if (error && error instanceof ApiResult) {
            this.message = error.message.blurb;
          }
        }
      );
  }

  /**
   * On modal close.
   */
  onClose() {
    this.bsModalRef.hide();
    this.notification.removeAll();
  }
}
