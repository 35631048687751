import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { SidebarState } from './sidebar.interfaces';
import { SidebarService } from './sidebar.service';

/**
 * Sidebar toggle.
 *
 * ```html
 * <zc-button zcSidebarToggle="mySidebarId">
 *   Toggle me!
 * </zc-button>
 * ```
 */
@Directive({
  selector: '[zcSidebarToggle]',
})
export class SidebarToggleDirective implements OnInit, OnDestroy {
  constructor(private sidebar: SidebarService) {}

  @Input() sidebarId: string;
  @Input() autoHide = true;
  @HostBinding('hidden') isHidden: boolean;
  private openSubscription: Subscription;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
    this.sidebar.toggle(this.sidebarId);
  }

  ngOnInit() {
    const component = this.sidebar.getById(this.sidebarId);

    if (component) {
      this.openSubscription = component.stateChange.subscribe(
        (state: SidebarState) => {
          this.isHidden = this.autoHide && state.isFixed;
        }
      );
    } else {
      this.isHidden = true;
    }
  }

  ngOnDestroy() {
    if (this.openSubscription) {
      this.openSubscription.unsubscribe();
    }
  }
}
