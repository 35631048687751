import { Component, Input, OnChanges } from '@angular/core';

import { TimelineDotState } from './timeline.interfaces';

@Component({
  selector: 'zc-timeline-dot',
  templateUrl: './timeline-dot.component.html',
  styleUrls: ['./timeline-dot.component.scss'],
})
export class TimelineDotComponent implements OnChanges {
  @Input() state: TimelineDotState;
  css: string;

  ngOnChanges() {
    this.setCss();
  }

  private setCss() {
    this.css = this.state ? `timeline__dot--${this.state}` : null;
  }
}
