<!-- sample report download -->
<div class="well well--signup">
  <div class="row">
    <div class="col-sm-9">
      <zc-image
        file="img/doc-form.png"
        alt="Document Image"
        class="me-2"></zc-image>
      <div class="well-content">
        <h3 class="mb-0">{{ 'CheckToHire.lblSampleReport' | translate }}</h3>
        <p>{{ 'CheckToHire.lblSampleReportDescription' | translate }}</p>
      </div>
    </div>
    <div class="col-sm-3 text-end">
      <a
        target="_self"
        href="pdf/Sample-MVR-Report.pdf"
        download="Sample Report.pdf"
        class="btn well__button">
        {{ 'Common.cmdDownload' | translate }}
      </a>
    </div>
  </div>
</div>

<h1 class="header-1 text-center">
  {{ 'CheckToHire.lblSignUpTitle' | translate }}
</h1>
<div class="row">
  <div class="col-sm-8 col-sm-offset-2">
    <form class="home-form" (ngSubmit)="OnSubmit()">
      <ng-container [formGroup]="FormGroup">
        <fieldset class="mb-2">
          <legend>{{ 'CheckToHire.lblMoreCompanyInfo' | translate }}</legend>

          <zc-form-field>
            <zc-label>{{ 'Common.lblCompanyName' | translate }}</zc-label>
            <zc-input formControlName="Name"></zc-input>
          </zc-form-field>

          <ng-container formGroupName="Address">
            <zc-form-field>
              <zc-label>{{ 'Common.lblAddressStreet' | translate }}</zc-label>
              <zc-input formControlName="Address1"></zc-input>
            </zc-form-field>

            <zc-form-field>
              <zc-label>{{ 'CheckToHire.lblAddress2' | translate }}</zc-label>
              <zc-input formControlName="Address2"></zc-input>
            </zc-form-field>

            <div class="row">
              <div class="col-md-6">
                <zc-form-field>
                  <zc-label>{{
                    'Common.lblAddressCountry' | translate
                  }}</zc-label>
                  <zc-select
                    formControlName="CountryCode"
                    [optionsAsync]="CountryOptions$"></zc-select>
                </zc-form-field>
              </div>
              <div class="col-md-6">
                <zc-form-field>
                  <zc-label>{{ 'Common.lblCity' | translate }}</zc-label>
                  <zc-input formControlName="City"></zc-input>
                </zc-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <zc-form-field>
                  <zc-label>{{
                    'Common.lblAddressState' | translate
                  }}</zc-label>
                  <zc-select
                    formControlName="State"
                    [optionsAsync]="StateOptions$"></zc-select>
                </zc-form-field>
              </div>
              <div class="col-md-6">
                <zc-form-field>
                  <zc-label>{{ 'Common.lblAddressZip' | translate }}</zc-label>
                  <zc-input formControlName="ZipCode"></zc-input>
                </zc-form-field>
              </div>
            </div>
          </ng-container>
        </fieldset>

        <fieldset class="mb-2">
          <legend>{{ 'CheckToHire.lblEmploymentInfo' | translate }}</legend>

          <zc-form-field>
            <zc-label>
              {{ 'CheckToHire.lblThirdPartyReseller' | translate }}
            </zc-label>
            <zc-radio-group formControlName="IsAThirdParty">
              <zc-radio-button
                *ngFor="let item of IsAThirdPartyOptions"
                [value]="item.value">
                {{ item.labelL10nKey | translate }}
              </zc-radio-button>
            </zc-radio-group>
          </zc-form-field>

          <ng-container formGroupName="Attributes">
            <zc-form-field *ngIf="IsAThirdParty">
              <zc-label>{{ 'CheckToHire.lblDOTNumber' | translate }}</zc-label>
              <zc-input formControlName="DOTNumber"></zc-input>
            </zc-form-field>
            {{setDOTNumberValidators(IsAThirdParty)}}
          </ng-container>
        </fieldset>

        <fieldset class="mb-2">
          <legend>{{ 'CheckToHire.lblApplicantInfo' | translate }}</legend>
          <ng-container formGroupName="Contact">
            <zc-form-field>
              <zc-label>{{ 'Common.lblFirstName' | translate }}</zc-label>
              <zc-input formControlName="FirstName"></zc-input>
            </zc-form-field>

            <zc-form-field>
              <zc-label>{{ 'Common.lblLastName' | translate }}</zc-label>
              <zc-input formControlName="LastName"></zc-input>
            </zc-form-field>

            <zc-form-field>
              <zc-label>{{ 'Common.lblTitle' | translate }}</zc-label>
              <zc-input formControlName="Title"></zc-input>
            </zc-form-field>

            <zc-form-field>
              <zc-label>{{ 'Common.lblPhone' | translate }}</zc-label>
              <zc-input type="tel" formControlName="Phone"></zc-input>
            </zc-form-field>

            <zc-form-field>
              <zc-label>{{ 'Common.lblEmail' | translate }}</zc-label>
              <zc-input type="email" formControlName="Email"></zc-input>
            </zc-form-field>

            <zc-form-field>
              <zc-label>{{ 'Common.lblCreatePassword' | translate }}</zc-label>
              <zc-input type="password" formControlName="Password"></zc-input>
              <zc-help-text>
                {{ 'CheckToHire.lblPasswordStandard' | translate }}
              </zc-help-text>
            </zc-form-field>

            <!-- <zc-form-field>
              <zc-label>{{ 'Common.lblConfirmPassword' | translate }}</zc-label>
              <zc-input
                type="password"
                formControlName="ConfirmPassword"
              ></zc-input>
            </zc-form-field> -->
          </ng-container>
        </fieldset>

        <zc-form-field>
          <zc-label>
            {{ 'Common.lblTerms' | translate }}
          </zc-label>
          <iframe
            class="terms"
            [src]="EulaUrl"
            *ngIf="EulaUrl; else noTerms"></iframe>
          <ng-template #noTerms>
            <p>Terms of service not available.</p>
          </ng-template>
          <zc-checkbox formControlName="Terms">
            {{ 'Common.lblTermsAgreement' | translate }}
          </zc-checkbox>
        </zc-form-field>

        <div class="text-center">
          <zc-button type="submit" color="primary" [busy]="BusySaving">
            {{ 'Common.cmdCreateAccount' | translate }}
          </zc-button>
        </div>
      </ng-container>
    </form>
  </div>
</div>
