import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import {
  FuzzyDateParts,
  FuzzyDateService,
  FuzzyDateStaticCode,
  L10nService,
} from '@zipcrim/common';
import { ButtonComponent } from '@zipcrim/common/button/button.component';
import uniqueId from 'lodash/uniqueId';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { FormFieldControl } from '../form-field/form-field-control';
import { FormService } from '../form.service';
import { FuzzyDateModalComponent } from './fuzzy-date-modal.component';

@Component({
  selector: 'zc-fuzzy-date',
  templateUrl: './fuzzy-date.component.html',
  styleUrls: ['./fuzzy-date.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormFieldControl,
      useExisting: FuzzyDateComponent,
    },
  ],
})
export class FuzzyDateComponent
  implements
    ControlValueAccessor,
    FormFieldControl<string>,
    OnInit,
    OnChanges,
    OnDestroy
{
  constructor(
    @Self() public ngControl: NgControl,
    private bsModal: BsModalService,
    private form: FormService,
    private fuzzyDate: FuzzyDateService,
    private l10n: L10nService,
    private ref: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();

    if (!value) {
      // clear display value
      this.displayValue = null;
    }
  }

  @Input() allowedStaticCodes: FuzzyDateStaticCode[];
  @Input() disabled = false;
  @Input() id = uniqueId('control-');
  @Input() label: string;
  @Input() max: Date;
  @Input() min: Date;
  @Input() placeholder = '';
  @Input() readonly = false;
  @ViewChild('btn', { static: false }) buttonRef: ButtonComponent;
  required: boolean;
  displayValue: string;
  isLoading: boolean;
  isFailure: boolean;
  readonly stateChanges = new Subject<void>();

  private _value: any;
  private parts: FuzzyDateParts;
  private control: FormControl;
  private readonly _destroyed = new Subject<void>();

  ngOnInit() {
    this.setRequired();
    this.ngControl.statusChanges
      .pipe(
        takeUntil(this._destroyed),
        tap(() => this.setRequired())
      )
      .subscribe();
  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this.stateChanges.complete();
  }

  onRetry() {
    this.isFailure = false;
    this.getTranslation();
  }

  onOpenModal() {
    const modal = this.bsModal.show(FuzzyDateModalComponent, {
      class: 'modal-sm',
    });
    const component = modal.content as FuzzyDateModalComponent;

    component.label = this.label;
    component.initialParts = this.parts;
    component.options = {
      allowedStaticCodes: this.allowedStaticCodes,
      required: this.form.hasRequired(this.control),
      min: this.min,
      max: this.max,
    };
    component.onChanges.next();
    component.result.then((res) => {
      this.value = res.Value;
      this.displayValue = res.Translation;
      this.parts = res.Parts;
      this.onTouched();
      this.buttonRef?.focus();
    }).catch(() => {
      this.onTouched();
      this.stateChanges.next();
      this.buttonRef?.focus();
    });
  }

  onChange = (_: any) => {
    // empty
  };

  onTouched = () => {
    // empty
  };

  writeValue(value: any) {
    const hasChanged = this.value !== value;
    this.value = value;

    if (hasChanged) {
      this.getTranslation();
    }

    this.ref.markForCheck();
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.ref.markForCheck();
  }

  private getTranslation() {
    if (!this.value) {
      // no translation needed
      return;
    }

    this.isLoading = true;

    this.fuzzyDate
      .translate({
        fuzzyDateString: this.value,
        fromLanguageCode: this.fuzzyDate.apiLocale,
        toLanguageCode: this.l10n.currentLocale,
        allowedStaticCodes: this.allowedStaticCodes,
      })
      .pipe(
        catchError((error) => {
          this.isFailure = true;
          throw error;
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((res) => {
        this.displayValue = res.Translation;
        this.parts = res.Parts;
      });
  }

  private setRequired() {
    const ctrl = this.ngControl.control;

    this.required = this.form.hasRequired(ctrl);
    this.stateChanges.next();
  }
}
