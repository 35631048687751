import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ApiMessage } from '@zipcrim/common';

@Component({
  selector: 'zc-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent implements OnChanges {
  @Input() apiMessages: ApiMessage[] = [];
  @Input() showIgnore = true;
  @Input() showSource = false;
  @Output() ignore = new EventEmitter<void>();

  errors: ApiMessage[] = [];
  warnings: ApiMessage[] = [];
  info: ApiMessage[] = [];
  message: ApiMessage[] = [];
  params = {
    errors: 0,
    warnings: 0,
    info: 0,
    message: 0,
  };

  ngOnChanges() {
    const errors: ApiMessage[] = [];
    const warnings: ApiMessage[] = [];
    const info: ApiMessage[] = [];
    const message: ApiMessage[] = [];

    this.apiMessages?.forEach(item => {
      const field = item.getFirstUiField();

      if (!field) {
        return;
      }

      switch (field.Severity) {
        case 'Error':
          errors.push(item);
          break;
        case 'Alert':
          if (field.Impact > 1) {
            warnings.push(item);
          } else {
            message.push(item);
          }
          break;
        default:
          info.push(item);
          break;
      }
    });

    this.errors = errors;
    this.warnings = warnings;
    this.info = info;
    this.params = {
      errors: errors.length,
      warnings: warnings.length,
      info: info.length,
      message: message.length,
    };
  }

  onIgnore() {
    this.ignore.emit();
  }
}
