import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ApiMessage, UiMetaService } from '@zipcrim/common';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { flatMap, map, take, tap } from 'rxjs/operators';

import {
  IterableNode,
  NodeData,
  PatchConfig,
  UiMetaConfig,
} from './dynamic-node.interfaces';
import { DynamicNodeService } from './dynamic-node.service';

/**
 * Dynamic node.
 *
 * ```html
 * <form>
 *   <zc-dynamic-node [Config]="myConfig" [Data]="myData"></zc-dynamic-node>
 * </form>
 * ```
 */
@Component({
  selector: 'zc-dynamic-node',
  templateUrl: './dynamic-node.component.html',
  providers: [DynamicNodeService],
})
export class DynamicNodeComponent implements OnChanges, OnInit {
  constructor(
    private _NgForm: NgForm,
    private _NodeService: DynamicNodeService,
    private _UiMetaService: UiMetaService
  ) {
    this.OnFormReady = this._OnFormReady.asObservable();
    this.OnPatch = this._OnPatch.asObservable();
    this.OnUiMeta = this._OnUiMeta.asObservable();
  }

  /**
   * Optional parent form group.
   */
  @Input()
  Form: FormGroup;

  /**
   * Dynamic node configuration.
   */
  @Input()
  Config: IterableNode;

  /**
   * Form data object.
   */
  @Input()
  Data: NodeData;

  /**
   * Collection of validation messages from an API response.
   */
  @Input()
  ApiMessages: ApiMessage[];

  /**
   * Collection of BO names used to apply ui meta information to form controls.
   */
  @Input()
  UiMeta: string[];

  /**
   * Optional mapping of BO object names to form group names.
   */
  @Input()
  UiMetaMap: { [key: string]: string };

  /**
   * Busy indicator.
   */
  @Input()
  Busy: boolean;

  /**
   * Emits when all nodes have rendered.
   */
  @Output()
  OnViewReady = new EventEmitter<void>();

  /**
   * Emits a when all `busy` and any UiMeta requests are completed.
   */
  OnFormReady: Observable<void>;

  /**
   * Emits a patch config value when a patch is issued.
   */
  OnPatch: Observable<PatchConfig>;

  /**
   * Emits a patch config value when the UI meta has loaded successfully.
   */
  OnUiMeta: Observable<UiMetaConfig>;

  /**
   * Indicates if the form has been built.
   */
  private _IsBuilt = false;

  /**
   * On form ready emitter.
   */
  private _OnFormReady = new ReplaySubject<void>(1);

  /**
   * On patch emitter.
   */
  private _OnPatch = new Subject<PatchConfig>();

  /**
   * On UI meta emitter.
   */
  private _OnUiMeta = new ReplaySubject<UiMetaConfig>(1);

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    const data = changes.Data && changes.Data.currentValue;

    if (this._IsBuilt && data) {
      this._NodeService.PatchValue(this.Form, this.Config, data);
    }

    if (this._IsBuilt && changes.Config) {
      // re-build to add any missing controls to the form
      this._NodeService.Build(this.Form, this.Config);
    }
  }

  /**
   * On init.
   */
  ngOnInit() {
    if (!this.Form) {
      this.Form = this._NgForm.form;
    }

    this._NodeService.Build(this.Form, this.Config);
    this._IsBuilt = true;

    if (this.Data) {
      this._NodeService.PatchValue(this.Form, this.Config, this.Data);
    }

    if (this.UiMeta) {
      this._UiMetaService
        .getByNames(this.UiMeta)
        .pipe(
          map((res) =>
            this._NodeService.GetUiMetaConfig(res.Items, this.UiMetaMap)
          ),
          tap((patch) => this._OnUiMeta.next(patch))
        )
        .subscribe();
    }

    this._SetFormReady();
    this._SetViewReady();
  }

  /**
   * Safely updates the configuration object.
   *
   * @param patch Patch configuration.
   */
  PatchConfig(patch: PatchConfig) {
    this._OnPatch.next(patch);
  }

  /**
   * Remove all form controls.
   */
  Clear() {
    this._NodeService.Clear(this.Form);
  }

  /**
   * Set form ready.
   */
  private _SetFormReady() {
    const pending = [];

    // if (this.Busy) {
    //   const busy$ = this._BusyService.ToObservable(this.Busy);
    //   pending.push(busy$);
    // }

    if (this.UiMeta) {
      pending.push(this.OnUiMeta);
    }

    if (pending.length === 0) {
      // nothing to wait for
      this._OnFormReady.next();
      return;
    }

    combineLatest(pending)
      .pipe(take(1))
      .subscribe(() => {
        this._OnFormReady.next();
      });
  }

  /**
   * Set view ready.
   */
  private _SetViewReady() {
    const containers$ =
      this._NodeService.Containers.size > 0
        ? [...this._NodeService.Containers.values()]
        : of(null);

    combineLatest(containers$)
      .pipe(
        // evaluate items **after** containers have emitted, to catch items added async
        flatMap(() =>
          this._NodeService.ControlItems.size > 0
            ? combineLatest([...this._NodeService.ControlItems.values()])
            : of(null)
        ),
        take(1)
      )
      .subscribe(() => {
        this.OnViewReady.emit();
      });
  }
}
