import { ICredential } from '../generated';

/**
 * Credential.
 */
export class Credential {
  constructor(source?: Partial<ICredential>) {
    this._Init(source);
  }

  ID: number;
  Credential: string;
  Authority: string;
  Location: string;
  IssueDate: string;
  ExpirationDate: string;
  LicenseNumber: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<ICredential>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Credential = source.Credential;
    this.Authority = source.Authority;
    this.Location = source.Location;
    this.IssueDate = source.IssueDate;
    this.ExpirationDate = source.ExpirationDate;
    this.LicenseNumber = source.LicenseNumber;
  }
}
