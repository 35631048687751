import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Country code / state mappings.
 */
interface ZipCodeMap {
  [state: string]: RegExp[];
}

/**
 * Expressions to match a valid driver license by US state.
 */
const US_DRIVER_LICENSE_REGEX: ZipCodeMap = {
  AL: [/^[0-9]{1,7}$/],
  AZ: [
    /(^[0-9]{9}$)|(^[a-zA-Z]{2}[0-9]{2,5}$)|(^[a-zA-Z]{1}[0-9]{1,8}$)|(^[a-zA-Z]{2}[0-9]{2,5}$)|(^[0-9]{9}$)|(^[a-zA-Z]{1}[0-9]{8}$)/,
  ],
  AK: [/^[0-9]{7,7}$/],
  AR: [/^[0-9]{4,9}$/],
  CA: [/^[a-zA-Z]{1}[0-9]{7}$/],
  CO: [/(^[a-zA-Z]{2}[0-9]{2,5}$)|(^[a-zA-Z]{1}[0-9]{3,6}$)|(^[0-9]{9}$)/],
  CT: [/^[0-9]{9}$/],
  DE: [/^[0-9]{1,7}$/],
  DC: [/(^[0-9]{9}$)|(^[0-9]{7}$)/],
  FL: [/^[a-zA-Z]{1}[0-9]{12}$/],
  GA: [/^[0-9]{7,9}$/],
  HI: [/(^[0-9]{9}$)|(^[a-zA-Z]{1}[0-9]{8}$)/],
  ID: [/(^[0-9]{9}$)|(^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$)/],
  IL: [/^[a-zA-Z]{1}[0-9]{11,12}$/],
  IN: [/(^[0-9]{9,10}$)|(^[a-zA-Z]{1}[0-9]{9}$)/],
  IA: [/([0-9]{3}[a-zA-Z]{2}[0-9]{4}$)|(^[0-9]{9}$)/],
  KS: [
    /(^[a-zA-Z]{1}[0-9]{8}$)|(^[0-9]{9}$)|(^([a-zA-Z]{1}[0-9]{1}){2}[a-zA-Z]{1}$)/,
  ],
  KY: [/(^[0-9]{9}$)|(^[a-zA_Z]{1}[0-9]{8,9}$)/],
  LA: [/^[0-9]{1,9}$/],
  ME: [/(^[0-9]{7}[a-zA-Z]{1}$)|(^[0-9]{7,8}$)/],
  MD: [/^[a-zA-Z]{1}[0-9]{12}$/],
  MA: [/(^[0-9]{9}$)|(^[a-zA-Z]{1}[0-9]{8}$)/],
  MI: [/(^[a-zA-Z]{1}[0-9]{12}$)|(^[a-zA-Z]{1}[0-9]{10}$)/],
  MN: [/^[a-zA-Z]{1}[0-9]{12}$/],
  MS: [/^[0-9]{9}$/],
  MO: [
    /(^[0-9]{9}$)|(^[0-9]{8}[a-zA-Z]{2}$)|(^[a-zA-Z]{1}[0-9]{6}[R]{1}$)|(^[a-zA-Z]{1}[0-9]{5,9}$)/,
  ],
  MT: [/(^[0-9]{14}$)|(^[0-9]{9}$)|(^[0-9]{13}$)|(^[a-zA-Z]{1}[0-9]{8}$)/],
  NE: [/^[a-zA-Z]{1}[0-9]{3,8}$/],
  NV: [/(^[X]{1}[0-9]{8}$)|(^[0-9]{12}$)|(^[0-9]{9,10}$)/],
  NH: [/^[0-9]{2}[a-zA-Z]{3}[0-9]{5}$/],
  NJ: [/^[a-zA-Z]{1}[0-9]{14}$/],
  NM: [/^[0-9]{8,9}$/],
  NY: [
    /(^[a-zA-Z]{1}[0-9]{7}$)|(^[a-zA-Z]{1}[0-9]{18}$)|(^[0-9]{8}$)|(^[0-9]{9}$)|(^[0-9]{16}$)|(^[a-zA-Z]{8}$)/,
  ],
  NC: [/^[0-9]{1,12}$/],
  ND: [/(^[0-9]{9}$)|(^[a-zA-Z]{3}[0-9]{6}$)/],
  OH: [/(^[0-9]{8}$)|(^[a-zA-Z]{2}[0-9]{3,7}$)|(^[a-zA-Z]{1}[0-9]{4,8}$)/],
  OK: [/(^[0-9]{9}$)|(^[a-zA-Z]{1}[0-9]{9}$)/],
  OR: [
    /(^[a-zA-Z]{2}[0-9]{5}$)|(^[a-zA-Z]{1}[0-9]{6}$)|(^[0-9]{1,9}$)|(^[0-9]{1,9}$)/,
  ],
  PA: [/^[0-9]{8}$/],
  RI: [/(^[0-9]{7}$) |(^[a-zA-Z]{1}[0-9]{6}$)/],
  SC: [/^[0-9]{5,11}$/],
  SD: [/(^[0-9]{6,10}$)|(^[0-9]{12}$)/],
  TN: [/^[0-9]{7,9}$/],
  TX: [/^[0-9]{7,8}$/],
  UT: [/^[0-9]{4,10}$/],
  VT: [/(^[a-zA-Z]{1}[0-9]{9,11}$)|(^[0-9]{9}$)/],
  VA: [
    /(^[a-zA-Z]{1}[0-9]{9,11}$)|(^[0-9]{12}$)|(^[0-9]{9}$)|(^[a-zA-Z]{1}[0-9]{8}$)/,
  ],
  WA: [/^(?=.{12}$)[a-zA-Z]{1,7}[a-zA-Z0-9\*]{4,11}$/],
  WV: [/(^[a-zA-Z]{1,2}[0-9]{5,6}$)|(^[0-9]{7}$)/],
  WI: [/^[a-zA-Z]{1}[0-9]{13}$/],
  WY: [/^[0-9]{9,10}$/],
};

/**
 * Expressions to match a valid driver license by CA state.
 */
const CA_DRIVER_LICENSE_REGEX: ZipCodeMap = {
  AB: [/^[0-9]{9}$/],
  BC: [/^[0-9]{7}$/],
  MB: [/^[a-zA-Z0-9]{12}$/],
  NB: [/^[0-9]{5,7}$/],
  NL: [/^[a-zA-Z]{1}[0-9]{9}$/],
  NT: [/^[0-9]{6}$/],
  NS: [/^[a-zA-Z]{5}[0-9]{9}$/],
  NU: [/^[0-9]{9}$/],
  ON: [/^[a-zA-Z]{1}[0-9]{14}$/],
  PE: [/^[0-9]{5,6}$/],
  QC: [/^[a-zA-Z]{1}[0-9]{12}$/],
  SK: [/^[0-9]{8}$/],
  YT: [/^[0-9]{10}$/],
};

/**
 * Validate a driver license number.
 */
export function DriverLicenseValidator(country: string, state: string) {
  let regex: RegExp[];

  switch (country) {
    case 'CA':
      regex = CA_DRIVER_LICENSE_REGEX[state];
      break;
    case 'US':
      regex = US_DRIVER_LICENSE_REGEX[state];
      break;
    default:
      regex = null;
      break;
  }

  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    if (!value || !regex || regex.some((item) => item.test(value))) {
      return null;
    }

    return {
      driverLicense: true,
    };
  };
}
