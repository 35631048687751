import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { map } from 'rxjs/operators';

import { RadioGroupComponent } from './radio-group.component';

@Component({
  selector: 'zc-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent {
  constructor(private group: RadioGroupComponent) {}

  @Input() value: any;
  @Input() data: any;
  @Output() valueChange = new EventEmitter<any>();
  name = this.group.name;
  isChecked$ = this.group.valueChange$.pipe(map((x) => x === this.value));
  get inline() {
    return this.group.inline;
  }
  get disabled() {
    return this.group.disabled;
  }
  get readonly() {
    return this.group.readonly;
  }
  get required() {
    return this.group.required;
  }

  onInput() {
    this.group.onChange(this.value);
    this.group.selectionChange.emit({
      value: this.value,
      data: this.data,
    });

    this.valueChange.emit({
      value: this.value,
      data: this.data,
    });
  }

  onBlur() {
    this.group.onBlur();
  }
}
