import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Self,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import uniqueId from 'lodash/uniqueId';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  finalize,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormFieldControl } from '../form-field/form-field-control';
import { FormService } from '../form.service';
import { ControlWidth, OptionConfig } from '../forms.interfaces';
import { SelectChange } from './select.interfaces';

@Component({
  selector: 'zc-select',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormFieldControl,
      useExisting: SelectComponent,
    },
  ],
})
export class SelectComponent
  implements
    ControlValueAccessor,
    FormFieldControl<any>,
    OnInit,
    OnChanges,
    OnDestroy
{
  constructor(
    @Self() public ngControl: NgControl,
    private form: FormService,
    private ref: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  @Input() deselect = '';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() id = uniqueId('control-');
  @Input() options: OptionConfig[];
  @Input() optionsAsync: Observable<OptionConfig[]>;
  @Input() placeholder = '';
  @Input() width: ControlWidth = 'md';
  @Output() selectionReady = new EventEmitter<SelectChange>();
  @Output() selectionChange = new EventEmitter<SelectChange>();
  required: boolean;
  options$: Observable<OptionConfig[]>;
  isLoading: boolean;
  isFailure: boolean;
  readonly stateChanges = new Subject<void>();

  private _value: any;
  private _optionsAsync: OptionConfig[];
  private readonly _destroyed = new Subject<void>();

  ngOnInit() {
    this.setRequired();
    this.ngControl.statusChanges
      .pipe(
        takeUntil(this._destroyed),
        tap(() => this.setRequired())
      )
      .subscribe();

    const value$ =
      this.value !== null
        ? this.ngControl.valueChanges.pipe(startWith(this.value))
        : this.ngControl.valueChanges;
    const options$ = this.options$ || of(null);

    combineLatest([value$, options$])
      .pipe(takeUntil(this._destroyed))
      .subscribe(() =>
        this.selectionChange.emit({
          value: this.value,
          data: this.getSelectedOption(),
        })
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stateChanges.next();

    if (changes.optionsAsync) {
      this.loadOptionsAsync();
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this.stateChanges.complete();
  }

  onBlur() {
    this.onTouched();
    this.stateChanges.next();
  }

  onRetry() {
    this.loadOptionsAsync();
  }

  onChange = (_: any) => {
    // empty
  };

  onTouched = () => {
    // empty
  };

  writeValue(value: string) {
    this.value = value;
    this.ref.markForCheck();
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.ref.markForCheck();
  }

  private setRequired() {
    const ctrl = this.ngControl.control;

    this.required = this.form.hasRequired(ctrl);
    this.stateChanges.next();
  }

  private getSelectedOption() {
    const options: OptionConfig[] = [];

    if (this.options) {
      options.push(...this.options);
    }

    if (this._optionsAsync) {
      options.push(...this._optionsAsync);
    }

    return options.find((x) => x.value === this.value);
  }

  private loadOptionsAsync() {
    this.isLoading = true;
    this.isFailure = false;

    this.options$ = this.optionsAsync?.pipe(
      tap((res) => {
        this._optionsAsync = res;
        const selected = this.getSelectedOption();

        this.selectionReady.emit({
          value: this.value,
          data: selected,
        });
      }),
      catchError((error) => {
        this.isFailure = true;
        throw error;
      }),
      finalize(() => {
        this.isLoading = false;
        this.ref.markForCheck();
      })
    );
  }
}
