/**
 * Document signature model.
 */
export class Signature {
  constructor(source: Partial<Signature>) {
    this.sectionName = source.sectionName;
    this.signatureName = source.signatureName;
    this.method = 'Image_V3';
    this.version = source.version;
    this.image = source.image;
    this.password = source.password;
    this.pin = source.pin;
  }

  sectionName: string;
  signatureName: string;
  method: string;
  version: number;
  image: string;
  password: string;
  pin: string;
}
