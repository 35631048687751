<label
  class="radio-button"
  [ngClass]="{ 'radio-button--inline': inline, disabled: disabled }"
>
  <input
    type="radio"
    class="radio-button__control"
    (blur)="onBlur()"
    (change)="onInput()"
    [attr.aria-required]="required"
    [attr.name]="name"
    [checked]="isChecked$ | async"
    [disabled]="disabled"
    [readonly]="readonly"
    [value]="value"
  />
  <ng-content></ng-content>
</label>
