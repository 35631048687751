import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import {
  FuzzyDateParts,
  FuzzyDateService,
  FuzzyDateStaticCode,
  L10nService,
  ModalWithResult,
  TranslateResult,
} from '@zipcrim/common';
import { format } from 'date-fns';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DatePartsConfig } from '../date-parts/date-parts.interfaces';
import { OptionConfig } from '../forms.interfaces';
import { ValidationMessage } from '../validation-messages/validation-messages.interfaces';
import { DatePartsValidator } from '@zipcrim/forms/validators/date-parts.validator';
import { FuzzyDateModalOptions } from './fuzzy-date-modal.interfaces';
import { FuzzyDateInputType } from './fuzzy-date.enums';

/**
 * Fuzzy date modal.
 */
@Component({
  templateUrl: './fuzzy-date-modal.component.html',
})
export class DynamicFuzzyDateModalComponent
  extends ModalWithResult<TranslateResult>
  implements OnInit, OnDestroy
{
  constructor(
    private _Fb: FormBuilder,
    private _FuzzyDate: FuzzyDateService,
    private _L10n: L10nService,
    protected _BsModalRef: BsModalRef
  ) {
    super(_BsModalRef);
    this._OnChanges = this.OnChanges.asObservable();
  }

  /**
   * Modal input label.
   */
  @Input()
  Label: string;

  /**
   * Initial input parts.
   */
  @Input()
  InitialParts: FuzzyDateParts;

  /**
   * Configuration options.
   */
  @Input()
  Options: FuzzyDateModalOptions;

  /**
   * Root form group.
   */
  FormGroup = this._Fb.group({
    type: null,
    date: this._Fb.group({
      Year: null,
      Month: null,
      Day: null,
    }),
  });

  get DateGroup() {
    return this.FormGroup.get('date') as FormGroup;
  }

  /**
   * Validation error to display.
   */
  Messages: ValidationMessage[];

  /**
   * Busy saving indicator.
   */
  BusySaving: boolean;

  /**
   * Type options.
   */
  TypeOptions: OptionConfig[];

  /**
   * Date parts configuration.
   */
  Parts: DatePartsConfig | null = null;

  /**
   * Emits a value when an "input" property has changed as a result setting the modal component properties.
   */
  OnChanges = new Subject<void>();

  /**
   * Listen for external "input" changes.
   */
  private _OnChanges: Observable<void>;

  /**
   * On init.
   */
  ngOnInit() {
    // wait for modal caller to set properties
    this._OnChanges.subscribe(() => {
      this.TypeOptions = this._GetTypeOptions();
      this._PatchInitialData();
    });
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    this.OnChanges.complete();
  }

  /**
   * On cancel.
   */
  OnCancel() {
    this.dismiss();
  }

  /**
   * On clear.
   */
  OnClear() {
    this.close({
      Parts: null,
      Translation: null,
      Value: '',
    });
  }

  /**
   * On submit.
   */
  OnSubmit() {
    if (this.FormGroup.invalid) {
      return;
    }

    const value = this._GetValue();

    if (!value) {
      this.close(null);
      return;
    }

    this._TranslateValue(value as string);
  }

  OnTypeChange(option?: OptionConfig) {
    if (!option) {
      return;
    }

    const type: FuzzyDateInputType = option.value;
    const validators: ValidatorFn[] = [];
    const day = this.FormGroup.get('date.Day');

    if (type === FuzzyDateInputType.ExactDate) {
      validators.push(
        DatePartsValidator({
          required: true,
          min: this.Options.min,
          max: this.Options.max,
          locale: this._L10n.currentLocale,
        })
      );

      this.Parts = {
        year: true,
        month: true,
        day: true,
      };

      if (day.disabled) {
        day.enable();
      }
    } else if (type === FuzzyDateInputType.FuzzyDate) {
      validators.push(
        DatePartsValidator({
          required: {
            year: true,
            month: true,
          },
          min: this.Options.min,
          max: this.Options.max,
          locale: this._L10n.currentLocale,
        })
      );

      this.Parts = {
        month: 'select',
        day: false,
      };

      if (day.enabled) {
        day.disable();
      }
    }

    const control = this.FormGroup.get('date');
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  /**
   * Get formatted string from form values.
   */
  private _GetValue() {
    const { value } = this.FormGroup;

    if (value.type === FuzzyDateInputType.Present) {
      return 'Present';
    }

    if (value.type === FuzzyDateInputType.ExactDate) {
      const parts = value.date;

      if (parts.Year && parts.Month && parts.Day) {

        var year = parts.Year as number;
        var month = parts.Month as number;
        var day = parts.Day as number;

        return format(
          new Date(year, month - 1, day),
          'yyyy-MM-dd'
        );
      }
    }

    if (value.type === FuzzyDateInputType.FuzzyDate) {
      const parts = value.date;

      if (parts.Month) {
        return `${parts.Month}/${parts.Year}`;
      // } else if (parts.Season) {
      //   return `${parts.Season} ${parts.Year}`;
      } else if (parts.Year) {
        return parts.Year;
      }
    }

    return null;
  }

  /**
   * Translate user input.
   *
   * @param value Date string to translate.
   */
  private _TranslateValue(value: string) {
    this.Messages = [];

    this.BusySaving = true;
    this._FuzzyDate
      .translate({
        fuzzyDateString: value,
        fromLanguageCode: this._FuzzyDate.apiLocale,
        toLanguageCode: this._L10n.currentLocale,
        allowedStaticCodes: this.Options.allowedStaticCodes,
      })
      .pipe(finalize(() => (this.BusySaving = false)))
      .subscribe(
        (res) => {
          this.close(res);
        },
        () => {
          const text = this._L10n.instant('Common.msgDateInvalidError');
          this.Messages = [{ text }];
        }
      );
  }

  /**
   * Get type options.
   */
  private _GetTypeOptions() {
    const codes = this.Options.allowedStaticCodes;
    const options: OptionConfig[] = [
      {
        value: FuzzyDateInputType.FuzzyDate,
        labelL10nKey: 'Common.lblDateEnter',
      },
      {
        value: FuzzyDateInputType.ExactDate,
        labelL10nKey: 'Common.lblDateExact',
      },
    ];

    if (codes && codes.indexOf(FuzzyDateStaticCode.Present) !== -1) {
      options.push({
        value: FuzzyDateInputType.Present,
        labelL10nKey: 'Common.lblPresent',
      });
    }

    return options;
  }

  private _PatchInitialData() {
    const parts = this.InitialParts;
    const year = parts?.Year ?? null;
    const month = parts?.Month ?? null;
    const day = parts?.Day ?? null;
    const code = parts?.Static;
    let type: FuzzyDateInputType;

    if (code === FuzzyDateStaticCode.Present) {
      type = FuzzyDateInputType.Present;
    } else if (year && month && day) {
      type = FuzzyDateInputType.ExactDate;
    } else {
      // default
      type = FuzzyDateInputType.FuzzyDate;
    }

    this.FormGroup.patchValue({
      type,
      date: {
        Year: year,
        Month: month,
        Day: day,
      },
    });
  }
}
