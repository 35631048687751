import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { CaseService } from '@zipcrim/common';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Guard route based on users case authorization.
 */
@Injectable({ providedIn: 'root' })
export class CaseGuard implements CanActivate {
  constructor(private _Case: CaseService) {}

  /**
   * Can activate.
   */
  canActivate(route: ActivatedRouteSnapshot) {
    const caseId = +route.paramMap.get('caseId');

    return this._Case.getRights(caseId).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
