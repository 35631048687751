import { Injectable } from '@angular/core';
import debounce from 'lodash/debounce';

import { WindowRefService } from '../window-ref/window-ref.service';

/**
 * Keep ColdFusion and FoxPro sessions alive while user is idle.
 */
@Injectable({ providedIn: 'root' })
export class IdleDetectionService {
  constructor(private windowRef: WindowRefService) {}

  /**
   * Storage key name.
   */
  private readonly flag = 'isTokenExtended';

  /**
   * Timeout interval before extending the session.
   */
  private readonly interval = 600000; // 10min

  private callback = debounce(() => {
    const isSet = sessionStorage.getItem(this.flag);

    if (!isSet) {
      sessionStorage.setItem(this.flag, 'true');

      // Set a timeout to avoid bombarding global scope function.
      setTimeout(() => {
        this.extendSession();
        sessionStorage.removeItem(this.flag);
      }, this.interval);
    }
  }, 500);

  init() {
    // extend immediately to prevent intermittent token timeout issues.
    this.extendSession();

    this.windowRef.nativeWindow.addEventListener(
      'mousemove',
      this.callback,
      false
    );
    this.windowRef.nativeWindow.addEventListener(
      'mousedown',
      this.callback,
      false
    );
    this.windowRef.nativeWindow.addEventListener(
      'keypress',
      this.callback,
      false
    );
    this.windowRef.nativeWindow.addEventListener(
      'DOMMouseScroll',
      this.callback,
      false
    );
    this.windowRef.nativeWindow.addEventListener(
      'mousewheel',
      this.callback,
      false
    );
    this.windowRef.nativeWindow.addEventListener(
      'touchmove',
      this.callback,
      false
    );
    this.windowRef.nativeWindow.addEventListener(
      'MSPointerMove',
      this.callback,
      false
    );
  }

  stopListening() {
    this.windowRef.nativeWindow.removeEventListener(
      'mousemove',
      this.callback
    );
    this.windowRef.nativeWindow.removeEventListener(
      'mousedown',
      this.callback
    );
    this.windowRef.nativeWindow.removeEventListener(
      'keypress',
      this.callback
    );
    this.windowRef.nativeWindow.removeEventListener(
      'DOMMouseScroll',
      this.callback
    );
    this.windowRef.nativeWindow.removeEventListener(
      'mousewheel',
      this.callback
    );
    this.windowRef.nativeWindow.removeEventListener(
      'touchmove',
      this.callback
    );
    this.windowRef.nativeWindow.removeEventListener(
      'MSPointerMove',
      this.callback
    );
  }

  /**
   * Safely call global function (provided by ColdFusion).
   */
  private extendSession() {
    const extendTimers = this.windowRef.nativeWindow.extendTimers;

    if (typeof extendTimers === 'function') {
      extendTimers();
    }
  }
}
