import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule as NgCommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { AttestModalComponent } from './attest/attest-modal.component';
import { HasRightsDirective } from './auth/has-rights.directive';
import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { AutomationIdDirective } from './automation-id/automation-id.directive';
import { BadgeComponent } from './badge/badge.component';
import { BusyComponent } from './busy/busy.component';
import { ButtonComponent } from './button/button.component';
import { CardBodyComponent } from './card/card-body.component';
import { CardFooterComponent } from './card/card-footer.component';
import { CardHeaderComponent } from './card/card-header.component';
import { CardComponent } from './card/card.component';
import { COMMON_ICONS } from './common.icons';
import { CommonModuleConfig } from './common.interfaces';
import { CountDownComponent } from './count-down/count-down.component';
import { EmptyComponent } from './empty/empty.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { HtmlComponent } from './html/html.component';
import { ImageComponent } from './image/image.component';
import { L10nPickerComponent } from './l10n/l10n-picker.component';
import { ConfirmModalComponent } from './modal/confirm-modal.component';
import { InfoModalComponent } from './modal/info-modal.component';
import { ModalBodyComponent } from './modal/modal-body.component';
import { ModalFooterComponent } from './modal/modal-footer.component';
import { ModalHeaderComponent } from './modal/modal-header.component';
import { ModalComponent } from './modal/modal.component';
import { NavHrefComponent } from './nav/nav-href.component';
import { NavItemComponent } from './nav/nav-item.component';
import { NavLinkComponent } from './nav/nav-link.component';
import { NavComponent } from './nav/nav.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotificationComponent } from './notification/notification.component';
import { SidebarBodyComponent } from './sidebar/sidebar-body.component';
import { SidebarToggleDirective } from './sidebar/sidebar-toggle.directive';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { StepCaptionComponent } from './stepper/step-caption.component';
import { StepTitleComponent } from './stepper/step-title.component';
import { StepComponent } from './stepper/step.component';
import { StepperNextDirective } from './stepper/stepper-next.directive';
import { StepperPreviousDirective } from './stepper/stepper-previous.directive';
import { StepperComponent } from './stepper/stepper.component';
import { TableCellDirective } from './table/table-cell.directive';
import { TableColumnDirective } from './table/table-column.directive';
import { TableComponent } from './table/table.component';
import { TagComponent } from './tag/tag.component';
import { TimeFromNowPipe } from './time/time-from-now.pipe';
import { TimelineCaptionComponent } from './timeline/timeline-caption.component';
import { TimelineDotComponent } from './timeline/timeline-dot.component';
import { TimelineItemComponent } from './timeline/timeline-item.component';
import { TimelineTitleComponent } from './timeline/timeline-title.component';
import { TimelineComponent } from './timeline/timeline.component';
import { OnlyNumbersDirective } from './only-numbers/only-numbers.directive';
import { SsnFormatPipe } from './ssn-format/ssn-format.pipe';
import { FocusInvalidInputDirective } from './validation/focus-invalid-input.directive';

@NgModule({
  imports: [
    NgCommonModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    RouterModule,
    FileUploadModule,
    A11yModule,
    TranslateModule,
    PaginationModule.forRoot(),
  ],
  declarations: [
    AttestModalComponent,
    AutoFocusDirective,
    AutomationIdDirective,
    BadgeComponent,
    BusyComponent,
    ButtonComponent,
    CardBodyComponent,
    CardComponent,
    CardFooterComponent,
    CardHeaderComponent,
    ConfirmModalComponent,
    InfoModalComponent,
    CountDownComponent,
    EmptyComponent,
    FileUploadComponent,
    HasRightsDirective,
    HtmlComponent,
    ImageComponent,
    L10nPickerComponent,
    ModalBodyComponent,
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    NavComponent,
    NavHrefComponent,
    NavItemComponent,
    NavLinkComponent,
    NotFoundComponent,
    NotificationComponent,
    OnlyNumbersDirective,
    SidebarBodyComponent,
    SidebarComponent,
    SidebarToggleDirective,
    SkeletonComponent,
    StepCaptionComponent,
    StepComponent,
    StepTitleComponent,
    StepperComponent,
    StepperNextDirective,
    StepperPreviousDirective,
    TableCellDirective,
    TableColumnDirective,
    TableComponent,
    TagComponent,
    TimeFromNowPipe,
    TimelineCaptionComponent,
    TimelineComponent,
    TimelineDotComponent,
    TimelineItemComponent,
    TimelineTitleComponent,
    SsnFormatPipe,
    FocusInvalidInputDirective,
  ],
  exports: [
    AlertModule,
    AutoFocusDirective,
    AutomationIdDirective,
    BadgeComponent,
    BusyComponent,
    ButtonComponent,
    CardBodyComponent,
    CardComponent,
    CardFooterComponent,
    CardHeaderComponent,
    CountDownComponent,
    EmptyComponent,
    FileUploadComponent,
    FontAwesomeModule,
    HasRightsDirective,
    HtmlComponent,
    ImageComponent,
    ModalBodyComponent,
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalModule,
    NavComponent,
    NavHrefComponent,
    NavItemComponent,
    NavLinkComponent,
    NgCommonModule,
    NotFoundComponent,
    NotificationComponent,
    OnlyNumbersDirective,
    SidebarBodyComponent,
    SidebarComponent,
    SidebarToggleDirective,
    SkeletonComponent,
    StepCaptionComponent,
    StepComponent,
    StepTitleComponent,
    StepperComponent,
    StepperNextDirective,
    StepperPreviousDirective,
    TableCellDirective,
    TableColumnDirective,
    TableComponent,
    TagComponent,
    TimeFromNowPipe,
    TimelineCaptionComponent,
    TimelineComponent,
    TimelineItemComponent,
    TimelineTitleComponent,
    TranslateModule,
    SsnFormatPipe,
    FocusInvalidInputDirective,
  ],
  providers: [
    {
      provide: 'commonModuleConfig',
      useValue: {},
    },
  ],
})
export class CommonModule {
  /**
   * Configure module.
   *
   * @param config Configuration object.
   */
  static forRoot(
    config: CommonModuleConfig
  ): ModuleWithProviders<CommonModule> {
    return {
      ngModule: CommonModule,
      providers: [
        {
          provide: 'commonModuleConfig',
          useValue: config,
        },
      ],
    };
  }

  constructor(_FaIconLibrary: FaIconLibrary) {
    _FaIconLibrary.addIcons(...COMMON_ICONS);
  }
}
