import {
  IContact,
  IContactAddress,
  IContactEmail,
  IContactNumber,
  IDomain,
} from '../generated';
import { ContactAddress } from './contact-address';
import { ContactEmail } from './contact-email';
import { ContactName } from './contact-name';
import { ContactNumber } from './contact-number';
import { Domain } from './domain';
import { ExternalAccess } from './external-access';

/**
 * Contact record.
 */
export class Contact {
  constructor(source?: Partial<IContact>) {
    this._Init(source);
  }

  ID: number;
  ClientCode: string;
  Status: 'Active' | 'Deleted' | 'Inactive' | 'Unverified';
  ExternalAccess: ExternalAccess;
  Name: ContactName;
  Emails: ContactEmail[];
  ContactNumbers: ContactNumber[];
  Addresses: ContactAddress[];
  Domains: Domain[];
  PrimaryEmail?: ContactEmail;
  PrimaryNumber?: ContactNumber;

  /**
   * Add an empty contact email if no primary exists.
   */
  AddEmailIfEmpty() {
    if (!this.PrimaryEmail) {
      this.Emails.push(new ContactEmail({ Purpose: 'Primary' }));
      this._SetPrimaryEmail();
    }

    return this;
  }

  /**
   * Add an empty contact number if no primary exists.
   */
  AddNumberIfEmpty() {
    if (!this.PrimaryNumber) {
      this.ContactNumbers.push(new ContactNumber({ Purpose: 'Primary' }));
      this._SetPrimaryNumber();
    }

    return this;
  }

  /**
   * Get the contact's primary client code.
   */
  GetClientCode() {
    if (this.Domains.length === 0) {
      return null;
    }

    return this.Domains[0].ClientCode || null;
  }

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<IContact>) {
    if (!source) {
      this.ExternalAccess = new ExternalAccess();
      this.Name = new ContactName();
      this.Emails = [];
      this.ContactNumbers = [];
      this.Addresses = [];
      this.Domains = [];
      return;
    }

    this.ID = source.ID;
    this.ClientCode = source.ClientCode;
    this.Status = source.Status;
    this.ExternalAccess = new ExternalAccess(source.ExternalAccess);
    this.Name = new ContactName(source.Name);

    const emails: IContactEmail[] | void = source.Emails?.Items;
    const numbers: IContactNumber[] | void = source.ContactNumbers?.Items;
    const addresses: IContactAddress[] | void = source.Addresses?.Items;
    const domains: IDomain[] | void = source.Domains?.Items;

    this.Emails = emails ? emails.map((item) => new ContactEmail(item)) : [];

    this.ContactNumbers = numbers
      ? numbers.map((item) => new ContactNumber(item))
      : [];

    this.Addresses = addresses
      ? addresses.map((item) => new ContactAddress(item))
      : [];

    this.Domains = domains
      ? this._FlattenDomains(domains.map((item) => new Domain(item)))
      : [];

    this._SetPrimaryEmail();
    this._SetPrimaryNumber();
  }

  /**
   * Update `PrimaryEmail` property.
   */
  private _SetPrimaryEmail() {
    const primary = this.Emails.find((item) => item.Purpose === 'Primary');
    this.PrimaryEmail = primary || this.Emails[0];
  }

  /**
   * Update `PrimaryNumber` property.
   */
  private _SetPrimaryNumber() {
    const primary = this.ContactNumbers.find(
      (item) => item.Purpose === 'Primary'
    );
    this.PrimaryNumber = primary || this.ContactNumbers[0];
  }

  /**
   * Flatten domains and roles to ensure the domain array contains a single object for each client.
   *
   * For example, this collection of domains ...
   * ```js
   * [
   *   { Client: 'ABC123', Roles: ['A', 'B'] },
   *   { Client: 'ABC123', Roles: ['A', 'C'] },
   *   { Client: 'XYX321', Roles: ['A', 'B'] }
   * ]
   * ```
   * ... would be flattened to be ...
   * ```js
   * [
   *   { Client: 'ABC123', Roles: ['A', 'B', 'C'] },
   *   { Client: 'XYX321', Roles: ['A', 'B'] }
   * ]
   * ```
   *
   * @param source Collection of domains.
   */
  private _FlattenDomains(source: Domain[]) {
    const result: Domain[] = [];

    source.forEach((item) => {
      const domain = result.find((x) => x.ClientCode === item.ClientCode);

      if (domain) {
        // duplicate domain found, add any roles from this domain that weren't present in the other one(s)
        const roles = item.Roles.filter(
          (x) => !domain.Roles.some((y) => y.Name === x.Name)
        );
        domain.Roles.push(...roles);
      } else {
        result.push(item);
      }
    });

    return result;
  }
}
