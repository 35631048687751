import { ILeadReport, ILeadReportFormat } from '../generated';

/**
 * Lead report.
 */
export class LeadReport {
  constructor(source?: Partial<ILeadReport>) {
    this._Init(source);
  }

  Format: ILeadReportFormat;
  Content: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<ILeadReport>) {
    if (!source) {
      return;
    }

    this.Format = source.Format;
    this.Content = source.Content;
  }
}
