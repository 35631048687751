<zc-modal>
  <zc-modal-header (dismiss)="onClose()">
    <h4 class="modal-title">{{ 'Common.lblAccountCreate' | translate }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <p>
      <b>{{ 'Common.lblAuthenticationCodeInstructions' | translate }}</b>
      <br />
      <b class="text-red">
        {{ 'Common.lblAuthenticationCodeNotUs' | translate }}
      </b>
    </p>
    <zc-input [ngModel]="AuthCode" [readonly]="true"> </zc-input>
    <zc-button color="link" (click)="onCopy()">
      <fa-icon icon="copy"></fa-icon>
      {{ 'Common.cmdCopyToClipboard' | translate }}
    </zc-button>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button color="primary" (click)="onClose()">
      {{ 'Common.lblOk' | translate }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
