import { Component, OnInit } from '@angular/core';
import { Contact } from '@zipcrim/common/models';
import { finalize } from 'rxjs/operators';

import { ContactsService } from '../contacts.service';

/**
 * Display a list contact records.
 *
 * ```html
 * <zc-contact-list></zc-contact-list>
 * ```
 */
@Component({
  selector: 'zc-contact-list',
  templateUrl: './contact-list.component.html',
})
export class ContactListComponent implements OnInit {
  constructor(private contactsService: ContactsService) {}

  /**
   * Collection of contacts.
   */
  contacts: Contact[];

  /**
   * Busy indicator.
   */
  busy: boolean;

  /**
   * On init.
   */
  ngOnInit() {
    this.busy = true;
    this.contactsService
      .getContacts()
      .pipe(finalize(() => (this.busy = false)))
      .subscribe((data) => (this.contacts = data));
  }
}
