import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiService,
  FieldSpec,
  IAnaGroup,
  IBoList,
  ICaseSummary,
  ICaseType,
  IUiMeta,
  L10nService,
} from '@zipcrim/common';
import { map } from 'rxjs/operators';

import { CaseSummary } from '../model/case-summary';
import { NextStep } from './interfaces';

@Injectable({ providedIn: 'root' })
export class CheckToHireSvcService {
  constructor(private _Api: ApiService, private _L10n: L10nService) {}

  /**
   * Get case types.
   */
  GetCaseTypes() {
    return this._Api
      .get<IBoList<ICaseType>>('checkToHireSvcs/caseTypes')
      .pipe(map((res) => res.payload));
  }

  /**
   * Get ana groups.
   */
  GetAnaGroups() {
    return this._Api
      .get<IBoList<IAnaGroup>>('checkToHireSvcs/anaGroups')
      .pipe(map((res) => res.payload));
  }

  /**
   * Get case summary.
   *
   * @param caseNum Case num.
   */
  GetCaseSummary(caseNum: string) {
    const fieldSpec = new FieldSpec().add([
      'ID',
      'CaseNum',
      'Case.ID',
      'Case.Leads',
    ]);
    const params = new HttpParams()
      .append('caseNum', caseNum)
      .append('fieldSpec', fieldSpec.toString());

    return this._Api
      .get<IBoList<ICaseSummary>>('checkToHireSvc/caseSummary', params)
      .pipe(
        map((res) => res.payload),
        map((res) => res.Items.map((item) => new CaseSummary(item, this._L10n)))
      );
  }

  /**
   * Get next step.
   *
   * @param caseNum Case num.
   */
  GetNextStep(caseNum: string) {
    const fieldSpec = new FieldSpec().add([
      'ID',
      'CaseType',
      'AnaGroups',
      'SubjectID',
    ]);
    const params = new HttpParams()
      .append('caseNum', caseNum.toString())
      .append('fieldSpec', fieldSpec.toString());

    return this._Api
      .get<NextStep>('checkToHireSvc/nextStep', params)
      .pipe(map((res) => res.payload));
  }

  /**
   * Get create spec.
   *
   * @param clientCode Client code.
   */
  GetCreateSpec(clientCode: string) {
    const params = new HttpParams().append('clientCode', clientCode);

    return this._Api
      .get<{ CaseType: string; UiMeta: IUiMeta }>(
        'checkToHireSvc/createSpec',
        params
      )
      .pipe(map((res) => res.payload));
  }

  /**
   * Find the index of the first case summary that contains the provided `leadNum`.
   *
   * @param source Case summary collection.
   * @param leadNum Lead number to find.
   */
  FindCaseSummaryIndex(source: CaseSummary[], leadNum: string) {
    if (!leadNum) {
      return 0;
    }

    const index = source.findIndex((item) =>
      item.LeadNum.some((x) => leadNum === x)
    );

    return index > -1 ? index : 0;
  }
}
