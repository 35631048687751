import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@zipcrim/common';
import { FormsModule } from '@zipcrim/forms';

import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { ContactPostSaveComponent } from './contact-detail/contact-post-save-modal.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactRolesComponent } from './contact-roles/contact-roles.component';
import { CONTACTS_ICONS } from './contacts.icons';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ClipboardModule],
  declarations: [
    ContactDetailComponent,
    ContactListComponent,
    ContactPostSaveComponent,
    ContactRolesComponent,
  ],
  exports: [
    CommonModule,
    ContactDetailComponent,
    ContactListComponent,
    ContactRolesComponent,
  ],
})
export class ContactsModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(...CONTACTS_ICONS);
  }
}
