<fieldset class="date-parts" [formGroup]="FormGroup">
  <ng-container [ngSwitch]="Order">
    <!-- month-day-year -->
    <ng-container *ngSwitchCase="'month-day-year'">
      <ng-container *ngTemplateOutlet="month"></ng-container>
      <ng-container *ngTemplateOutlet="day"></ng-container>
      <ng-container *ngTemplateOutlet="year"></ng-container>
    </ng-container>

    <!-- day-month-year -->
    <ng-container *ngSwitchCase="'day-month-year'">
      <ng-container *ngTemplateOutlet="day"></ng-container>
      <ng-container *ngTemplateOutlet="month"></ng-container>
      <ng-container *ngTemplateOutlet="year"></ng-container>
    </ng-container>

    <!-- year-month-day -->
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="year"></ng-container>
      <ng-container *ngTemplateOutlet="month"></ng-container>
      <ng-container *ngTemplateOutlet="day"></ng-container>
    </ng-container>
  </ng-container>

  <!-- month -->
  <ng-template #month>
    <div
      *ngIf="Inputs.month"
      class="date-parts__item date-parts__item--month"
      [ngClass]="[
        'date-parts__item--' + (Inputs.month === true ? 'input' : Inputs.month)
      ]"
      [ngSwitch]="Inputs.month"
    >
      <label class="control-label" [for]="Id">
        {{ 'Common.lblMonth' | translate }}
      </label>
      <zc-dynamic-select
        *ngSwitchCase="'select'"
        zcFormFieldAriaDescribedBy
        formControlName="Month"
        [Id]="Id"
        [Readonly]="Readonly"
        [Options]="MonthOptions"
      >
      </zc-dynamic-select>

      <input
        *ngSwitchDefault
        type="number"
        zcFormFieldAriaDescribedBy
        formControlName="Month"
        class="form-control"
        min="1"
        max="12"
        [id]="Id"
        [placeholder]="'Common.lblDateFormatMm' | translate"
        [readonly]="Readonly"
      />
    </div>
  </ng-template>

  <!-- day -->
  <ng-template #day>
    <div *ngIf="Inputs.day" class="date-parts__item date-parts__item--day">
      <label class="control-label" [for]="Id + '-day'">
        {{ 'Common.lblDay' | translate }}
      </label>
      <input
        type="number"
        zcFormFieldAriaDescribedBy
        formControlName="Day"
        class="form-control"
        min="1"
        max="31"
        [id]="Id + '-day'"
        [placeholder]="'Common.lblDateFormatDd' | translate"
        [readonly]="Readonly"
      />
    </div>
  </ng-template>

  <!-- year -->
  <ng-template #year>
    <div *ngIf="Inputs.year" class="date-parts__item date-parts__item--year">
      <label class="control-label" [for]="Id + '-year'">
        {{ 'Common.lblYear' | translate }}
      </label>
      <input
        type="number"
        zcFormFieldAriaDescribedBy
        formControlName="Year"
        class="form-control"
        [id]="Id + '-year'"
        [placeholder]="'Common.lblDateFormatYyyy' | translate"
        [readonly]="Readonly"
      />
    </div>
  </ng-template>
</fieldset>
