import { ApiMessage } from '../api/api-message';
import { IBlurb } from '../generated';
import { AttestOption } from './attest-option';

/**
 * Attest prompt.
 */
export class AttestPrompt {
  constructor(source?: Partial<ApiMessage>) {
    this._Init(source);
  }

  AttestOptionID: number;
  AttestOptionName: string;
  Description: string;
  ValidationID: number;
  Options: AttestOption[];
  AttestedOn: string;
  get HasAttested() {
    return !!this.AttestedOn;
  }

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<ApiMessage>) {
    this.Options = [];

    if (!source || !source.uiField) {
      return;
    }

    const data = source.uiField.Data;
    const keys = Object.keys(data);
    const field = data[keys[0]];
    const blurb: IBlurb = source?.innerMessages[0]?.blurb;

    this.AttestOptionID = field.AttestOptionID;
    this.AttestOptionName = field.AttestOptionName;
    this.Description = blurb;
    this.ValidationID = field.ValidationID;
    this.AttestedOn = field.AttestedOn;
  }
}
