import { Component } from '@angular/core';
import { AuthService } from '@zipcrim/common';

@Component({
  selector: 'c2h-home',
  templateUrl: 'home.component.html',
})
export class HomeComponent {
  constructor(private _Auth: AuthService) {}

  /**
   * On logout.
   */
  OnLogout() {
    this._Auth.logout();
  }
}
