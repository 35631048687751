<zc-summary-group
  *ngIf="errors.length > 0"
  type="error"
  [title]="'Common.lblValidationErrors' | translate : params"
  [description]="'Common.lblValidationDescription' | translate"
  [apiMessages]="errors"
  [showSource]="showSource">
  <zc-button color="secondary" (click)="onIgnore()" *ngIf="showIgnore">
    {{ 'Common.cmdIgnore' | translate }}
  </zc-button>
</zc-summary-group>

<zc-summary-group
  *ngIf="warnings.length > 0"
  type="warning"
  [title]="'Common.lblValidationWarnings' | translate : params"
  [description]="'Common.lblValidationDescription' | translate"
  [apiMessages]="warnings"
  [showSource]="showSource"></zc-summary-group>

<zc-summary-group
  *ngIf="info.length > 0"
  type="info"
  [title]="'Common.lblValidationInfos' | translate : params"
  [description]="'Common.lblValidationDescription' | translate"
  [apiMessages]="info"
  [showSource]="showSource"></zc-summary-group>

<zc-summary-group
  *ngIf="message.length > 0"
  type="message"
  [title]="'Common.lblValidationInfos' | translate : params"
  [description]="'Common.lblValidationDescription' | translate"
  [apiMessages]="message"
  [showSource]="showSource"></zc-summary-group>
