import { Directive, HostBinding, Input } from '@angular/core';

import { DynamicFormFieldLayout } from '../form-field/form-field.interfaces';

/**
 * Set the layout for a group of form fields.
 *
 * ```html
 * <form zcForm Layout="horizontal">
 *   <zc-form-field>
 *     <input type="text" />
 *   <zc-form-field>
 * </form>
 * ```
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form',
})
export class FormDirective {
  /**
   * Form layout.
   */
  @Input()
  Layout: DynamicFormFieldLayout;

  /**
   * Horizontal form.
   */
  @HostBinding('class.form-horizontal')
  get DescribedBy() {
    return this.Layout === 'horizontal';
  }
}
