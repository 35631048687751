<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ Label }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <form #form="ngForm" (ngSubmit)="OnSubmit()">
      <ng-container [formGroup]="FormGroup">
        <zc-dynamic-form-field>
          <zc-dynamic-radio-group
            zcAutoFocus
            formControlName="type"
            (SelectionChange)="OnTypeChange($event)"
            [Options]="TypeOptions"
          ></zc-dynamic-radio-group>
        </zc-dynamic-form-field>

        <zc-dynamic-form-field>
          <zc-dynamic-date-parts
            [FormGroup]="DateGroup"
            [Parts]="Parts"
          ></zc-dynamic-date-parts>
          <zc-dynamic-errors ControlName="date"></zc-dynamic-errors>
        </zc-dynamic-form-field>
      </ng-container>
    </form>
  </zc-modal-body>
  <zc-modal-footer>
    <div class="d-flex">
      <ng-container *ngIf="!Options?.required">
        <zc-button Color="link" (click)="OnClear()">
          {{ 'Common.btnClear' | translate }}
        </zc-button>
        <div class="flex-grow"></div>
      </ng-container>
      <zc-button Color="secondary" class="mr-1" (click)="OnCancel()">
        {{ 'Common.lblCancel' | translate }}
      </zc-button>
      <zc-button
        Type="submit"
        Color="primary"
        [busy]="BusySaving"
        [form]="form"
      >
        {{ 'Common.cmdContinue' | translate }}
      </zc-button>
    </div>
  </zc-modal-footer>
</zc-modal>
