<zc-sidebar>
  <zc-nav direction="vertical" class="pt-2">
    <zc-nav-link link="/account/my-account">
      {{ 'Common.lblMyAccount' | translate }}
    </zc-nav-link>
    <zc-nav-link link="/account/user" *zcHasRights="'Contact'">
      {{ 'Common.lblContacts' | translate }}
    </zc-nav-link>
    <zc-nav-link link="/account/workflow">
      {{ 'CheckToHire.lblWorkflow' | translate }}
    </zc-nav-link>
  </zc-nav>
</zc-sidebar>
