import { AbstractControl, ValidationErrors } from '@angular/forms';
import isArray from 'lodash/isArray';

/**
 * Convert a character template to a regex segment.
 *
 * @param character Character to convert.
 */
function _CharToRegex(character: string) {
  switch (character) {
    case 'A':
      return '[a-zA-Z]';
    case '9':
      return '[0-9]';
    case 'X':
      return '[a-zA-Z|0-9]';
    case '-':
      return '[-| ]{0,1}';
    case '/':
      return '[/| ]{0,1}';
    case '.':
      return '[.]{0,1}';
    default:
      return `[${character}]`;
  }
}

/**
 * Validate a string pattern.
 *
 * Template characters:
 * - `X` equals any alpha-numeric character
 * - `9` equals any numeric character
 * - `A` equals any alpha character
 *
 * @param template String template(s) used to validate.
 */
export function StringPatternValidator(template: string | string[]) {
  let patterns: RegExp[] = [];

  if (template) {
    if (!isArray(template)) {
      template = [template];
    }

    patterns = template.map((item) => {
      const pattern = item
        .split('')
        .map((char) => _CharToRegex(char))
        .join('');

      return new RegExp(`^${pattern}$`, 'i');
    });
  }

  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    if (!value || patterns.length === 0) {
      return null;
    }

    if (patterns.some((x) => x.test(value))) {
      return null;
    }

    return {
      pattern: true,
    };
  };
}
