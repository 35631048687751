import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ILeadRequest,
  LeadService,
  ModalService,
  WorkOrderService,
} from '@zipcrim/common';
import groupBy from 'lodash/groupBy';
import { finalize, mergeMap } from 'rxjs/operators';

import { CheckToHireSvcService } from '../../common/check-to-hire-svc.service';
import { ReportComponent } from '../report.component';
import { ReportService } from '../report.service';

@Component({
  selector: 'c2h-finalize-and-order-dashboard',
  templateUrl: 'finalize-and-order.component.html',
  styleUrls: ['finalize-and-order.component.scss'],
})
export class FinalizeAndOrderComponent implements OnInit {
  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _C2HService: CheckToHireSvcService,
    private _LeadService: LeadService,
    private _Modal: ModalService,
    private _Parent: ReportComponent,
    private _ReportService: ReportService,
    private _Router: Router,
    private _WorkOrder: WorkOrderService
  ) {}

  /**
   * Used to return join of all lead name
   */
  get JoinOfAllLeadName() {
    return this._ReportService.NextStepTitle;
  }

  /**
   * Busy indicator.
   */
  Busy: boolean;

  /**
   * Lead requests that have not yet been processed.
   */
  CurrentItems: { [key: string]: ILeadRequest[] } = {};

  /**
   * Lead requests that have been previously processed.
   */
  HistoricalItems: { [key: string]: ILeadRequest[] } = {};

  /**
   * Store status of collapsed.
   */
  IsCollapsed = false;

  /**
   * Is empty selection.
   */
  IsEmptySelection: boolean;

  /**
   * Used to store Is Current data available
   */
  IsCurrentDataAvailable = false;

  /**
   * Used to store Is Historical data available.
   */
  IsHistoricalDataAvailable = false;

  /**
   * Used to store busy state
   */
  BusyStatus: boolean;

  /**
   * On init.
   */
  ngOnInit() {
    this.Busy = true;
    this._WorkOrder
      .analyze(this._ReportService.WorkOrderId)
      .pipe(
        mergeMap(() =>
          this._WorkOrder.getLeadRequests(this._ReportService.WorkOrderId)
        ),
        finalize(() => (this.Busy = false))
      )
      .subscribe((res) => {
        const current = res.Items.filter((item) => !item.IsAlreadyOrdered);
        const historical = res.Items.filter((item) => !!item.IsAlreadyOrdered);
        this.IsCurrentDataAvailable = current.length !== 0;
        this.IsHistoricalDataAvailable = historical.length !== 0;

        this.CurrentItems = groupBy(current, 'LeadGroup');
        this.HistoricalItems = groupBy(historical, 'LeadGroup');
      });
  }

  /**
   * On lead request checkbox change.
   *
   * @param lead Lead request record.
   * @param event Change event.
   */
  OnCheckChange(lead: ILeadRequest, event: Event) {
    const input = event.target as HTMLInputElement;

    this._LeadService
      .submitRequest({
        Fields: {
          ID: lead.ID,
          IsRequested: input.checked,
        },
      })
      .subscribe();
  }

  /**
   * On buy report.
   */
  OnBuyReport() {
    this.BusyStatus = true;
    this._WorkOrder
      .process(this._ReportService.WorkOrderId)
      .pipe(
        mergeMap(() =>
          this._C2HService.GetCaseSummary(this._ReportService.CaseNum)
        ),
        finalize(() => (this.BusyStatus = false))
      )
      .subscribe((res) => {
        const index = res.length - 1;
        this._Parent.RefreshNextStep()
          .subscribe(() => {
            this._Router.navigate(
              [
                'reports',
                this._ReportService.CaseId,
                this._ReportService.CaseNum,
                'show-report',
              ],
              { queryParams: { leadNum: res[index].LeadNum } }
            );
          });
      });
  }

  /**
   * Used to hide and show reports want to rerun.
   */
  Collapsed() {
    this.IsCollapsed = !this.IsCollapsed;
  }

  /**
   * Used to open warning modal
   */
  OpenModal() {
    this._Modal
      .confirm({
        messageL10nKey: 'Common.lblAreYouSure',
      })
      .then(
        () => {
          this._WorkOrder
            .remove(this._ReportService.WorkOrderId)
            .subscribe(() => {
              this._Parent.RefreshNextStep()
                .subscribe(() => {
                  this._Router.navigate([
                    'reports',
                    this._ReportService.CaseId,
                    this._ReportService.CaseNum,
                    'show-report',
                  ]);
                });
              });
        },
        () => {
          // empty
        }
      );
  }

  /**
   * Used to redirect user to prepare
   */
  BackToPrepare() {
    this._Router.navigate(['../prepare-order'], {
      relativeTo: this._ActivatedRoute,
    });
  }
}
