import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ngModel], [formControl], [formControlName]',
})
export class NativeElementInjectorDirective implements OnInit {
  constructor(
    private controlDir: NgControl,
    private host: ElementRef<HTMLFormElement>
  ) {}
  ngOnInit() {
    if (this.controlDir.control) {
      // @ts-ignore
      this.controlDir.control['nativeElement'] = this.host.nativeElement;
    }
  }
}
