import { ICaseSummary, L10nService } from '@zipcrim/common';
import { Case } from '@zipcrim/common/models';

/**
 * Case summary.
 */
export class CaseSummary {
  constructor();
  constructor(source: Partial<ICaseSummary>, l10n: L10nService);
  constructor(source?: Partial<ICaseSummary>, l10n?: L10nService) {
    this._Init(source, l10n);
  }

  ID: number;
  DateSubmitted: string;
  CaseType: string;
  CaseNum: string;
  Case: Case;
  LeadNum: string[];
  Status: 'Pending' | 'Complete';

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<ICaseSummary>, l10n?: L10nService) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.DateSubmitted = source.DateSubmitted;
    this.CaseType = source.CaseType;
    this.CaseNum = source.CaseNum;
    this.Case = new Case(source.Case, l10n);
    this.LeadNum = this.Case.Leads.map((item) => item.LeadNum);
    this.Status = this.Case.Leads.some((item) => item.Status === 'Pending')
      ? 'Pending'
      : 'Complete';
  }
}
