import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Matches only alpha characters.
 */
const ALPHA_ONLY_REGEX = /^[a-zA-Z\s]*$/;

/**
 * Validate alpha characters only.
 */
export function AlphaOnlyValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (!value || ALPHA_ONLY_REGEX.test(value)) {
    return null;
  }

  return {
    alphaOnly: true,
  };
}
