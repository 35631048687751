<h1 class="header-2 title">{{ JoinOfAllLeadName }}</h1>

<!-- tabs -->
<zc-nav class="mb-2">
  <zc-nav-link [link]="['../prepare-order']">
    {{ 'CheckToHire.lblPrepareOrder' | translate }}
  </zc-nav-link>
  <zc-nav-item [active]="true">
    {{ 'CheckToHire.lblFinalizeOrder' | translate }}
  </zc-nav-item>
</zc-nav>

<!-- busy -->
<ng-container *ngIf="Busy; else content">
  <div class="d-flex justify-content-between mb-4">
    <div class="flex-grow-1">
      <zc-skeleton
        [rows]="[[{ size: 3 }, { size: 3 }], [{ size: 5 }]]"></zc-skeleton>
    </div>
  </div>
  <div class="mb-3">
    <zc-skeleton [rows]="[[{ size: 2 }], [{ size: 5 }]]"></zc-skeleton>
  </div>
</ng-container>

<ng-template #content>
  <zc-card
    [color]="IsEmptySelection ? 'warning' : 'dark'"
    [title]="'CheckToHire.lblFinalizeOrderHeader' | translate">
    <zc-card-body>
      <div class="mb-5" *ngIf="!IsCurrentDataAvailable">
        <h1 class="header-2">
          <fa-icon class="primary me-1" icon="info-circle"></fa-icon>
          {{ 'CheckToHire.lblNothingNewToOrder' | translate }}
        </h1>
        <p *ngIf="IsHistoricalDataAvailable && !IsCurrentDataAvailable">
          {{ 'CheckToHire.msgShowPreviouslyReqReports' | translate }}
          <zc-button color="link" (click)="Collapsed()">
            {{ 'CheckToHire.lblReportsToReRun' | translate }}
          </zc-button>
        </p>
      </div>

      <div class="table-responsive" *ngIf="IsCurrentDataAvailable">
        <table class="table">
          <thead>
            <tr>
              <th>{{ 'CheckToHire.lblGroup' | translate }}</th>
              <th>
                <span>{{ 'CheckToHire.lblOrder' | translate }}</span>
                <span class="ms-2">
                  {{ 'CheckToHire.lblItemDescription' | translate }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of CurrentItems | keyvalue">
              <td>{{ item.key }}</td>
              <td>
                <div *ngFor="let lead of item.value" style="margin-left: 12px">
                  <zc-checkbox
                    [disabled]="lead.IsReadOnly"
                    [ngModel]="lead.IsRequested"
                    (change)="OnCheckChange(lead, $event)">
                    <span style="margin-left: 25px">{{
                      lead.Description
                    }}</span>
                  </zc-checkbox>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- historical -->
      <a
        (click)="Collapsed()"
        [attr.aria-expanded]="!IsCollapsed"
        aria-controls="collapseBasic"
        *ngIf="IsHistoricalDataAvailable">
        <fa-icon [icon]="IsCollapsed ? 'minus' : 'plus'"></fa-icon>
        {{ 'CheckToHire.lblShowPreviouslyReqReports' | translate }}
      </a>

      <div class="table-responsive" [collapse]="!IsCollapsed">
        <table class="table">
          <thead>
            <tr>
              <th>{{ 'CheckToHire.lblGroup' | translate }}</th>
              <th>
                <span>{{ 'CheckToHire.lblOrderAgain' | translate }}</span>
                <span class="ms-2">
                  {{ 'CheckToHire.lblItemDescription' | translate }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of HistoricalItems | keyvalue">
              <td>{{ item.key }}</td>
              <td>
                <div *ngFor="let lead of item.value" style="margin-left: 32px">
                  <span *ngIf="lead.IsReadOnly; else content">
                    {{ 'Common.lblUnableToOrderAgain' | translate }}
                    <fa-icon
                      [styles]="{ color: 'dodgerblue' }"
                      class="me-1"
                      icon="info-circle"
                      [popover]="'Common.msgUnableToOrderAgain' | translate"
                      placement="bottom"
                      triggers="mouseenter:mouseleave">
                    </fa-icon>
                    <span style="margin-left: 42px">{{
                      lead.Description
                    }}</span>
                  </span>
                  <ng-template #content>
                    <zc-checkbox
                      [ngModel]="lead.IsRequested"
                      (change)="OnCheckChange(lead, $event)">
                      <span style="margin-left: 42px">{{
                        lead.Description
                      }}</span>
                    </zc-checkbox>
                  </ng-template>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </zc-card-body>

    <zc-card-footer>
      <div class="d-flex justify-content-between">
        <div>
          <zc-button color="link" (click)="BackToPrepare()">
            <fa-icon icon="chevron-left"></fa-icon>
            {{ 'CheckToHire.lblBackToPrepare' | translate }}
          </zc-button>
        </div>
        <div>
          <zc-button color="link" class="me-1" (click)="OpenModal()">
            {{ 'Common.lblCancel' | translate }}
          </zc-button>
          <zc-button
            color="primary"
            (click)="OnBuyReport()"
            [busy]="BusyStatus">
            {{ 'CheckToHire.lblOrder' | translate }}
          </zc-button>
        </div>
      </div>
    </zc-card-footer>
  </zc-card>
</ng-template>
