<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ 'Common.lblLocalePicker' | translate }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <!-- locales -->
    <div class="list-group">
      <a
        *ngFor="let item of locales"
        class="list-group-item"
        (click)="onSelect(item)"
        [class.locale--active]="item === selected">
        <span>{{ item }}</span>
        <fa-icon icon="check" class="locale__icon"></fa-icon>
      </a>
    </div>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button color="info" outline class="me-1" (click)="onCancel()">
      {{ 'Common.lblCancel' | translate }}
    </zc-button>
    <zc-button color="primary" (click)="onDone()">
      {{ 'Common.cmdDone' | translate }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
