import { NgModule } from '@angular/core';
import { CommonModule } from '@zipcrim/common';

import { InvoiceListComponent } from './invoice-list/invoice-list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [InvoiceListComponent],
  exports: [CommonModule, InvoiceListComponent],
})
export class FinancialModule {}
