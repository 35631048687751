import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Validate a number is within a range.
 *
 * Note: Min and max endpoints are inclusive.
 */
export function BetweenValidator(min: number, max: number) {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    // zero is a valid value here
    if (!value && value !== 0) {
      return null;
    }

    if (value < min || value > max) {
      return {
        between: { min, max },
      };
    }

    return null;
  };
}
