import { IEducation } from '../generated';
import { L10nService } from '../l10n/l10n.service';
import { Address } from './address';
import { Certification } from './certification';

/**
 * Education.
 */
export class Education {
  constructor();
  constructor(source: Partial<IEducation>, l10n: L10nService);
  constructor(source?: Partial<IEducation>, l10n?: L10nService) {
    this._Init(source, l10n);
  }

  SchoolName: string;
  SchoolType: string;
  Major: string;
  Degree: string;
  StartDate: string;
  EndDate: string;
  GraduationDate: string;
  HasGraduated: boolean;
  GPAScale: string;
  ID: number;
  Address: Address;
  Certifications: Certification[];

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   * @param l10n L10n service instance.
   */
  private _Init(source?: Partial<IEducation>, l10n?: L10nService) {
    if (!source) {
      this.Address = new Address(null, l10n);
      this.Certifications = [];
      return;
    }

    this.SchoolName = source.SchoolName;
    this.SchoolType = source.SchoolType;
    this.Major = source.Major;
    this.Degree = source.Degree;
    this.StartDate = source.StartDate;
    this.EndDate = source.EndDate;
    this.GraduationDate = source.GraduationDate;
    this.HasGraduated =
      source.HasGraduated == null ? null : source.HasGraduated;
    this.GPAScale = source.GPAScale;
    this.Address = new Address(source.Address, l10n);
    this.ID = source.ID;
    this.Certifications = source.Certifications
      ? source.Certifications.Items.map((item) => new Certification(item))
      : [];
  }
}
