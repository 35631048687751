import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm, Validators } from '@angular/forms';
import { IAddress, IUiMetaField } from '@zipcrim/common';
import { OptionsService } from '@zipcrim/forms';
import {
  AddressValidator,
  CityValidator,
  ZipCodeValidator,
} from '@zipcrim/forms/validators';

/**
 * Component for entering subject addresses.
 */
@Component({
  selector: 'c2h-data-entry-address',
  templateUrl: './data-entry-address.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
})
export class DataEntryAddressComponent implements OnInit {
  constructor(private _Options: OptionsService) {}

  /**
   * Data to hydrate form.
   */
  @Input()
  Data: IAddress[];

  /**
   * UiMeta field spec.
   */
  @Input()
  UiMeta: IUiMetaField;

  // todo: replace with reactive form
  Config: any;

  /**
   * On init.
   */
  ngOnInit() {
    this._SetConfig();
  }

  /**
   * Set form configuration.
   */
  private _SetConfig() {
    this.Config = {
      nodeType: 'container',
      nodes: [
        {
          nodeType: 'collection',
          name: 'Addresses',
          titleL10nKey: 'Common.lblAddress',
          require: {
            min: this.UiMeta.Min,
            max: this.UiMeta.Max,
          },
          nodes: [
            {
              nodeType: 'hidden',
              name: 'ID',
            },
            {
              nodeType: 'input',
              name: 'StreetName',
              labelL10nKey: 'Common.lblAddressStreet',
              validators: [Validators.required, AddressValidator],
            },
            {
              nodeType: 'input',
              name: 'Address2',
              labelL10nKey: 'Common.lblAddressApt',
              validators: [AddressValidator],
            },
            {
              nodeType: 'select',
              name: 'Country',
              value: 'US',
              labelL10nKey: 'Common.lblAddressCountry',
              optionsAsync: this._Options.getCountries(),
              validators: [Validators.required],
            },
            {
              nodeType: 'input',
              name: 'City',
              labelL10nKey: 'Common.lblCity',
              validators: [Validators.required, CityValidator],
            },
            {
              nodeType: 'container',
              css: 'row',
              nodes: [
                {
                  nodeType: 'select',
                  name: 'State',
                  labelL10nKey: 'Common.lblAddressState',
                  css: 'col-sm-4',
                  listen: {
                    field: 'Country',
                    onChange: (value: string) => ({
                      optionsAsync: this._Options.getRegions(value),
                    }),
                  },
                  validators: [Validators.required],
                },
                {
                  nodeType: 'input',
                  name: 'ZipCode',
                  labelL10nKey: 'Common.lblAddressZip',
                  css: 'col-sm-4',
                  validators: [Validators.required, ZipCodeValidator(null)],
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
