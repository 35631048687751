import { Component } from '@angular/core';

@Component({
  selector: 'c2h-walkthrough',
  templateUrl: 'walk-through.component.html',
  styleUrls: ['walk-through.component.scss'],
})
export class WalkthroughComponent {
  /**
   * Indicates if the walkthrough has been closed.
   */
  IsClosed = false;

  /**
   * On close.
   */
  OnClose() {
    this.IsClosed = true;
  }
}
