import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { ApiMessage } from './api/api-message';
import { L10nService } from './l10n/l10n.service';
import { NotificationService } from './notification/notification.service';

/**
 * Global error handler.
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  /**
   * Handle error.
   */
  handleError(error: Error | HttpErrorResponse) {
    const notificationService = this.injector.get(NotificationService);
    const l10nService = this.injector.get(L10nService);
    let text: string;

    if (error instanceof HttpErrorResponse) {
      // ApiService handles its own errors, this is in case the HttpClient is used directly
      if (!navigator.onLine) {
        // offline error
        text = l10nService.instant('Common.msgNoApiResultFail');
      } else {
        // http error (403, 404, etc.)
        text = `${error.status} - ${error.message}`;
      }
    } else if (error instanceof Error) {
      // client error (TypeError, ReferenceError, etc.)
      text = error.message;
    } else {
      // unknown object thrown, do nothing (..for now, might need to add handling as the need arises)
    }

    if (text) {
      const message = new ApiMessage(text);
      notificationService.add(message);
    }

    // eslint-disable-next-line no-console
    console.error(error);
  }
}
