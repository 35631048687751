import { HttpHeaders, HttpParams } from '@angular/common/http';

import { IBlurb, IUiField } from '../generated';

/**
 * Request configuration.
 */
export interface RequestConfig {
  method: string;
  url: string;
  options: RequestOptions;
}

/**
 * Request options.
 */
export interface RequestOptions {
  body?: any;
  headers?: HttpHeaders;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json';
  skipTokenRefresh?: boolean;
  isHubRequest?: boolean;
}

/**
 * Request "ZH" header object structure.
 */
export interface RequestHeader {
  /**
   * Requested language code.
   */
  LC: string;

  /**
   * Tokens.
   */
  T?: { [key: string]: any };
}

/**
 * Response result.
 */
export interface ResponseResult {
  Result: ResultStatus;
  Payload?: any;
  Message?: ResponseMessage;
  ZS?: string;
}

/**
 * Response message.
 */
export interface ResponseMessage {
  Blurb: IBlurb;
  Severity?: MessageSeverity;
  Target?: MessageTarget;
  Source?: string;
  InnerMessages?: ResponseMessage[];
  UiField?: IUiField;
}

/**
 * Message severity.
 */
export type MessageSeverity =
  | 'Trace'
  | 'Debug'
  | 'Info'
  | 'Explain'
  | 'Unclassified'
  | 'Disregard'
  | 'Alert'
  | 'Error'
  | 'Fatal';

/**
 * Message target.
 */
export type MessageTarget = 'User' | 'Admin' | 'Dev';

/**
 * Result status.
 */
export type ResultStatus = 'Success' | 'Failure' | 'Expired' | 'Abort';

/**
 * Payload structure for file responses.
 */
export interface FilePayload {
  Name: string;
  Data: Blob;
}

/**
 * Object structure needed to convert session.
 */
export interface SessionPostData {
  SessionGuid: string;
  UserName: string;
  ClientCode: string;
  ContactID: number;
}

/**
 * We create an inmutable array of OK statuses for cast the result to the ResultStatus.
 * If server responses within a 200 range then is a 'Success' ResultStatus that can be cast from Hub responses.
 * Source: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#successful_responses
 */
export const okResponseStatuses: ReadonlyArray<number> = [
  200, 201, 202, 203, 204, 205, 206,
];
