import isObject from 'lodash/isObject';
import set from 'lodash/set';

/**
 * Field spec implementation.
 */
export class FieldSpec {
  /**
   * Collection of field names.
   */
  private _Fields: string[] = [];

  /**
   * Include all public properties.
   */
  all() {
    this.addField('*');
    return this;
  }

  /**
   * Include the server defined default fields.
   */
  default() {
    this.addField('#');
    return this;
  }

  /**
   * Include a server defined named spec.
   *
   * @param name Field spec name.
   */
  named(name: string) {
    this.addField(`#${name}`);
    return this;
  }

  /**
   * Include specific fields by name.
   *
   * @param names Collection of field names to get.
   */
  add(names: string[]) {
    names.forEach((name) => this.addField(name));
    return this;
  }

  /**
   * To string.
   */
  toString() {
    const tree = {};
    this._Fields.forEach((item) => set(tree, item, true));

    const result = this.convertToString(tree);
    return `[${result}]`;
  }

  /**
   * Safely add a field to the collection.
   *
   * @param name Field name to add.
   */
  private addField(name: string) {
    if (this._Fields.indexOf(name) === -1) {
      this._Fields.push(name);
    }
  }

  /**
   * Convert a nested object structure to a field list.
   *
   * @param tree Object to convert to string.
   */
  private convertToString(tree: { [key: string]: any }) {
    const keys = Object.keys(tree);
    let result = '';

    keys.forEach((key) => {
      const value = tree[key];

      if (isObject(value)) {
        const fields = this.convertToString(value);
        result += `${key}[${fields}],`;
      } else {
        result += `${key},`;
      }
    });

    // remove trailing comma
    result = result.slice(0, -1);

    return result;
  }
}
