import { Injectable } from '@angular/core';
import isString from 'lodash/isString';
import { Observable, Subject } from 'rxjs';

import { ApiMessage } from '../api/api-message';

/**
 * Global notification service.
 */
@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor() {
    this.onAdd = this.addEmitter.asObservable();
    this.onRemoveAll = this.removeAllEmitter.asObservable();
  }

  onAdd: Observable<ApiMessage>;
  onRemoveAll: Observable<void>;
  private addEmitter = new Subject<ApiMessage>();
  private removeAllEmitter = new Subject<void>();

  add(message: ApiMessage | string) {
    if (isString(message)) {
      message = new ApiMessage(message);
    }

    this.addEmitter.next(message);
  }

  removeAll() {
    this.removeAllEmitter.next();
  }
}
