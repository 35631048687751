import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiMessage } from '../../api/api-message';
import { CommonModuleConfig } from '../../common.interfaces';
import { NotificationService } from '../../notification/notification.service';
import { AddressParts } from './address.interface';

/**
 * Address service.
 */
@Injectable({ providedIn: 'root' })
export class AddressService {
  constructor(
    private http: HttpClient,
    private notification: NotificationService,
    @Inject('commonModuleConfig') private config: CommonModuleConfig
  ) {}

  /**
   * Validate an address.
   *
   * @param address Address to validate.
   */
  validate(address: AddressParts) {
    if (this.config.skipColdFusion) {
      return of(true);
    }

    const params = new HttpParams()
      .append('action', 'ajaxservices')
      .append('page_frame', 'ajaxfr')
      .append('cmd', '101D')
      .append('city', address.city)
      .append('state', address.state)
      .append('zip', address.zip);

    // note: this ColdFusion service call should eventually be moved to a C# API
    return this.http.get('/', { responseType: 'text', params }).pipe(
      map((res) => {
        if (res !== '1') {
          const msg = new ApiMessage(res);
          this.notification.add(msg);
          throw new Error(res);
        }

        return true;
      })
    );
  }
}
