import { IContactAddress } from '../generated';

/**
 * Contact address.
 */
export class ContactAddress {
  constructor(source?: Partial<IContactAddress>) {
    this._Init(source);
  }

  ID: number;
  Address1: string;
  Address2?: string;
  Address3?: string;
  City: string;
  State: string;
  ZipCode: string;
  CountryCode: string;
  LocationType: 'Home' | 'Business';
  Purpose:
    | 'Unknown'
    | 'General'
    | 'Mailing'
    | 'Shipping'
    | 'Billing'
    | 'SAML'
    | 'Remittance';

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IContactAddress>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Address1 = source.Address1;
    this.Address2 = source.Address2;
    this.Address3 = source.Address3;
    this.City = source.City;
    this.State = source.State;
    this.ZipCode = source.ZipCode;
    this.CountryCode = source.CountryCode;
    this.LocationType = source.LocationType;
    this.Purpose = source.Purpose;
  }
}
