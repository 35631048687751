import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { FormService } from '@zipcrim/forms/form.service';
import { FormDirective } from '../form/form.directive';
import { DynamicFormFieldLayout } from './form-field.interfaces';

/**
 * Form field.
 *
 * ```html
 * <zc-dynamic-form-field>
 *   <zc-dynamic-label>Example</zc-dynamic-label>
 *   <zc-dynamic-input [(ngModel)]="myValue"></zc-dynamic-input>
 * </zc-dynamic-form-field>
 * ```
 */
@Component({
  selector: 'zc-dynamic-form-field',
  exportAs: 'zcFormField',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormFieldComponent implements OnInit, OnChanges, AfterContentInit {
  constructor(
    private _FormService: FormService,
    @Optional() private _FormDirective: FormDirective
  ) {
    this.HasRequired = this._HasRequired.asObservable();
  }

  /**
   * Form layout.
   */
  @Input()
  Layout: DynamicFormFieldLayout;

  /**
   * Form field id.
   */
  @Input()
  Id: string;

  /**
   * Id value for `aria-describedby` attribute.
   */
  AriaDescribedById: string;

  /**
   * Id value for `aria-labeledby` attribute.
   */
  AriaLabeledById: string;

  /**
   * Label css classes.
   */
  LabelCss: string[];

  /**
   * Field css classes.
   */
  FieldCss: string[];

  /**
   * Control reference.
   */
  @ContentChild(NgControl)
  Control?: NgControl;

  /**
   * Emits a value when the required validation status changes.
   */
  HasRequired: Observable<boolean>;

  /**
   * Has required subject.
   */
  private _HasRequired = new ReplaySubject<boolean>(1);

  /**
   * On init.
   */
  ngOnInit() {
    this.Id = this.Id || this._FormService.GetAnchorId();
    this.AriaDescribedById = this.Id + '__describedby';
    this.AriaLabeledById = this.Id + '__label';

    if (!this.Layout && this._FormDirective) {
      this.Layout = this._FormDirective.Layout;
      this._SetLayoutCss();
    }
  }

  /**
   * On changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.Layout) {
      this._SetLayoutCss();
    }
  }

  /**
   * After content init.
   */
  ngAfterContentInit() {
    this.Control?.statusChanges
      .pipe(
        startWith([]),
        map(() =>
          this._FormService.hasRequired(this.Control.control as FormControl)
        ),
        tap((value) => this._HasRequired.next(value))
      )
      .subscribe();
  }

  /**
   * Set CSS classes based on layout.
   */
  private _SetLayoutCss() {
    let labelCss: string[];
    let fieldCss: string[];

    switch (this.Layout) {
      case 'horizontal':
        labelCss = ['col-sm-4', 'col-lg-3', 'form-field__label'];
        fieldCss = ['col-sm-8', 'col-md-6', 'col-lg-4'];
        break;
      default:
        labelCss = [];
        fieldCss = [];
        break;
    }

    this.LabelCss = labelCss;
    this.FieldCss = fieldCss;
  }
}
