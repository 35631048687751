import {
  faBan,
  faCheck,
  faCopy,
  faEdit,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

export const CONTACTS_ICONS = [
  faBan,
  faCheck,
  faCopy,
  faEdit,
  faExclamationTriangle,
];
