import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import orderBy from 'lodash/orderBy';

import { FilterSpec } from '../api/filter-spec';
import { FilterSpecSortDirection } from '../api/filter-spec.enums';
import { TableColumnDirective } from './table-column.directive';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

/**
 * Table component.
 *
 * ```html
 * <zc-table [data]="MyData">
 *   <zc-column header="First Name">
 *     <ng-template zcCell let-row="row">{{row.FirstName}}</ng-template>
 *   </zc-column>
 *   <zc-column header="Last Name">
 *     <ng-template zcCell let-row="row">{{row.LastName}}</ng-template>
 *   </zc-column>
 * </zc-table>
 * ```
 */
@Component({
  selector: 'zc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input() busy: boolean;
  @Input() color: 'transparent';
  @Input() stripped: boolean;
  @Input() data: Array<{ [key: string]: any }>;
  @Input() filterLocally = false;
  @Input() enablePagination = false;
  @Output() filterChange = new EventEmitter<FilterSpec>();
  @ContentChildren(TableColumnDirective)
  columns: QueryList<TableColumnDirective>;
  css: string[];
  isEmpty = true;
  filterSpecSortDirection = FilterSpecSortDirection;
  currentPage = 1;
  private currentSort: TableColumnDirective[] = [];
  private _dataSize: number;
  private _pageSize: number = 75;
  private _paginatedData: Array<{ [key: string]: any }>;
  private get hasData() {
    return this.data && this.data.length > 0;
  }

  get dataSize() {
    return this._dataSize;
  }
  set dataSize(value: number) {
    this._dataSize = value;
  }

  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
    this.currentPage = 1;
    this.paginateData();
  }

  get paginatedData() {
    return this._paginatedData;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.setCss();

    if (changes.data) {
      // data changed, not waiting on busy
      this.isEmpty = !this.hasData;

      this.dataSize = this.data?.length ?? 0;

      if (!this.isEmpty) {
        this.paginateData();
      }
    }
  }

  onColumnSort(column: TableColumnDirective, event: MouseEvent) {
    let direction = column.sortDirection;

    if (!column.isSortable) {
      return;
    }

    if (direction === FilterSpecSortDirection.Asc) {
      // asc -> desc
      direction = FilterSpecSortDirection.Desc;
    } else if (direction === FilterSpecSortDirection.Desc) {
      // desc -> none
      direction = null;
    } else {
      // none -> asc
      direction = FilterSpecSortDirection.Asc;
    }

    if (event.ctrlKey) {
      // multi-column sort
      const index = this.currentSort.findIndex(item => item === column);

      if (index === -1) {
        // add column to sort order
        this.currentSort.push(column);
      } else if (!direction) {
        // remove column from sort
        this.currentSort.splice(index, 1);
      }
    } else {
      // single column sort
      this.currentSort = [column];

      // remove sort from all columns
      this.columns.forEach(item => (item.sortDirection = null));
    }

    column.sortDirection = direction;

    if (this.filterLocally) {
      this.sortLocally();
    } else {
      this.sortRemotely();
    }
  }

  setCurrentPage($event: PageChangedEvent) {
    this.currentPage = $event.page;
    this.paginateData();
  }

  private sortRemotely() {
    const spec = new FilterSpec();
    const sort = this.currentSort
      .filter(item => item.sortDirection)
      .map(item => ({
        name: item.sortProperty,
        direction: item.sortDirection,
      }));

    if (sort.length > 0) {
      spec.sort(sort);
    }

    this.filterChange.emit(spec);
  }

  private sortLocally() {
    const fields: string[] = [];
    const dirs: Array<'asc' | 'desc'> = [];

    this.currentSort
      .filter(item => item.sortDirection)
      .forEach(item => {
        fields.push(item.sortProperty);
        dirs.push(
          item.sortDirection === FilterSpecSortDirection.Asc ? 'asc' : 'desc'
        );
      });

    this.data = orderBy(this.data, fields, dirs);
    this.paginateData();
  }

  private setCss() {
    const classes = ['table'];

    if (this.color) {
      classes.push(`table--${this.color}`);
    }

    if (this.stripped) {
      classes.push('table-striped');
    }

    this.css = classes;
  }

  private paginateData() {
    if (this.enablePagination) {
      this._paginatedData = this.data.slice(
        this.pageSize * (this.currentPage - 1),
        this.pageSize * this.currentPage
      );
    } else {
      this._paginatedData = this.data;
    }

    this.cdr.markForCheck();
  }
}
