import { IMiscAttribute, IMiscAttributeName } from '../generated';

/**
 * Misc attribute.
 */
export class MiscAttribute {
  constructor(source?: Partial<IMiscAttribute>) {
    this._Init(source);
  }

  ID: number;
  Value: string;
  Code: IMiscAttributeName;
  CountryCode?: string;

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IMiscAttribute>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Value = source.Value;
    this.Code = source.Code;
    this.CountryCode = source.CountryCode;
  }
}
