import { Inject, Injectable, Optional } from '@angular/core';
import { AuthenticationModuleConfig } from 'ps-angular-authentication';
import { OAuthResponse } from 'ps-angular-authentication';
import { WindowRefService } from '..';

@Injectable({ providedIn: 'root' })
export class OAuthService {
  constructor(
    private windowRef: WindowRefService,
    @Inject('authenticationConfig')
    @Optional()
    private config?: AuthenticationModuleConfig
  ) {}

  /**
   * Session storage key.
   */
  private readonly tokenCacheKey = 'OAuth';

  /**
   * Local data for user session storage key.
   */
  private readonly basicAuthTokenKey = 'authLocalData';

  /**
   * The oauth token.
   */
  private oAuthToken: string;

  /**
   * Sets the basic credential users.
   *
   * @param data string with user and password BASE64 encoded
   */
  setBasicAuthForLocal(data: string) {
    sessionStorage.setItem(this.basicAuthTokenKey, data);
  }

  /**
   * Sets the oauth token.
   *
   * @param token jwt
   */
  setToken(token: string) {
    sessionStorage.setItem(this.tokenCacheKey, token);

    this.oAuthToken = token;
  }

  /**
   * Cleans the oauth token from cache.
   */
  cleanToken() {
    sessionStorage.removeItem(this.tokenCacheKey);
  }

  /**
   *
   * @returns boolean
   */
  tokenExists() {
    return !!this.getToken();
  }

  /**
   * Gets the OAuth token.
   * @returns Oauth token.
   * We do not access the token directly we use encapsulation methods for get and set the token
   */
  getToken() {
    if (!this.oAuthToken) {
      const token = sessionStorage.getItem(this.tokenCacheKey);

      if (token) {
        this.oAuthToken = token;
      }
    }

    return this.oAuthToken;
  }

  /**
   * Basic auth data saved for local development
   * @returns
   */
  getBasicAuthForLocal() {
    return sessionStorage.getItem(this.basicAuthTokenKey);
  }

  async refreshToken() {
    let scope = `ZipcrimWs.SmartClient`;

    if (this.config) {
      scope = `ZipcrimWs.${this.config.appCode}`;
    }

    const result = await this.windowRef.nativeWindow.getToken(scope);

    if (result) {
      const oAuthObj = { ...result } as OAuthResponse;
      // With this we should already set the token but ensure that works properly
      this.setToken(oAuthObj.access_token);
      this.oAuthToken = oAuthObj.access_token;
    } else {
      throw new Error('Could not refresh token!');
    }
  }
}
