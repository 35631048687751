import { IContactInfo } from '../generated';

/**
 * Contact info.
 */
export class ContactInfo {
  constructor(source?: Partial<IContactInfo>) {
    this._Init(source);
  }

  ID: number;
  Value: string;
  Category: 'Unspecified' | 'Home' | 'Work' | 'Day' | 'Evening' | 'Emergency';
  Type: 'Phone' | 'Mobile' | 'Email' | 'Sms' | 'SocialNet';

  /**
   * Initialize object with `source` data.
   *
   * @param source Source data object.
   */
  private _Init(source?: Partial<IContactInfo>) {
    if (!source) {
      return;
    }

    this.ID = source.ID;
    this.Value = source.Value;
    this.Category = (source.Category as any) || 'Unspecified';
    this.Type = (source.Type as any) || 'Phone';
  }
}
