import { Component } from '@angular/core';

/**
 * Account root.
 */
@Component({
  templateUrl: 'account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent {}
