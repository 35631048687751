<zc-modal>
  <zc-modal-header [showClose]="false">
    <h4 class="modal-title">{{ job?.Description }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <form>
      <!-- authorized -->
      <ng-container
        *ngIf="!isUnauthorizedFormEnable && !isInvitationSent"
        [formGroup]="authorizationForm">
        <zc-form-field>
          <zc-label>
            {{ 'Common.lblAreYouAuthorizedToSignDocument' | translate }}
          </zc-label>
          <zc-radio-group formControlName="AuthorizationOption">
            <zc-radio-button
              *ngFor="let item of authOptions"
              [value]="item.value">
              {{ item.labelL10nKey | translate }}
            </zc-radio-button>
          </zc-radio-group>
        </zc-form-field>
      </ng-container>

      <!-- unauthorized -->
      <ng-container
        *ngIf="isUnauthorizedFormEnable"
        [formGroup]="unauthorizedUserForm">
        <h2 class="header-2 text-green">
          {{ 'Common.lblWhoIsAuthorized' | translate }}
        </h2>
        <p class="mb-1">
          {{ 'Common.lblWhoIsAuthorizedDescription' | translate }}
        </p>

        <zc-form-field>
          <zc-label>{{ 'Common.lblFirstName' | translate }}</zc-label>
          <zc-input formControlName="FirstName"></zc-input>
        </zc-form-field>

        <zc-form-field>
          <zc-label>{{ 'Common.lblLastName' | translate }}</zc-label>
          <zc-input formControlName="LastName"></zc-input>
        </zc-form-field>

        <zc-form-field>
          <zc-label>{{ 'Common.lblTitle' | translate }}</zc-label>
          <zc-input formControlName="Title"></zc-input>
        </zc-form-field>

        <zc-form-field>
          <zc-label>{{ 'Common.lblEmail' | translate }}</zc-label>
          <zc-input type="email" formControlName="Email"></zc-input>
        </zc-form-field>

        <zc-form-field>
          <zc-label>{{ 'Common.lblConfirmEmail' | translate }}</zc-label>
          <zc-input type="email" formControlName="ConfirmEmail"></zc-input>
        </zc-form-field>
      </ng-container>

      <!-- invitation sent -->
      <ng-container *ngIf="isInvitationSent">
        <h2
          class="header-2 text-center"
          translate
          [translateParams]="{ email: newEmail }">
          Common.lblInvitationSent
        </h2>
        <p>{{ 'Common.lblInvitationSentDescription' | translate }}</p>
      </ng-container>
    </form>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button color="info" outline class="me-1" (click)="onCancel()">
      {{ 'Common.lblCancel' | translate }}
    </zc-button>
    <!-- Okay Button for unauthorized user form -->
    <zc-button
      *ngIf="isInvitationSent"
      color="primary"
      class="me-1"
      (click)="onOk()">
      {{ 'Common.lblOk' | translate }}
    </zc-button>
    <!-- Next Button for unauthorized user form -->
    <zc-button
      *ngIf="isUnauthorizedFormEnable"
      color="primary"
      (click)="next(true)"
      [busy]="busy"
      [disabled]="unauthorizedUserForm.invalid">
      {{ 'Common.cmdSendInvitation' | translate }}
    </zc-button>
    <!-- Next Button for authorized user form  -->
    <zc-button
      *ngIf="!isUnauthorizedFormEnable && !isInvitationSent"
      color="primary"
      [busy]="busy"
      (click)="next()">
      {{ 'Common.cmdNext' | translate }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
