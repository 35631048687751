<ng-container *ngFor="let option of AllOptions">
  <zc-dynamic-checkbox
    [Id]="option.id"
    [name]="option.name"
    [(ngModel)]="option.value"
    (ngModelChange)="OnChange()"
    [Disabled]="option.disabled"
    [Readonly]="option.readonly"
  >
    {{ option.label }}
    <zc-dynamic-help-text *ngIf="option.description">
      {{ option.description }}
    </zc-dynamic-help-text>
  </zc-dynamic-checkbox>
</ng-container>
