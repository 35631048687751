import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import uniqueId from 'lodash/uniqueId';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { FormFieldControl } from '../form-field/form-field-control';
import { FormService } from '../form.service';
import { RadioChange } from './radio-group.interfaces';

/**
 * Available radio group layouts.
 */
export type RadioGroupLayout = 'inline';

@Component({
  selector: 'zc-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormFieldControl,
      useExisting: RadioGroupComponent,
    },
  ],
})
export class RadioGroupComponent
  implements
    ControlValueAccessor,
    FormFieldControl<any>,
    OnInit,
    OnChanges,
    OnDestroy
{
  constructor(
    @Self() public ngControl: NgControl,
    private form: FormService,
    private ref: ChangeDetectorRef
  ) {
    ngControl.valueAccessor = this;
    this.valueChange$ = this.valueChange.asObservable();
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(value);
    this.valueChange.next(value);
    this.stateChanges.next();
  }

  @Input() disabled = false;
  @Input() id = uniqueId('control-');
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() inline = false;
  @Output() selectionChange = new EventEmitter<RadioChange>();
  name = uniqueId('radio-group-');
  required: boolean;
  valueChange$: Observable<any>;
  readonly stateChanges = new Subject<void>();

  private _value: any;
  private valueChange = new BehaviorSubject(null);
  private readonly _destroyed = new Subject<void>();

  ngOnInit() {
    this.setRequired();
    this.ngControl.statusChanges
      .pipe(
        takeUntil(this._destroyed),
        tap(() => this.setRequired())
      )
      .subscribe();
  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this.stateChanges.complete();
  }

  onBlur() {
    this.onTouched();
    this.stateChanges.next();
  }

  onChange = (_: any) => {
    // empty
  };

  onTouched = () => {
    // empty
  };

  writeValue(value: string) {
    this.value = value;
    this.ref.markForCheck();
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.ref.markForCheck();
  }

  private setRequired() {
    const ctrl = this.ngControl.control;

    this.required = this.form.hasRequired(ctrl);
    this.stateChanges.next();
  }
}
