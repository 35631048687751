import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusyService {
  private _isBusy = new Subject<boolean>();

  public isBusy$ = this._isBusy.asObservable();

  makeBusy() {
    this._isBusy.next(true);
  }

  makeIdle() {
    this._isBusy.next(false);
  }
}
