/**
 * Supported filter spec operators.
 */
export enum FilterSpecOperator {
  Equals = '=',
  GreaterThan = '>',
  GreaterThanOrEquals = '>=',
  In = ':',
  LessThan = '<',
  LessThanOrEquals = '<=',
  NotEquals = '<>',
}

/**
 * Supported filter spec group types.
 */
export enum FilterSpecGroupType {
  And = ',',
  Or = '|',
}

/**
 * Supported filter spec sort orders.
 */
export enum FilterSpecSortDirection {
  Asc = 'a',
  Desc = 'd',
}
