import { Component, OnInit } from '@angular/core';
import { IJob } from '@zipcrim/common';
import { JobService } from '@zipcrim/work-flow';
import { finalize, tap } from 'rxjs/operators';

/**
 * Display account activation jobs.
 */
@Component({
  templateUrl: 'onboard.component.html',
})
export class OnboardComponent implements OnInit {
  constructor(private _JobService: JobService) {}

  /**
   * Account activation jobs.
   */
  Jobs: IJob[];

  /**
   * Busy loading indicator.
   */
  BusyLoading: boolean;

  /**
   * On init.
   */
  ngOnInit() {
    this.GetJobs();
  }

  /**
   * Get all available workflow jobs.
   */
  GetJobs() {
    this.BusyLoading = true;
    this._JobService
      .getAccountActivationJobs()
      .pipe(
        tap((res) => (this.Jobs = res.Items)),
        finalize(() => (this.BusyLoading = false))
      )
      .subscribe();
  }
}
