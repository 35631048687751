import { NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@zipcrim/common';
import { FormsModule } from '@zipcrim/forms';
import { WorkFlowModule } from '@zipcrim/work-flow';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';

import { DocumentListComponent } from './document-list/document-list.component';
import { DOCUMENT_SIGNING_ICONS } from './document-signing.icons';
import { SignatureModalComponent } from './signature-modal/signature-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WorkFlowModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  declarations: [DocumentListComponent, SignatureModalComponent],
  exports: [CommonModule, DocumentListComponent],
})
export class DocumentSigningModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(...DOCUMENT_SIGNING_ICONS);
  }
}
