<div class="fuzzy-date">
  <!-- loading -->
  <zc-busy *ngIf="isLoading" size="xs" class="me-1"> </zc-busy>

  <!-- failed -->
  <zc-button
    size="sm"
    color="link"
    [buttonClasses]="['btn-condensed', 'me-1']"
    [tooltip]="'Common.lblFailedRetry' | translate"
    (click)="onRetry()"
    *ngIf="isFailure">
    <fa-icon icon="exclamation-triangle" class="text-danger"></fa-icon>
    {{ 'Common.cmdTryAgain' | translate }}
  </zc-button>

  <!-- display value -->
  <span
    *ngIf="!isLoading && !isFailure && (displayValue || value)"
    class="fuzzy-date__value">
    {{ displayValue || value }}
  </span>

  <zc-button
    #btn
    *ngIf="!readonly"
    color="primary"
    outline
    [attr.aria-describedby]="id + '__errors'"
    [attr.aria-labeledby]="id + '__label'"
    [attr.aria-required]="required"
    [class.float-end]="value"
    [disabled]="disabled"
    (click)="onOpenModal()">
    {{ (value ? 'Common.lblDateEdit' : 'Common.lblDateEnter') | translate }}
  </zc-button>
</div>
