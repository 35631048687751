import { Injectable } from '@angular/core';
import * as rxjs from 'rxjs';
import { distinctUntilChanged, filter, share } from 'rxjs/operators';
import { KeyCode } from './keyCode.enum';

/**
 * Keyboard shortcuts service.
 */
@Injectable({ providedIn: 'root' })
export class HotkeysService {
  /**
   * Add global hotkey.
   *
   * @param keys Keyboard keys.
   */
  addGlobal(keys: KeyCode[]) {
    // Observables for all keydown and keyup events
    const keyDown$ = rxjs.fromEvent<KeyboardEvent>(document, 'keydown');
    const keyUp$ = rxjs.fromEvent<KeyboardEvent>(document, 'keyup');

    // All KeyboardEvents - emitted only when KeyboardEvent changes (key or type)
    const keyEvents$ = rxjs.merge(keyDown$, keyUp$).pipe(
      distinctUntilChanged((a, b) => a.code === b.code && a.type === b.type),
      share()
    );

    // Create KeyboardEvent Observable for specified KeyCode
    const createKeyPressStream = (charCode: KeyCode) =>
      keyEvents$.pipe(filter((event) => event.code === charCode.valueOf()));

    // Create Event Stream for every KeyCode in shortcut
    const keyCodeEvents$ = keys.map((s) => createKeyPressStream(s));

    // Emit when specified keys are pressed (keydown).
    // Emit only when all specified keys are pressed at the same time.
    // More on combineLatest below
    return rxjs
      .combineLatest(keyCodeEvents$)
      .pipe(
        filter<KeyboardEvent[]>((arr) => arr.every((a) => a.type === 'keydown'))
      );
  }
}
