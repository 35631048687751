<zc-card
  color="primary"
  [title]="'CheckToHire.lblOrderAnotherReport' | translate">
  <zc-card-body>
    <form #Form="ngForm">
      <ng-container [formGroup]="FormGroup">
        <zc-form-field>
          <zc-label>{{ 'CheckToHire.lblChooseStage' | translate }}</zc-label>
          <zc-select
            formControlName="CaseType"
            [optionsAsync]="CaseTypeOptions$"
            (selectionChange)="OnCaseTypeChange($event)"></zc-select>
        </zc-form-field>

        <h3 class="header-2 text-green">
          {{ 'CheckToHire.lblReports' | translate }}
        </h3>

        <zc-dynamic-form-field>
          <!-- TODO: EJA: I added the following to fix an error: [ngModelOptions]="{standalone: true}" -->
          <zc-checkbox
            [ngModelOptions]="{standalone: true}"
            *ngFor="let option of AnaGroupOptions"
            [(ngModel)]="option.value">
            {{ option.label }}
            <zc-help-text *ngIf="option.description">
              {{ option.description }}
            </zc-help-text>
          </zc-checkbox>
        </zc-dynamic-form-field>
      </ng-container>
    </form>
    <zc-button color="link" (click)="OpenNextStepModal()">
      {{ 'CheckToHire.lblCustomizeFurther' | translate }}
    </zc-button>
  </zc-card-body>
  <zc-card-footer class="text-end">
    <a routerLink="/dashboard" class="btn btn-link me-1">
      {{ 'CheckToHire.lblBackToDashboard' | translate }}
    </a>
    <zc-button color="primary" (click)="OnNext()" [busy]="BusySaving">
      {{ 'Common.cmdNext' | translate }}
    </zc-button>
  </zc-card-footer>
</zc-card>
