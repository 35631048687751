import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupName, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAnaGroup, SaveSpec, WorkOrderService } from '@zipcrim/common';
import { AnaGroup } from '@zipcrim/common/models';
import { SelectChange } from '@zipcrim/forms/select/select.interfaces';
import { BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

import {
  NextStepModalComponent,
} from '../next-step-modal/next-step-modal.component';
import { ReportComponent } from '../report.component';
import { ReportService } from '../report.service';

@Component({
  selector: 'c2h-add-another',
  templateUrl: './add-another.component.html',
})
export class CheckOptionComponent {
  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _Fb: FormBuilder,
    private _ModalService: BsModalService,
    private _Parent: ReportComponent,
    private _ReportService: ReportService,
    private _Router: Router,
    private _WorkOrder: WorkOrderService
  ) {}

  /**
   * Form reference.
   */
  @ViewChild('Form', { static: true })
  Form: NgForm;

  /**
   * Busy saving indicator.
   */
  BusySaving: boolean;

  FormGroup = this._Fb.group({
    CaseType: this._Fb.control(this._ReportService.CaseType, [
      Validators.required,
    ]),
  });

  FormGroupName: FormGroupName;

  CaseTypeOptions$ = this._ReportService.GetStageOptions();
  AnaGroupOptions: any[] = [];

  OnCaseTypeChange(event: SelectChange) {
    const items: IAnaGroup[] = event.data?.AnaGroups.Items || [];

    this.AnaGroupOptions = items.map((item) => ({
      name: item.Code,
      label: item.Code,
      value: true,
      description: item.DescriptionBlurb,
      readonly: item.IsReadOnly,
    }));
  }

  /**
   * Used to open next step modal
   */
  OpenNextStepModal() {
    const modalRef = this._ModalService.show(NextStepModalComponent, {
      initialState: {
        ReportService: this._ReportService,
      },
    });

    const component = modalRef.content as NextStepModalComponent;
    component.result.then(() => {
      this._Parent.RefreshNextStep()
        .subscribe(() => {
          this._Router.navigate(['prepare-order'], {
            relativeTo: this._ActivatedRoute,
          });
      });
    }).catch(() => {
      // empty
    });
  }

  /**
   * On next.
   */
  OnNext() {
    const value = this.Form.form.value;
    const groups = Object.keys(value)
      .filter((key) => this._ReportService.LeadNames.includes(key))
      .map(
        (key) =>
          new AnaGroup({
            LeadClass: key,
            IsSelected: value[key],
          })
      );

    const spec = new SaveSpec(
      'WorkOrder',
      {
        CaseType: value.CaseType,
        CaseNum: this._ReportService.CaseNum,
        AnaGroups: groups,
      },
      true
    );

    this.BusySaving = true;
    this._WorkOrder
      .save(0, spec)
      .pipe(finalize(() => (this.BusySaving = false)))
      .subscribe(() => {
        this._Parent.RefreshNextStep()
          .subscribe(() => {
            this._Router.navigate(['prepare-order'], {
                relativeTo: this._ActivatedRoute,
              }
            );
          });
      });
  }
}
