import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import sortBy from 'lodash/sortBy';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService, IBoList } from '../common';
import {
  ICountry,
  INationalID,
  IOptionSet,
  IRegion,
} from '../common/generated';
import { OptionConfig, OptionSetCode } from './forms.interfaces';

@Injectable({ providedIn: 'root' })
export class OptionsService {
  constructor(private api: ApiService) {}

  private optionSetCache = new Map<string, OptionConfig[]>();
  private regionCache = new Map<string, OptionConfig[]>();
  private countryCache: OptionConfig[];
  private nationIdsCache: OptionConfig[];

  getOptionSet(code: OptionSetCode | string) {
    const cached = this.optionSetCache.get(code);

    if (cached) {
      return of(cached);
    }

    const url = `optionSet/byCode`;
    const params = new HttpParams()
      .append('optionSetCode', code)
      .append('isDeprecatedIncluded', 'false');

    return this.api
      .get<{ Code: string; Options: IBoList<IOptionSet> }>(url, params)
      .pipe(
        map((res) => res.payload.Options.Items),
        map((items) => {
          const output: OptionConfig[] = items.map((item) => ({
            label: item.Blurb,
            value: item.Value,
          }));

          return output;
        }),
        tap((items) => this.optionSetCache.set(code, items))
      );
  }

  getCountries(forceToTop?: string[]) {
    if (this.countryCache) {
      return of(this.countryCache);
    }

    return this.api.get<IBoList<ICountry>>('shared/countries').pipe(
      map((res) => res.payload.Items),
      map((items) => {
        let output: OptionConfig[] = items
          .filter((item) => !!item.CustomCode)
          .map((item) => ({
            ...item,
            label: item.Blurb,
            value: item.CustomCode.replace(/\s/g, ''),
          }));

        output = sortBy(output, ['label']);

        if (forceToTop) {
          forceToTop.reverse().forEach((code) => {
            const index = output.findIndex((x) => x.value === code);

            if (index !== -1) {
              const item = output.splice(index, 1)[0];
              output.unshift(item);
            }
          });
        }

        // cache values for reuse
        this.countryCache = output;

        return output;
      })
    );
  }

  getRegions(countryCode: string) {
    const cached = this.regionCache.get(countryCode);

    if (cached) {
      return of(cached);
    }

    const url = 'region/byCountryCode';
    const params = new HttpParams().append('countryCode', countryCode);

    return this.api
      .get<{ Type: string; Regions: IBoList<IRegion> }>(url, params)
      .pipe(
        map((res) => (res.payload.Regions.Items || []) as IRegion[]),
        map((items) => {
          if (items.length === 0) {
            return null;
          }
          
          const output: OptionConfig[] = items.map((item) => ({
            label: item.Blurb,
            value: item.Code,
          }));

          return sortBy(output, ['label']);
        }),
        tap((items) => this.regionCache.set(countryCode, items))
      );
  }

  getNationalIDs() {
    if (this.nationIdsCache) {
      return of(this.nationIdsCache);
    }

    return this.api.get<IBoList<INationalID>>('nationalIDs').pipe(
      map((res) => res.payload.Items),
      map((items) => {
        const output: OptionConfig[] = [];

        items.forEach((item) => {
          const countryCode = item.Code;
          const countryName = item.Blurb;

          item.NationalIDs.forEach((id) => {
            const value = id.Code;
            let label = id.Blurb ? countryName + ' - ' + id.Blurb : '';

            if (id.IssuingCountryDescription) {
              label += ` ${id.IssuingCountryDescription}`;
            }

            output.push({
              ...id,
              CountryCode: countryCode,
              CountryName: countryName,
              value,
              label,
            });
          });
        });

        return sortBy(output, ['label']);
      }),
      tap((items) => (this.nationIdsCache = items))
    );
  }
}
