<zc-modal>
  <zc-modal-header (dismiss)="onClose()">
    <h4 class="modal-title">{{ 'Common.lblSignHere' | translate }}</h4>
  </zc-modal-header>
  <zc-modal-body>
    <!-- use previous -->
    <div class="row" *ngIf="previousSignature">
      <div class="col-sm-6">
        <zc-checkbox
          [(ngModel)]="usePreviousSignature"
          (ngModelChange)="loadSignature($event)"
        >
          {{ 'Common.lblLoadPreviousSign' | translate }}
        </zc-checkbox>
      </div>
    </div>

    <!-- signature -->
    <div class="signature-area">
      <!-- <signature-pad
        #pad
        [options]="signaturePadOptions"
        (onEndEvent)="onDrawComplete()">
      </signature-pad> -->
      <div class="signature-area__actions">
        <zc-button
          color="link"
          (click)="clearSignature()"
          *ngIf="!usePreviousSignature"
        >
          {{ 'Common.btnClear' | translate }}
        </zc-button>
      </div>
    </div>

    <!-- authorization -->
    <form #form="ngForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-sm-6">
          <zc-form-field>
            <zc-label>
              {{
                (authorizationType === 'Pin'
                  ? 'Common.lblPin'
                  : 'Common.lblPassword'
                ) | translate
              }}
            </zc-label>
            <zc-input
              #input="ngModel"
              type="password"
              name="auth"
              required
              [(ngModel)]="authValue"
              [zcPassword]="authorizationType === 'Password'"
            >
            </zc-input>
            <zc-error *ngIf="message">{{ message }}</zc-error>
          </zc-form-field>
        </div>
      </div>
    </form>
  </zc-modal-body>
  <zc-modal-footer>
    <zc-button
      color="primary"
      type="submit"
      [busy]="busy"
      [disabled]="form.invalid || isSignatureEmpty"
      [form]="form"
    >
      {{ 'Common.cmdSubmit' | translate }}
    </zc-button>
  </zc-modal-footer>
</zc-modal>
